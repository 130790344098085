import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Text, View, TouchableOpacity } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { useNavigate } from "utils";

import images from "assets/images";
import { headerIcons } from "assets/icons";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { checkCart, createNewInvoice } from "../../redux/actions/home";
import { usePrevious } from "../../utils/hook";
import { setAxiosDefaultHeaders } from "../../configs/axiosDebugger";
import { Colors } from "utils";
import { logout } from "../../redux/actions/auth";
import { createNewEstimate } from "../../redux/actions/estimate";
import { Alert } from "../../App";
import { getInvoice } from "../../redux/actions/invoice";

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const [showHeader, setShowHeader] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [headerCartClick, setHeaderCartClick] = React.useState(false);

  const onPressNavigate = (pathname) => navigate(pathname);

  React.useEffect(() => {
    if (/\/login/.test(location.pathname)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    if (/^\/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [location]);

  const gradientColor = isAdmin
    ? ["#EF8545", "#5F9B89"]
    : ["#64B5B8", "#DF931C"];
  const logo = isAdmin ? images.admin_logo : images.logo;
  const routePrefix = isAdmin ? "/admin" : "";

  const prevCreatingNewInvoice = usePrevious(props.isCreatingNewInvoice);
  useEffect(() => {
    if (prevCreatingNewInvoice && !props.isCreatingNewInvoice) {
      if (props.isCreatedNewInvoice) {
        navigate("purchase-supplier", {
          new_invoice: props.newInvoice,
        });
      }
    }
  }, [props.isCreatingNewInvoice]);

  const favicon = getFaviconEl();
  favicon.href = isAdmin
    ? "/icon_kanehira_admin.png"
    : "/icon_kanehira_user.png";

  const appleIcon = getAppleIcon();
  appleIcon.href = isAdmin
    ? "/icon_kanehira_admin.png"
    : "/icon_kanehira_user.png";

  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  function getAppleIcon() {
    return document.getElementById("appleIcon");
  }

  const checkCart = () => {
    //mean: is_invoice_empty  =true when dont have invoice record or have atleast 1 invoice record and invoice have invoice-detail <=0
    if (props.cart.is_invoice_empty) {
      //go to [003-006/ 仕入リスト（空の状態）]
      navigate("cart", {
        invoice_id: "",
      });
    } else {
      setHeaderCartClick(true);
      navigate("buy-review", {
        // 003-002
        new_invoice: props.cart?.invoice,
      });

      navigate("cart", {
        invoice_id: props.cart?.invoice?.id, // we send invoice_id instead of send "new_invoice" object, because many flow go to this screen "cart"
      });
    }
  };

  const doLogout = () => {
    setAxiosDefaultHeaders({
      token: "",
      uuid: "",
    });
    props.logout({});
    navigate("");
  };

  useEffect(() => {
    props.checkCart();
  }, []);

  const showLogOutConfirmModal = () => {
    Alert.alert("", "ログアウトしますか?", [
      {
        label: "はい",
        primary: true,
        onPress: () => {
          doLogout();
          Alert.hideModal();
        },
      },
      {
        label: "いいえ",
        primary: false,
        onPress: () => {
          Alert.hideModal();
        },
      },
    ]);
  };
  // console.log("hung props?.cart?.invoice:", props?.cart?.invoice);
  const reloadPage = () => {
    window.location.reload(true);
  };
  const getCartCount = () => {
    if (props?.cart?.is_invoice_empty == false) {
      if (location.pathname.includes("cart-preview")) {
        var count = 0;
        const invoice_details = props?.cart?.invoice?.invoice_details;
        for (const [index, detail] of invoice_details.entries()) {
          if (detail.amount > 0 && detail.unit_price > 0) {
            count++;
          }
        }
        return count;
      } else {
        return props?.cart?.invoice.invoice_details?.length;
      }
    } else {
      return 0;
    }
  };
  return (
    showHeader && (
      <LinearGradient
        colors={gradientColor}
        start={isAdmin ? { x: 0.0, y: 0.2 } : { x: 0.0, y: 0.25 }}
        end={isAdmin ? { x: 0.5, y: 1.6 } : { x: 0.5, y: 1.0 }}
        locations={isAdmin ? [0, 0.6] : []}
      >
        <View style={styles.contentWrap}>
          <TouchableOpacity onPress={() => onPressNavigate("/")}>
            <Image source={logo} style={styles.logo} resizeMode={"contain"} />
          </TouchableOpacity>
          <View style={styles.rightWrap}>
            <TouchableOpacity
              style={styles.reloadButtonWrap}
              onPress={() => reloadPage()}
            >
              <Image source={headerIcons.reload} style={styles.reloadButton} />
            </TouchableOpacity>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text style={styles.name}>{props.account?.name}</Text>
                <TouchableOpacity onPress={() => showLogOutConfirmModal()}>
                  <Text style={styles.logoutButton}>ログアウト</Text>
                </TouchableOpacity>
              </View>
              {/* <Text style={styles.email}>{props.account?.email}</Text> */}
            </View>
            <TouchableOpacity
              onPress={() => onPressNavigate(routePrefix + "/account")}
              style={styles.button}
            >
              <Image source={headerIcons.profile} style={styles.buttonIcon} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => checkCart()} style={styles.button}>
              {getCartCount() > 0 ? <Badge number={getCartCount()} /> : <></>}
              <Image source={headerIcons.cart} style={styles.buttonIcon} />
            </TouchableOpacity>
          </View>
        </View>
      </LinearGradient>
    )
  );
};

const Badge = ({ number }) => {
  return (
    <View
      style={{
        backgroundColor: "red",
        borderRadius: 50,
        height: 15,
        width: 15,
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 1,
        marginRight: -5,
        marginTop: -2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ fontSize: 10, color: "#fff", alignSelf: "center" }}>
        {number}
      </Text>
    </View>
  );
};

const mapStateToProps = (state) => ({
  isCheckedCart: state.home.isCheckedCart,
  isCheckingCart: state.home.isCheckingCart,
  cart: state.home.cart,

  isCreatedNewInvoice: state.home.isCreatedNewInvoice,
  isCreatingNewInvoice: state.home.isCreatingNewInvoice,
  newInvoice: state.home.newInvoice,

  account: state.auth.account,

  isCreatedNewEstimate: state.estimate.isCreatedNewEstimate,
  isCreatingNewEstimate: state.estimate.isCreatingNewEstimate,
  newEstimate: state.estimate.newEstimate,

  isGettingInvoice: state.invoice.isGettingInvoice,
  isGotInvoice: state.invoice.isGotInvoice,
  invoice: state.invoice.invoice,

  isResettingCart: state.home.isResettingCart,
  isResetCartFinish: state.home.isResetCartFinish,
});
const mapDispatchToProps = {
  checkCart,
  createNewInvoice,
  createNewEstimate,
  logout,
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const styles = StyleSheet.create({
  reloadButtonWrap: {
    alignItems: "center",
    justifyContent: "center",
    marginEnd: 10,
  },
  contentWrap: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 20,
    paddingHorizontal: 15,
    paddingTop: 40,
  },
  logo: {
    width: 100,
    height: 55,
  },
  name: {
    alignSelf: "flex-end",
    fontWeight: "bold",
    color: "white",
    fontSize: "14pt",
    marginRight: "10pt",
  },
  email: {
    color: "white",
    fontSize: "12pt",
  },
  rightWrap: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    marginLeft: 10,
  },
  buttonIcon: {
    width: 50,
    height: 50,
  },
  reloadButton: {
    width: 20,
    height: 20,
  },
  logoutButton: {
    borderRadius: "20pt",
    backgroundColor: "white",
    color: Colors.Primary,
    fontSize: "10pt",
    paddingVertical: "4pt",
    paddingHorizontal: "5pt",
  },
});
