import { createRequestTypes } from "./utils";

export const UPDATE_CUSTOMER_ESTIMATE = createRequestTypes(
  "UPDATE_CUSTOMER_ESTIMATE"
);
export const updateCustomerEstimate = (params) => ({
  type: UPDATE_CUSTOMER_ESTIMATE.REQUEST,
  params,
});

export const GET_LAST_ESTIMATE = createRequestTypes("GET_LAST_ESTIMATE");
export const getLastEstimate = (params) => ({
  type: GET_LAST_ESTIMATE.REQUEST,
  params,
});

export const INSERT_PRODUCTS_TO_ESTIMATE = createRequestTypes(
  "INSERT_PRODUCTS_TO_ESTIMATE"
);
export const insertProductsToEstimate = (params) => ({
  type: INSERT_PRODUCTS_TO_ESTIMATE.REQUEST,
  params,
});

export const GET_ESTIMATE = createRequestTypes("GET_ESTIMATE");
export const getEstimate = (params) => ({
  type: GET_ESTIMATE.REQUEST,
  params,
});

export const UPDATE_ESTIMATE = createRequestTypes("UPDATE_ESTIMATE");
export const updateEstimate = (params) => ({
  type: UPDATE_ESTIMATE.REQUEST,
  params,
});

export const CREATE_NEW_ESTIMATE = createRequestTypes("CREATE_NEW_ESTIMATE");
export const createNewEstimate = (params) => ({
  type: CREATE_NEW_ESTIMATE.REQUEST,
  params,
});

export const DELETE_ESTIMATE_DETAIL = createRequestTypes(
  "DELETE_ESTIMATE_DETAIL"
);
export const deleteEstimateDetail = (params) => ({
  type: DELETE_ESTIMATE_DETAIL.REQUEST,
  params,
});

export const ESTIMATE_UPLOAD_PDF = createRequestTypes("ESTIMATE_UPLOAD_PDF");
export const uploadPdf = (params) => ({
  type: ESTIMATE_UPLOAD_PDF.REQUEST,
  params,
});

export const ESTIMATE_GENERATE_PDF = createRequestTypes(
  "ESTIMATE_GENERATE_PDF"
);
export const generatePdf = (params) => ({
  type: ESTIMATE_GENERATE_PDF.REQUEST,
  params,
});

export const DELETE_ESTIMATE = createRequestTypes("DELETE_ESTIMATE");
export const deleteEstimate = (params) => ({
  type: DELETE_ESTIMATE.REQUEST,
  params,
});

export const ESTIMATE_SCROLL_Y_OFFSET = "ESTIMATE_SCROLL_Y_OFFSET";
export const saveEstimateScrollYOffset = (params) => ({
  type: ESTIMATE_SCROLL_Y_OFFSET,
  params,
});

export const COPY_ESTIMATE = createRequestTypes("COPY_ESTIMATE");
export const copyEstimate = (params) => ({
  type: COPY_ESTIMATE.REQUEST,
  params,
});
