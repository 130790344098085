import React from "react";
import { StyleSheet, Image, TouchableOpacity } from "react-native";
import Block from "./Block";
import Button from "./Button";
import TextView from "./TextView";
import { defaultIcons, commonIcons } from "assets/icons";
import { Colors } from "utils";

const CheckBox = ({
  label = "",
  ratio = 0.8,
  size = 10,
  check = true,
  onCheckBoxPress,
  fontSize,
}) => {
  const _ratio = ratio;
  return (
    <Block direction="row" marginRight="5pt">
      <TouchableOpacity
        onPress={onCheckBoxPress}
        style={{ flexDirection: "row" }}
      >
        <Block
          height={`${size}pt`}
          width={`${size}pt`}
          borderWidth="1pt"
          borderRadius="2pt"
          borderColor={Colors.SecondPrimary}
          marginRight="2pt"
        >
          {check && (
            <Image
              style={{
                height: `${size * _ratio}pt`,
                width: `${size * _ratio}pt`,
                position: "absolute",
                top: "-2pt",
              }}
              source={defaultIcons.check}
            />
          )}
        </Block>
        {label && (
          <TextView
            size={fontSize ? fontSize : "9pt"}
            color={Colors.Primary}
            regular
          >
            {label}
          </TextView>
        )}
      </TouchableOpacity>
    </Block>
  );
};

export default CheckBox;

const styles = StyleSheet.create({});
