import React, { useState, useEffect } from "react";
import { StyleSheet, Dimensions, ScrollView } from "react-native";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import { getMarketPriceData } from "../../redux/actions/home";
import { connect } from "react-redux";
import { TouchableOpacity } from "react-native-web";
import { usePrevious } from "../../utils/hook";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const { width } = Dimensions.get("window");
const BUTTON_WIDTH = (width - 48) / 10;
const Tab2 = (props) => {
  const [marketPriceChartData, setMarketPriceChartData] = useState([]);
  const [selectedDayButton, setSelectedDayButton] = useState(2);
  const [hideLineChart, setHideLineChart] = useState({
    pt: false,
    pd: false,
    rh: false,
  });
  const prevGettingMarketPriceData = usePrevious(
    props.isGettingMarketPriceData
  );

  const setHideLineChartValue = (key) => {
    setHideLineChart((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    props.getMarketPriceData({
      days_ago: 14,
    });
  }, []);

  useEffect(() => {
    if (prevGettingMarketPriceData && !props.isGettingMarketPriceData) {
      if (props.isGotMarketPriceData) {
        const data = [];
        const data1 = props.marketPriceData;
        data1.map((item) => {
          data.push({
            date: formatDateTime(item.date, DateTimeFormatter.MM_DD),
            PT: item.pt_jpy,
            PD: item.pd_jpy,
            RH: item.rh_jpy,
          });
        });

        setMarketPriceChartData(data);
      }
    }
  }, [props.isGettingMarketPriceData]);

  const getMarketPriceData = (index, days_ago) => {
    setSelectedDayButton(index);
    props.getMarketPriceData({
      days_ago: days_ago,
    });
  };

  const SimpleBarChart = () => {
    return (
      <ResponsiveContainer width={"95%"} height={400}>
        <LineChart data={marketPriceChartData} width={"95%"} height={400}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: "10pt" }} />
          <YAxis
            tick={{ fontSize: "10pt" }}
            tickFormatter={(tick) => {
              return formatMoney(tick);
            }}
          />
          <Tooltip formatter={(label) => formatMoney(label)} />

          <Line
            dataKey="PT"
            label="PT"
            stroke="#058BBC"
            type="monotone"
            dot={false}
            hide={hideLineChart.pt}
          />
          <Line
            dataKey="PD"
            label="PD"
            stroke="#CCCC03"
            type="monotone"
            dot={false}
            hide={hideLineChart.pd}
          />
          <Line
            dataKey="RH"
            label="RH"
            stroke="#D136A7"
            type="monotone"
            dot={false}
            hide={hideLineChart.rh}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const Button = ({
    title,
    color,
    textColor = Colors.SecondPrimary,
    index,
    onPress,
  }) => (
    <Block
      width={color ? BUTTON_WIDTH : "10%"}
      paddingVertical="3pt"
      center
      borderWidth="1pt"
      borderColor={color ? color : Colors.SecondPrimary}
      color={color ? color : Colors.White}
      borderRadius="5pt"
      marginHorizontal="2pt"
      alignSelf="left"
    >
      <TouchableOpacity onPress={onPress}>
        <TextView size="11pt" color={textColor}>
          {title}
        </TextView>
      </TouchableOpacity>
    </Block>
  );
  return (
    <Block marginTop="20pt">
      <Block direction="row">
        <Button
          title="3日"
          color={0 === selectedDayButton ? "#E0F0F1" : ""}
          onPress={() => getMarketPriceData(0, 3)}
        />
        <Button
          title="7日"
          color={1 === selectedDayButton ? "#E0F0F1" : ""}
          onPress={() => getMarketPriceData(1, 7)}
        />
        <Button
          title="14日"
          color={2 === selectedDayButton ? "#E0F0F1" : ""}
          onPress={() => getMarketPriceData(2, 14)}
        />
        <Button
          title="30日"
          color={3 === selectedDayButton ? "#E0F0F1" : ""}
          onPress={() => getMarketPriceData(3, 30)}
        />
        <Button
          title="60日"
          color={4 === selectedDayButton ? "#E0F0F1" : ""}
          onPress={() => getMarketPriceData(4, 60)}
        />
        <Block block direction="row" justifyContent="flex-end">
          <Button
            title="PT"
            color={hideLineChart.pt ? "#ADADAD" : "#058BBC"}
            textColor="#fff"
            onPress={() => setHideLineChartValue("pt")}
          />
          <Button
            title="PD"
            color={hideLineChart.pd ? "#ADADAD" : "#CCCC03"}
            textColor="#fff"
            onPress={() => setHideLineChartValue("pd")}
          />
          <Button
            title="RH"
            color={hideLineChart.rh ? "#ADADAD" : "#D136A7"}
            textColor="#fff"
            onPress={() => setHideLineChartValue("rh")}
          />
        </Block>
      </Block>
      <Block marginTop="10pt" height={450} marginLeft="-10pt">
        <ScrollView>
          <SimpleBarChart />
        </ScrollView>
      </Block>
    </Block>
  );
};

const styles = StyleSheet.create({});

const mapStateToProps = (state) => ({
  marketPriceData: state.home.marketPriceData,
  isGettingMarketPriceData: state.home.isGettingMarketPriceData,
  isGotMarketPriceData: state.home.isGotMarketPriceData,
});
const mapDispatchToProps = {
  getMarketPriceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab2);
