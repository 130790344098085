export const INVOICE_STATUS = {
  PURCHASING: 0,
  PURCHASE_COMPLETED: 1,
  TRANSFER_REQUESTED: 2,
  PROCESSED: 3,
};

export const ESTIMATE_STATUS = {
  ESTIMATING: 0,
  ESTIMATE_COMPLETED: 1,
  SEND_REQUESTED: 2,
  PROCESSED: 3,
};

export const ESTIMATE_PIC_ATTACH = {
  NO_ATTACH: 0,
  ATTACHED: 1,
};

export const ESTIMATE_SEND_TYPE = {
  FAX: 0,
  EMAIL: 1,
};

export const QUOTATION_FLOW = {
  QUOTATION_REVIEW_NO_IMAGE: 0,
  QUOTATION_REVIEW_WITH_IMAGE: 1,
  SUBMIT_REQUEST: 2,
  FINISH_ESTIMATE: 3,
};

export const TRANSFER_TYPE = {
  ADVANCE_TRANSFER: 0,
  TRANSFER_WHEN_PURCHASE: 1,
};

export const ACCOUNT_LOGIN_AUTH = {
  MOBILE: 0,
  MOBILE_MANAGER: 1,
};

export const CART_UPDATE_INVOICE_BEHAVIOR = {
  PREVIEW_INVOICE: 1,
  SUBMIT_INVOICE: 2,
  UPDATE_INVOICE_ON_BLUR: 3,
};

export const ACCOUNT_STATUS_FILTER_DATA = [
  {
    name: "未処理のステータス",
    type: 0,
    status_name: "未処理",
  },
  {
    name: "すべてのステータス",
    type: 1,
    status_name: "",
  },
  {
    name: "処理済のステータス",
    type: 2,
    status_name: "処理済",
  },
  {
    name: "確認済のステータス",
    type: 3,
    status_name: "確認済",
  },
];

export const ACCOUNT_PREVIOUS_VOUCHER_TYPE = {
  INVOICE: 0,
  ESTIMATE: 1,
};

export const INFORMATION_TYPE = {
  TODAY: 0,
  OFFICE: 1,
};

export const STATUS_MANAGEMENT_RESULT_TYPE = {
  INVOICE: 0,
  ESTIMATE: 1,
  TRANSFER: 2,
};
