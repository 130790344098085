import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  FlatList,
} from "react-native";

import { Block } from "components";
import Input from "components/common/Input";
import DateInput from "components/common/DateInput";
import { Colors } from "utils";
import { useNavigate } from "utils";
import { searchCustomers } from "../../redux/actions/customer";
import { createTransfer } from "../../redux/actions/transfer";
import { connect } from "react-redux";
import { TRANSFER_TYPE } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { usePrevious } from "../../utils/hook";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import { MoneyFormatter, formatMoney } from "utils/MoneyFormatter";
import Title from "components/common/Title";

/**
 * 007-001 / 振込依頼
 * @param {*} props
 */
const TransferRequest = (props) => {
  const navigate = useNavigate();
  let transferAmount;
  const [
    sendTransferRequestValidation,
    setSendTransferRequestValidation,
  ] = useState({
    didChooseCustomer: undefined,
    didEnterTotalPrice: undefined,
    didSelectTransferType: undefined,
    didInputDate: undefined,
  });
  // const [transferListValidation, setTransferListValidation] = useState({
  //   didChooseCustomer: undefined,
  // });

  const [transfer, setTransfer] = useState({});

  const setTransferValue = (key, value) => {
    setTransfer((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const validateSendTransferRequest = () => {
    // setTransferListValidation({
    //   didChooseCustomer: undefined,
    // });

    setSendTransferRequestValidation({
      didChooseCustomer: transfer.selectedTransfer !== undefined,
      didEnterTotalPrice:
        transfer.total_price !== "" &&
        formatMoney(
          transfer.total_price,
          MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
        ) > 0,
      didSelectTransferType:
        transfer.transfer_type !== "" && transfer.transfer_type >= 0,
      didInputDate: transfer.date !== undefined && transfer.date !== "",
    });
  };

  const validateTransferList = () => {
    setSendTransferRequestValidation({
      didChooseCustomer: undefined,
      didEnterTotalPrice: undefined,
      didSelectTransferType: undefined,
      didInputDate: undefined,
    });

    // setTransferListValidation({
    //   didChooseCustomer: transfer.selectedTransfer !== undefined,
    // });
    navigate("transfer-request/list", {
      selectedCustomer: transfer.selectedTransfer,
    });
  };

  useEffect(() => {
    setTransferValue("selectedTransfer", undefined);
  }, [props.searchCustomerResults]);

  useEffect(() => {
    if (
      sendTransferRequestValidation.didChooseCustomer &&
      sendTransferRequestValidation.didEnterTotalPrice &&
      sendTransferRequestValidation.didSelectTransferType
    ) {
      props.createTransfer({
        date: transfer.date,
        limit_date:
          transfer.limit_date == undefined || transfer.limit_date == ""
            ? null
            : transfer.limit_date,
        customer_id: transfer.selectedTransfer.id,
        type: transfer.transfer_type,
        total_price: formatMoney(
          transfer.total_price,
          MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
        ),
        remark: transfer.remark,
      });
    }
  }, [sendTransferRequestValidation]);

  const prevCreatingTransfer = usePrevious(props.isCreatingTransfer);
  useEffect(() => {
    if (prevCreatingTransfer && !props.isCreatingTransfer) {
      if (props.isCreatedTransfer) {
        //go to  007-003 / 振込依頼・送信完了画面
        navigate("transfer-request/transfered", {
          createdTransfer: props.createdTransfer,
        });
      }
    }
  }, [props.isCreatingTransfer]);

  const checkDidChooseCustomer = () => {
    if (sendTransferRequestValidation.didChooseCustomer !== undefined) {
      return !sendTransferRequestValidation.didChooseCustomer;
    }
    // else if (transferListValidation.didChooseCustomer !== undefined) {
    //   return !transferListValidation.didChooseCustomer;
    // }
  };

  // useEffect(() => {
  //   if (transferListValidation.didChooseCustomer) {
  //     navigate("transfer-request/list", {
  //       selectedCustomer: transfer.selectedTransfer,
  //     });
  //   }
  // }, [transferListValidation]);

  const searchCustomerHandler = (searchName) => {
    props.searchCustomers({
      name: searchName,
    });
  };

  return (
    <View style={styles.wrap}>
      <Title title={"振込依頼"} />
      <View style={styles.dateWrap}>
        <View
          style={{
            flex: 1,
            marginRight: 20,
            zIndex: 1,
          }}
        >
          <DateInput
            label={"取引日"}
            containerStyle={[
              sendTransferRequestValidation.didInputDate !== undefined &&
                !sendTransferRequestValidation.didInputDate && {
                  borderWidth: 1,
                  borderColor: "red",
                },
              { padding: "5pt" },
            ]}
            onDateChange={(date) => {
              setTransferValue(
                "date",
                formatDateTime(date, DateTimeFormatter.YYYY_MM_DD_HH_MM_HYPHEN)
              );
            }}
            imageStyles={{ height: "24pt", width: "24pt" }}
          />
        </View>
        <View style={{ flex: 1 }}>
          <DateInput
            label={"振込期日"}
            onDateChange={(date) => {
              setTransferValue(
                "limit_date",
                formatDateTime(date, DateTimeFormatter.YYYY_MM_DD_HH_MM_HYPHEN)
              );
            }}
            imageStyles={{ height: "24pt", width: "24pt" }}
            containerStyle={{ padding: "5pt" }}
          />
        </View>
      </View>

      <View>
        <InputWithSuggestion
          placeHolder={""}
          label={"取引先検索"}
          fetchApi={(searchName) => searchCustomerHandler(searchName)}
          data={props.searchCustomerResults}
          onSelectedItem={(item) => setTransferValue("selectedTransfer", item)}
          inputStyle={
            checkDidChooseCustomer() && { borderColor: "red", borderWidth: 1 }
          }
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <View style={{ flex: 1 }}>
          <Input
            label={"振込金額"}
            hideIcon
            tailText={"円"}
            textInputStyle={{ textAlign: "right" }}
            value={transfer.total_price}
            onChangeText={(value) =>
              setTransferValue("total_price", formatMoney(value))
            }
            wrapStyle={
              sendTransferRequestValidation.didEnterTotalPrice !== undefined &&
              !sendTransferRequestValidation.didEnterTotalPrice && {
                borderColor: "red",
              }
            }
            keyboardType={"phone-pad"}
            refHandler={(input) => (transferAmount = input)}
            selectTextOnFocus
            onFocus={transferAmount?.focus()}
          />
        </View>
        <View
          style={[
            { paddingLeft: 20 },
            sendTransferRequestValidation.didSelectTransferType !== undefined &&
              !sendTransferRequestValidation.didSelectTransferType && {
                borderColor: "red",
                borderWidth: 1,
              },
          ]}
        >
          <Text style={styles.radioTitle}>振込内容</Text>
          <View style={[styles.radio, {}]}>
            <TouchableOpacity
              onPress={() => {
                setTransferValue(
                  "transfer_type",
                  TRANSFER_TYPE.ADVANCE_TRANSFER
                );
              }}
              style={styles.btnCheck}
            >
              {transfer?.transfer_type === TRANSFER_TYPE.ADVANCE_TRANSFER ? (
                <Block
                  color="black"
                  height={"6pt"}
                  width={"6pt"}
                  borderRadius={"5pt"}
                />
              ) : (
                <Block width={"5pt"} height={"5pt"} />
              )}
            </TouchableOpacity>
            <label
              for={"tt-advance"}
              style={{ color: Colors.Primary, fontSize: "12pt" }}
            >
              事前振込
            </label>
          </View>
          <View style={styles.radio}>
            <TouchableOpacity
              onPress={() => {
                setTransferValue(
                  "transfer_type",
                  TRANSFER_TYPE.TRANSFER_WHEN_PURCHASE
                );
              }}
              style={styles.btnCheck}
            >
              {transfer?.transfer_type ===
              TRANSFER_TYPE.TRANSFER_WHEN_PURCHASE ? (
                <Block
                  color="black"
                  height={"6pt"}
                  width={"6pt"}
                  borderRadius={"5pt"}
                />
              ) : (
                <Block width={"5pt"} height={"5pt"} />
              )}
            </TouchableOpacity>
            <label
              for={"tt-paid"}
              style={{ color: Colors.Primary, fontSize: "12pt" }}
            >
              仕入時振込
            </label>
          </View>
        </View>
      </View>
      <View>
        <Input
          label={"備  考"}
          hideIcon
          onChangeText={(value) => setTransferValue("remark", value)}
        />
      </View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity
          // onPress={() => navigate('transfer-request/transfered')}
          onPress={() => validateSendTransferRequest()}
          style={{ flex: 1, paddingVertical: "12pt", marginRight: 10 }}
        >
          <View style={styles.btn}>
            <Text style={styles.btnTitle}>振込依頼を送信</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => validateTransferList()}
          style={{ flex: 1, paddingVertical: "12pt" }}
        >
          <View style={styles.btn}>
            <Text style={styles.btnTitle}>振込依頼一覧</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const fakeData = [];
for (let i = 0; i < 10; i++)
  fakeData.push(
    {
      key: `item_0_${i}`,
      label: "有限会社カネヒラ商会",
    },
    {
      key: `item_1_${i}`,
      label: "金田金属株式会社",
    },
    {
      key: `item_2_${i}`,
      label: "兼光解体",
    },
    {
      key: `item_3_${i}`,
      label: "吉金興業",
    },
    {
      key: `item_4_${i}`,
      label: "鷲尾金属工業株式会社",
    }
  );

const styles = StyleSheet.create({
  wrap: {
    paddingBottom: 20,
    paddingHorizontal: "24pt",
  },
  dateWrap: {
    zIndex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  btn: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    paddingHorizontal: 23,
    paddingVertical: "12pt",
    borderRadius: 5,
    alignItems: "center",
  },
  btnTitle: {
    color: "white",
    fontSize: "13pt",
  },
  list: {
    width: "90%",
    height: 200,
    alignSelf: "flex-end",
    borderWidth: 1,
    borderColor: Colors.SecondPrimary,
    borderRadius: 5,
    zIndex: -1,
  },
  listItem: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
  },
  radio: {
    flexDirection: "row",
    marginTop: 5,
    alignItems: "center",
  },
  radioTitle: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  btnCheck: {
    borderWidth: 1,
    borderColor: Colors.Primary,
    padding: 4,
    borderRadius: "8pt",
    marginRight: "5pt",
  },
});

const mapStateToProps = (state) => ({
  searchCustomerResults: state.customer.searchCustomerResults,
  isCreatingTransfer: state.transfer.isCreatingTransfer,
  isCreatedTransfer: state.transfer.isCreatedTransfer,
  createdTransfer: state.transfer.createdTransfer,
});
const mapDispatchToProps = {
  searchCustomers,
  createTransfer,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequest);
