import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Back from "components/common/Back";
import { Colors, nor } from "utils";
import { Block } from "components";
import { useHistory } from "react-router-dom";
import {
  formatMoney,
  MoneyFormatter,
  validateInteger,
  formatInvoiceDetailAmount,
} from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  getTransferCustomers,
  updateTransfer,
} from "../../redux/actions/transfer";
import {
  updateInvoice,
  createTransferInvoice,
  generatePdf,
} from "../../redux/actions/invoice";
import { connect } from "react-redux";
import { INVOICE_STATUS } from "../../utils/Constant";
import { useNavigate } from "utils";
import DateInput from "components/common/DateInput";
import validator from "validator";
import { TransferView, InputAmountView } from "components/cart";
import { Alert } from "../../App";
import { usePrevious } from "../../utils/hook";
import { checkCart } from "../../redux/actions/home";
import Title from "components/common/Title";

/**
 * //screen: 003-004-01 / 仕切書プレビュー
 */
const Preview = ({ ...props }) => {
  const history = useHistory();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState(
    history?.location?.state?.invoice
  );
  const { use_transfer } = history?.location?.state;
  const [isFinishSubmit, setIsFinishSubmit] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [limitDate, setLimitDate] = useState(undefined);
  const [inputAmountChange, setInputAmountChange] = useState({
    type: -1,
    amount: 0,
  }); //0: transfer amount change, 1: cash amount change
  const transfer = use_transfer ? props.transferCustomers[0] : undefined;

  useEffect(() => {
    props.checkCart();
    if (use_transfer) {
      props.getTransferCustomers({
        customer_id: invoiceData?.customer_info?.id,
        customer_name: "",
      });
    } else {
    }
  }, []);

  useEffect(() => {
    if (inputAmountChange.type == 0) {
      // tranfer amount change
      const formatedAmount = formatMoney(
        inputAmountChange.amount,
        MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
      );
      const transferTotalPrice =
        transfer !== undefined ? transfer?.total_price : 0;
      const cash =
        +invoiceData?.total_price - +formatedAmount - +transferTotalPrice;

      setCashAmount(formatMoney(cash));
      setTransferAmount(formatMoney(formatedAmount));
    } else if (inputAmountChange.type == 1) {
      const formatedAmount = formatMoney(
        inputAmountChange.amount,
        MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
      );

      const transferTotalPrice =
        transfer !== undefined ? transfer?.total_price : 0;
      const transferAmount =
        +invoiceData?.total_price - +formatedAmount - +transferTotalPrice;
      setTransferAmount(formatMoney(transferAmount));
      setCashAmount(formatMoney(formatedAmount));
    }
  }, [inputAmountChange]);

  const updateInvoice = () => {
    const invoice_details = invoiceData?.invoice_details.map((item) => {
      return {
        id: item.id,
        product_id: item.product_info?.id,
        amount: item.amount,
        price: item.price,
        unit_price: item.unit_price,
        total_price: item.total_price,
      };
    });

    const updateInvoice = {
      id: invoiceData.id,
      price: invoiceData.price,
      tax: invoiceData.tax,
      total_price: invoiceData.total_price,
      status: INVOICE_STATUS.PURCHASE_COMPLETED,
      invoice_details: invoice_details,
      transfer_price: formatMoney(
        transferAmount,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      cash_price: formatMoney(
        cashAmount,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      limit_date:
        limitDate === undefined || limitDate === "" ? null : limitDate,
      update_type: 1,
      use_transfer: use_transfer,
      is_submit: true,
    };
    props.updateInvoice(updateInvoice);
  };

  const updateTransfer = () => {
    if (transfer !== undefined) {
      props.updateTransfer({
        id: transfer?.id,
        invoice_id: invoiceData?.id,
      });
    }
    const formatedTransferAmount = formatMoney(
      transferAmount,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );

    if (formatedTransferAmount !== undefined && formatedTransferAmount > 0) {
      const transferModel = {
        limit_date:
          limitDate === undefined || limitDate === "" ? null : limitDate,
        customer_id: invoiceData?.customer_info?.id,
        invoice_id: invoiceData?.id,
        total_price: formatedTransferAmount,
      };
      props.createTransferInvoice(transferModel);
    }
  };

  const submit = () => {
    //Update record into table invoices, with invoices.status = 1
    // Update record into table invoice_details, with invoice_details.invoice_id = invoices.code
    // set invoice_details.name = products.name
    // Update record into table transfers, with transfer.invoice_id = invoices.code
    // Go to [003-005-1 / 仕切書プレビュー・振込依頼完了画面]
    if (checkHaveAtLeaseOneInvoiceDetail()) {
      if (checkTotalEqualPayment()) {
        if (checkHaveTransferDateWhenHaveTransferAmount()) {
          updateInvoice();
          updateTransfer();
          setIsFinishSubmit(true);
        }
      }
    }
  };

  const checkInvoiceDetailEmpty = () => {
    const invoice_details = invoiceData.invoice_details;
    if (
      invoice_details === undefined ||
      invoice_details === null ||
      invoice_details?.length <= 0
    ) {
      return true;
    } else {
      for (const [index, detail] of invoice_details.entries()) {
        if (detail.amount > 0 && detail.unit_price > 0) {
          return false;
        }
      }
      return true;
    }
  };

  const checkHaveAtLeaseOneInvoiceDetail = () => {
    if (checkInvoiceDetailEmpty()) {
      Alert.alert("", "製品が選択されていません。", [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]);
      return false;
    } else {
      return true;
    }
  };

  const checkHaveTransferDateWhenHaveTransferAmount = () => {
    const formatTransferAmount = formatMoney(
      transferAmount,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );
    if (
      formatTransferAmount > 0 &&
      (limitDate === undefined || limitDate === "")
    ) {
      Alert.alert("", "振込期日を設定してください。", [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]);
      return false;
    }
    return true;
  };
  const checkTotalEqualPayment = () => {
    const total_price = invoiceData.total_price;
    const formatCashAmount = formatMoney(
      cashAmount,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );
    const formatTransferAmount = formatMoney(
      transferAmount,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );
    const alreadyTransferAmount =
      transfer?.total_price === undefined ? 0 : transfer?.total_price;

    if (
      total_price >
      +alreadyTransferAmount + +formatTransferAmount + +formatCashAmount
    ) {
      Alert.alert("", "支払金額の内訳を入力してください", [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]);
      return false;
    }
    return true;
  };

  const goToAccount = () => {
    //Go to [002-001 / アカウント]
    navigate("account");
  };

  const goToHomeScreen = () => {
    //Go to [001-001 / TOP（貴金属相場）]
    navigate("");
  };

  const prevUpdatingInvoice = usePrevious(props.isUpdatingInvoice);
  useEffect(() => {
    if (prevUpdatingInvoice && !props.isUpdatingInvoice) {
      if (props.isUpdatedInvoice) {
        props.checkCart();
        props.generatePdf({
          id: invoiceData.id,
          use_transfer: use_transfer,
        });
      }
    }
  }, [props.isUpdatingInvoice]);

  return (
    <View style={{ paddingBottom: 20, paddingHorizontal: "24pt" }}>
      <Title backTitle={"仕入リスト"} title="仕切書プレビュー" />
      <View style={{}}>
        <Text style={styles.description}>
          取引日：
          {formatDateTime(invoiceData?.date)}
        </Text>
        <Text style={styles.description}>
          取引先コード：{invoiceData?.customer_info?.code}
        </Text>
        <Text style={styles.description}>
          取引先名：{invoiceData?.customer_info?.name}
        </Text>
        <Text style={styles.description}>
          担当者：{invoiceData?.account_info?.name}
        </Text>
      </View>

      <View style={styles.tableTitle}>
        <Text style={styles.subDesc}>取引コード：{invoiceData?.code}</Text>
      </View>

      <Row
        isHeader
        name={"品名・分類"}
        amount={"数 量"}
        unit_price={"仕入単価"}
        price={"金 額"}
      />
      <ScrollView style={{ height: 220 }}>
        {invoiceData?.invoice_details?.map(
          (item, index) =>
            item?.amount > 0 &&
            item.unit_price > 0 && (
              <Row
                key={index}
                name={item?.name}
                code={item?.product_info?.code}
                amount={formatInvoiceDetailAmount(item?.amount)}
                unit_price={formatMoney(item?.unit_price)}
                price={formatMoney(item?.total_price)}
                forceSolid={index === invoiceData?.invoice_details?.length - 1}
              />
            )
        )}
      </ScrollView>
      <View
        style={{
          width: "100%",
          marginTop: 2,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: Colors.SecondPrimary,
          borderStyle: "solid",
        }}
      />

      <View style={styles.sumWrap}>
        <SumRow
          name={"小 計（税別）"}
          value={formatMoney(invoiceData?.price) + "円"}
        />
        <SumRow name={"消費税"} value={formatMoney(invoiceData?.tax) + "円"} />
        <SumRow
          name={"合  計（税込）"}
          value={formatMoney(invoiceData?.total_price) + "円"}
        />
      </View>

      {isFinishSubmit ? (
        <FinishSubmitView
          alreadyTransferAmount={transfer?.total_price}
          transferAmount={transferAmount}
          cashAmount={cashAmount}
          goToAccount={goToAccount}
          goToHomeScreen={goToHomeScreen}
        />
      ) : (
        <InputAmountView
          transfer={transfer}
          invoiceData={invoiceData}
          setInputAmountChange={setInputAmountChange}
          transferAmount={transferAmount}
          cashAmount={cashAmount}
          submit={submit}
          setLimitDate={setLimitDate}
        />
      )}
    </View>
  );
};

const Row = ({
  name,
  code,
  amount,
  unit_price,
  price,
  isHeader,
  forceSolid,
}) => {
  const fontWeight = isHeader ? "bold" : "normal";
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: Colors.SecondPrimary,
        borderBottomWidth: 1,
        borderStyle: isHeader || forceSolid ? "solid" : "dashed",
      }}
    >
      <View style={{ flex: 2, paddingVertical: 5 }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {name} {isHeader ? "" : "【" + code + "】"}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "center" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? amount : amount}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "center" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? unit_price : unit_price + "円"}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "flex-end" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? price : price + "円"}
        </Text>
      </View>
    </View>
  );
};

const FinishSubmitView = ({
  alreadyTransferAmount,
  transferAmount,
  cashAmount,
  goToAccount,
  goToHomeScreen,
}) => {
  const formatedTransferAmount = formatMoney(
    transferAmount,
    MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
  );
  const haveTransfer =
    formatedTransferAmount !== undefined && formatedTransferAmount > 0;
  return (
    <View>
      {haveTransfer && (
        <View>
          <TransferView transferAmount={alreadyTransferAmount} />
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <View>
                <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                  口座振込
                </Text>
              </View>
              <View style={[styles.row, { alignItems: "center" }]}>
                <View style={styles.row}>
                  <Text style={styles.amountText}>
                    {formatMoney(
                      transferAmount,
                      MoneyFormatter.ZERO_DECIMAL_FORMAT
                    )}
                  </Text>
                </View>
                <Text style={styles.smallText}> 円</Text>
              </View>
            </View>
            <View style={{ width: 32 }} />
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <View>
                <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                  小口現金
                </Text>
              </View>
              <View style={[styles.row, { alignItems: "center" }]}>
                <View style={styles.row}>
                  <Text style={styles.amountText}>
                    {formatMoney(
                      cashAmount,
                      MoneyFormatter.ZERO_DECIMAL_FORMAT
                    )}
                  </Text>
                </View>
                <Text style={styles.smallText}> 円</Text>
              </View>
            </View>
          </View>
        </View>
      )}

      <Block
        borderWidth="3pt"
        borderColor={Colors.SecondPrimary}
        paddingTop="5pt"
        paddingBottom="5pt"
        marginTop="40pt"
        marginLeft="50pt"
        marginRight="50pt"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Text
          style={{
            color: Colors.Primary,
            fontWeight: "bold",
            fontSize: "12pt",
          }}
        >
          {haveTransfer ? "振込依頼を送信しました。" : "査定を完了しました。"}
        </Text>
      </Block>

      <View
        style={{
          flexDirection: "row",
          paddingVertical: 25,
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {haveTransfer && (
          <TouchableOpacity
            style={[styles.finishButton, { marginRight: 10 }]}
            onPress={goToAccount}
          >
            <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
              ステータスを確認
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={goToHomeScreen}
          style={[styles.finishButton]}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            TOPへ
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const SumRow = ({ name, value }) => (
  <View style={{ flexDirection: "row", marginBottom: 5 }}>
    <View style={{ flex: 2 }} />
    <View style={{ flex: 1 }}>
      <Text style={[styles.smallText, { fontWeight: "bold" }]}>{name}</Text>
    </View>
    <View style={{ flex: 1, paddingLeft: 10, alignItems: "flex-end" }}>
      <Text style={styles.smallText}>{value}</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: "15pt",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "10pt",
    fontSize: "18pt",
  },
  description: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "13pt",
  },
  tableTitle: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
  subDesc: {
    color: Colors.Primary,
    marginTop: "10pt",
    fontSize: "12pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 5,
  },
  cellText: {
    fontWeight: "bold",
    color: Colors.Primary,
  },
  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  button: {
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    paddingHorizontal: "64pt",
    alignItems: "center",
    borderRadius: 5,
  },
  buttonWrap: {
    paddingTop: "32pt",
    paddingBottom: 5,
    alignItems: "center",
  },
  smallText: {
    fontSize: "12pt",
    color: Colors.Primary,
  },
  largeText: {
    fontSize: "18pt",
    fontWeight: "bold",
    color: Colors.Primary,
  },
  sumWrap: {
    paddingVertical: "8pt",
    borderColor: Colors.Primary,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  finishButton: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    alignItems: "center",
    borderRadius: 5,
  },
  amountText: {
    fontSize: 18,
    color: Colors.Primary,
    fontWeight: "bold",
  },
});

const mapStateToProps = (state) => ({
  transferCustomers: state.transfer.transferCustomers,
  isUpdatingInvoice: state.invoice.isUpdatingInvoice,
  isUpdatedInvoice: state.invoice.isUpdatedInvoice,
});
const mapDispatchToProps = {
  getTransferCustomers,
  updateTransfer,
  updateInvoice,
  createTransferInvoice,
  checkCart,
  generatePdf,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
