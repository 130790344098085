import React from "react";
import { View, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  block: {
    flex: 1,
  },
});

const Block = ({
  block,
  margin,
  padding,
  paddingHorizontal,
  paddingVertical,
  marginHorizontal,
  marginVertical,
  width,
  height,
  border,
  borderWidth,
  borderColor,
  centered,
  color,
  style,
  direction,
  middle,
  children,
  justifyContent,
  alignItems,
  borderRadius,
  shadow,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  flex,
  center,
  paddingTop,
  paddingLeft,
  paddingBottom,
  paddingRight,
  borderRightWidth,
  borderLeftWidth,
  borderBottomWidth,
  borderTopWidth,
  borderTopColor,
  borderBottomColor,
  borderStyle,
  alignSelf,
  position,
  bottom,
  top,
  left,
  right,
  ...props
}) => {
  const styleComponent = StyleSheet.flatten([
    block && styles.block,
    margin && { margin },
    padding && { padding },
    width && { width },
    height && { height },
    border && { borderWidth: 1, borderColor: "gray" },
    borderWidth && { borderWidth },
    borderColor && { borderColor },
    color && { backgroundColor: color },
    centered && { justifyContent: "center" },
    justifyContent && { justifyContent },
    direction && { flexDirection: direction ? "row" : "column" },
    paddingVertical && { paddingVertical },
    paddingHorizontal && { paddingHorizontal },
    middle && { alignItems: "center" },
    borderRadius && { borderRadius },
    marginTop && { marginTop: marginTop },
    flex && { flex },
    center && { alignItems: "center", justifyContent: "center" },
    shadow && {
      shadowOpacity: 0.12,
      shadowRadius: 15,
      shadowColor: "gray",
      shadowOffset: { height: 0, width: 0 },
    },
    paddingTop && { paddingTop },
    paddingLeft && { paddingLeft },
    paddingBottom && { paddingBottom },
    paddingRight && { paddingRight },
    marginLeft && { marginLeft },
    marginRight && { marginRight },
    marginBottom && { marginBottom },
    alignSelf && { alignSelf },
    alignItems && { alignItems },
    marginHorizontal && { marginHorizontal },
    marginVertical && { marginVertical },
    borderRightWidth && { borderRightWidth },
    borderLeftWidth && { borderLeftWidth },
    position && { position },
    bottom && { bottom },
    borderBottomWidth && { borderBottomWidth },
    borderTopWidth && { borderTopWidth },
    borderTopColor && { borderTopColor },
    borderBottomColor && { borderBottomColor },
    borderStyle && { borderStyle },
    top && { top },
    left && { left },
    right && { right },
    style,
  ]);
  return (
    <View style={styleComponent} {...props}>
      {children}
    </View>
  );
};

export default Block;
