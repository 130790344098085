import { createRequestTypes } from "./utils";

export const GET_ACCOUNT_CART = createRequestTypes("GET_ACCOUNT_CART");
export const getAccountCart = (params) => ({
  type: GET_ACCOUNT_CART.REQUEST,
  params,
});

export const GET_STATUS_MANAGEMENT = createRequestTypes(
  "GET_STATUS_MANAGEMENT"
);
export const getStatusManagement = (params) => ({
  type: GET_STATUS_MANAGEMENT.REQUEST,
  params,
});

export const SEARCH_ACCOUNT_VOUCHER = createRequestTypes(
  "SEARCH_ACCOUNT_VOUCHER"
);
export const searchAccountVoucher = (params) => ({
  type: SEARCH_ACCOUNT_VOUCHER.REQUEST,
  params,
});
