import { createRequestTypes } from "./utils";

export const GET_CUSTOMERS = createRequestTypes("GET_CUSTOMERS");
export const getCustomers = (params) => ({
  type: GET_CUSTOMERS.REQUEST,
  params,
});

export const SEARCH_CUSTOMERS = createRequestTypes("SEARCH_CUSTOMERS");
export const searchCustomers = (params) => ({
  type: SEARCH_CUSTOMERS.REQUEST,
  params,
});

export const SEARCH_CUSTOMER_TRANSFER = createRequestTypes(
  "SEARCH_CUSTOMER_TRANSFER"
);
export const searchCustomerTransfer = (params) => ({
  type: SEARCH_CUSTOMER_TRANSFER.REQUEST,
  params,
});

export const GET_CUSTOMER_TRANSFER = createRequestTypes(
  "GET_CUSTOMER_TRANSFER"
);
export const getCustomerTransfer = (params) => ({
  type: GET_CUSTOMER_TRANSFER.REQUEST,
  params,
});
