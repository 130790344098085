import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";

const InsertProductModal = ({ justInsertProductList }) => {
  return (
    <View>
      <Text>製品を追加しました</Text>
      <ScrollView style={{ maxHeight: 200, marginTop: 5 }}>
        {justInsertProductList?.map((item, index) => {
          return <Text key={`${index}`}>{item.name}</Text>;
        })}
      </ScrollView>
    </View>
  );
};

export default InsertProductModal;
