import React, { useRef } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  PanResponder,
  Animated,
  Image,
} from "react-native";
import { Colors } from "utils";
import { useNavigate } from "utils";
import CheckBox from "../common/CheckBox";
import { defaultIcons } from "assets/icons";
import { ESTIMATE_STATUS, ESTIMATE_PIC_ATTACH } from "../../utils/Constant";
import validator from "validator";
import {
  formatMoney,
  MoneyFormatter,
  validateInteger,
} from "../../utils/MoneyFormatter";

const Package = ({
  onTrash,
  estimate,
  estimate_detail,
  estimateDetailIndex,
  unitPriceOnBlur,
  unitPrice2OnBlur,
  onUnitPriceChange,
  onUnitPrice2Change,
  onNameChange,
  nameOnBlur,
  onImageCheckChange,

  ...props
}) => {
  let unitPriceInput;
  let unitPrice2Input;
  const navigate = useNavigate();

  const gotoEstimateDetail = () => {
    //go to 003-003-2 / 製品詳細
    // onGoToEstimateDetail();
    const data = estimate;
    const detail = data?.estimate_details?.map((item) => {
      return {
        ...item,
        onImageAttachCheck: null,
        onUnitPriceChange: null,
        onUnitPrice2Change: null,
        onNameChange: null,
      };
    });

    data.estimate_details = detail;
    navigate("/quotation-list/detail", {
      estimate: data,
      estimateDetailIndex: estimateDetailIndex,
    });
  };

  const validateValueWithCommas = (value, handler) => {
    const formatValue = formatMoney(
      value,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );
    if (validateInteger(formatValue)) {
      handler(formatMoney(value));
    }
  };

  return (
    <View style={styles.wrap} {...props}>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
        <TouchableOpacity
          onPress={() => gotoEstimateDetail()}
          style={[styles.titleWrap]}
        >
          <View style={{ paddingRight: 14 }}>
            <Text style={{ fontSize: "12pt", color: Colors.Primary }}>
              品 名
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: "14pt", color: Colors.Primary }}>
              {estimate_detail?.product_info?.name}【
              {estimate_detail?.product_info?.code}】
            </Text>
          </View>
        </TouchableOpacity>
        <View
          style={{
            position: "absolute",
            right: "5pt",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: "12pt", color: Colors.Primary }}>
            表示名
          </Text>
          <View style={{}}>
            <TextInput
              style={styles.textInput}
              value={estimate_detail.name}
              onChangeText={(value) => {
                // estimate_detail.onNameChange(value);
                onNameChange(value);
              }}
              onBlur={nameOnBlur}
            />
          </View>
          <TouchableOpacity
            onPress={() => onTrash(estimate_detail)}
            style={styles.btnTrash}
          >
            <Image
              resizeMode="contain"
              source={defaultIcons.trash}
              style={styles.imgTrash}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: 1,
          backgroundColor: Colors.SecondPrimary,
        }}
      ></View>
      <View style={{ flexDirection: "row", padding: 10, alignItems: "center" }}>
        <View style={{ flex: 1 }}>
          <CheckBox
            label={"画像を添付"}
            check={estimate_detail.pic_attach === ESTIMATE_PIC_ATTACH.ATTACHED}
            onCheckBoxPress={() => {
              // estimate_detail.onImageAttachCheck(estimate_detail.pic_attach);
              onImageCheckChange(estimate_detail.pic_attach);
            }}
            fontSize={"12pt"}
          />
        </View>
        <View style={styles.containerInput}>
          <View>
            <Text style={styles.fieldName}>仕入単価</Text>
          </View>
          <View style={{ flex: 1, alignItems: "center", flexDirection: "row" }}>
            <TextInput
              style={styles.textInput}
              value={formatMoney(estimate_detail.unit_price2)}
              onChangeText={(value) => {
                validateValueWithCommas(value, onUnitPrice2Change);
              }}
              onBlur={unitPrice2OnBlur}
              keyboardType={"phone-pad"}
              ref={(input) => (unitPrice2Input = input)}
              selectTextOnFocus
              onFocus={unitPrice2Input?.focus()}
            />
            <Text> ~ </Text>
            <TextInput
              style={styles.textInput}
              value={formatMoney(estimate_detail.unit_price)}
              onChangeText={(value) => {
                validateValueWithCommas(value, onUnitPriceChange);
              }}
              onBlur={unitPriceOnBlur}
              keyboardType={"phone-pad"}
              ref={(input) => (unitPriceInput = input)}
              selectTextOnFocus
              onFocus={unitPriceInput?.focus()}
            />
          </View>
          <Text
            style={{
              color: Colors.Primary,
              marginLeft: 3,
              fontSize: 15,
            }}
          >
            円
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  textInput: {
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
    textAlign: "right",
    width: 100,
  },
  fieldName: {
    fontSize: "12pt",
    color: Colors.SecondPrimary,
    fontWeight: "bold",
  },
  wrapSwipable: {
    height: "100%",
    width: 100,
    position: "absolute",
    right: -100,
    alignItems: "center",
    justifyContent: "center",
  },
  btnTrash: {
    padding: "5pt",
    backgroundColor: "red",
    borderRadius: 50,
    marginHorizontal: 5,
  },
  imgTrash: { height: "12pt", width: "12pt" },
  containerInput: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
  },
});

export default Package;
