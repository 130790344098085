import React from "react";
import { TouchableOpacity, Image, View } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import CatalogCheckBox from "./CatalogCheckBox";
// import {
//   LazyLoadImage,
//   LazyLoadComponent,
// } from "react-lazy-load-image-component";
// import OnImagesLoaded from "react-on-images-loaded";
const Card = ({
  index,
  product,
  onPress,
  onCheckBoxPress,
  isShowCheckBox,
  onImageLoaded,
  onImageTimeout,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        borderRadius: 5,
      }}
    >
      {/* <View
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={{
            uri: product?.pic_path,
          }}
          style={{ height: "50vh", width: "100%" }}
          resizeMode="contain"
        />
      </View> */}
      <Block direction="row" marginHorizontal="20pt" marginTop="10pt">
        <Block block direction="row">
          <Block
            center
            color={Colors.Primary}
            paddingHorizontal="2pt"
            paddingVertical="1pt"
            marginRight="3pt"
          >
            <TextView size="12pt" color={Colors.White} bold>
              {product?.code}
            </TextView>
          </Block>
          <Block center marginVertical="5pt">
            <TextView size="12pt" regular color={Colors.Primary}>
              {product?.maker_info?.name}
            </TextView>
          </Block>
        </Block>
        <Block center marginVertical="5pt">
          <TextView size="13pt" regular color={Colors.Primary}>
            {product?.name}
          </TextView>
        </Block>
      </Block>
      {isShowCheckBox && (
        <Block marginHorizontal="20pt" marginVertical="5pt">
          <CatalogCheckBox
            label="追加"
            check={product?.is_check}
            onCheckBoxPress={onCheckBoxPress}
          />
        </Block>
      )}
      <View style={{ marginHorizontal: 20 }}>
        <View
          style={{
            width: "100%",
            height: 1,
            backgroundColor: Colors.Primary,
            marginTop: 20,
          }}
        ></View>
      </View>
    </TouchableOpacity>
  );
};

export default Card;
