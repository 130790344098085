import React, { useState, useEffect } from "react";
import { StyleSheet, Image, ScrollView } from "react-native";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import { ColorSpread } from "./Tab1";
import { getAverageMarketPrice } from "../../redux/actions/home";
import { usePrevious } from "../../utils/hook";
import { connect } from "react-redux";
import { defaultIcons } from "assets/icons";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";

const Tab3 = (props) => {
  const [data, setData] = useState([]);
  const prevGettingAverageMarketPrice = usePrevious(
    props.isGettingAverageMarketPrice
  );

  useEffect(() => {
    props.getAverageMarketPrice();
  }, []);

  useEffect(() => {
    if (prevGettingAverageMarketPrice && !props.isGettingAverageMarketPrice) {
      if (props.isGotAverageMarketPrice) {
        const spread_pt =
          props.averageMarketPrice?.last?.avg_pt -
          props.averageMarketPrice?.last_two_week?.avg_pt;

        const spread_pt_percentage =
          (spread_pt / props.averageMarketPrice?.last?.avg_pt) * 100;

        const spread_pd =
          props.averageMarketPrice?.last?.avg_pd -
          props.averageMarketPrice?.last_two_week?.avg_pd;

        const spread_pd_percentage =
          (spread_pd / props.averageMarketPrice?.last?.avg_pd) * 100;

        const spread_rh =
          props.averageMarketPrice?.last?.avg_rh -
          props.averageMarketPrice?.last_two_week?.avg_rh;

        const spread_rh_percentage =
          (spread_rh / props.averageMarketPrice?.last?.avg_rh) * 100;

        const d = [
          {
            title: "PT",
            title_jpy: "プラチナ",
            last_week_monday: props.averageMarketPrice?.last?.monday_date,
            last_week_friday: props.averageMarketPrice?.last?.friday_date,
            last_week_average: props.averageMarketPrice?.last?.avg_pt,

            last_two_week_monday:
              props.averageMarketPrice?.last_two_week?.monday_date,
            last_two_week_friday:
              props.averageMarketPrice?.last_two_week?.friday_date,
            last_two_week_average:
              props.averageMarketPrice?.last_two_week?.avg_pt,
            spread_amount: spread_pt,
            spread_percentage: spread_pt_percentage,
          },
          {
            title: "PD",
            title_jpy: "パラジウム",
            last_week_monday: props.averageMarketPrice?.last?.monday_date,
            last_week_friday: props.averageMarketPrice?.last?.friday_date,
            last_week_average: props.averageMarketPrice?.last?.avg_pd,

            last_two_week_monday:
              props.averageMarketPrice?.last_two_week?.monday_date,
            last_two_week_friday:
              props.averageMarketPrice?.last_two_week?.friday_date,
            last_two_week_average:
              props.averageMarketPrice?.last_two_week?.avg_pd,
            spread_amount: spread_pd,
            spread_percentage: spread_pd_percentage,
          },
          {
            title: "RH",
            title_jpy: "ロジウム",
            last_week_monday: props.averageMarketPrice?.last?.monday_date,
            last_week_friday: props.averageMarketPrice?.last?.friday_date,
            last_week_average: props.averageMarketPrice?.last?.avg_rh,

            last_two_week_monday:
              props.averageMarketPrice?.last_two_week?.monday_date,
            last_two_week_friday:
              props.averageMarketPrice?.last_two_week?.friday_date,
            last_two_week_average:
              props.averageMarketPrice?.last_two_week?.avg_rh,
            spread_amount: spread_rh,
            spread_percentage: spread_rh_percentage,
          },
        ];

        setData(d);
      }
    }
  }, [props.isGettingAverageMarketPrice]);

  const Item = ({ item, index }) => {
    return (
      <Block
        block
        marginTop="10pt"
        marginBottom="10pt"
        paddingBottom="20pt"
        style={index !== 2 && [styles.dash]}
        centered
      >
        <Block direction="row">
          <Block width="18%">
            <TextView
              bold
              size="42pt"
              color={Colors.Primary}
              center
              lineHeight={0}
              style={{ lineHeight: "30pt" }}
            >
              {item.title}
            </TextView>
            <Block marginTop={"8pt"}>
              <TextView center regular size="10pt" color={Colors.Primary}>
                {item.title_jpy}
              </TextView>
            </Block>
          </Block>
          <Block width="42%" justifyContent="space-between">
            <Block direction="row">
              <TextView
                backgroundColor={Colors.Primary}
                regular
                size="9pt"
                color={Colors.White}
                paddingVertical="1pt"
                paddingHorizontal="3pt"
              >
                前週
              </TextView>
              <Block block />
            </Block>
            <TextView regular size="9pt" color={Colors.Primary}>
              {formatDateTime(
                item.last_week_monday,
                DateTimeFormatter.MM_DD_JP
              )}
              （月）〜
              {formatDateTime(
                item.last_week_friday,
                DateTimeFormatter.MM_DD_JP
              )}
              （金）
            </TextView>
            <Block direction="row">
              <TextView
                backgroundColor={Colors.Primary}
                regular
                size="9pt"
                color={Colors.White}
                paddingVertical="1pt"
                paddingHorizontal="3pt"
              >
                前々週
              </TextView>
              <Block block />
            </Block>
            <TextView regular size="9pt" color={Colors.Primary}>
              {formatDateTime(
                item.last_two_week_monday,
                DateTimeFormatter.MM_DD_JP
              )}
              （月）〜
              {formatDateTime(
                item.last_two_week_friday,
                DateTimeFormatter.MM_DD_JP
              )}
              （金）
            </TextView>
          </Block>
          <Block width="17%">
            <Block
              flex={0.5}
              direction="row"
              color={`${Colors.Primary}20`}
              borderWidth={StyleSheet.hairlineWidth}
              borderColor={`${Colors.Primary}20`}
            >
              <Block block height="100%" paddingTop="3pt" paddingLeft="3pt">
                <TextView regular size="6pt" color={Colors.Primary}>
                  平 均
                </TextView>
              </Block>
              <Block flex={2} center>
                <TextView regular size="12pt" color={Colors.Primary}>
                  {formatMoney(item.last_week_average)}
                </TextView>
              </Block>
            </Block>
            <Block
              flex={0.5}
              marginTop="5pt"
              direction="row"
              color={`${Colors.Primary}20`}
              borderWidth={StyleSheet.hairlineWidth}
              borderColor={`${Colors.Primary}20`}
            >
              <Block block height="100%" paddingTop="3pt" paddingLeft="3pt">
                <TextView regular size="6pt" color={Colors.Primary}>
                  平 均
                </TextView>
              </Block>
              <Block flex={2} center>
                <TextView regular size="12pt" color={Colors.Primary}>
                  {formatMoney(item.last_two_week_average)}
                </TextView>
              </Block>
            </Block>
          </Block>
          <Block direction="row" width="25%">
            <Block justifyContent="space-around" paddingHorizontal="5pt">
              <TextView regular size="10pt" color={Colors.Primary}>
                円/g
              </TextView>
              <TextView regular size="10pt" color={Colors.Primary}>
                円/g
              </TextView>
            </Block>
            <Block block center color="white">
              <Image
                style={{ height: "25pt", width: "25pt" }}
                source={
                  item.spread_amount > 0
                    ? defaultIcons.arrow_up
                    : defaultIcons.arrow_down
                }
              />
              <TextView
                regular
                size="10pt"
                color={ColorSpread(item.spread_amount)}
              >
                {item.spread_amount > 0 ? "+" : ""}
                {formatMoney(item.spread_amount)} 円
              </TextView>
              <TextView
                regular
                size="10pt"
                color={ColorSpread(item.spread_percentage)}
              >
                {item.spread_percentage > 0 ? "+" : ""}
                {formatMoney(
                  item.spread_percentage,
                  MoneyFormatter.TWO_DECIMAL_FORMAT
                )}{" "}
                %
              </TextView>
            </Block>
          </Block>
        </Block>
      </Block>
    );
  };

  return (
    <Block block paddingBottom="20pt" marginTop="10pt">
      <ScrollView>
        {data.map((item, index) => (
          <Item key={`${index}`} {...{ item, index }} />
        ))}
      </ScrollView>
    </Block>
  );
};

const mapStateToProps = (state) => ({
  isGettingAverageMarketPrice: state.home.isGettingAverageMarketPrice,
  isGotAverageMarketPrice: state.home.isGotAverageMarketPrice,
  averageMarketPrice: state.home.averageMarketPrice,
});
const mapDispatchToProps = {
  getAverageMarketPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab3);

const styles = StyleSheet.create({
  dash: {
    borderStyle: "dashed",
    borderBottomWidth: 1,
    borderBottomColor: Colors.SecondPrimary,
  },
});
