import React from "react";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import Button from "components/common/Button";
import { TouchableOpacity } from "react-native-web";

const PreviewItem = ({
  isHeader = false,
  value1 = "",
  value2 = "",
  value3 = "",
  value4,
  blocks = [3, 1, 1, 1],
  centers = ["left", "left", "left", "left"],
  onPress,
  onValue1Press,
  onValue2Press,
  onValue3Press,
  onValue4Press,
}) => {
  return (
    <Block
      direction="row"
      borderBottomWidth="1pt"
      borderBottomColor={Colors.Primary}
      borderStyle={isHeader ? "solid" : "dashed"}
      paddingVertical="7pt"
    >
      <Block flex={blocks[0]}>
        <TouchableOpacity
          onPress={() => {
            if (onValue1Press !== undefined) onValue1Press();
          }}
        >
          <TextView
            size={isHeader ? "11pt" : "11pt"}
            color={Colors.Primary}
            style={{
              fontWeight: isHeader ? "bold" : "400",
              textAlign: centers[0],
            }}
          >
            {value1}
          </TextView>
        </TouchableOpacity>
      </Block>
      <Block flex={blocks[1]}>
        <TouchableOpacity
          onPress={() => {
            if (onValue2Press !== undefined) onValue2Press();
          }}
        >
          <TextView
            size={isHeader ? "11pt" : "11pt"}
            color={Colors.Primary}
            style={{
              fontWeight: isHeader ? "bold" : "400",
              textAlign: centers[1],
            }}
          >
            {value2}
          </TextView>
        </TouchableOpacity>
      </Block>
      <Block flex={blocks[2]}>
        <TouchableOpacity
          onPress={() => {
            if (onValue3Press !== undefined) onValue3Press();
          }}
        >
          <TextView
            size={isHeader ? "11pt" : "11pt"}
            color={Colors.Primary}
            style={{
              fontWeight: isHeader ? "bold" : "400",
              textAlign: centers[2],
            }}
          >
            {value3}
          </TextView>
        </TouchableOpacity>
      </Block>
      <Block flex={blocks[3]}>
        <TouchableOpacity
          onPress={() => {
            if (onValue4Press !== undefined) onValue4Press();
          }}
        >
          {React.isValidElement(value4) ? (
            value4
          ) : (
            <TextView
              size={isHeader ? "11pt" : "11pt"}
              color={Colors.Primary}
              style={{
                fontWeight: isHeader ? "bold" : "400",
                textAlign: centers[3],
              }}
            >
              {value4}
            </TextView>
          )}
        </TouchableOpacity>
      </Block>
    </Block>
  );
};

export default PreviewItem;
