import React, { useState, useEffect } from "react";
import { Image, ScrollView, View, Text } from "react-native";
import "react-datepicker/dist/react-datepicker.css";
import { Block, TextView, Button } from "components";
import {
  ListSaved,
  SectionHeader,
  PastQuote,
  AccountStatusCart,
} from "components/account";
import { Colors, useNavigate } from "utils";
import { defaultIcons } from "assets/icons";
import {
  getAccountCart,
  getStatusManagement,
} from "../../redux/actions/account";

import { deleteEstimate } from "../../redux/actions/estimate";
import { connect } from "react-redux";
import {
  ACCOUNT_STATUS_FILTER_DATA,
  ACCOUNT_PREVIOUS_VOUCHER_TYPE,
} from "../../utils/Constant";

import { usePrevious } from "../../utils/hook";
import Title from "components/common/Title";

/**
 * 002-001 / アカウント
 * @param {*} props
 */
const Account = (props) => {
  const navigate = useNavigate();
  const [showSelect, setShowSelect] = useState(false);
  const [showInvoiceCanlendar, setShowInvoiceCanlendar] = useState(false);
  const [showEstimateCanlendar, setShowEstimateCanlendar] = useState(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState(
    ACCOUNT_STATUS_FILTER_DATA[0]
  );

  useEffect(() => {
    props.getAccountCart();
  }, []);

  const onSelected = (filterItem) => {
    setSelectedFilterItem(filterItem);
    setShowSelect((prev) => !prev);
  };
  useEffect(() => {
    props.getStatusManagement({
      filter_type: selectedFilterItem.type,
    });
  }, [selectedFilterItem]);
  const onAccountInvoicePress = () => {
    navigate("purchase-supplier", {});
    navigate("buy-review", {
      new_invoice: props.accountCart?.account_invoice,
    });

    navigate("cart", {
      invoice_id: props.accountCart?.account_invoice?.id,
    });
  };

  const prevDeletingEstimate = usePrevious(props.isDeletingEstimate);
  useEffect(() => {
    if (prevDeletingEstimate && !props.isDeletingEstimate) {
      if (props.isDeletedEstimate) {
        props.getAccountCart();
      }
    }
  }, [props.isDeletingEstimate]);

  // console.log("hung showCanlendar:", showCanlendar);
  return (
    <Block paddingBottom={20} paddingHorizontal={"24pt"}>
      <Title title="マイアカウント" />
      <SectionHeader title="保存中のリスト" />
      <ListSaved
        title={"仕入リスト"}
        cart={props.accountCart?.account_invoice}
        onRecordPress={() => onAccountInvoicePress()}
      />

      <ListSaved
        title={"見積書"}
        cart={props.accountCart?.account_estimate}
        onRecordPress={(item) => {
          // console.log("hung item:", item);
          navigate("quotation-list", {
            estimate_id: item?.id,
          });
        }}
        onDeletePress={(item) => props.deleteEstimate({ estimate_id: item.id })}
      />
      <SectionHeader title="過去の仕切書" />
      <View style={{ zIndex: showInvoiceCanlendar ? 2 : 1 }}>
        <PastQuote
          onSearch={(fromDate, toDate, customerName) => {
            navigate("past-dividers", {
              title: "仕切書一覧",
              type: ACCOUNT_PREVIOUS_VOUCHER_TYPE.INVOICE,
              from_date: fromDate,
              to_date: toDate,
              customer_name: customerName,
            });
          }}
          onPressNavigate={() =>
            navigate("past-dividers", { title: "仕切書一覧" })
          }
          onCalendarOpen={() => {
            setShowInvoiceCanlendar(true);
            setShowEstimateCanlendar(false);
          }}
        />
      </View>
      <SectionHeader title="過去の見積書" />
      <View style={{ zIndex: showEstimateCanlendar ? 2 : 1 }}>
        <PastQuote
          onSearch={(fromDate, toDate, customerName) => {
            navigate("past-dividers", {
              title: "見積書一覧",
              type: ACCOUNT_PREVIOUS_VOUCHER_TYPE.ESTIMATE,
              from_date: fromDate,
              to_date: toDate,
              customer_name: customerName,
            });
          }}
          onCalendarOpen={() => {
            setShowInvoiceCanlendar(false);
            setShowEstimateCanlendar(true);
          }}
        />
      </View>
      <Block direction="row" style={{ zIndex: 1 }}>
        <Block block>
          <TextView paddingVertical={8} size={13} color={Colors.Primary} bold>
            ステータス管理
          </TextView>
        </Block>
        <Button
          block
          onPress={() => setShowSelect((prev) => !prev)}
          style={{ flex: 1.5 }}
        >
          <Block
            flex={1.5}
            borderWidth={1}
            borderRadius={5}
            borderColor={Colors.SecondPrimary}
            direction="row"
            justifyContent="space-between"
            paddingHorizontal={10}
            middle
          >
            <TextView size="10pt" color={Colors.Primary} regular>
              {selectedFilterItem.name}
            </TextView>
            <Image
              source={defaultIcons.caret_down}
              style={{ height: 10, width: 15 }}
            />
          </Block>
        </Button>
        <Block block />
      </Block>
      <Block direction="row" style={{ zIndex: 1 }}>
        <Block block />
        <Block flex={1.5}>
          {showSelect && <Select onSelected={onSelected} />}
        </Block>
        <Block block />
      </Block>
      <Block
        borderWidth={2}
        borderColor={Colors.SecondPrimary}
        height={150}
        marginTop={10}
        style={{ borderRightWidth: 0, borderLeftWidth: 0 }}
      >
        <ScrollView>
          {props.statusManagementList?.map((item, key) => (
            <Block key={`${key}`}>
              <AccountStatusCart
                statusItem={item}
                patterns={key < 1 ? 1 : 2}
                title="未処理"
                content="振込依頼：2020年5月25日・兼光解体"
              />
            </Block>
          ))}
        </ScrollView>
      </Block>
    </Block>
  );
};

// export default Account;

const mapStateToProps = (state) => ({
  accountCart: state.account.accountCart,
  statusManagementList: state.account.statusManagementList,
  isDeletingEstimate: state.estimate.isDeletingEstimate,
  isDeletedEstimate: state.estimate.isDeletedEstimate,
});
const mapDispatchToProps = {
  getAccountCart,
  getStatusManagement,
  deleteEstimate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

const Select = ({ onSelected }) => {
  return (
    <Block
      position="absolute"
      height="122pt"
      borderWidth="1pt"
      borderColor={Colors.SecondPrimary}
      borderRadius="3pt"
      width="100%"
      color={Colors.White}
      bottom="-122pt"
      style={{
        zIndex: 1,
      }}
    >
      <ScrollView>
        {ACCOUNT_STATUS_FILTER_DATA.map((item, index) => (
          <Button
            key={`${index}`}
            onPress={() => {
              onSelected(item);
            }}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.SecondPrimary,
              paddingVertical: 10,
            }}
          >
            <TextView size="10pt" color={Colors.Primary} regular>
              {item.name}
            </TextView>
          </Button>
        ))}
      </ScrollView>
    </Block>
  );
};
