import {
  SEARCH_PRODUCTS,
  UPDATE_PRODUCT,
  SEARCH_PRODUCT_CATALOG,
  SAVE_SEARCH_PRODUCT_CATALOG,
  UPDATE_SEARCH_PRODUCT_CATALOG_RESULT_LIST,
  GET_PRODUCT_CATALOG_DETAIL,
  CLEAR_SEARCH_PRODUCT_CATALOG,
  KEEP_SEARCH_PRODUCT_CATALOG,
} from "redux/actions/product";
import { processPagingProductCatalog } from "./utils";

const initialState = {
  isSearchingProduct: false,
  isSearchProductFinish: false,
  products: [],
  errorMsg: "",
  isUpdatingProduct: false,
  isUpdatedProduct: false,
  updatedProduct: {},

  isSearchingProductCatalog: false,
  isSearchedProductCatalog: false,
  searchProductCatalogNextPage: 1,
  searchProductCatalogReachEnd: false,
  searchProductCatalogResult: [],
  searchProductCatalogResultCount: 0,

  savedSearchProductCatalog: {
    productName: "",
    selectedMaker: undefined,
  },

  isGettingProductCatalogDetail: false,
  isGotProductCatalogDetail: false,
  productCatalogDetail: undefined,

  keepSearchProductCatalog: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_PRODUCTS.REQUEST:
      return {
        ...state,
        isSearchProductFinish: false,
        isSearchingProduct: true,
        products: [],
      };

    case SEARCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        isSearchProductFinish: true,
        isSearchingProduct: false,
        products: action.result,
      };

    case SEARCH_PRODUCTS.FAIL:
      return {
        ...state,
        isSearchProductFinish: false,
        isSearchingProduct: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case UPDATE_PRODUCT.REQUEST:
      return {
        ...state,
        isUpdatingProduct: true,
        isUpdatedProduct: false,
        updatedProduct: {},
      };

    case UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        isUpdatingProduct: false,
        isUpdatedProduct: true,
        updatedProduct: action.result,
      };

    case UPDATE_PRODUCT.FAIL:
      return {
        ...state,
        isUpdatingProduct: false,
        isUpdatedProduct: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SEARCH_PRODUCT_CATALOG.REQUEST:
      return {
        ...state,
        isSearchingProductCatalog: true,
        isSearchedProductCatalog: false,
        updatedProduct: {},
      };

    case SEARCH_PRODUCT_CATALOG.SUCCESS:
      return {
        ...state,
        isSearchingProductCatalog: false,
        isSearchedProductCatalog: true,
        // searchProductCatalogResult: action.result,

        ...processPagingProductCatalog(
          "searchProductCatalogResult",
          state,
          action.result,
          "searchProductCatalogNextPage",
          "searchProductCatalogReachEnd",
          "searchProductCatalogResultCount"
        ),
      };

    case SEARCH_PRODUCT_CATALOG.FAIL:
      return {
        ...state,
        isSearchingProductCatalog: false,
        isSearchedProductCatalog: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case UPDATE_SEARCH_PRODUCT_CATALOG_RESULT_LIST:
      return {
        ...state,
        searchProductCatalogResult: action.params.searchProductCatalogResult,
      };

    case GET_PRODUCT_CATALOG_DETAIL.REQUEST:
      return {
        ...state,
        isGettingProductCatalogDetail: true,
        isGotProductCatalogDetail: false,
      };

    case GET_PRODUCT_CATALOG_DETAIL.SUCCESS:
      return {
        ...state,
        isGettingProductCatalogDetail: false,
        isGotProductCatalogDetail: true,
        productCatalogDetail: action.result,
      };

    case GET_PRODUCT_CATALOG_DETAIL.FAIL:
      return {
        ...state,
        isGettingProductCatalogDetail: false,
        isGotProductCatalogDetail: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SAVE_SEARCH_PRODUCT_CATALOG:
      return {
        ...state,
        savedSearchProductCatalog: {
          productName: action.params.productName,
          selectedMaker: action.params.selectedMaker,
        },
      };

    case CLEAR_SEARCH_PRODUCT_CATALOG:
      return {
        ...state,
        searchProductCatalogResult: state.keepSearchProductCatalog
          ? state.searchProductCatalogResult
          : [],
        savedSearchProductCatalog: state.keepSearchProductCatalog
          ? state.savedSearchProductCatalog
          : {
              productName: "",
              selectedMaker: undefined,
            },
      };
    case KEEP_SEARCH_PRODUCT_CATALOG:
      return {
        ...state,
        keepSearchProductCatalog: action.params?.isKeep,
      };

    default:
      return state;
  }
}
