import { createRequestTypes } from "./utils";

export const GET_METAL_MARKET_PRICE = createRequestTypes(
  "GET_METAL_MARKET_PRICE"
);
export const getMetalMarketPrice = (params) => ({
  type: GET_METAL_MARKET_PRICE.REQUEST,
  params,
});

export const RESET_CART = createRequestTypes("RESET_CART");
export const reset_cart = (params) => ({ type: RESET_CART.REQUEST, params });

export const CHECK_CART = createRequestTypes("CHECK_CART");
export const checkCart = (params) => ({ type: CHECK_CART.REQUEST, params });

export const CREATE_NEW_INVOICE = createRequestTypes("CREATE_NEW_INVOICE");
export const createNewInvoice = (params) => ({
  type: CREATE_NEW_INVOICE.REQUEST,
  params,
});

export const GET_MARKET_PRICE_DATA = createRequestTypes(
  "GET_MARKET_PRICE_DATA"
);
export const getMarketPriceData = (params) => ({
  type: GET_MARKET_PRICE_DATA.REQUEST,
  params,
});

export const GET_AVERAGE_MARKET_PRICE = createRequestTypes(
  "GET_AVERAGE_MARKET_PRICE"
);
export const getAverageMarketPrice = (params) => ({
  type: GET_AVERAGE_MARKET_PRICE.REQUEST,
  params,
});
