import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import Back from "components/common/Back";
import { Colors, nor } from "utils";
import { Block } from "components";
import { useHistory } from "react-router-dom";
import {
  formatMoney,
  MoneyFormatter,
  validateInteger,
  formatInvoiceDetailAmount,
} from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  getTransferCustomers,
  updateTransfer,
} from "../../redux/actions/transfer";
import {
  updateInvoice,
  createTransferInvoice,
  generatePdf,
  getInvoice,
} from "../../redux/actions/invoice";
import { connect } from "react-redux";
import { INVOICE_STATUS } from "../../utils/Constant";
import { useNavigate } from "utils";
import DateInput from "components/common/DateInput";
import validator from "validator";
import { TransferView, InputAmountView } from "components/cart";
import { Alert } from "../../App";
import { usePrevious } from "../../utils/hook";
import { checkCart } from "../../redux/actions/home";
import Title from "components/common/Title";

/**
 * //screen: 002-002-1 / 仕切書プレビュー
 */
const InvoiceReview = ({ ...props }) => {
  const history = useHistory();
  const navigate = useNavigate();
  const { invoice_id } = history?.location?.state;

  const [sortedInvoiceDetail, setSortedInvoiceDetail] = useState([]);
  useEffect(() => {
    props.getInvoice({
      invoice_id: invoice_id,
    });
  }, []);

  const prevGettingInvoice = usePrevious(props.isGettingInvoice);
  useEffect(() => {
    if (prevGettingInvoice && !props.isGettingInvoice) {
      if (props.isGotInvoice) {
        props.getTransferCustomers({
          customer_id: props.invoice?.customer_info?.id,
          customer_name: "",
        });
        const data = props.invoice?.invoice_details;
        setSortedInvoiceDetail(data);
      }
    }
  }, [props.isGettingInvoice]);

  return (
    <View style={{ paddingBottom: 20, paddingHorizontal: "24pt" }}>
      <Title backTitle={"仕切書一覧"} title="仕切書プレビュー" />
      <View style={{ paddingVertical: 10 }}>
        <Text style={styles.description}>
          取引日：
          {formatDateTime(props.invoice?.date)}
        </Text>
        <Text style={styles.description}>
          取引先コード：{props.invoice?.customer_info?.code}
        </Text>
        <Text style={styles.description}>
          取引先名：{props.invoice?.customer_info?.name}
        </Text>
        <Text style={styles.description}>
          担当者：{props.invoice?.account_info?.name}
        </Text>
      </View>

      <View style={styles.tableTitle}>
        <Text style={styles.subDesc}>取引コード：{props.invoice?.code}</Text>
      </View>
      <Row
        isHeader
        name={"品名・分類"}
        amount={"数 量"}
        unit_price={"仕入単価"}
        price={"金 額"}
      />
      {sortedInvoiceDetail?.map(
        (item, index) =>
          item?.amount > 0 &&
          item.unit_price > 0 && (
            <Row
              key={index}
              name={item?.name}
              code={item?.product_info?.code}
              amount={formatInvoiceDetailAmount(item?.amount)}
              unit_price={formatMoney(item?.unit_price)}
              price={formatMoney(item?.total_price)}
              forceSolid={index === props.invoice?.invoice_details?.length - 1}
            />
          )
      )}
      <View
        style={{
          width: "100%",
          marginTop: 2,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: Colors.SecondPrimary,
          borderStyle: "solid",
        }}
      />

      <View style={styles.sumWrap}>
        <SumRow
          name={"小 計（税別）"}
          value={formatMoney(props.invoice?.price) + "円"}
        />
        <SumRow
          name={"消費税"}
          value={formatMoney(props.invoice?.tax) + "円"}
        />
        <SumRow
          name={"合  計（税込）"}
          value={formatMoney(props.invoice?.total_price) + "円"}
        />
      </View>

      <FinishSubmitView
        alreadyTransferAmount={props.transferCustomers[0]?.total_price}
        transferAmount={props.invoice?.transfer_price}
        cashAmount={props.invoice?.cash_price}
      />
    </View>
  );
};

const Row = ({
  name,
  code,
  amount,
  unit_price,
  price,
  isHeader,
  forceSolid,
}) => {
  const fontWeight = isHeader ? "bold" : "normal";
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: Colors.SecondPrimary,
        borderBottomWidth: 1,
        borderStyle: isHeader || forceSolid ? "solid" : "dashed",
      }}
    >
      <View style={{ flex: 2, paddingVertical: 5 }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {name} {isHeader ? "" : "【" + code + "】"}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "center" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? amount : amount}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "center" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? unit_price : unit_price + "円"}
        </Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 5, alignItems: "flex-end" }}>
        <Text style={[styles.smallText, { fontWeight }]}>
          {isHeader ? price : price + "円"}
        </Text>
      </View>
    </View>
  );
};

const FinishSubmitView = ({
  alreadyTransferAmount,
  transferAmount,
  cashAmount,
}) => {
  //   const haveTransfer = transferAmount !== undefined && transferAmount > 0;
  return (
    <View>
      <View>
        <TransferView transferAmount={alreadyTransferAmount} />
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
            <View>
              <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                口座振込
              </Text>
            </View>
            <View style={[styles.row, { alignItems: "center" }]}>
              <View style={styles.row}>
                <Text style={styles.amountText}>
                  {formatMoney(
                    transferAmount,
                    MoneyFormatter.ZERO_DECIMAL_FORMAT
                  )}
                </Text>
              </View>
              <Text style={styles.smallText}> 円</Text>
            </View>
          </View>
          <View style={{ width: 32 }} />
          <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
            <View>
              <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                小口現金
              </Text>
            </View>
            <View style={[styles.row, { alignItems: "center" }]}>
              <View style={styles.row}>
                <Text style={styles.amountText}>
                  {formatMoney(cashAmount, MoneyFormatter.ZERO_DECIMAL_FORMAT)}
                </Text>
              </View>
              <Text style={styles.smallText}> 円</Text>
            </View>
          </View>
        </View>
      </View>

      {/* <Block
        borderWidth="3pt"
        borderColor={Colors.SecondPrimary}
        paddingTop="5pt"
        paddingBottom="5pt"
        marginTop="40pt"
        marginLeft="50pt"
        marginRight="50pt"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Text style={{ color: Colors.Primary, fontWeight: "bold" }}>
          {haveTransfer ? "振込依頼を送信しました。" : "査定を完了しました。"}
        </Text>
      </Block> */}
      {/* <View
        style={{
          flexDirection: "row",
          paddingVertical: 32,
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {haveTransfer && (
          <TouchableOpacity
            style={[styles.finishButton, { marginRight: 10 }]}
            onPress={() => goToAccount}
          >
            <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
              ステータスを確認
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={goToHomeScreen}
          style={[styles.finishButton]}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            TOPへ
          </Text>
        </TouchableOpacity>
      </View> */}
    </View>
  );
};

const SumRow = ({ name, value }) => (
  <View style={{ flexDirection: "row", marginBottom: 5 }}>
    <View style={{ flex: 2 }} />
    <View style={{ flex: 1 }}>
      <Text style={[styles.smallText, { fontWeight: "bold" }]}>{name}</Text>
    </View>
    <View style={{ flex: 1, paddingLeft: 10, alignItems: "flex-end" }}>
      <Text style={styles.smallText}>{value}</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: "15pt",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "10pt",
    fontSize: "18pt",
  },
  description: {
    color: Colors.Primary,
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  tableTitle: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
  subDesc: {
    color: Colors.Primary,
    marginTop: "10pt",
    fontSize: "12pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 5,
  },
  cellText: {
    fontWeight: "bold",
    color: Colors.Primary,
  },
  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  button: {
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    paddingHorizontal: "64pt",
    alignItems: "center",
    borderRadius: 5,
  },
  buttonWrap: {
    paddingTop: "32pt",
    paddingBottom: 5,
    alignItems: "center",
  },
  smallText: {
    fontSize: "12pt",
    color: Colors.Primary,
  },
  largeText: {
    fontSize: "18pt",
    fontWeight: "bold",
    color: Colors.Primary,
  },
  sumWrap: {
    paddingVertical: "10pt",
    borderColor: Colors.Primary,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  finishButton: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    alignItems: "center",
    borderRadius: 5,
  },
  amountText: {
    fontSize: 18,
    color: Colors.Primary,
    fontWeight: "bold",
  },
});

const mapStateToProps = (state) => ({
  transferCustomers: state.transfer.transferCustomers,
  isUpdatingInvoice: state.invoice.isUpdatingInvoice,
  isUpdatedInvoice: state.invoice.isUpdatedInvoice,

  isGettingInvoice: state.invoice.isGettingInvoice,
  isGotInvoice: state.invoice.isGotInvoice,
  invoice: state.invoice.invoice,
});
const mapDispatchToProps = {
  getTransferCustomers,
  updateTransfer,
  updateInvoice,
  createTransferInvoice,
  checkCart,
  generatePdf,
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReview);
