import React, { useState } from "react";
import { Block, Empty, TextView, FooterButton, WrapperBlock } from "components";
import { Colors } from "utils";
import { data, data1 } from "./fakePartitionPreview";
import { PreviewItem } from "components/partition-preview";
import { ScrollView } from "react-native-web";
import Title from "components/common/Title";
const PartitionReview = () => {
  const [empty, setEmpty] = useState(false);
  return (
    <WrapperBlock>
      <Title backTitle={"TOP"} title="仕入リスト" />
      {empty ? (
        <Empty />
      ) : (
        <Block block>
          <Block block paddingTop="15pt">
            <Block>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                取引日：2020年5月25日
              </TextView>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                取引先名：兼光解体 様
              </TextView>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                担当者：平田佳彬
              </TextView>
              <Block marginTop="10pt">
                <TextView
                  size="8pt"
                  color={Colors.Primary}
                  bold
                  lineHeight="18pt"
                >
                  見積書コード：M20200523-21001-62001
                </TextView>
              </Block>
            </Block>
            <Block
              flex={3}
              borderWidth="1pt"
              borderColor={Colors.Primary}
              borderRightWidth="0pt"
              borderLeftWidth="0pt"
            >
              <PreviewItem
                isHeader
                value1="コード ▼"
                value2="メーカー ▼"
                value3="品名・分類 ▼"
                value4="単  価 ▼"
                blocks={[3, 1, 1, 1.2]}
                centers={["left", "center", "center", "center"]}
              />
              <ScrollView>
                {data.map(({ value1, value2, value3, value4 }, index) => {
                  return (
                    <PreviewItem
                      key={`${index}`}
                      {...{ value1, value2, value3, value4 }}
                      blocks={[3, 1, 1, 1.2]}
                      centers={["left", "center", "center", "right"]}
                    />
                  );
                })}
              </ScrollView>
            </Block>
            <Block
              borderBottomWidth={1}
              borderBottomColor={Colors.SecondPrimary}
              width={"50%"}
              marginTop={1}
            />
          </Block>
          <Block block>
            <Block block>
              <Block
                block
                justifyContent="space-around"
                borderWidth="1pt"
                borderColor={Colors.Primary}
                marginTop="1pt"
                borderRightWidth="0pt"
                borderLeftWidth="0pt"
              >
                {data1.map(({ title, value }, index) => {
                  return (
                    <Block key={`${index}`} direction="row">
                      <Block flex={3}></Block>
                      <Block flex={1}>
                        <TextView size="8pt" color={Colors.Primary} bold>
                          {title}
                        </TextView>
                      </Block>
                      <Block flex={1}>
                        <TextView
                          textAlign="right"
                          size="10pt"
                          color={Colors.Primary}
                          regular
                        >
                          {value}
                        </TextView>
                      </Block>
                    </Block>
                  );
                })}
              </Block>
              <Block block>
                <Block paddingVertical="10pt">
                  <Block direction="row" width="50%">
                    <Block block>
                      <TextView size="8pt" bold color={Colors.Primary}>
                        事前振込
                      </TextView>
                    </Block>
                    <Block block>
                      <TextView size="10pt" bold color={Colors.Primary}>
                        2,000,000 円
                      </TextView>
                    </Block>
                  </Block>
                  <Block direction="row" width="100%" marginTop="5pt">
                    <Block block>
                      <TextView size="8pt" bold color={Colors.Primary}>
                        事前振込
                      </TextView>
                    </Block>
                    <Block block>
                      <TextView size="10pt" bold color={Colors.Primary}>
                        2,000,000 円
                      </TextView>
                    </Block>
                    <Block block>
                      <TextView size="8pt" bold color={Colors.Primary}>
                        小口現金
                      </TextView>
                    </Block>
                    <Block block>
                      <TextView size="10pt" bold color={Colors.Primary}>
                        891,260 円
                      </TextView>
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
            <FooterButton
              label="FAX送付依頼を送信しました。"
              button1="ステータスを確認"
              button2="TOPへ"
            />
          </Block>
        </Block>
      )}
    </WrapperBlock>
  );
};

export default PartitionReview;
