import {
  GET_METAL_MARKET_PRICE,
  RESET_CART,
  CHECK_CART,
  CREATE_NEW_INVOICE,
  GET_MARKET_PRICE_DATA,
  GET_AVERAGE_MARKET_PRICE,
} from "../../redux/actions/home";

const initialState = {
  isGettingMetalMarketPrice: false,
  isGotMetalMarketPrice: false,
  metalMarketPrice: {},
  errorMsg: "",
  isCheckingCart: false,
  isCheckedCart: false,
  cart: {},

  isResettingCart: false,
  isResetCartFinish: false,
  newCart: {},

  isCreatingNewInvoice: false,
  isCreatedNewInvoice: false,
  newInvoice: {},

  isGettingMarketPriceData: false,
  isGotMarketPriceData: false,
  marketPriceData: [],

  isGettingAverageMarketPrice: false,
  isGotAverageMarketPrice: false,
  averageMarketPrice: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_METAL_MARKET_PRICE.REQUEST:
      return {
        ...state,
        isGotMetalMarketPrice: false,
        isGettingMetalMarketPrice: true,
      };

    case GET_METAL_MARKET_PRICE.SUCCESS:
      return {
        ...state,
        isGotMetalMarketPrice: true,
        isGettingMetalMarketPrice: false,
        metalMarketPrice: action.result,
      };

    case GET_METAL_MARKET_PRICE.FAIL:
      return {
        ...state,
        isGotMetalMarketPrice: false,
        isGettingMetalMarketPrice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case RESET_CART.REQUEST:
      return {
        ...state,
        isResettingCart: true,
        isResetCartFinish: false,
      };

    case RESET_CART.SUCCESS:
      return {
        ...state,
        isResettingCart: false,
        isResetCartFinish: true,
        newCart: action.result,
      };

    case RESET_CART.FAIL:
      return {
        ...state,
        isResettingCart: false,
        isResetCartFinish: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case CHECK_CART.REQUEST:
      return {
        ...state,
        isCheckingCart: true,
        isCheckedCart: false,
      };

    case CHECK_CART.SUCCESS:
      return {
        ...state,
        isCheckingCart: false,
        isCheckedCart: true,
        cart: action.result,
      };

    case CHECK_CART.FAIL:
      return {
        ...state,
        isCheckingCart: false,
        isCheckedCart: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case CREATE_NEW_INVOICE.REQUEST:
      return {
        ...state,
        isCreatingNewInvoice: true,
        isCreatedNewInvoice: false,
      };

    case CREATE_NEW_INVOICE.SUCCESS:
      return {
        ...state,
        isCreatingNewInvoice: false,
        isCreatedNewInvoice: true,
        newInvoice: action.result,
      };

    case CREATE_NEW_INVOICE.FAIL:
      return {
        ...state,
        isCreatingNewInvoice: false,
        isCreatedNewInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_MARKET_PRICE_DATA.REQUEST:
      return {
        ...state,
        isGettingMarketPriceData: true,
        isGotMarketPriceData: false,
      };

    case GET_MARKET_PRICE_DATA.SUCCESS:
      return {
        ...state,
        isGettingMarketPriceData: false,
        isGotMarketPriceData: true,
        marketPriceData: action.result,
      };

    case GET_MARKET_PRICE_DATA.FAIL:
      return {
        ...state,
        isGettingMarketPriceData: false,
        isGotMarketPriceData: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_AVERAGE_MARKET_PRICE.REQUEST:
      return {
        ...state,
        isGettingAverageMarketPrice: true,
        isGotAverageMarketPrice: false,
      };

    case GET_AVERAGE_MARKET_PRICE.SUCCESS:
      return {
        ...state,
        isGettingAverageMarketPrice: false,
        isGotAverageMarketPrice: true,
        averageMarketPrice: action.result,
      };

    case GET_AVERAGE_MARKET_PRICE.FAIL:
      return {
        ...state,
        isGettingAverageMarketPrice: false,
        isGotAverageMarketPrice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
