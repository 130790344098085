import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Colors } from "utils";
import { getMetalMarketPrice } from "../../redux/actions/home";
import { connect } from "react-redux";
import {
  DateTimeFormatter,
  formatDateTime,
  formatDateTimeLocal,
} from "utils/DateUtility";

const TabView = (props) => {
  // let newDate = new Date();
  // let date = newDate.getDate();
  // let month = newDate.getMonth() + 1;
  // let hours = newDate.getHours();
  // let minutes = newDate.getMinutes();
  const refreshData = () => {
    props.getMetalMarketPrice();
  };

  const TextTab = (content, index) => {
    return (
      <Text
        style={[
          styles.txtTab,
          props.tabIndex === index && { color: Colors.White },
        ]}
      >
        {content}
      </Text>
    );
  };

  return (
    <>
      <View style={styles.containerTime}>
        <View>
          <Text style={styles.txtTime}>最新の相場更新日時</Text>
          <Text style={styles.txtTime}>
            {formatDateTimeLocal(
              props.metalMarketPrice?.latest_price?.modified,
              DateTimeFormatter.YYYY_MM_DD_HH_MM_JP
            )}
          </Text>
        </View>
        <TouchableOpacity
          style={styles.btnUpdate}
          onPress={() => refreshData()}
        >
          <Text style={styles.txtUpdate}>最新データに更新</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.tabs}>
        <TouchableOpacity
          onPress={() => props.onPressTab(0)}
          style={[
            styles.tab,
            styles.radiusLeft,
            props.tabIndex === 0 && { backgroundColor: Colors.SecondPrimary },
          ]}
        >
          {TextTab("本日の貴金属相場", 0)}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => props.onPressTab(1)}
          style={[
            styles.tab,
            { borderLeftWidth: 0, borderRightWidth: 0 },
            props.tabIndex === 1 && { backgroundColor: Colors.SecondPrimary },
          ]}
        >
          {TextTab("貴金属相場推移グラフ", 1)}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => props.onPressTab(2)}
          style={[
            styles.tab,
            styles.radiusRight,
            props.tabIndex === 2 && { backgroundColor: Colors.SecondPrimary },
          ]}
        >
          {TextTab(`前週・前々週の\n平均相場比較`, 2)}
        </TouchableOpacity>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  metalMarketPrice: state.home.metalMarketPrice,
});
const mapDispatchToProps = {
  getMetalMarketPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabView);

const styles = StyleSheet.create({
  containerTime: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10pt",
  },
  btnUpdate: {
    paddingHorizontal: "15pt",
    paddingVertical: "5pt",
    backgroundColor: Colors.SecondPrimary,
  },
  txtUpdate: {
    color: Colors.White,
    fontSize: "10pt",
  },
  txtTime: {
    color: Colors.Gray,
    fontSize: "12pt",
    letterSpacing: "2pt",
  },
  tabs: {
    flexDirection: "row",
    marginTop: "10pt",
  },
  txtTab: {
    color: Colors.SecondPrimary,
    fontSize: "10pt",
    textAlign: "center",
    fontWeight: "500",
  },
  tab: {
    flex: 1,
    padding: "2pt",
    borderWidth: 1,
    borderColor: Colors.SecondPrimary,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.WhiteLight01,
    paddingVertical: "3pt",
  },
  radiusLeft: { borderTopLeftRadius: 30, borderBottomLeftRadius: 30 },
  radiusRight: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
});
