import React from "react";
import { ScrollView, Dimensions, TouchableOpacity } from "react-native";
// import { Link } from "react-router-dom";
import { Colors } from "utils";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import {
  formatDateTime,
  DateTimeFormatter,
  getDayOfWeek,
  NOW_DATE,
} from "../../utils/DateUtility";
import { useHistory, useLocation } from "react-router-dom";
// import { TouchableOpacity } from "react-native-web";

const { height } = Dimensions.get("window");
const NoticeToday = ({
  showCreate,
  routePrefix = "",
  office = false,
  informationData,
  onShowAllPress,
  onCreatePress,
}) => {
  const [isAdmin, setIsAdmin] = React.useState(false);

  const location = useLocation();
  React.useEffect(() => {
    if (/^\/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [location]);

  return (
    <Block marginTop={10}>
      <Block direction="row" justifyContent="space-between">
        <TextView bold size={13} color={Colors.SecondPrimary}>
          {office ? "事務からのお知らせ" : "本日の通達事項"}
        </TextView>
        <TextView bold size={13} color={Colors.SecondPrimary}>
          {formatDateTime(informationData?.date)}（
          {getDayOfWeek(new Date(informationData?.date))}）
        </TextView>
      </Block>
      <Block
        borderWidth={1}
        borderColor={Colors.SecondPrimary}
        marginTop={10}
        padding={10}
        height={height * 0.15}
      >
        <ScrollView>
          <TextView
            regular
            size={13}
            color={Colors.Gray}
            style={{ lineHeight: 20 }}
          >
            {informationData?.message}
          </TextView>
        </ScrollView>
      </Block>
      <Block direction="row" justifyContent="flex-end">
        <TouchableOpacity onPress={onShowAllPress}>
          <Block
            paddingVertical={2}
            paddingHorizontal={8}
            color={Colors.SecondPrimary}
            marginTop={5}
          >
            <TextView bold size={"10pt"} color={Colors.White}>
              {office ? "過去のお知らせ" : "過去の通達事項"}
            </TextView>
          </Block>
        </TouchableOpacity>
        {isAdmin && !office && (
          <TouchableOpacity onPress={onCreatePress} style={{ marginStart: 10 }}>
            <Block
              paddingVertical={2}
              paddingHorizontal={8}
              color={Colors.SecondPrimary}
              marginTop={5}
            >
              <TextView bold size={10} color={Colors.White}>
                新規作成
              </TextView>
            </Block>
          </TouchableOpacity>
        )}

        {/* {showCreate && (
          <Link
            style={{ marginLeft: 10 }}
            to={{
              pathname: routePrefix + "/notice/create",
              state: { office },
            }}
          >
            <Block
              paddingVertical={2}
              paddingHorizontal={8}
              color={Colors.SecondPrimary}
              marginTop={5}
            >
              <TextView bold size={10} color={Colors.White}>
                新規作成
              </TextView>
            </Block>
          </Link>
        )} */}
      </Block>
    </Block>
  );
};

export default NoticeToday;
