import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
import { WrapperBlock, Block, TextView } from "components";
import { NoticeStatus, NoticeToday } from "components/notice";
import { Footer } from "components/notice";
import { PAST_NOTICE } from "containers/past-notice/fakePastNotice";
import { Colors, useNavigate } from "utils";
import { connect } from "react-redux";
import {
  getInformation,
  getLatestUpdateTransaction,
} from "../../redux/actions/general";
import { INFORMATION_TYPE } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import InvoiceEstimateButton from "components/common/InvoiceEstimateButton";
import Title from "components/common/Title";

/**
 * 004-001-01 / お知らせ
 * @param {*} props
 */
const Notice = (props) => {
  const navigate = useNavigate();

  const onPressNavigate = (pathname) => navigate(pathname, {});
  const onPressStatus = (key) => navigate("account");

  useEffect(() => {
    props.getInformation({
      type: INFORMATION_TYPE.TODAY,
    });
    props.getInformation({
      type: INFORMATION_TYPE.OFFICE,
    });
    props.getLatestUpdateTransaction();
  }, []);

  return (
    <Block paddingBottom="10pt" paddingHorizontal="24pt">
      <Title title={"お知らせ"} />
      <Block block>
        <Block flex={6}>
          <NoticeToday
            showCreate
            informationData={props.todayInformationList[0]}
            onShowAllPress={() =>
              navigate("/past-notice", {
                information_type: INFORMATION_TYPE.TODAY,
              })
            }
            onCreatePress={() => {
              navigate("/notice/create", {});
            }}
          />
          <NoticeToday
            office
            informationData={props.officeInformationList[0]}
            onShowAllPress={() =>
              navigate("/past-notice", {
                information_type: INFORMATION_TYPE.OFFICE,
              })
            }
          />
        </Block>
        <Block flex={4}>
          <Block flex={2}>
            <TextView bold size={13} color={Colors.SecondPrimary}>
              ステータス通知
            </TextView>
            <Block
              block
              borderWidth="1pt"
              borderColor={Colors.SecondPrimary}
              borderRightWidth="0pt"
              borderLeftWidth="0pt"
              paddingBottom="10pt"
              height="100pt"
            >
              <ScrollView>
                {props.latestUpdatedTransactionList?.map((item, key) => (
                  <NoticeStatus
                    onPress={() => onPressStatus(key)}
                    key={`${key}`}
                    title={""}
                    content={item?.message}
                  />
                ))}
              </ScrollView>
            </Block>
          </Block>
          <Block flex={3}>
            {/* <Footer onPress={onPressNavigate} /> */}
            <InvoiceEstimateButton />
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

const mapStateToProps = (state) => ({
  todayInformationList: state.general.todayInformationList,
  officeInformationList: state.general.officeInformationList,
  latestUpdatedTransactionList: state.general.latestUpdatedTransactionList,
});
const mapDispatchToProps = {
  getInformation,
  getLatestUpdateTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
