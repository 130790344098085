import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  FlatList,
  Image,
} from "react-native";

import { Title, Block, Button, TextView, WrapperBlock } from "components";
import Input from "components/common/Input";

import DateInput from "components/common/DateInput";
import { Colors } from "utils";
import { useNavigate } from "utils";
import { searchCustomers } from "../../redux/actions/customer";
import { createTransfer } from "../../redux/actions/transfer";
import { connect } from "react-redux";
import { TRANSFER_TYPE } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { usePrevious } from "../../utils/hook";
import { commonIcons } from "assets/icons";

const DropdownList = ({
  label,
  defaultSelectedItemName,
  defaultSelectedItem,
  data,
  onSelectedItem,
  wrapStyle,
  textInputStyle,
  suggestionListStyle,
}) => {
  const [selectedItemName, setSelectedItemName] = useState(
    defaultSelectedItemName
  );
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  // const [searchItemName, setSearchItemName] = useState("");
  const [isShowList, setIsShowList] = React.useState(false);

  // useEffect(() => {
  //   if (searchItemName !== "" && searchItemName !== selectedItemName) {
  //     fetchApi(searchItemName);
  //     setIsShowList(true);
  //   } else if (searchItemName === "") {
  //     onSelectedItem(undefined);
  //     setIsShowList(false);
  //   } else {
  //     setIsShowList(false);
  //   }
  // }, [searchItemName]);

  useEffect(() => {
    // setSelectedItemName(selectedItem?.name);
  }, [selectedItem]);

  const selectItemHandler = (item, index) => {
    setSelectedItem(item);
    setSelectedItemName(item.name);
    // setSearchItemName(item.name);
    setIsShowList(false);
    onSelectedItem(item);
  };

  return (
    <View style={wrapStyle}>
      <Block block marginTop="10pt">
        {/* <Input
          dropdownIcon
          placeholder={placeHolder}
          placeholderTextColor={`${Colors.SecondPrimary}40`}
          label={label}
          value={searchItemName}
          paddingVertical={
            inputPaddingVertical !== undefined ? inputPaddingVertical : 15
          }
          onChangeText={(value) => setSearchItemName(value)}
          wrapStyle={inputStyle}
          disabled
        /> */}
        <View style={[styles.wrap]}>
          <View style={{ flex: 1 }}>
            {!!label && <Text style={styles.label}>{label}</Text>}
            <View style={[styles.inputWrap]}>
              <Text style={[styles.textInput, textInputStyle]}>
                {selectedItemName}
              </Text>
              <Button onPress={() => setIsShowList((prev) => !prev)}>
                <Image
                  source={commonIcons.arrow_down}
                  style={styles.dropdownIcon}
                />
              </Button>
            </View>
          </View>
        </View>
      </Block>
      {isShowList && (
        <Block
          color="#ECECEC"
          width="90%"
          height="140pt"
          alignSelf="flex-end"
          borderWidth="1pt"
          borderColor={Colors.SecondPrimary}
          borderRadius="3pt"
          style={suggestionListStyle}
        >
          <FlatList
            data={data}
            style={{ flex: 1 }}
            keyExtractor={(_, index) => `${index}`}
            renderItem={({ item, index }) => {
              return (
                <Block
                  key={`${index}`}
                  paddingVertical="10pt"
                  paddingLeft="15pt"
                  borderWidth={1}
                  borderColor={Colors.PrimaryLight}
                  color={
                    item?.id === selectedItem?.id
                      ? Colors.PrimaryLight
                      : "white"
                  }
                >
                  <TouchableOpacity
                    onPress={() => selectItemHandler(item, index)}
                  >
                    <TextView color={Colors.Primary} size={14} bold>
                      {item.name}
                    </TextView>
                  </TouchableOpacity>
                </Block>
              );
            }}
          />
        </Block>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  label: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginTop: "12pt",
    marginBottom: "6pt",
    fontSize: "12pt",
  },
  inputWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: 5,
  },
  textInput: {
    flex: 1,
    padding: "15pt",
  },
  searchIcon: {
    width: "20pt",
    height: "20pt",
    marginHorizontal: "10pt",
  },
  dropdownIcon: {
    width: "15pt",
    height: "10pt",
    marginHorizontal: "10pt",
  },
  tailText: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "5pt",
  },
});

export default DropdownList;
