import { LOGIN, LOGOUT } from "redux/actions/auth";

const initialState = {
  isLoggedIn: false,
  isLoggingIn: false,
  account: {},
  errorMsg: "",
  isLoggingOut: false,
  isLoggedOut: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: true,
      };

    case LOGIN.SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        account: action.result,
      };

    case LOGIN.FAIL:
      // console.log('hung LOGIN.FAIL, action:', action.result?.errorMessage?.response?.data?.error);
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case LOGOUT.REQUEST:
      console.log("hung LOGOUT:", LOGOUT);
      return { ...initialState };

    default:
      return state;
  }
}
