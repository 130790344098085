import { createRequestTypes } from "./utils";

export const GET_TRANSFER_CUSTOMERS = createRequestTypes(
  "GET_TRANSFER_CUSTOMERS"
);
export const getTransferCustomers = (params) => ({
  type: GET_TRANSFER_CUSTOMERS.REQUEST,
  params,
});

export const UPDATE_TRANSFER = createRequestTypes("UPDATE_TRANSFER");
export const updateTransfer = (params) => ({
  type: UPDATE_TRANSFER.REQUEST,
  params,
});


export const CREATE_TRANSFER = createRequestTypes("CREATE_TRANSFER");
export const createTransfer = (params) => ({
  type: CREATE_TRANSFER.REQUEST,
  params,
});



export const SEARCH_TRANSFER = createRequestTypes("SEARCH_TRANSFER");
export const searchTransfer = (params) => ({
  type: SEARCH_TRANSFER.REQUEST,
  params,
});
