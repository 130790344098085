import axios from 'axios';
import {setAxiosDefaultHeaders} from "../../configs/axiosDebugger";
import store from "../store";


export const replaceParams = (apiURL, params) => {
  let _result = apiURL;
  if (typeof params === 'object') {
    Object.keys(params).forEach(key => {
      _result = _result.replace(`{${key}}`, params[key]);
    });
  }
  return _result;
};


export function axiosGet(URL, config = {}) {
    const { isLoggedIn, account } = store.getState().auth;
     if (isLoggedIn) {
        const { token, uuid } = account;
        setAxiosDefaultHeaders({
          token,
          uuid,
        });
      } else {
        setAxiosDefaultHeaders({
          token: '',
          uuid: '',
        });
      }
  return axios
    .get(URL, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        resp_status: response.status,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error.response
    }));
}

export function axiosDelete(URL, config = {}) {
  return axios
    .delete(URL, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        resp_status: response.status,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error.response
    }));
}
export function axiosPost(URL, post, config = {}) {
    const { isLoggedIn, account } = store.getState().auth;
     if (isLoggedIn) {
        const { token, uuid } = account;
        setAxiosDefaultHeaders({
          token,
          uuid,
        });
      } else {
        setAxiosDefaultHeaders({
          token: '',
          uuid: '',
        });
      }
     console.log('oki post', config);
     config.withCredentials = true;
     config.credentials = 'include';
     console.log(URL, post, config);
  return axios
    .post(URL, post, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200 || response.status === 201) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        resp_status: response.status,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error
    }));
}
