import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Back from "components/common/Back";
import OtherInvoiceRecord from "components/common/OtherInvoiceRecord";

import { Colors, nor } from "utils";
import Package from "components/cart/Package";
import { useNavigate, useNavigateReplace } from "utils";
import { Block, TextView, Button } from "components";
import { useHistory } from "react-router-dom";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { connect } from "react-redux";
import {
  getOtherInvoice,
  // getLastInvoice,
  updateInvoice,
} from "../../redux/actions/invoice";
import { getSetting } from "../../redux/actions/general";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  INVOICE_STATUS,
  CART_UPDATE_INVOICE_BEHAVIOR,
} from "../../utils/Constant";
import { usePrevious } from "../../utils/hook";
import Title from "components/common/Title";
import { getProductCatalogDetail } from "../../redux/actions/product";

const Cart = (props) => {
  const navigate = useNavigate();
  const navigateReplace = useNavigateReplace();

  const history = useHistory();
  const [invoice, setInvoiceData] = useState(history?.location?.state?.invoice);
  const [invoiceDetailIndex, setInvoiceDetailIndex] = useState(
    history?.location?.state?.invoiceDetailIndex
  );
  const invoiceDetail = invoice.invoice_details[invoiceDetailIndex];
  // const [lastInvoiceDetailByProduct, setLastInvoiceDetailByProduct] = useState(
  //   {}
  // );
  const { use_transfer } = history?.location?.state;
  // const lastInvoice = props.lastInvoice?.invoice;
  const [fromToUnitPrice, setFromToUnitPrice] = useState("");
  const [submitInvoiceHandler, setSubmitInvoiceHandler] = useState(false);

  const calculateEstimatePrice = () => {
    //     仕入単価＝(products.adjust_value_pt × market_prices.pt_jpy)
    //  + (products.adjust_value_pd × market_prices.pd_jpy)
    //  + (products.adjust_value_rh × market_prices.rh_jpy)
    // 下限仕入単価＝仕入単価 × (settings.target_profit_rate) / 100)
    // 上限仕入単価＝下限仕入単価 × (settings.limit_profit_rate) / 100)
    // => 仕入単価目安 = {下限仕入単価} ~ {上限仕入単価}
    const { pt_jpy, pd_jpy, rh_jpy } = invoice?.market_price;
    const {
      total_value_pd,
      total_value_pt,
      total_value_rh,
    } = invoiceDetail?.product_info;
    const unitPrice =
      total_value_pd * pd_jpy +
      total_value_pt * pt_jpy +
      total_value_rh * rh_jpy;

    const fromUnitPrice =
      unitPrice * (props.setting?.target_profit_rate * 0.01);
    const toUnitPrice =
      fromUnitPrice * (props.setting?.limit_profit_rate * 0.01);
    // 14,000円〜15,500円
    const s =
      formatMoney(fromUnitPrice) + "円〜" + formatMoney(toUnitPrice) + "円";
    setFromToUnitPrice(s);
  };

  useEffect(() => {
    props.getSetting();

    props.getOtherInvoice({
      product_id: invoiceDetail?.product_info?.id,
    });
    calculateEstimatePrice();

    // props.getLastInvoice({
    //   customer_id: invoice?.customer_info?.id,
    // });
    props.getProductCatalogDetail({
      product_id: invoiceDetail?.product_info?.id,
      customer_id: invoice?.customer_info?.id,
    });
  }, []);

  // useEffect(() => {
  //   const invoideDetailProduct = props.lastInvoice?.invoice?.invoice_details?.filter(
  //     (item) => item.product_info?.id == invoiceDetail?.product_info?.id
  //   );
  //   setLastInvoiceDetailByProduct(invoideDetailProduct?.[0]);
  // }, [props.lastInvoice]);

  useEffect(() => {
    calculateEstimatePrice();
  }, [props.setting]);

  const updateInvoice = () => {
    const invoice_details = invoice?.invoice_details.map((item) => {
      return {
        id: item.id,
        product_id: item.product_info?.id,
        amount: formatMoney(
          item.amount,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        price: formatMoney(
          item.price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        unit_price: formatMoney(
          item.unit_price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        total_price: formatMoney(
          item.total_price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
      };
    });

    const updateInvoice = {
      id: invoice.id,
      price: formatMoney(
        invoice.price,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      tax: formatMoney(
        invoice.tax,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      total_price: formatMoney(
        invoice.total_price,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      status: INVOICE_STATUS.PURCHASE_COMPLETED,
      invoice_details: invoice_details,
      transfer_price: 0,
      cash_price: 0,
      use_transfer: use_transfer,
      is_submit: true,
    };

    props.updateInvoice(updateInvoice);
  };

  const renderPriceColorText = (currentPrice, prevPrice) => {
    if (currentPrice > prevPrice) {
      return styles.redText;
    } else {
      return styles.lightBlueText;
    }
  };

  const prevUpdatingInvoice = usePrevious(props.isUpdatingInvoice);
  useEffect(() => {
    if (prevUpdatingInvoice && !props.isUpdatingInvoice) {
      if (props.isUpdatedInvoice && submitInvoiceHandler) {
        history.goBack();
        navigateReplace("cart", {
          finish_purchase_from_product_detail: true,
        });
      }
    }
  }, [props.isUpdatingInvoice]);

  const getUnitPriceByLastInvoiceByCustomer = (last_invoice_by_customer) => {
    if (
      last_invoice_by_customer !== null ||
      last_invoice_by_customer !== undefined
    ) {
      const matchedInvoiceDetail = last_invoice_by_customer?.invoice_details?.filter(
        (item) => item.product_info?.id == invoiceDetail?.product_info?.id
      );
      if (matchedInvoiceDetail !== undefined) {
        return matchedInvoiceDetail[0]?.unit_price;
      }
    }
  };

  return (
    <View>
      <View style={{ paddingBottom: 10, paddingHorizontal: "24pt" }}>
        <Title backTitle={"仕入リスト"} title="製品詳細" />
        <View style={{}}>
          <Text style={styles.description}>
            取引先コード：{invoice?.customer_info?.code}
          </Text>
          <Text style={styles.description}>
            取引先名：{invoice?.customer_info?.name}
          </Text>
          <Text style={styles.description}>本日の貴金属相場</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: "5pt" }}>
          <View style={styles.cell}>
            <TextView size="13pt" color={Colors.Primary} bold>
              PT：{formatMoney(invoice?.market_price?.pt_jpy)}円
            </TextView>
          </View>
          <View style={[styles.cell, { marginHorizontal: "5pt" }]}>
            <TextView size="13pt" color={Colors.Primary} bold>
              PD：{formatMoney(invoice?.market_price?.pd_jpy)}円
            </TextView>
          </View>
          <View style={styles.cell}>
            <TextView size="13pt" color={Colors.Primary} bold>
              RH：{formatMoney(invoice?.market_price?.rh_jpy)}円
            </TextView>
          </View>
        </View>
        <View style={styles.mainWrap}>
          <View>
            <View style={styles.titleWrap2}>
              <View style={{ paddingRight: "14pt" }}>
                <Text style={{ fontSize: "12pt", color: Colors.Primary }}>
                  品 名
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: "14pt", color: Colors.Primary }}>
                  {invoiceDetail?.name}【{invoiceDetail?.product_info?.code}】
                </Text>
              </View>
            </View>
            <View style={{ padding: 10, paddingTop: 0 }}>
              <View style={[styles.sectionHeader, styles.borderBottom1]}>
                <View style={[styles.row, styles.borderBottom]}>
                  <View style={styles.rowFlex}>
                    <Text style={styles.headerBadge}>仕入単価目安</Text>
                    <Text style={[styles.headerText, { fontSize: 20 }]}>
                      {fromToUnitPrice}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.rowFlex}>
                    <Text style={styles.headerBadge}>前回仕入単価</Text>
                    <Text style={styles.headerText}>
                      {formatMoney(
                        getUnitPriceByLastInvoiceByCustomer(
                          props.productCatalogDetail?.last_invoice_by_customer
                        )
                      )}
                      円
                    </Text>
                  </View>
                  <View style={styles.rowFlex}>
                    <Text style={[styles.headerBadge]}>前回仕入日</Text>
                    <Text style={[styles.headerText, { fontSize: "10pt" }]}>
                      {formatDateTime(
                        props.productCatalogDetail?.last_invoice_by_customer
                          ?.date
                      )}
                    </Text>
                  </View>
                </View>
                <View style={[styles.row, { justifyContent: "space-between" }]}>
                  <Text style={styles.headerBadge}>前回仕入時相場</Text>
                  <Text style={[styles.badgeSmall]}>PT</Text>
                  <Text
                    style={renderPriceColorText(
                      invoice?.market_price?.pt_jpy,
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.pt_jpy
                    )}
                  >
                    {formatMoney(
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.pt_jpy
                    )}
                    円
                  </Text>
                  <Text style={[styles.badgeSmall]}>PD</Text>
                  <Text
                    style={renderPriceColorText(
                      invoice?.market_price?.pd_jpy,
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.pd_jpy
                    )}
                  >
                    {formatMoney(
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.pd_jpy
                    )}
                    円
                  </Text>
                  <Text style={[styles.badgeSmall]}>RH</Text>
                  <Text
                    style={renderPriceColorText(
                      invoice?.market_price?.rh_jpy,
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.rh_jpy
                    )}
                  >
                    {formatMoney(
                      props.productCatalogDetail?.last_invoice_by_customer
                        ?.rh_jpy
                    )}
                    円
                  </Text>
                </View>
              </View>
              <ScrollView style={{ maxHeight: 110 }}>
                {props.otherInvoiceList?.map((item, index) => {
                  return (
                    <OtherInvoiceRecord
                      key={`${index}`}
                      otherInvoice={item}
                      invoice_market_price={props.invoice?.market_price}
                    />
                  );
                })}
              </ScrollView>
            </View>
          </View>
        </View>
      </View>

      <Block>
        <Image
          source={{
            uri: invoiceDetail?.product_info?.pic_path,
          }}
          style={{ height: 390, width: "100%" }}
          resizeMode="contain"
        />
      </Block>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrap: {
    marginTop: "8pt",
    borderColor: Colors.Primary,
    borderWidth: 1,
    borderRadius: 5,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  titleWrap2: {
    flexDirection: "row",
    paddingVertical: "8pt",
    paddingHorizontal: "10pt",
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "10pt",
    fontSize: "16pt",
  },
  description: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "13pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 3,
  },
  cellText: {
    fontWeight: "bold",
    color: Colors.Primary,
    fontSize: 15,
  },
  textInput: {
    flex: 1,
    padding: "5pt",
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  button: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    borderRadius: 5,
  },
  headerText: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "11pt",
  },
  headerBadge: {
    color: "white",
    backgroundColor: Colors.Primary,
    paddingVertical: "2pt",
    paddingHorizontal: 8,
    minWidth: 90,
    textAlign: "center",
    marginRight: "8pt",
    fontSize: "12pt",
  },
  badgeSmall: {
    minWidth: 0,
    alignSelf: "center",
    color: "white",
    backgroundColor: Colors.Primary,
    paddingVertical: "2pt",
    paddingHorizontal: 7,
    textAlign: "center",
    marginRight: "10pt",
    fontSize: "8pt",
  },
  rowFlex: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: "3pt",
  },
  redText: {
    color: "#D90000",
    fontWeight: "bold",
    fontSize: "10pt",
  },
  contentHeader: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginVertical: "3pt",
  },
  contentBadge: {
    color: "white",
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "2pt",
    paddingHorizontal: "10pt",
    textAlign: "center",
    marginRight: "10pt",
    fontSize: "12pt",
  },
  price: {
    color: Colors.SecondPrimary,
    fontSize: "18pt",
    fontWeight: "bold",
  },
  date: {
    color: Colors.SecondPrimary,
    fontSize: "16pt",
    fontWeight: "bold",
  },
  lightBlueText: {
    color: "#00CBDC",
    fontWeight: "bold",
    fontSize: "10pt",
  },
  borderBottom: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderStyle: "dashed",
  },
  borderBottom1: {
    borderBottomColor: Colors.GrayLight,
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderStyle: "solid",
  },
});

const mapStateToProps = (state) => ({
  twoLastInvoice: state.invoice.twoLastInvoice,
  setting: state.general.setting,
  otherInvoiceList: state.invoice.otherInvoiceList,
  // lastInvoice: state.invoice.lastInvoice,
  isUpdatingInvoice: state.invoice.isUpdatingInvoice,
  isUpdatedInvoice: state.invoice.isUpdatedInvoice,
  updatedInvoice: state.invoice.updatedInvoice,

  productCatalogDetail: state.product.productCatalogDetail,
});
const mapDispatchToProps = {
  getSetting,
  getOtherInvoice,
  // getLastInvoice,
  updateInvoice,
  getProductCatalogDetail,
  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
