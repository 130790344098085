import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  ScrollView,
} from "react-native";
import { Button, Input } from "components";
import { Colors } from "utils";
import ModalAnalysis from "components/modal/ModalAnalysis";
import ItemAnalysis from "./ItemAnalysis";
import { DATA } from "./fakeSettting";
import { connect } from "react-redux";
import { searchProducts, updateProduct } from "../../redux/actions/product";
import { usePrevious } from "../../utils/hook";
import Title from "components/common/Title";

/**
 * 【管理者】008-001-2 / サンプル分析データ
 * 【管理者】008-001-3 / サンプル分析データ・補正率入力
 */
const Analysis = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [search, setSearch] = useState("");

  const handleSort = (type) => {
    if (type) {
      let _data = data.sort((a, b) => a.code - b.code);
      setData([..._data]);
    } else {
      let _data = data.sort((a, b) => a.name.localeCompare(b.name));
      setData([..._data]);
    }
  };

  useEffect(() => {}, []);

  const prevSearchingProduct = usePrevious(props.isSearchingProduct);
  useEffect(() => {
    if (prevSearchingProduct && !props.isSearchingProduct) {
      if (props.isSearchProductFinish) {
        setData(props.products);
      }
    }
  }, [props.isSearchingProduct]);

  const searchHandler = () => {
    props.searchProducts({
      search_name: search,
      search_code: search,
    });
  };

  const prevUpdatingProduct = usePrevious(props.isUpdatingProduct);
  useEffect(() => {
    if (prevUpdatingProduct && !props.isUpdatingProduct) {
      if (props.isUpdatedProduct) {
        let d = data;
        d = d.map((item) => {
          if (item.id === props.updatedProduct.id) {
            item.adjust_rate_pt = props.updatedProduct.adjust_rate_pt;
            item.adjust_rate_pd = props.updatedProduct.adjust_rate_pd;
            item.adjust_rate_rh = props.updatedProduct.adjust_rate_rh;

            item.adjust_value_pt = props.updatedProduct.adjust_value_pt;
            item.adjust_value_pd = props.updatedProduct.adjust_value_pd;
            item.adjust_value_rh = props.updatedProduct.adjust_value_rh;

            item.total_value_pt = props.updatedProduct.total_value_pt;
            item.total_value_pd = props.updatedProduct.total_value_pd;
            item.total_value_rh = props.updatedProduct.total_value_rh;
          }
          return item;
        });
        setData(d);
      }
    }
  }, [props.isUpdatingProduct]);

  return (
    <View style={styles.wrap}>
      <Title title={"設  定"} />
      <View style={styles.headerRow}>
        <View style={{ flex: 1 }}>
          <Text style={styles.headerLabel}>サンプル分析データ・マスタ</Text>
        </View>
        <View>
          <Input
            paddingVertical={5}
            onChangeText={(value) => setSearch(value)}
            onPress={() => searchHandler()}
          />
        </View>
      </View>
      <View style={styles.tableHeader}>
        <TouchableOpacity onPress={() => handleSort(true)} style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>コード ▼</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handleSort(false)}
          style={styles.flex2}
        >
          <Text style={styles.tableHeaderLabel}>品名・分類 ▼</Text>
        </TouchableOpacity>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>採集データ{"\n"}（g）</Text>
        </View>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>補正率{"\n"}（%）</Text>
        </View>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>個別補正{"\n"}（g）</Text>
        </View>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>全体補正{"\n"}（g）</Text>
        </View>
      </View>
      <ScrollView style={{ height: "50vh" }}>
        {data.map((item, index) => {
          return (
            <ItemAnalysis
              onPress={() => {
                setSelectedProduct(item);
                setIsVisible(true);
              }}
              key={String(index)}
              item={item}
            />
          );
        })}
      </ScrollView>

      <ModalAnalysis
        isVisible={isVisible}
        onUpdate={(
          selectedProduct,
          adjustRatePT,
          adjustRatePD,
          adjustRateRH
        ) => {
          setIsVisible(false);

          props.updateProduct({
            product_id: selectedProduct?.id,
            adjust_rate_pt: adjustRatePT,
            adjust_rate_pd: adjustRatePD,
            adjust_rate_rh: adjustRateRH,
          });
        }}
        onClose={() => setIsVisible(false)}
        selectedProduct={selectedProduct}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: "10pt",
    paddingHorizontal: "24pt",
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    borderBottomColor: Colors.Primary,
    borderBottomWidth: 1,
  },
  headerLabel: {
    color: Colors.Primary,
    fontSize: 16,
    fontWeight: "bold",
  },
  tableHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
  },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  tableHeaderLabel: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    color: Colors.Primary,
    alignSelf: "flex-start",
  },
  tableDataRow: {
    flexDirection: "row",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.SecondPrimary,
    borderStyle: "dashed",
  },
  tableDataLabel: {
    fontSize: 12,
    color: Colors.Primary,
    marginBottom: 2,
  },
  tableDataLabelFilled: {
    fontSize: 12,
    color: Colors.Primary,
    marginBottom: 2,
    backgroundColor: Colors.PrimaryLight,
    alignSelf: "flex-end",
    paddingVertical: 2,
    paddingHorizontal: 10,
    minWidth: 40,
    textAlign: "right",
  },
  badge: {
    backgroundColor: Colors.Primary,
    paddingVertical: 1,
    paddingHorizontal: 5,
    color: "white",
    fontSize: 10,
    marginRight: 5,
  },
  dataRow: {
    flexDirection: "row",
    alignItems: "center",
    height: 22,
  },
  tableDataNumber: {
    fontSize: 12,
    color: Colors.Primary,
    textAlign: "right",
    minWidth: 32,
  },
});

const mapStateToProps = (state) => ({
  // setting: state.general.setting,
  isSearchProductFinish: state.product.isSearchProductFinish,
  isSearchingProduct: state.product.isSearchingProduct,
  products: state.product.products,

  isUpdatingProduct: state.product.isUpdatingProduct,
  isUpdatedProduct: state.product.isUpdatedProduct,
  updatedProduct: state.product.updatedProduct,
});
const mapDispatchToProps = {
  searchProducts,
  updateProduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(Analysis);

// export default Analysis;
