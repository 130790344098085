import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
import { Block, TextView, WrapperBlock, Button } from "components";
import { connect } from "react-redux";
import { NoticeStatus } from "components/notice";
import { PAST_NOTICE } from "./fakePastNotice";
import { Colors, useNavigate } from "utils";
import { useHistory, useLocation } from "react-router-dom";
import { INFORMATION_TYPE } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { usePrevious } from "../../utils/hook";
import { getInformation } from "../../redux/actions/general";
import { Alert } from "../../App";
import Title from "components/common/Title";

const PastNotice = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useHistory();
  const isAdmin = location.pathname.substring(1, 6) === "admin";
  const { information_type } = history?.location?.state;
  const [informationData, setInformationData] = useState([]);
  // let information_data = [];

  // console.log("hung officeInformationList:", props.officeInformationList);

  useEffect(() => {
    props.getInformation({
      type: information_type,
    });
  }, []);

  const prevGettingInformationList = usePrevious(
    props.isGettingInformationList
  );
  useEffect(() => {
    if (prevGettingInformationList && !props.isGettingInformationList) {
      if (props.isGotInformationList) {
        setInformationData(
          information_type === INFORMATION_TYPE.TODAY
            ? props.todayInformationList
            : props.officeInformationList
        );
      }
    }
  }, [props.isGettingInformationList]);

  const showMessageDialog = (message) => {
    Alert.alert(
      "",
      message,
      [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ],
      {},
      { alignItems: "flex-start", textAlign: "left" }
    );
  };

  return (
    <WrapperBlock>
      <Block flex={7}>
        <Title title={"お知らせ"} />
        <Block marginTop={20} marginBottom={10}>
          <TextView color={Colors.Primary} size={13}>
            {information_type == INFORMATION_TYPE.TODAY
              ? "過去の通達事項"
              : "過去の事務からのお知らせ"}
          </TextView>
        </Block>
        <Block
          block
          borderRightWidth="0pt"
          borderLeftWidth="0pt"
          borderColor={Colors.SecondPrimary}
          borderWidth="1pt"
          paddingBottom="5pt"
        >
          <ScrollView>
            {informationData.map((item, key) => (
              <NoticeStatus
                key={`${key}`}
                title={formatDateTime(
                  item?.date,
                  DateTimeFormatter.YYYY_MM_DD_JP_DOT
                )}
                content={item?.message}
                onPress={() => showMessageDialog(item?.message)}
              />
            ))}
          </ScrollView>
        </Block>
      </Block>
      <Block flex={3}>
        <Button
          // onPress={() => (isAdmin ? history.goBack() : navigate("/"))}
          onPress={() => history.goBack()}
          smallCentered
          paddingVertical="8pt"
        >
          <TextView bold size={12} color={Colors.White}>
            お知らせTOPへ
          </TextView>
        </Button>
      </Block>
    </WrapperBlock>
  );
};

// export default PastNotice;

const mapStateToProps = (state) => ({
  todayInformationList: state.general.todayInformationList,
  officeInformationList: state.general.officeInformationList,

  isGettingInformationList: state.general.isGettingInformationList,
  isGotInformationList: state.general.isGotInformationList,
});
const mapDispatchToProps = {
  getInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastNotice);
