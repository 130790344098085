import React from "react";
import { Colors } from "utils";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import CheckBox from "components/common/CheckBox";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const ItemTable = ({
  name,
  check,
  amount,
  unit_price,
  total_price,
  onCheckBoxPress,
}) => {
  return (
    <Block
      direction="row"
      justifyContent="space-between"
      middle
      marginTop="3pt"
      borderBottomWidth="1pt"
      borderBottomColor={Colors.SecondPrimary}
      borderStyle="dashed"
      paddingBottom="5pt"
      paddingTop="5pt"
    >
      <Block flex={2} direction="row" middle>
        <CheckBox
          label={name}
          size={12}
          check={check}
          onCheckBoxPress={onCheckBoxPress}
          fontSize={"11pt"}
        />
      </Block>
      <Block block>
        <TextView size="11pt" color={Colors.Primary} regular textAlign="right">
          {amount}
        </TextView>
      </Block>
      <Block block>
        <TextView textAlign="right" size="11pt" color={Colors.Primary} regular>
          {formatMoney(unit_price) + "円"}
        </TextView>
      </Block>
      <Block block>
        <TextView textAlign="right" size="11pt" color={Colors.Primary} regular>
          {formatMoney(total_price) + "円"}
        </TextView>
      </Block>
    </Block>
  );
};

ItemTable.defaultProps = {
  title: "",
  check: false,
  value1: "",
  value2: "",
  value3: "",
};
export default ItemTable;
