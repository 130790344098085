import Button from "components/common/Button";
import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { useHistory } from "react-router-dom";
import { Colors } from "utils";
import Back from "./Back";

const Title = ({ backTitle, title, onBack }) => {
  return (
    <View style={styles.titleWrap}>
      {backTitle && <Back label={backTitle} onPress={onBack} />}
      <Text style={[styles.title, { marginLeft: backTitle ? "10pt" : "0" }]}>
        {title}
      </Text>
    </View>
  );
};

export default Title;

const styles = StyleSheet.create({
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: "15pt",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "18pt",
  },
});
