import { useLocation, useHistory } from "react-router-dom";

function useNavigate() {
  const location = useLocation();
  const history = useHistory();

  const navigate = (screen, state) => {
    let routeName = "";
    if (/^\/admin/.test(location.pathname)) {
      if (!/^\/admin/.test(screen)) routeName = "/admin";
      if (!/^\//.test(screen)) routeName += "/";
      routeName += screen;
    } else {
      if (!/^\//.test(screen)) routeName = "/" + screen;
      else routeName = screen;
    }

    history.push(routeName, state);
  };

  return navigate;
}

function useNavigateReplace() {
  const location = useLocation();
  const history = useHistory();

  const replace = (screen, state) => {
    let routeName = "";
    if (/^\/admin/.test(location.pathname)) {
      if (!/^\/admin/.test(screen)) routeName = "/admin";
      if (!/^\//.test(screen)) routeName += "/";
      routeName += screen;
    } else {
      if (!/^\//.test(screen)) routeName = "/" + screen;
      else routeName = screen;
    }

    history.push(routeName, state);
  };

  return replace;
}

export { useNavigate, useNavigateReplace };
