import React from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Image,
} from "react-native";
import { defaultIcons } from "assets/icons";
import { Colors } from "utils";
import { useNavigate } from "utils";

import {
  formatMoney,
  MoneyFormatter,
  validateDecimal,
} from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";

const Package = ({
  invoice,
  invoice_detail,
  use_transfer,
  invoiceDetailIndex,
  onTrash,
  onGoToDetail,
  amountOnBlur,
  unitPriceOnBlur,
  amountOnFocus,
  unitPriceOnFocus,
  ...props
}) => {
  const navigate = useNavigate();
  let amountInput;
  let unitPriceInput;

  const gotoInvoiceDetail = () => {
    //go to 003-003-2 / 製品詳細
    const data = invoice;
    const detail = data?.invoice_details?.map((item) => {
      return {
        ...item,
        onQuantityChange: null,
        onUnitPriceChange: null,
      };
    });

    data.invoice_details = detail;
    navigate("/cart/detail", {
      invoice: data,
      invoiceDetailIndex: invoiceDetailIndex,
      use_transfer: use_transfer,
    });
  };
  const validateValueWithCommas = (value, handler) => {
    const formatValue = formatMoney(
      value,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );

    if (validateDecimal(formatValue)) {
      handler(formatMoney(value));
    }
  };
  // const aaa = (value) => {
  //   console.log("hung aaa value:", value);
  //   if (value.includes(".")) {
  //     const [whole, decimal] = String(value).split(".");
  //     console.log("hung aaa decimal:", decimal);
  //     if (decimal !== "" && Number(decimal) > 0) {
  //       return formatMoney(value, MoneyFormatter.TWO_DECIMAL_FORMAT);
  //     } else if (decimal === "") {
  //       return value;
  //     } else {
  //       return formatMoney(value);
  //     }
  //   } else {
  //     return value;
  //   }
  // };
  const validateQuantityValueWithCommas = (value, handler) => {
    // const formatValue = formatMoney(
    //   value,
    //   MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
    // );
    // console.log("hung TWO_DECIMAL_FORMAT_NO_COMMAS formatValue:", formatValue);
    // if (validateDecimal(formatValue)) {
    //   handler(formatMoney(value, MoneyFormatter.TWO_DECIMAL_FORMAT));
    // }
    console.log("hung validateQuantityValueWithCommas value:", value);
    handler(value);
  };
  return (
    <View style={styles.wrap} {...props}>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => {
            gotoInvoiceDetail();
            onGoToDetail();
          }}
          style={styles.titleWrap}
        >
          <View style={{ paddingRight: "14pt" }}>
            <Text style={{ fontSize: "12pt", color: Colors.Primary }}>
              品 名
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              numberOfLines={1}
              style={{ fontSize: "16pt", color: Colors.Primary }}
            >
              {invoice_detail?.name}【{invoice_detail?.product_info?.code}】
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.wrapBtnTrash}
          onPress={() => onTrash(invoice_detail)}
        >
          <View style={styles.btnTrash}>
            <Image
              resizeMode="contain"
              source={defaultIcons.trash}
              style={styles.imgTrash}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.container1}>
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <Text style={styles.fieldName}>数 量</Text>
          <View style={{ flex: 1 }}>
            <TextInput
              style={styles.textInput}
              value={invoice_detail?.amount}
              onChangeText={(value) => {
                validateQuantityValueWithCommas(
                  value,
                  invoice_detail.onQuantityChange
                );
                // if (validateDecimal(value)) {
                //   invoice_detail.onQuantityChange(value);
                // }
              }}
              onBlur={(value) => {
                amountOnBlur();
              }}
              keyboardType={"decimal-pad"}
              ref={(input) => (amountInput = input)}
              selectTextOnFocus
              onFocus={amountInput?.focus()}
            />
          </View>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "10pt",
          }}
        >
          <View>
            <Text style={styles.fieldName}>仕入単価</Text>
          </View>
          <View style={{ flex: 1 }}>
            <TextInput
              style={styles.textInput}
              value={formatMoney(invoice_detail?.unit_price)}
              onChangeText={(value) => {
                validateValueWithCommas(
                  value,
                  invoice_detail.onUnitPriceChange
                );
              }}
              onBlur={unitPriceOnBlur}
              keyboardType={"phone-pad"}
              ref={(input) => (unitPriceInput = input)}
              selectTextOnFocus
              onFocus={unitPriceInput?.focus()}
            />
          </View>
          <Text
            style={{
              color: Colors.Primary,
              marginLeft: 3,
              fontSize: "13pt",
            }}
          >
            円
          </Text>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "10pt",
          }}
        >
          <View>
            <Text style={styles.fieldName}>金 額</Text>
          </View>
          <View style={{ flex: 1 }}>
            <TextInput
              style={styles.textInput}
              value={formatMoney(invoice_detail?.total_price)}
            />
          </View>
          <Text
            style={{
              color: Colors.Primary,
              marginLeft: "3pt",
              fontSize: "13pt",
            }}
          >
            円
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: "5pt",
    marginBottom: "5pt",
    marginTop: "5pt",
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    flex: 1,
    paddingHorizontal: "10pt",
  },
  textInput: {
    flex: 1,
    paddingVertical: "6pt",
    paddingHorizontal: "5pt",
    marginLeft: "3pt",
    backgroundColor: Colors.PrimaryLight2,
    textAlign: "right",
  },
  fieldName: {
    fontSize: "12pt",
    color: Colors.Primary,
    fontWeight: "400",
  },
  btnTrash: {
    backgroundColor: "red",
    padding: "8pt",
    marginRight: "5pt",
    borderRadius: "20pt",
  },
  imgTrash: {
    height: "10pt",
    width: "10pt",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container1: {
    flexDirection: "row",
    paddingVertical: "5pt",
    paddingHorizontal: "10pt",
  },
  wrapBtnTrash: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: "5pt",
  },
});

export default Package;
