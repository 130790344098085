import React, { useState, forwardRef, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import { Colors } from "utils";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { commonIcons } from "assets/icons";
import CustomDatePickerStyle from "./CustomDatePickerStyle.css";

registerLocale("ja", ja);

const DateInput = ({
  label,
  style,
  containerStyle,
  textStyle,
  onDateChange,
  value,
  imageStyles,
  onCalendarOpen,
}) => {
  const [date, setDate] = useState();

  useEffect(() => {
    if (onDateChange !== undefined) onDateChange(date);
  }, [date]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TouchableOpacity onPress={onClick} style={style}>
      <View style={[styles.inputWrap, containerStyle]}>
        <View style={styles.textInput}>
          <Text style={textStyle}>{value || " "} </Text>
        </View>
        <Image
          source={commonIcons.calendar}
          style={[styles.icon, imageStyles]}
          resizeMode={"contain"}
        />
      </View>
    </TouchableOpacity>
  ));

  return (
    <>
      {!!label && <Text style={styles.label}>{label}</Text>}
      <DatePicker
        locale="ja"
        customInput={<CustomInput />}
        selected={date}
        onChange={setDate}
        popperPlacement={"auto"}
        value={value}
        dateFormat={"yyyy/MM/dd"}
        popperModifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
        onCalendarOpen={() => {
          if (onCalendarOpen !== undefined) onCalendarOpen();
        }}
      />
    </>
  );
};

export default DateInput;

const styles = StyleSheet.create({
  label: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginTop: 12,
    marginBottom: 6,
    fontSize: "12pt",
  },
  inputWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: 5,
  },
  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingLeft: 10,
    justifyContent: "center",
    fontSize: "12pt",
  },
  icon: {
    width: 20,
    height: 20,
    marginLeft: "5pt",
  },
});
