import React from "react";
import { StyleSheet, ScrollView, Dimensions } from "react-native";
import { useHistory } from "react-router-dom";
import { Block, TextView } from "components";
import { useNavigate } from "utils";

import { Colors } from "utils";
import { Footer } from "components/notice";
import NoticeStatus from "components/notice/NoticeStatus";
import NoticeToday from "components/notice/NoticeToday";
import { PAST_NOTICE } from "containers/past-notice/fakePastNotice";
import Title from "components/common/Title";

const { height } = Dimensions.get("window");
const HEIGHT = height * 0.1;
const styles = StyleSheet.create({
  containerStyle: {
    borderTopWidth: 2,
    borderTopColor: Colors.SecondPrimary,
    borderBottomWidth: 2,
    borderBottomColor: Colors.SecondPrimary,
  },
});

const Notice = () => {
  const navigate = useNavigate();

  const onPressNavigate = (pathname) => navigate(pathname);
  const onPressStatus = (key) => navigate("account");

  return (
    <Block block paddingHorizontal={24} paddingBottom={20}>
      <Title title={"お知らせ"} />
      <Block block>
        <NoticeToday routePrefix={"/admin"} showCreate />
        <NoticeToday routePrefix={"/admin"} office />
        <Block marginTop={10}>
          <TextView bold size={13} color={Colors.SecondPrimary}>
            ステータス通知
          </TextView>
          <Block marginTop={10} height={HEIGHT}>
            <ScrollView style={styles.containerStyle}>
              {PAST_NOTICE.map(({ title, content }, key) => (
                <NoticeStatus
                  onPress={() => onPressStatus(key)}
                  {...{ key, title, content }}
                />
              ))}
            </ScrollView>
          </Block>
        </Block>
        <Footer onPress={onPressNavigate} />
      </Block>
    </Block>
  );
};

export default Notice;
