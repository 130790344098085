


import { createStore ,applyMiddleware} from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import mySaga from './sagas'
import  axiosDebugger ,{setAxiosDefaultHeaders} from '../configs/axiosDebugger';


const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// let store = createStore(persistedReducer)
// let persistor = persistStore(store);
// export default createStore(persistedReducer,applyMiddleware(sagaMiddleware));

// export default () => {
//   let store = createStore(persistedReducer,applyMiddleware(sagaMiddleware))
//   let persistor = persistStore(store)
//   return  {store,persistor}
// }
let rehydrationComplete;

export function rehydration() {
  return new Promise((resolve, reject) => {
    rehydrationComplete = resolve;
  });
}

const store = createStore(persistedReducer,applyMiddleware(sagaMiddleware));
export default store
export const persistor = persistStore(store, {}, ()=>{
    if (rehydrationComplete)
        rehydrationComplete();
     const { isLoggedIn, account } = store.getState().auth;
     if (isLoggedIn) {
        const { token, uuid } = account;
        setAxiosDefaultHeaders({
          token,
          uuid,
        });
      } else {
        setAxiosDefaultHeaders({
          token: '',
          uuid: '',
        });
      }
});


 sagaMiddleware.run(mySaga)