import React, { useState, useEffect } from "react";
import { View, Text, ScrollView, StyleSheet, Image } from "react-native";
import { Block, Button, TextView, WrapperBlock } from "components";

import { PreviewItem } from "components/partition-preview";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { searchAccountVoucher } from "../../redux/actions/account";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { commonIcons } from "assets/icons";
import { usePrevious } from "../../utils/hook";
import Back from "components/common/Back";
import { Colors, Metrics, nor, useNavigate } from "utils";
import {
  ACCOUNT_PREVIOUS_VOUCHER_TYPE,
  ACCOUNT_STATUS_FILTER_DATA,
} from "../../utils/Constant";
import Title from "components/common/Title";

/**
 * 002-002 / 過去の仕切書
 * 002-003 / 過去の見積書
 * @param {*} props
 */
const PastDividers = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useHistory();
  const { type, from_date, to_date, customer_name } = history?.location?.state;
  const [sortedAccountVoucherList, setSortedAccountVoucherList] = useState([]);
  console.log("hung type:", type);
  const [sortAscending, setSortAscending] = useState({
    sortDate: true,
    sortCustomer: true,
    sortCode: true,
  });

  const setSortAscendingValue = (key, value) => {
    setSortAscending((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const prevSearchingAccountVoucher = usePrevious(
    props.isSearchingAccountVoucher
  );
  useEffect(() => {
    if (prevSearchingAccountVoucher && !props.isSearchingAccountVoucher) {
      if (props.isSearchedAccountVoucher) {
        setSortedAccountVoucherList(props.searchAccountVoucherList);
      }
    }
  }, [props.isSearchingAccountVoucher]);

  const sortDataHandler = (sortType) => {
    const data = sortedAccountVoucherList.sort((a, b) => {
      if (sortType === 1) {
        setSortAscendingValue("sortDate", !sortAscending.sortDate);
        if (a?.date > b?.date) return !sortAscending.sortDate ? 1 : -1;
        if (a?.date < b?.date) return !sortAscending.sortDate ? -1 : 1;
        return 0;
      } else if (sortType === 2) {
        setSortAscendingValue("sortCustomer", !sortAscending.sortCustomer);
        if (a?.customer_name > b?.customer_name)
          return !sortAscending.sortCustomer ? 1 : -1;
        if (a?.customer_name < b?.customer_name)
          return !sortAscending.sortCustomer ? -1 : 1;
        return 0;
      } else if (sortType === 3) {
        //単価順: Sort by Product Price
        setSortAscendingValue("sortCode", !sortAscending.sortCode);
        if (a?.voucher_code > b?.voucher_code)
          return !sortAscending.sortCode ? 1 : -1;
        if (a?.voucher_code < b?.voucher_code)
          return !sortAscending.sortCode ? -1 : 1;
        return 0;
      }
    });

    setSortedAccountVoucherList(data);
  };

  const onPressNavigate = () => {
    navigate("quotation-review", { pattern: "002-003" });
  };

  useEffect(() => {
    props.searchAccountVoucher({
      type: type,
      from_date: from_date,
      to_date: to_date,
      customer_name: customer_name !== undefined ? customer_name : "",
    });
  }, []);

  const onRecordPress = (item) => {
    if (type === ACCOUNT_PREVIOUS_VOUCHER_TYPE.ESTIMATE) {
      navigate("estimate-review", {
        estimate_id: item?.voucher_id,
      });
    } else if (type === ACCOUNT_PREVIOUS_VOUCHER_TYPE.INVOICE) {
      navigate("invoice-review", {
        invoice_id: item?.voucher_id,
      });
    }
  };
  return (
    <WrapperBlock>
      <Block block paddingBottom="30pt">
        <Title
          backTitle={"戻る"}
          title={
            type === ACCOUNT_PREVIOUS_VOUCHER_TYPE.INVOICE
              ? "仕切書一覧"
              : "見積書一覧"
          }
        />
        {/* <View style={styles.titleWrap}>
          <Back label={"戻る"} />
          <Text style={styles.title}>
            {location?.state?.title ? location?.state?.title : "仕切書一覧"}
          </Text>
        </View> */}

        <Block
          direction="row"
          paddingVertical="6.5pt"
          borderTopWidth="1pt"
          borderTopColor={Colors.Primary}
          marginTop="10pt"
        >
          <Block flex={2}>
            <Button onPress={() => sortDataHandler(1)}>
              <TextView bold size="10pt" color={Colors.Primary}>
                取引日 ▼
              </TextView>
            </Button>
          </Block>
          <Block flex={2}>
            <Button onPress={() => sortDataHandler(2)}>
              <TextView bold size="10pt" color={Colors.Primary}>
                取引先 ▼
              </TextView>
            </Button>
          </Block>
          <Block flex={4}>
            <Button onPress={() => sortDataHandler(3)}>
              <TextView bold size="10pt" color={Colors.Primary}>
                {type == ACCOUNT_PREVIOUS_VOUCHER_TYPE.ESTIMATE
                  ? "見積書コード ▼"
                  : "仕切書コード ▼"}
              </TextView>
            </Button>
          </Block>
          <Block block />
        </Block>
        <Block
          borderWidth="1pt"
          borderColor={Colors.Primary}
          borderRightWidth="0pt"
          borderLeftWidth="0pt"
          block
        >
          <ScrollView>
            {sortedAccountVoucherList?.map((item, index) => (
              <PreviewItem
                key={`${index}`}
                value1={formatDateTime(
                  item.date,
                  DateTimeFormatter.YYYY_MM_DD_JP_DOT
                )}
                value2={item.customer_name}
                value3={item.voucher_code}
                value4={
                  item.pdf_url !== undefined &&
                  item.pdf_url !== "" && (
                    <Image
                      source={commonIcons.pdf}
                      style={{ height: "15pt", width: "12pt" }}
                      resizeMode="contain"
                    />
                  )
                }
                centers={[1, 1, 1, 1]}
                blocks={[2, 2, 4, 1]}
                onValue1Press={() => onRecordPress(item)}
                onValue2Press={() => onRecordPress(item)}
                onValue3Press={() => onRecordPress(item)}
                onValue4Press={() => window.open(item.pdf_url)}
              />
            ))}
          </ScrollView>
        </Block>
      </Block>
    </WrapperBlock>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: 24,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: nor(18),
  },
});

const mapStateToProps = (state) => ({
  searchAccountVoucherList: state.account.searchAccountVoucherList,
  isSearchingAccountVoucher: state.account.isSearchingAccountVoucher,
  isSearchedAccountVoucher: state.account.isSearchedAccountVoucher,
});
const mapDispatchToProps = {
  searchAccountVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastDividers);
