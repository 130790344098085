import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  FlatList,
} from "react-native";
import { Button } from "components";
import Card from "components/transfer/Card";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createTransfer, searchTransfer } from "../../redux/actions/transfer";
import FullLoading from "components/common/FullLoading";
import { Colors, useNavigate } from "utils";
import { usePrevious } from "../../utils/hook";
import Title from "components/common/Title";

/**
 * 007-002 / 振込依頼・振込依頼一覧
 * @param {*} props
 */
const TransferList = (props) => {
  const navigate = useNavigate();
  const history = useHistory();
  const { selectedCustomer } = history?.location?.state;
  const { transfer_id } = history?.location?.state;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.searchTransfer({
      customer_id: selectedCustomer == undefined ? -1 : selectedCustomer.id,
      transfer_id: transfer_id == undefined ? -1 : transfer_id,
    });
    setLoading(true);
  }, []);

  const goToAccount = () => {
    //TODO:  Go to [002-001 / アカウント]
  };

  const goToHomeScreen = () => {
    //Go to [001-001 / TOP（貴金属相場）]
    navigate("");
  };

  const prevSearchingTransfer = usePrevious(props.isSearchingTransfer);
  useEffect(() => {
    if (prevSearchingTransfer && !props.isSearchingTransfer) {
      setLoading(false);
    }
  }, [props.isSearchingTransfer]);

  return (
    <View style={styles.wrap}>
      <Title title={"振込依頼一覧"} />
      <ScrollView style={{ height: "50vh" }}>
        {props.searchTransfers?.map((item, index) => {
          return <Card key={index} item={item} />;
        })}
      </ScrollView>
      {loading && <FullLoading />}
      <View style={styles.buttonWrap}>
        <TouchableOpacity
          onPress={() => navigate("account")}
          style={[styles.button, { marginRight: 10 }]}
        >
          <Text style={{ color: "white", fontSize: "13pt" }}>
            ステータスを確認
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigate("/")} style={styles.button}>
          <Text style={{ color: "white", fontSize: "13pt" }}>TOPへ</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: "24pt",
  },
  field: {
    fontWeight: "bold",
    color: Colors.Primary,
    marginBottom: 5,
  },
  note: {
    color: Colors.Primary,
    fontWeight: "normal",
  },
  button: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    paddingVertical: 10,
    borderRadius: 3,
  },
  buttonWrap: { flexDirection: "row", marginTop: 5 },
});

const mapStateToProps = (state) => ({
  searchTransfers: state.transfer.searchTransfers,
  isSearchingTransfer: state.transfer.isSearchingTransfer,
  isSearchedTransfer: state.transfer.isSearchedTransfer,
});
const mapDispatchToProps = {
  searchTransfer,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferList);
