import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Colors } from "utils";

const InvoiceConfirm = ({ invoice }) => {
  return (
    <View style={{ minHeight: 300 }}>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text style={{ fontWeight: "bold", fontSize: 20 }}>
          保存中のリストがあります。
        </Text>
        <Text style={{ fontSize: 16 }}>この取引を続けますか？</Text>
      </View>
      <View
        style={{
          width: "100%",
          height: 2,
          backgroundColor: "black",
          marginVertical: 10,
        }}
      ></View>
      <Text style={{ marginStart: 10, fontSize: 16 }}>
        {invoice?.customer_info?.name}
      </Text>
      <ScrollView style={{ maxHeight: 200 }}>
        {invoice?.invoice_details?.map((detail, index) => {
          return (
            <View key={`${index}`} style={{}}>
              <InvoiceDetail invoice_detail={detail} />
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

const InvoiceDetail = ({ invoice_detail }) => {
  return (
    <View style={styles.wrap}>
      <TouchableOpacity onPress={() => {}} style={styles.titleWrap}>
        <View style={{ paddingRight: 10 }}>
          <Text style={{ fontSize: 15, color: Colors.Primary }}>品 名</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text
            style={{ fontSize: 16, color: Colors.Primary }}
            numberOfLines={1}
          >
            {invoice_detail?.name}【{invoice_detail?.product_info?.code}】
          </Text>
        </View>
      </TouchableOpacity>
      <View style={{ flexDirection: "row", padding: 5 }}>
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <View>
            <Text style={styles.fieldName}>数 量</Text>
          </View>
          <View style={{ paddingHorizontal: 10 }}>
            <Text style={styles.amount}>{invoice_detail?.amount}</Text>
          </View>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
            marginStart: 10,
          }}
        >
          <View>
            <Text style={styles.fieldName}>仕入単価</Text>
          </View>
          <View style={{ paddingHorizontal: 10 }}>
            <Text style={styles.amount}>{invoice_detail?.unit_price}</Text>
          </View>
          <Text
            style={{
              color: Colors.Primary,
              marginLeft: 3,
              fontSize: 15,
            }}
          >
            円
          </Text>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
          }}
        >
          <View>
            <Text style={styles.fieldName}>金 額</Text>
          </View>
          <View style={{ paddingHorizontal: 10 }}>
            <Text style={styles.amount}>{invoice_detail?.total_price}</Text>
          </View>
          <Text
            style={{
              color: Colors.Primary,
              marginLeft: 3,
              fontSize: 15,
            }}
          >
            円
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    borderColor: Colors.PrimaryLight2,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 5,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomColor: Colors.PrimaryLight2,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
  fieldName: {
    fontSize: 15,
    color: Colors.Primary,
    fontWeight: "bold",
  },
  amount: {
    fontSize: 15,
  },
});

export default InvoiceConfirm;
