import React, { useState, useEffect } from 'react';

export const handleFocus = (event) => event.target.select();

// const [timeOutHandler, setTimeOutHandler] = useState(undefined);

// export const searchTimeOut = (search, fetApi) => {
//   if (timeOutHandler !==undefined) clearTimeout(timeOutHandler);
//   setTimeOutHandler(
//     setTimeout(() => {
//       if (search !== "") {
//         fetApi(search);
//       }
//     }, 300)
//   );
// };


export const isAmountGreaterThanZero = (unit_price) => {
    return unit_price !== undefined && unit_price !== null && unit_price > 0;
  };
  
