import React, { useState, useEffect } from "react";
import Block from "components/common/Block";
import Input from "components/common/Input";
import DateInput from "components/common/DateInput";
import { Colors } from "utils";
import TextView from "components/common/TextView";
import Button from "components/common/Button";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import { connect } from "react-redux";
import { searchCustomers } from "../../redux/actions/customer";
import {
  formatDateTime,
  DateTimeFormatter,
  formatDateTimeLocal,
} from "../../utils/DateUtility";

const PastQuote = ({ onPressNavigate, onSearch, onCalendarOpen, ...props }) => {
  const [
    searchCustomerTimeOutHandler,
    setSearchCustomerTimeOutHandler,
  ] = useState(undefined);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [showLeftCalendar, setShowLeftCalendar] = useState(false);
  const [showRightCalendar, setShowRightCalendar] = useState(false);

  const searchCustomerHandler = (searchName) => {
    if (searchCustomerTimeOutHandler !== undefined)
      clearTimeout(searchCustomerTimeOutHandler);
    setSearchCustomerTimeOutHandler(
      setTimeout(() => {
        if (searchName !== "") {
          props.searchCustomers({
            name: searchName,
          });
        }
      }, 300)
    );
  };

  return (
    <Block>
      <Block direction="row" marginTop={10}>
        <Block centered marginRight={10}>
          <TextView bold color={Colors.Primary}>
            取引先で絞り込む
          </TextView>
        </Block>
        <Block block>
          {/* <Input paddingVertical={5} /> */}
          <InputWithSuggestion
            placeHolder={""}
            label={""}
            fetchApi={(searchName) => searchCustomerHandler(searchName)}
            data={props.searchCustomerResults}
            onSelectedItem={(item) => setCustomerName(item?.name)}
            inputPaddingVertical={5}
          />
        </Block>
      </Block>
      <Block>
        <TextView bold color={Colors.Primary}>
          期間を指定する
        </TextView>
      </Block>
      <Block direction="row" middle marginVertical={10}>
        <Block block style={{ zIndex: showLeftCalendar ? 2 : 1 }}>
          <DateInput
            onDateChange={(date) => {
              setFromDate(
                formatDateTimeLocal(date, DateTimeFormatter.YYYY_MM_DD_HYPHEN)
              );
            }}
            onCalendarOpen={() => {
              setShowLeftCalendar(true);
              setShowRightCalendar(false);
              onCalendarOpen();
            }}
          />
        </Block>
        <Block marginHorizontal={10}>
          <TextView regular h5 color={Colors.SecondPrimary}>
            ~
          </TextView>
        </Block>
        <Block block style={{ zIndex: showRightCalendar ? 2 : 1 }}>
          <DateInput
            onDateChange={(date) => {
              setToDate(
                formatDateTimeLocal(date, DateTimeFormatter.YYYY_MM_DD_HYPHEN)
              );
            }}
            onCalendarOpen={() => {
              setShowLeftCalendar(false);
              setShowRightCalendar(true);
              onCalendarOpen();
            }}
          />
        </Block>
        <Button
          onPress={() => onSearch(fromDate, toDate, customerName)}
          justifyContent="flex-end"
          alignItems="flex-end"
          paddingVertical={7}
          paddingHorizontal={20}
          color={Colors.SecondPrimary}
          borderRadius={5}
          marginLeft={10}
        >
          <TextView color={Colors.White} size={"9pt"} bold>
            一覧を表示
          </TextView>
        </Button>
      </Block>
    </Block>
  );
};

const mapStateToProps = (state) => ({
  searchCustomerResults: state.customer.searchCustomerResults,
  // isUpdatingCustomerEstimate: state.estimate.isUpdatingCustomerEstimate,
  // isUpdatedCustomerEstimate: state.estimate.isUpdatedCustomerEstimate,
  // updatedCustomerEstimate: state.estimate.updatedCustomerEstimate,
  // invoiceDetailSearchByProductName:
  //   state.invoice.invoiceDetailSearchByProductName,
  // products: state.product.products,
});
const mapDispatchToProps = {
  searchCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastQuote);
