import React, { useEffect, useState } from "react";
import {
  Block,
  Empty,
  TextView,
  FooterButton,
  WrapperBlock,
  Button,
  CheckBox,
} from "components";
import { Colors, isAmountGreaterThanZero } from "utils";
import { data, data1 } from "./fakeQuotationReview";
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Dimensions,
  ScrollView,
  Text,
} from "react-native";
import { useNavigate } from "utils";
import { commonIcons } from "assets/icons";
import { TextItem } from "components/quotation-review";
import { useLocation } from "react-router-dom";
import { PreviewItem } from "components/partition-preview";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import images from "assets/images";
import {
  ESTIMATE_SEND_TYPE,
  ESTIMATE_STATUS,
  ESTIMATE_PIC_ATTACH,
  QUOTATION_FLOW,
} from "../../utils/Constant";
import {
  getEstimate,
  updateEstimate,
  uploadPdf,
  generatePdf,
} from "../../redux/actions/estimate";
import Title from "components/common/Title";
import { Alert } from "../../App";

const screenHeight = Dimensions.get("window").height;
const HEIGHT_IMAGE = screenHeight / 7;
const SORT_TYPE = {
  SORT_PRODUCT_CODE: 1,
  SORT_PRODUCT_NAME: 2,
  SORT_UNIT_PRICE: 3,
  SORT_MAKER: 4,
};

const sortData = [
  {
    name: "コード順",
    type: SORT_TYPE.SORT_PRODUCT_CODE,
  },
  {
    name: "メーカー",
    type: SORT_TYPE.SORT_MAKER,
  },
  {
    name: "品名順",
    type: SORT_TYPE.SORT_PRODUCT_NAME,
  },
  {
    name: "単価順",
    type: SORT_TYPE.SORT_UNIT_PRICE,
  },
];

/**
 * 003-010-1 / 見積書プレビュー（画像なし）
 */
const QuotationReview = (props) => {
  const navigate = useNavigate();
  const [empty, setEmpty] = useState(false);
  const [select, setSelect] = useState(true);
  const [selectSortType, setSelectSortType] = useState(sortData[0]);
  const [sortAscending, setSortAscending] = useState(true);
  const [sortedEstimateDetail, setSortedEstimateDetail] = useState(undefined);
  const location = useLocation();
  const { estimate, pattern } = location?.state;
  const [nextScreenPattern, setNextScreenPattern] = useState(0);
  const [sendTypeChange, setSendTypeChange] = useState(ESTIMATE_SEND_TYPE.FAX);
  const [updateSort, setUpdateSort] = useState(false);
  // const [pdfShareHandler, setPdfShareHandler] = useState(false);
  const [noImageSortAscending, setNoImageSortAscending] = useState({
    sortCode: true,
    sortName: true,
    sortUnitPrice: true,
    sortMaker: true,
  });
  const [noImageSortType, setNoImageSortType] = useState(
    SORT_TYPE.SORT_PRODUCT_CODE
  );

  const submitRequest = () => {
    const updateEstimate = {
      id: estimate.id,
      status: ESTIMATE_STATUS.SEND_REQUESTED,
      send_type: sendTypeChange,
    };
    props.updateEstimate(updateEstimate);
    setNextScreenPattern(QUOTATION_FLOW.SUBMIT_REQUEST);
  };
  const finishEstimate = () => {
    const updateEstimate = {
      id: estimate.id,
      status: ESTIMATE_STATUS.ESTIMATE_COMPLETED,
    };
    props.updateEstimate(updateEstimate);
    setNextScreenPattern(QUOTATION_FLOW.FINISH_ESTIMATE);
  };
  const selectSortTypeHandler = (sortType) => {
    setSelect((e) => !e);
    const data = { ...sortType };

    setSelectSortType(data);
  };

  useEffect(() => {
    if (select) {
      sortDataHandler();
    }
  }, [selectSortType, select]);

  useEffect(() => {
    sortDataHandler();
  }, [sortAscending]);

  const sortDataHandler = () => {
    const data = estimate.estimate_details;

    const data1 = data.sort((a, b) => {
      if (selectSortType.type === SORT_TYPE.SORT_PRODUCT_CODE) {
        //コード順: Sort by Product Code

        if (a?.product_info?.code > b?.product_info?.code)
          return sortAscending ? 1 : -1;
        if (a?.product_info?.code < b?.product_info?.code)
          return sortAscending ? -1 : 1;
        return 0;
      } else if (selectSortType.type === SORT_TYPE.SORT_PRODUCT_NAME) {
        //品名順: Sort by Product Name
        if (a?.name > b?.name) return sortAscending ? 1 : -1;
        if (a?.name < b?.name) return sortAscending ? -1 : 1;
        return 0;
      } else if (selectSortType.type === SORT_TYPE.SORT_UNIT_PRICE) {
        //単価順: Sort by Product Price
        if (a?.unit_price > b?.unit_price) return sortAscending ? 1 : -1;
        if (a?.unit_price < b?.unit_price) return sortAscending ? -1 : 1;
        return 0;
      } else if (selectSortType.type === SORT_TYPE.SORT_MAKER) {
        //Maker: Sort by Maker
        const aa =
          a?.product_info?.maker_info?.name !== undefined
            ? a?.product_info?.maker_info?.name
            : "";
        const bb =
          b?.product_info?.maker_info?.name !== undefined
            ? b?.product_info?.maker_info?.name
            : "";
        // console.log("hung aa:", aa, "bb:", bb);
        if (aa > bb) return !sortAscending ? 1 : -1;
        if (aa < bb) return !sortAscending ? -1 : 1;
        return 0;
      }
    });

    const haveImageData = data1.filter(
      (item) => item.pic_attach === ESTIMATE_PIC_ATTACH.ATTACHED
    );
    const noImageData = data1.filter(
      (item) => item.pic_attach === ESTIMATE_PIC_ATTACH.NO_ATTACH
    );
    const sortDataWithImageShowFirst = [...haveImageData, ...noImageData];
    setSortedEstimateDetail(sortDataWithImageShowFirst);
    setUpdateSort((prev) => !prev);
  };
  useEffect(() => {
    // setSortedEstimateDetail(estimate?.estimate_details);
  }, []);
  useEffect(() => {
    generatePdfHandler();
  }, [updateSort]);

  const generatePdfHandler = () => {
    let sort_condition = "";
    let is_ascending = true;

    if (pattern === QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE) {
      sort_condition = selectSortType.type;
      is_ascending = sortAscending;
    } else {
      sort_condition = noImageSortType;
      is_ascending =
        noImageSortType === SORT_TYPE.SORT_PRODUCT_CODE
          ? noImageSortAscending.sortCode
          : noImageSortType === SORT_TYPE.SORT_PRODUCT_NAME
          ? noImageSortAscending.sortName
          : noImageSortType === SORT_TYPE.SORT_UNIT_PRICE
          ? noImageSortAscending.sortUnitPrice
          : noImageSortAscending.sortMaker;
    }

    props.generatePdf({
      id: estimate.id,
      sort_condition: sort_condition,
      is_ascending: is_ascending,
    });
  };

  const prevUpdatingEstimate = usePrevious(props.isUpdatingEstimate);
  useEffect(() => {
    if (prevUpdatingEstimate && !props.isUpdatingEstimate) {
      if (props.isUpdatedEstimate) {
        generatePdfHandler();
        navigate("quotation-complete", {
          estimate: props.updatedEstimate,
          pattern: nextScreenPattern,
        });
      }
    }
  }, [props.isUpdatingEstimate]);

  const setNoImageSortAscendingValue = (key, value) => {
    setNoImageSortAscending((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const sortDataNoImageHandler = (noImageSortType) => {
    const data = estimate.estimate_details;

    setNoImageSortType(noImageSortType);
    const data1 = data.sort((a, b) => {
      if (noImageSortType === SORT_TYPE.SORT_PRODUCT_CODE) {
        //コード順: Sort by Product Code
        setNoImageSortAscendingValue(
          "sortCode",
          !noImageSortAscending.sortCode
        );

        if (a?.product_info?.code > b?.product_info?.code)
          return !noImageSortAscending.sortCode ? 1 : -1;
        if (a?.product_info?.code < b?.product_info?.code)
          return !noImageSortAscending.sortCode ? -1 : 1;
        return 0;
      } else if (noImageSortType === SORT_TYPE.SORT_PRODUCT_NAME) {
        //品名順: Sort by Product Name
        setNoImageSortAscendingValue(
          "sortName",
          !noImageSortAscending.sortName
        );
        if (a?.name > b?.name) return !noImageSortAscending.sortName ? 1 : -1;
        if (a?.name < b?.name) return !noImageSortAscending.sortName ? -1 : 1;
        return 0;
      } else if (noImageSortType === SORT_TYPE.SORT_UNIT_PRICE) {
        //単価順: Sort by Product Price
        setNoImageSortAscendingValue(
          "sortUnitPrice",
          !noImageSortAscending.sortUnitPrice
        );
        if (a?.unit_price > b?.unit_price)
          return !noImageSortAscending.sortUnitPrice ? 1 : -1;
        if (a?.unit_price < b?.unit_price)
          return !noImageSortAscending.sortUnitPrice ? -1 : 1;
        return 0;
      } else if (noImageSortType === SORT_TYPE.SORT_MAKER) {
        //Maker: Sort by Maker
        const aa =
          a?.product_info?.maker_info?.name !== undefined
            ? a?.product_info?.maker_info?.name
            : "";
        const bb =
          b?.product_info?.maker_info?.name !== undefined
            ? b?.product_info?.maker_info?.name
            : "";

        setNoImageSortAscendingValue(
          "sortMaker",
          !noImageSortAscending.sortMaker
        );
        if (aa > bb) return !noImageSortAscending.sortMaker ? 1 : -1;
        if (aa < bb) return !noImageSortAscending.sortMaker ? -1 : 1;
        return 0;
      }
    });

    setSortedEstimateDetail(data1);
    setUpdateSort((prev) => !prev);
  };

  const quotationPreviewUI = () => (
    <FooterButton
      label=""
      button1="この内容で送付依頼を送る"
      button2="見積を保存"
      onPress1={() => submitRequest()}
      onPress2={() => finishEstimate()}
    />
  );

  const renderBottomUI = () => {
    if (
      pattern == QUOTATION_FLOW.QUOTATION_REVIEW_NO_IMAGE ||
      pattern == QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE
    ) {
      return quotationPreviewUI();
    }
  };

  return (
    <View>
      <Block paddingHorizontal="24pt">
        <Title backTitle={"見積単価"} title="見積書プレビュー" />
      </Block>
      <Block block style={{}}>
        <View style={{ flex: 1 }}>
          <Block block>
            <Block paddingHorizontal="24pt">
              <TextView size="13pt" color={Colors.Primary} bold>
                御見積日：{formatDateTime(estimate?.date)}
              </TextView>
              <TextView size="13pt" color={Colors.Primary} bold>
                取引先コード：{estimate?.customer_info?.code}
              </TextView>
              <TextView size="13pt" color={Colors.Primary} bold>
                取引先名：{estimate?.customer_info?.name} {"様"}
              </TextView>
              <TextView size="13pt" color={Colors.Primary} bold>
                担当者：{estimate?.account_info?.name}
              </TextView>
              <Block
                marginTop="10pt"
                borderBottomWidth="1pt"
                borderBottomColor={Colors.Primary}
                marginBottom="5pt"
              >
                <TextView size="13pt" color={Colors.Primary} bold>
                  見積書コード：{estimate?.code}
                </TextView>
              </Block>
            </Block>

            {/* 003-010-1 / 見積書プレビュー（画像なし） – 1 */}

            {(pattern === "002-003" ||
              pattern === QUOTATION_FLOW.QUOTATION_REVIEW_NO_IMAGE) && (
              <Block
                flex={3}
                marginTop="-5pt"
                paddingBottom="10pt"
                paddingHorizontal="24pt"
              >
                <PreviewItem
                  isHeader
                  value1="コード ▼"
                  value2="メーカー ▼"
                  value3="品名・分類 ▼"
                  value4="単  価 ▼"
                  blocks={[1, 1, 1, 1]}
                  centers={["left", "left", "left", "right"]}
                  onValue1Press={() =>
                    sortDataNoImageHandler(SORT_TYPE.SORT_PRODUCT_CODE)
                  }
                  onValue2Press={() =>
                    sortDataNoImageHandler(SORT_TYPE.SORT_MAKER)
                  }
                  onValue3Press={() =>
                    sortDataNoImageHandler(SORT_TYPE.SORT_PRODUCT_NAME)
                  }
                  onValue4Press={() =>
                    sortDataNoImageHandler(SORT_TYPE.SORT_UNIT_PRICE)
                  }
                />
                <ScrollView
                  contentContainerStyle={{
                    borderBottomWidth: "1pt",
                    borderTopColor: Colors.Primary,
                  }}
                  style={{ height: screenHeight - 625 }}
                >
                  {sortedEstimateDetail?.map((item, index) => {
                    return (
                      <PreviewItem
                        key={`${index}`}
                        value1={item?.product_info?.code}
                        value2={item?.product_info?.maker_info?.name}
                        value3={item.name}
                        value4={renderUnitPrice(item)}
                        blocks={[1, 1, 1, 1]}
                        centers={["left", "left", "left", "right"]}
                      />
                    );
                  })}
                </ScrollView>
              </Block>
            )}
            {/*pattern === 003-009-2  */}
            {(pattern === "003-009-1" ||
              pattern === QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE) && (
              <Block block>
                <Block
                  direction="row"
                  middle
                  style={{ zIndex: 1 }}
                  paddingHorizontal="24pt"
                >
                  <TextView size="12pt" color={Colors.Primary}>
                    並べ替え
                  </TextView>
                  <Block width="20%" height="20pt" marginLeft="10pt" center>
                    {!select ? (
                      <Block
                        position="absolute"
                        height="120pt"
                        borderWidth="1pt"
                        borderColor={Colors.SecondPrimary}
                        borderRadius="3pt"
                        width="100%"
                        color={Colors.White}
                        bottom="-100pt"
                        style={{
                          zIndex: 1,
                        }}
                      >
                        <ScrollView>
                          {sortData.map((item, index) => (
                            <Button
                              key={`${index}`}
                              onPress={() => selectSortTypeHandler(item)}
                              style={{
                                borderBottomWidth: 1,
                                borderBottomColor: Colors.SecondPrimary,
                                paddingVertical: 6,
                                paddingLeft: 5,
                              }}
                            >
                              <TextView
                                size="12pt"
                                color={Colors.Primary}
                                regular
                              >
                                {item.name}
                              </TextView>
                            </Button>
                          ))}
                        </ScrollView>
                      </Block>
                    ) : (
                      <Block
                        borderWidth="1pt"
                        borderColor={Colors.SecondPrimary}
                        borderRadius="3pt"
                        width="100%"
                        marginTop="10pt"
                        color={Colors.White}
                      >
                        <Button
                          onPress={() => setSelect((e) => !e)}
                          style={{
                            borderBottomWidth: 1,
                            borderBottomColor: Colors.SecondPrimary,
                            paddingVertical: 6,
                            paddingLeft: 5,
                          }}
                        >
                          <TextView size="12pt" color={Colors.Primary} regular>
                            {selectSortType.name}
                          </TextView>
                        </Button>
                      </Block>
                    )}
                  </Block>
                  <Block marginHorizontal="10pt">
                    <CheckBox
                      label="昇 順"
                      check={sortAscending}
                      onCheckBoxPress={() => setSortAscending((e) => !e)}
                      fontSize={"12pt"}
                    />
                  </Block>
                  <CheckBox
                    label="降 順"
                    check={!sortAscending}
                    onCheckBoxPress={() => setSortAscending((e) => !e)}
                    fontSize={"12pt"}
                  />
                </Block>
                <View style={{ height: screenHeight - 605 }}>
                  <FlatList
                    style={{ marginTop: 15 }}
                    extraData={updateSort}
                    data={sortedEstimateDetail}
                    keyExtractor={(item, index) => `${index}`}
                    renderItem={renderItem}
                  />
                </View>
              </Block>
            )}
          </Block>
        </View>

        <View flex={0.3} style={{ marginTop: 10 }}>
          <Block block />
          {(pattern === "003-009-1" ||
            pattern === QUOTATION_FLOW.QUOTATION_REVIEW_NO_IMAGE ||
            pattern === QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE) && (
            <View style={[styles.radio, { alignItems: "flex-end" }]}>
              <View style={{ flex: 1 }}>
                <View style={[styles.radio, { marginStart: 10 }]}>
                  <View style={styles.radio}>
                    <input
                      type={"radio"}
                      name={"transfertype"}
                      id={"fax"}
                      checked={sendTypeChange === ESTIMATE_SEND_TYPE.FAX}
                      onChange={(value) =>
                        setSendTypeChange(ESTIMATE_SEND_TYPE.FAX)
                      }
                    />
                    <label
                      for={"fax"}
                      style={{ color: Colors.Primary, fontSize: 20 }}
                    >
                      FAX
                    </label>
                  </View>
                  <View style={[styles.radio, { marginLeft: 10 }]}>
                    <input
                      type={"radio"}
                      name={"transfertype"}
                      id={"email"}
                      checked={sendTypeChange === ESTIMATE_SEND_TYPE.EMAIL}
                      onChange={(value) =>
                        setSendTypeChange(ESTIMATE_SEND_TYPE.EMAIL)
                      }
                    />
                    <label
                      for={"email"}
                      style={{ color: Colors.Primary, fontSize: 20 }}
                    >
                      メール
                    </label>
                  </View>
                </View>
              </View>
              {props.generatedPdfUrl !== "" && (
                <View style={{ flex: 1, marginTop: 5 }}>
                  <TouchableOpacity
                    onPress={() => {
                      if (navigator.share) {
                        navigator
                          .share({
                            title: "Pdf",
                            url: props.generatedPdfUrl,
                          })
                          .then(() => {
                            console.log("Thanks for sharing!");
                          })
                          .catch((error) => {
                            //this will catch the second share attempt
                            window.location.reload(true); // now share works again
                          });
                      } else {
                        // fallback
                      }
                    }}
                  >
                    <Image
                      source={commonIcons.share}
                      style={{
                        width: "35pt",
                        height: "35pt",

                        alignSelf: "center",
                        bottom: "10pt",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View style={{ flex: 1 }} />
            </View>
          )}

          {renderBottomUI()}
          {pattern === "002-003" && (
            <Block position="absolute" bottom="20pt" width="100%">
              <FooterButton label="" button1="選  択" />
            </Block>
          )}
        </View>
      </Block>
    </View>
  );
};

const styles = StyleSheet.create({
  radio: {
    flexDirection: "row",
  },
});

// export default QuotationReview;

const mapStateToProps = (state) => ({
  // isGettingEstimate: state.estimate.isGettingEstimate,
  // isGotEstimate: state.estimate.isGotEstimate,
  // estimate: state.estimate.estimate,
  isUpdatingEstimate: state.estimate.isUpdatingEstimate,
  isUpdatedEstimate: state.estimate.isUpdatedEstimate,
  updatedEstimate: state.estimate.updatedEstimate,

  // isUploadingEstimatePdf: state.estimate.isUploadingEstimatePdf,
  // isUploadedEstimatePdf: state.estimate.isUploadedEstimatePdf,
  // estimateWhichUploadPdf: state.estimate.estimateWhichUploadPdf,
  generatedPdfUrl: state.estimate.generatedPdfUrl,
});
const mapDispatchToProps = {
  // getEstimate,
  updateEstimate,
  uploadPdf,
  generatePdf,

  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationReview);

const renderItem = ({ item, index }) => {
  const { pic_path } = item.product_info;
  return (
    <Block>
      {item.pic_attach == ESTIMATE_PIC_ATTACH.ATTACHED && (
        <Image
          source={{
            uri:
              pic_path == undefined || pic_path == ""
                ? images.no_image
                : pic_path,
          }}
          style={{ height: "80vw", width: "100%" }}
          resizeMode="contain"
        />
      )}
      <Block paddingHorizontal="24pt">
        <TextItem
          value1={item.product_info?.code}
          value2={item.product_info?.maker_info?.name}
          value3={item.name}
          value4={renderUnitPrice(item)}
        />
      </Block>
    </Block>
  );
};

const renderUnitPrice = (estimateDetail) => {
  if (
    isAmountGreaterThanZero(estimateDetail?.unit_price) &&
    isAmountGreaterThanZero(estimateDetail?.unit_price2)
  ) {
    return (
      formatMoney(estimateDetail.unit_price2) +
      " ~ " +
      formatMoney(estimateDetail.unit_price) +
      "円"
    );
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price)) {
    return formatMoney(estimateDetail.unit_price) + "円";
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price2)) {
    return formatMoney(estimateDetail.unit_price2) + "円";
  } else {
    return formatMoney(estimateDetail.unit_price) + "円";
  }
};
