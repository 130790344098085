import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
} from "react-native";
import {} from "components";
import Card from "components/transfer/Card";
import { useHistory } from "react-router-dom";
import { Colors, useNavigate } from "utils";
import Title from "components/common/Title";

/**
 * 007-003 / 振込依頼・送信完了画面
 * @param {*} props
 */
const Transfered = (props) => {
  const history = useHistory();
  const navigate = useNavigate();
  const { createdTransfer } = history?.location?.state;
  console.log("hung createdTransfer:", createdTransfer);

  const goToAccount = () => {
    //TODO:  Go to [002-001 / アカウント]
  };

  const goToHomeScreen = () => {
    //Go to [001-001 / TOP（貴金属相場）]
    navigate("");
  };

  return (
    <View style={styles.wrap}>
      <Title title={"振込依頼完了"} />
      <ScrollView style={{ height: "50vh" }}>
        {createdTransfer !== undefined && (
          <Card hideStatus item={createdTransfer} />
        )}
      </ScrollView>
      <View>
        <TouchableOpacity style={[styles.button, styles.outlineButton]}>
          <Text style={{ color: Colors.Primary, fontSize: "12pt" }}>
            振込依頼を送信しました。
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.buttonWrap}>
        <TouchableOpacity
          style={[styles.button, { marginRight: 10 }]}
          onPress={() => navigate("account")}
        >
          <Text style={{ color: "white", fontSize: "12pt" }}>
            ステータスを確認
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => navigate("/")}>
          <Text style={{ color: "white", fontSize: "12pt" }}>TOPへ</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: "24pt",
  },
  field: {
    fontWeight: "bold",
    color: Colors.Primary,
    marginBottom: 5,
  },
  note: {
    color: Colors.Primary,
    fontWeight: "normal",
  },
  button: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    paddingVertical: 10,
    borderRadius: 3,
  },
  buttonWrap: { flexDirection: "row", marginTop: 5 },
  outlineButton: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: Colors.Primary,
    alignSelf: "center",
    paddingHorizontal: 32,
    marginVertical: 10,
  },
});

export default Transfered;
