import { takeEvery, put } from "redux-saga/effects";
import {
  GET_TRANSFER_CUSTOMERS,
  UPDATE_CUSTOMER,
  GET_LAST_INVOICE,
  INSERT_PRODUCTS_TO_INVOICE,
  GET_INVOICE,
  UPDATE_INVOICE,
  // GET_TWO_LAST_INVOICE,
  GET_OTHER_INVOICE,
  DELETE_INVOICE_DETAIL,
  SEARCH_PAST_PERFORMANCE,
  INVOICE_UPLOAD_PDF,
  CREATE_TRANSFER_INVOICE,
  SEARCH_PRODUCT_IN_INVOICE_DETAIL,
  INVOICE_GENERATE_PDF,
} from "../actions/invoice";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* updateCustomer(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.UPDATE_CUSTOMER, params);
  if (result.success) {
    yield put({
      type: UPDATE_CUSTOMER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: UPDATE_CUSTOMER.FAIL,
      result,
    });
  }
}

function* getLastInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.INVOICE.GET_LAST_INVOICE, params)
  );
  if (result.success) {
    yield put({
      type: GET_LAST_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_LAST_INVOICE.FAIL,
      result,
    });
  }
}

function* insertProductsToInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(
    API.INVOICE.INSERT_PRODUCTS_TO_INVOICE,
    params
  );

  if (result.success) {
    yield put({
      type: INSERT_PRODUCTS_TO_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: INSERT_PRODUCTS_TO_INVOICE.FAIL,
      result,
    });
  }
}

function* getInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(replaceParams(API.INVOICE.GET_INVOICE, params));
  if (result.success) {
    yield put({
      type: GET_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_INVOICE.FAIL,
      result,
    });
  }
}

function* updateInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.UPDATE_INVOICE, params);
  if (result.success) {
    yield put({
      type: UPDATE_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: UPDATE_INVOICE.FAIL,
      result,
    });
  }
}

function* getOtherInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.INVOICE.GET_OTHER_INVOICE, params)
  );
  if (result.success) {
    yield put({
      type: GET_OTHER_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_OTHER_INVOICE.FAIL,
      result,
    });
  }
}

function* deleteInvoiceDetail(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.DELETE_INVOICE_DETAIL, params);
  if (result.success) {
    yield put({
      type: DELETE_INVOICE_DETAIL.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: DELETE_INVOICE_DETAIL.FAIL,
      result,
    });
  }
}

function* searchPastPerformance(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.INVOICE.SEARCH_PERFORMANCE, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_PAST_PERFORMANCE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: SEARCH_PAST_PERFORMANCE.FAIL,
      result,
    });
  }
}

function* uploadPdf(obj) {
  const { params = {} } = obj;
  const formData = new FormData();
  formData.append("files", params.files, params.file_name);
  formData.append("invoice_id", params.invoice_id);
  const result = yield axiosPost(API.INVOICE.UPLOAD_PDF, formData);
  if (result.success) {
    yield put({
      type: INVOICE_UPLOAD_PDF.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: INVOICE_UPLOAD_PDF.FAIL,
      result,
    });
  }
}

function* createTransferInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.CREATE_TRANSFER_INVOICE, params);
  if (result.success) {
    yield put({
      type: CREATE_TRANSFER_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: CREATE_TRANSFER_INVOICE.FAIL,
      result,
    });
  }
}

function* searchProductInInvoiceDetail(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.INVOICE.SEARCH_PRODUCT_IN_INVOICE_DETAIL, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_PRODUCT_IN_INVOICE_DETAIL.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: SEARCH_PRODUCT_IN_INVOICE_DETAIL.FAIL,
      result,
    });
  }
}

function* generatePdf(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.GENERATE_PDF, params);

  if (result.success) {
    yield put({
      type: INVOICE_GENERATE_PDF.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: INVOICE_GENERATE_PDF.FAIL,
      result,
    });
  }
}

export default function* () {
  // yield takeEvery(GET_TRANSFER_CUSTOMERS.REQUEST, getTransferCustomers);
  yield takeEvery(UPDATE_CUSTOMER.REQUEST, updateCustomer);
  yield takeEvery(GET_LAST_INVOICE.REQUEST, getLastInvoice);
  yield takeEvery(INSERT_PRODUCTS_TO_INVOICE.REQUEST, insertProductsToInvoice);
  yield takeEvery(GET_INVOICE.REQUEST, getInvoice);
  yield takeEvery(UPDATE_INVOICE.REQUEST, updateInvoice);
  // yield takeEvery(GET_TWO_LAST_INVOICE.REQUEST, getTwoLastInvoice);
  yield takeEvery(GET_OTHER_INVOICE.REQUEST, getOtherInvoice);
  yield takeEvery(DELETE_INVOICE_DETAIL.REQUEST, deleteInvoiceDetail);
  yield takeEvery(SEARCH_PAST_PERFORMANCE.REQUEST, searchPastPerformance);
  yield takeEvery(INVOICE_UPLOAD_PDF.REQUEST, uploadPdf);
  yield takeEvery(CREATE_TRANSFER_INVOICE.REQUEST, createTransferInvoice);
  yield takeEvery(
    SEARCH_PRODUCT_IN_INVOICE_DETAIL.REQUEST,
    searchProductInInvoiceDetail
  );
  yield takeEvery(INVOICE_GENERATE_PDF.REQUEST, generatePdf);
}
