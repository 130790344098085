const ITEMS_PER_PAGE = 10;

export const processPaging = (
  dataKey,
  state,
  result,
  nextPageKey = "nextPagePage",
  reachEndKey = "reachEnd",
  totalKey = "totalPages"
) => {
  const totalPages = Math.ceil((result?.count || 0) / ITEMS_PER_PAGE);
  const nextPageMatch = (result?.next || "").match(/page=(\d+)/);
  const reachEnd = nextPageMatch == null || nextPageMatch == undefined;

  const nextPage = nextPageMatch ? nextPageMatch[1] : totalPages + 1;

  const currentPage = +nextPage - 1;
  const _result = {
    [totalKey]: totalPages,
  };
  if (currentPage == 0) {
    _result[nextPageKey] = 0;
    _result[dataKey] = result.results;
  } else if (currentPage == 1) {
    _result[nextPageKey] = nextPage;
    _result[dataKey] = result.results;
  } else {
    _result[nextPageKey] = nextPage;
    _result[dataKey] = Array.from(state[dataKey]).concat(result.results);
  }
  _result[reachEndKey] = reachEnd;

  return _result;
};

export const processPagingProductCatalog = (
  dataKey,
  state,
  result,
  nextPageKey = "nextPagePage",
  reachEndKey = "reachEnd",
  resultCount,
  totalKey = "totalPages"
) => {
  const totalPages = Math.ceil((result?.count || 0) / ITEMS_PER_PAGE);
  const nextPageMatch = (result?.next || "").match(/page=(\d+)/);
  const reachEnd = nextPageMatch == null || nextPageMatch == undefined;

  const nextPage = nextPageMatch ? nextPageMatch[1] : totalPages + 1;

  const currentPage = +nextPage - 1;
  const _result = {
    [totalKey]: totalPages,
  };

  const resultData = result.results?.map((item) => {
    return {
      ...item,
      is_check: false,
    };
  });

  if (currentPage == 0) {
    _result[nextPageKey] = 0;
    _result[dataKey] = resultData;
  } else if (currentPage == 1) {
    _result[nextPageKey] = nextPage;
    _result[dataKey] = resultData;
  } else {
    _result[nextPageKey] = nextPage;
    _result[dataKey] = Array.from(state[dataKey]).concat(resultData);
  }
  _result[reachEndKey] = reachEnd;
  _result[resultCount] = resultData.length;

  return _result;
};
