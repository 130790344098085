import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  ScrollView,
  FlatList,
  ActivityIndicator,
} from "react-native";
import Back from "components/common/Back";
import { Colors, nor } from "utils";
import { commonIcons } from "assets/icons";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { searchPastPerformance } from "../../redux/actions/invoice";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { getSetting } from "../../redux/actions/general";
import { TouchableOpacity } from "react-native-web";
import { Block, Button, TextView, WrapperBlock } from "components";
import { searchProducts } from "../../redux/actions/product";
import { searchCustomers } from "../../redux/actions/customer";
import FullLoading from "components/common/FullLoading";
import { usePrevious } from "../../utils/hook";
import DropdownList from "components/common/DropdownList";
import { getMakers } from "../../redux/actions/general";
import Title from "components/common/Title";
import PerformanceRow from "components/past-performance/PerformanceRow";
import { debounce } from "lodash";

/**
 * 006-002-1 / 過去実績表示画面（パターン1）
 * @param {*} props
 */
const Preview = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isAdmin = location.pathname.substring(1, 6) === "admin";
  const { search_params } = history?.location?.state;
  const [searchParams, setSearchParams] = useState(
    history?.location?.state?.search_params
  );
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setisLoadingMore] = useState(false);
  // console.log('hung searchParams: ', searchParams);
  // console.log('setting:', props.setting);
  const setSearchParamState = (key, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [
    searchWhenSelectMakerChange,
    setSearchWhenSelectMakerChange,
  ] = useState(false);

  useEffect(() => {
    // props.searchPastPerformance(searchParams);
    props.getSetting();
    props.getMakers();
    setLoading(true);
  }, []);

  useEffect(() => {
    search();
  }, [searchWhenSelectMakerChange]);

  const renderFromToDate = () => {
    let str = "";
    if (
      search_params.from_date !== undefined &&
      search_params.from_date !== ""
    ) {
      str += formatDateTime(search_params.from_date);
    }

    if (search_params.to_date !== undefined && search_params.to_date !== "") {
      str += "〜" + formatDateTime(search_params.to_date);
    }

    return str;
  };

  const calculateUpvalue = (invoice, invoice_detail) => {
    if (
      invoice !== undefined &&
      invoice_detail !== undefined &&
      invoice_detail?.product_info !== undefined &&
      invoice_detail?.product_info !== null
    ) {
      const { pt_jpy, pd_jpy, rh_jpy } = invoice;
      const {
        total_value_pd,
        total_value_pt,
        total_value_rh,
      } = invoice_detail?.product_info;
      const unitPrice =
        total_value_pd * pd_jpy +
        total_value_pt * pt_jpy +
        total_value_rh * rh_jpy;

      const fromUnitPrice =
        unitPrice * (props.setting?.target_profit_rate * 0.01);
      const toUnitPrice =
        fromUnitPrice * (props.setting?.limit_profit_rate * 0.01);
      return formatMoney(toUnitPrice);
    }
    return 0;
  };

  const search = () => {
    const searchData = {
      ...searchParams,
      customer_name:
        searchParams?.customer_name !== undefined
          ? searchParams.customer_name
          : "",
      product_name:
        searchParams?.product_name !== undefined
          ? searchParams.product_name
          : "",
      maker_name:
        searchParams?.maker_name !== undefined ? searchParams.maker_name : "",
      page: 1,
    };
    props.searchPastPerformance(searchData);
    setLoading(true);
  };

  const searchProduct = (searchProductName) => {
    props.searchProducts({
      search_name: searchProductName,
      search_code: "",
    });
  };

  const searchCustomer = (searchCustomerName) => {
    props.searchCustomers({
      name: searchCustomerName,
    });
  };
  const prevSearchingPerformance = usePrevious(props.isSearchingPerformance);
  useEffect(() => {
    if (prevSearchingPerformance && !props.isSearchingPerformance) {
      setLoading(false);
      setisLoadingMore(false);
    }
  }, [props.isSearchingPerformance]);

  const onEndReached = debounce(() => {
    const { searchPerformanceNextPage, searchPerformanceReachEnd } = props;

    if (!isLoadingMore && !searchPerformanceReachEnd) {
      const searchData = {
        ...searchParams,
        customer_name:
          searchParams?.customer_name !== undefined
            ? searchParams.customer_name
            : "",
        product_name:
          searchParams?.product_name !== undefined
            ? searchParams.product_name
            : "",
        maker_name:
          searchParams?.maker_name !== undefined ? searchParams.maker_name : "",
        page: searchPerformanceNextPage,
      };

      props.searchPastPerformance(searchData);
      setisLoadingMore(true);
    }
  });

  return (
    <View
      style={{
        flex: 1,
        paddingBottom: 20,
        paddingHorizontal: "24pt",
      }}
    >
      {/* <View style={styles.titleWrap}>
        <Back label={"過去実績検索"} />
        <Text style={styles.title}>過去実績</Text>
      </View> */}
      <Title backTitle={"過去実績検索"} title="過去実績" />
      <View style={{ paddingTop: 10 }}>
        <View style={{ flexDirection: "row", marginBottom: 5 }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.description}>
              {isAdmin ? "取引先：" : "取引先："}
            </Text>
          </View>
          <Search
            defaultValue={searchParams.customer_name}
            // onChangeText={(value) => {
            //   setSearchParamState("customer_name", value.trim());
            // }}
            onSearch={() => search()}
            fetchApi={(searchName) => searchCustomer(searchName)}
            data={props.searchCustomerResults}
            onSelectedItem={(item) =>
              setSearchParamState("customer_name", item?.name?.trim())
            }
          />
          <View style={{ flex: 1 }}></View>
        </View>
        <View style={{ flexDirection: "row", marginBottom: 5 }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.description}>
              {isAdmin ? "品名：" : "品名・分類："}
            </Text>
          </View>
          <Search
            defaultValue={searchParams.product_name}
            // onChangeText={(value) => {
            //   setSearchParamState("product_name", value.trim());
            // }}
            fetchApi={(searchName) => searchProduct(searchName)}
            data={props.products}
            onSelectedItem={(item) =>
              setSearchParamState("product_name", item?.name?.trim())
            }
            onSearch={() => search()}
          />
          <View style={{ flex: 1 }}></View>
        </View>
        <View style={{ flexDirection: "row", marginBottom: 5 }}>
          <View style={{ flex: 0.6 }}>
            <Text style={styles.description}>
              {isAdmin ? "メーカー：" : "メーカー："}
            </Text>
          </View>
          <DropdownList
            label={""}
            data={props.makerList}
            defaultSelectedItemName={search_params?.maker_name}
            onSelectedItem={(item) => {
              setSearchParamState("maker_name", item?.name?.trim());
              setSearchWhenSelectMakerChange((prev) => !prev);
            }}
            wrapStyle={{
              flex: 1,
              paddingVertical: 10,

              marginTop: -20,
            }}
            textInputStyle={{ paddingVertical: 10 }}
          />
          <View style={{ flex: 0.6 }}></View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginBottom: 5,
            borderBottomWidth: "1pt",
            borderBottomColor: Colors.Primary,
            paddingBottom: "5pt",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={styles.description}>期間：{renderFromToDate()}</Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          height: 100,
        }}
      >
        <FlatList
          data={props.searchPerformanceResult}
          style={{ flex: 1, height: 100 }}
          keyExtractor={(_, index) => `${index}`}
          renderItem={({ item, index }) => {
            return <PerformanceRow key={index} item={item} {...props} />;
          }}
          onEndReachedThreshold={0.5}
          onEndReached={onEndReached}
          ListFooterComponent={() =>
            isLoadingMore && (
              <View style={{ padding: 5 }}>
                <ActivityIndicator />
              </View>
            )
          }
        />

        {loading && <FullLoading />}
      </View>
    </View>
  );
};

const Search = ({
  onSearch,
  fetchApi,
  onSelectedItem,
  defaultValue,
  data,
  ...props
}) => {
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [searchItemName, setSearchItemName] = useState(defaultValue);
  const [isShowCustomerList, setIsShowCustomerList] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(true);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else if (searchItemName !== "" && searchItemName !== selectedItemName) {
      fetchApi(searchItemName);
      setIsShowCustomerList(true);
    } else if (searchItemName === "") {
      onSelectedItem(undefined);
      setIsShowCustomerList(false);
    } else {
      setIsShowCustomerList(false);
    }
  }, [searchItemName]);

  const selectItemHandler = (item, index) => {
    setSelectedItem(item);
    setSelectedItemName(item.name);
    setSearchItemName(item.name);
    setIsShowCustomerList(false);
    onSelectedItem(item);
  };

  return (
    <View>
      <View
        style={{
          flex: 1,
          borderColor: Colors.SecondPrimary,
          borderWidth: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextInput
          style={{ flex: 1, fontSize: "12pt" }}
          value={searchItemName}
          onChangeText={(value) => setSearchItemName(value)}
          {...props}
        />
        <TouchableOpacity onPress={onSearch}>
          <Image
            source={commonIcons.search}
            style={{ width: 14, height: 14, marginHorizontal: 10 }}
          />
        </TouchableOpacity>
      </View>
      {isShowCustomerList && (
        <Block
          color="#ECECEC"
          width="90%"
          height="140pt"
          alignSelf="flex-end"
          borderWidth="1pt"
          borderColor={Colors.SecondPrimary}
          borderRadius="3pt"
        >
          <FlatList
            data={data}
            style={{ flex: 1 }}
            keyExtractor={(_, index) => `${index}`}
            renderItem={({ item, index }) => {
              return (
                <Block
                  key={`${index}`}
                  paddingVertical="10pt"
                  paddingLeft="15pt"
                  borderWidth={1}
                  borderColor={Colors.PrimaryLight}
                  color={
                    item?.id === selectedItem?.id
                      ? Colors.PrimaryLight
                      : "white"
                  }
                >
                  <TouchableOpacity
                    onPress={() => selectItemHandler(item, index)}
                  >
                    <TextView color={Colors.SecondPrimary} size={14} regular>
                      {item.name}
                      {item.code !== undefined ? "【" + item.code + "】" : ""}
                    </TextView>
                  </TouchableOpacity>
                </Block>
              );
            }}
          />
        </Block>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: "bold",
    color: Colors.Primary,
  },
  marketText: {
    marginStart: 10,
    fontSize: 11,
    fontWeight: "bold",
    color: Colors.Primary,
  },
  badge: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 1,
    paddingHorizontal: 5,
    marginHorizontal: 5,
    backgroundColor: Colors.Primary,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: nor(18),
  },
  description: {
    color: Colors.Primary,
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  tableTitle: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  smallText: {
    fontSize: 12,
    color: Colors.Primary,
  },
});

const mapStateToProps = (state) => ({
  searchPerformanceResult: state.invoice.searchPerformanceResult,
  isSearchingPerformance: state.invoice.isSearchingPerformance,
  isSearchedPerformance: state.invoice.isSearchedPerformance,
  searchPerformanceNextPage: state.invoice.searchPerformanceNextPage,
  searchPerformanceReachEnd: state.invoice.searchPerformanceReachEnd,

  setting: state.general.setting,
  // isUpdatingCustomerEstimate: state.estimate.isUpdatingCustomerEstimate,
  // isUpdatedCustomerEstimate: state.estimate.isUpdatedCustomerEstimate,
  // updatedCustomerEstimate: state.estimate.updatedCustomerEstimate,

  isSearchingProduct: state.product.isSearchingProduct,
  isSearchProductFinish: state.product.isSearchProductFinish,
  products: state.product.products,

  searchCustomerResults: state.customer.searchCustomerResults,
  makerList: state.general.makerList,
});

const mapDispatchToProps = {
  searchPastPerformance,
  getSetting,
  searchProducts,
  searchCustomers,
  getMakers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);

//export default Preview;
