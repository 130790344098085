import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  ScrollView,
} from "react-native";
import { FilledInput, Button, TextView } from "components";
import { Colors, Metrics } from "utils";
import { useNavigate } from "utils";
import { connect } from "react-redux";
import { getSetting, updateSetting } from "../../redux/actions/general";
import validator from "validator";
import { TextInput } from "react-native-web";
import { usePrevious } from "../../utils/hook";
import { validateInteger } from "../../utils/MoneyFormatter";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import FullLoading from "components/common/FullLoading";
import Title from "components/common/Title";

/**
 * 【管理者】008-001-1 / 設定
 */
const Settings = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [settingData, setSettingData] = useState({});

  const setSettingState = (key, value) => {
    setSettingData((prev) => ({
      ...prev,
      [key]: formatMoney(value, MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS),
    }));
  };

  useEffect(() => {
    props.getSetting();
    setLoading(true);
  }, []);

  const prevGettingSetting = usePrevious(props.isGettingSetting);
  useEffect(() => {
    if (prevGettingSetting && !props.isGettingSetting) {
      if (props.isGotSetting) {
        setSettingState("id", props.setting?.id);
        setSettingState("adjust_rate_pd", props.setting?.adjust_rate_pd);
        setSettingState("adjust_rate_pt", props.setting?.adjust_rate_pt);
        setSettingState("adjust_rate_rh", props.setting?.adjust_rate_rh);
        setSettingState("limit_profit_rate", props.setting?.limit_profit_rate);
        setSettingState(
          "target_profit_rate",
          props.setting?.target_profit_rate
        );
      }
      setLoading(false);
    }
  }, [props.isGettingSetting]);

  return (
    <View style={styles.wrap}>
      <Title title={"設  定"} />
      <View style={styles.sectionHeader}>
        <Text style={styles.sectionHeaderLabel}>利益率設定</Text>
      </View>

      <ScrollView style={{ height: "50vh" }}>
        <View style={styles.fieldRow}>
          <View style={styles.inputCol}>
            <FilledInput
              hideIcon
              headText={"目標利益率"}
              tailText={"%"}
              value={formatMoney(settingData.target_profit_rate)}
              keyboardType="numeric"
              textInputStyle={{ textAlign: "right" }}
              onChangeText={(value) => {
                const formatValue = formatMoney(
                  value,
                  MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
                );
                if (validateInteger(formatValue)) {
                  setSettingState("target_profit_rate", value);
                }
              }}
              keyboardType={"phone-pad"}
            />
          </View>
          <View style={styles.descriptionCol}>
            <Text style={styles.inputDescription}>
              ※最安値の仕入単価参考数値を算出
            </Text>
          </View>
        </View>
        <View style={styles.fieldRow}>
          <View style={styles.inputCol}>
            <FilledInput
              hideIcon
              headText={"上限利益率"}
              tailText={"%"}
              textInputStyle={{ textAlign: "right" }}
              value={settingData.limit_profit_rate}
              onChangeText={(value) => {
                const formatValue = formatMoney(
                  value,
                  MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
                );
                if (validateInteger(formatValue)) {
                  setSettingState("limit_profit_rate", value);
                }
              }}
              keyboardType={"phone-pad"}
            />
          </View>
          <View style={styles.descriptionCol}>
            <Text style={styles.inputDescription}>
              ※仕入単価の上限（損益分岐点）を算出
            </Text>
          </View>
        </View>
        <Button
          smallCentered
          onPress={() => {
            props.updateSetting(settingData);
          }}
          style={{
            width: Metrics.appW * 0.25,
            paddingVertical: "8pt",
            alignItems: "center",
          }}
        >
          更&nbsp;&nbsp;新
        </Button>

        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderLabel}>
            サンプル分析データ補正率設定（製品全体）
          </Text>
        </View>

        <View style={styles.fieldRow}>
          <FilledInput
            headText={"PT"}
            tailText={"%"}
            wrapStyle={{ flex: 1 }}
            headTextStyle={{ fontSize: "22pt" }}
            textInputStyle={{ textAlign: "right" }}
            value={settingData.adjust_rate_pt}
            onChangeText={(value) => {
              const formatValue = formatMoney(
                value,
                MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
              );
              if (validateInteger(formatValue)) {
                setSettingState("adjust_rate_pt", value);
              }
            }}
            keyboardType={"phone-pad"}
          />
          <FilledInput
            headText={"PD"}
            tailText={"%"}
            wrapStyle={{ flex: 1, paddingHorizontal: 14 }}
            headTextStyle={{ fontSize: "22pt" }}
            textInputStyle={{ textAlign: "right" }}
            value={settingData.adjust_rate_pd}
            onChangeText={(value) => {
              const formatValue = formatMoney(
                value,
                MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
              );
              if (validateInteger(formatValue)) {
                setSettingState("adjust_rate_pd", value);
              }
            }}
            keyboardType={"phone-pad"}
          />
          <FilledInput
            headText={"RH"}
            tailText={"%"}
            wrapStyle={{ flex: 1 }}
            headTextStyle={{ fontSize: "22pt" }}
            textInputStyle={{ textAlign: "right" }}
            value={settingData.adjust_rate_rh}
            onChangeText={(value) => {
              const formatValue = formatMoney(
                value,
                MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
              );
              if (validateInteger(formatValue)) {
                setSettingState("adjust_rate_rh", value);
              }
            }}
            keyboardType={"phone-pad"}
          />
        </View>
        <Button
          smallCentered
          onPress={() => {
            props.updateSetting(settingData);
          }}
          style={{
            width: Metrics.appW * 0.25,
            paddingVertical: "8pt",
            alignItems: "center",
          }}
        >
          更&nbsp;&nbsp;新
        </Button>

        <Button
          outline
          onPress={() => navigate("settings/analysis")}
          style={{
            width: "100%",
            paddingVertical: "10pt",
            alignItems: "center",
          }}
        >
          <TextView size={"15pt"} color={Colors.Primary} bold>
            サンプル分析データ マスタ管理
          </TextView>
        </Button>
      </ScrollView>

      <Button
        mediumCentered
        onPress={() => {
          navigate("");
        }}
        style={{
          width: Metrics.appW * 0.5,
          paddingVertical: "10pt",
          alignItems: "center",
        }}
      >
        <TextView size={"15pt"} color={"white"} bold>
          TOPへ
        </TextView>
      </Button>
      {loading && <FullLoading />}
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: "10pt",
    paddingHorizontal: "24pt",
  },
  sectionHeader: {
    backgroundColor: Colors.Primary,
    color: "white",
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginTop: 14,
  },
  sectionHeaderLabel: {
    color: "white",
    fontWeight: "bold",
    fontSize: "14pt",
  },
  fieldRow: {
    flexDirection: "row",
    marginTop: 10,
  },
  row: {
    flexDirection: "row",
  },
  inputCol: {
    flex: 1,
  },
  descriptionCol: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingLeft: 15,
  },
  inputDescription: {
    color: Colors.SecondPrimary,
    fontSize: 12,
  },
});

const mapStateToProps = (state) => ({
  setting: state.general.setting,
  isGettingSetting: state.general.isGettingSetting,
  isGotSetting: state.general.isGotSetting,

  updatedSetting: state.general.updatedSetting,
});
const mapDispatchToProps = {
  getSetting,
  updateSetting,
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);

// export default Settings;
