import Button from "components/common/Button";
import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { useHistory } from "react-router-dom";
import { Colors } from "utils";

const Title = ({ title = "", title1 = "" }) => {
  const history = useHistory();
  return (
    <View style={styles.wrap}>
      <View style={[styles.content, { paddingLeft: title1 ? 8 : 0 }]}>
        {!title1 ? (
          <Text style={styles.txtTitle}>{title}</Text>
        ) : (
          <View style={styles.wrapTitle1}>
            <View style={styles.containerTitle1}>
              <View style={styles.bgTitle1} />
              <Button onPress={() => history.goBack()}>
                <Text style={styles.txtTitle1}>{title}</Text>
              </Button>
            </View>
            <Text style={[styles.txtTitle, styles.mgLeft]}>{title1}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default Title;

const styles = StyleSheet.create({
  wrap: {
    width: "100%",
  },
  content: {
    width: "100%",
    backgroundColor: Colors.White,
    justifyContent: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: 20,
  },
  txtTitle: {
    fontSize: "18pt",
    color: Colors.Primary,
    fontWeight: "bold",
  },
  txtTitle1: {
    fontSize: 14,
    color: Colors.White,
    fontWeight: "bold",
    textAlign: "center",
  },
  wrapTitle1: { flexDirection: "row" },
  containerTitle1: {
    backgroundColor: Colors.SecondPrimary,
    justifyContent: "center",

    paddingHorizontal: 10,
    alignItems: "center",
  },
  bgTitle1: {
    height: 16,
    width: 16,
    backgroundColor: Colors.SecondPrimary,
    transform: [{ rotate: "45deg" }],
    position: "absolute",
    left: -8,
  },
  mgLeft: {
    marginLeft: 10,
  },
});
