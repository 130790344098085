import { createRequestTypes } from "./utils";

export const GET_SETTING = createRequestTypes("GET_SETTING");
export const getSetting = (params) => ({ type: GET_SETTING.REQUEST, params });

export const UPDATE_SETTING = createRequestTypes("UPDATE_SETTING");
export const updateSetting = (params) => ({
  type: UPDATE_SETTING.REQUEST,
  params,
});

export const GET_LATEST_MARKET_PRICE = createRequestTypes(
  "GET_LATEST_MARKET_PRICE"
);
export const getLatestMarketPrice = (params) => ({
  type: GET_LATEST_MARKET_PRICE.REQUEST,
  params,
});

export const GET_TAX_RATE = createRequestTypes("GET_TAX_RATE");
export const getTaxRate = (params) => ({
  type: GET_TAX_RATE.REQUEST,
  params,
});

export const GET_INFORMATION = createRequestTypes("GET_INFORMATION");
export const getInformation = (params) => ({
  type: GET_INFORMATION.REQUEST,
  params,
});

export const GET_LATEST_UPDATE_TRANSACTION = createRequestTypes(
  "GET_LATEST_UPDATE_TRANSACTION"
);
export const getLatestUpdateTransaction = (params) => ({
  type: GET_LATEST_UPDATE_TRANSACTION.REQUEST,
  params,
});

export const INSERT_INFORMATION = createRequestTypes("INSERT_INFORMATION");
export const insertInformation = (params) => ({
  type: INSERT_INFORMATION.REQUEST,
  params,
});

export const GET_MAKERS = createRequestTypes("GET_MAKERS");
export const getMakers = (params) => ({
  type: GET_MAKERS.REQUEST,
  params,
});
