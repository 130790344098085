import { createRequestTypes } from "./utils";

export const SEARCH_PRODUCTS = createRequestTypes("SEARCH_PRODUCTS");
export const searchProducts = (params) => ({
  type: SEARCH_PRODUCTS.REQUEST,
  params,
});

export const UPDATE_PRODUCT = createRequestTypes("UPDATE_PRODUCT");
export const updateProduct = (params) => ({
  type: UPDATE_PRODUCT.REQUEST,
  params,
});

export const SEARCH_PRODUCT_CATALOG = createRequestTypes(
  "SEARCH_PRODUCT_CATALOG"
);
export const searchProductCatalog = (params) => ({
  type: SEARCH_PRODUCT_CATALOG.REQUEST,
  params,
});

export const SAVE_SEARCH_PRODUCT_CATALOG = "SAVE_SEARCH_PRODUCT_CATALOG";
export const saveSearchProductCatalog = (params) => ({
  type: SAVE_SEARCH_PRODUCT_CATALOG,
  params,
});

export const CLEAR_SEARCH_PRODUCT_CATALOG = "CLEAR_SEARCH_PRODUCT_CATALOG";
export const clearSearchProductCatalog = (params) => ({
  type: CLEAR_SEARCH_PRODUCT_CATALOG,
  params,
});


export const KEEP_SEARCH_PRODUCT_CATALOG = "KEEP_SEARCH_PRODUCT_CATALOG";
export const keepSearchProductCatalog = (params) => ({
  type: KEEP_SEARCH_PRODUCT_CATALOG,
  params,
});

export const UPDATE_SEARCH_PRODUCT_CATALOG_RESULT_LIST =
  "UPDATE_SEARCH_PRODUCT_CATALOG_RESULT_LIST";
export const updateSearchProductCatalogResultList = (params) => ({
  type: UPDATE_SEARCH_PRODUCT_CATALOG_RESULT_LIST,
  params,
});

export const GET_PRODUCT_CATALOG_DETAIL = createRequestTypes(
  "GET_PRODUCT_CATALOG_DETAIL"
);
export const getProductCatalogDetail = (params) => ({
  type: GET_PRODUCT_CATALOG_DETAIL.REQUEST,
  params,
});
