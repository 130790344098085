import React, { useEffect, useState } from "react";
import {
  Block,
  Empty,
  TextView,
  FooterButton,
  WrapperBlock,
  Button,
  CheckBox,
} from "components";
import { Colors, isAmountGreaterThanZero } from "utils";
import { data, data1 } from "./fakeQuotationReview";
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Dimensions,
  ScrollView,
} from "react-native";
import { useNavigate } from "utils";
import { commonIcons } from "assets/icons";
import { TextItem } from "components/quotation-review";
import { useLocation } from "react-router-dom";
import { PreviewItem } from "components/partition-preview";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  ESTIMATE_SEND_TYPE,
  ESTIMATE_STATUS,
  ESTIMATE_PIC_ATTACH,
  QUOTATION_FLOW,
} from "../../utils/Constant";
import { getEstimate, updateEstimate } from "../../redux/actions/estimate";
import Title from "components/common/Title";

/**
 * 003-011-1 / 見積書プレビュー（画像なし）・送付依頼完了画面
 * 003-011-2 / 見積書プレビュー（画像なし）・見積完了画面
 * @param {*} props
 */
const QuotationComplete = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { estimate, pattern } = location?.state;

  const checkStatus = () => {
    //Go to [002-001 / アカウント]
    navigate("account");
  };

  const backToHome = () => {
    //Go to [001-001 / TOP（貴金属相場）]
    navigate("", {});
  };

  const finishEstimateUI = () => (
    <FooterButton
      label="見積を完了しました。"
      button1="TOPへ"
      button2=""
      onPress1={() => backToHome()}
      onPress2={() => {}}
    />
  );

  const submitRequestUI = () => (
    <FooterButton
      label={
        estimate.send_type == ESTIMATE_SEND_TYPE.FAX
          ? "FAX送付依頼を送信しました。"
          : "メール送付依頼を送信しました。"
      }
      button1="ステータスを確認"
      button2="TOPへ"
      onPress1={() => checkStatus()}
      onPress2={() => backToHome()}
    />
  );

  const renderBottomUI = () => {
    if (pattern == QUOTATION_FLOW.FINISH_ESTIMATE) {
      return finishEstimateUI();
    } else if (pattern == QUOTATION_FLOW.SUBMIT_REQUEST) {
      return submitRequestUI();
    }
  };

  return (
    <WrapperBlock>
      <Title backTitle={"見積単価"} title="見積書プレビュー" />
      <Block block style={{ marginBottom: "30pt" }}>
        <Block block paddingTop="15pt">
          <Block>
            <TextView size="13pt" color={Colors.Primary} bold lineHeight="18pt">
              御見積日：{formatDateTime(estimate?.date)}
            </TextView>
            <TextView size="13pt" color={Colors.Primary} bold lineHeight="18pt">
              取引先名：{estimate?.customer_info?.name} {"様"}
            </TextView>
            <TextView size="13pt" color={Colors.Primary} bold lineHeight="18pt">
              担当者：{estimate?.account_info?.name}
            </TextView>
            <Block
              marginTop="10pt"
              borderBottomWidth="1pt"
              borderBottomColor={Colors.Primary}
              marginBottom="5pt"
            >
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                見積書コード：{estimate?.code}
              </TextView>
            </Block>
          </Block>

          <Block flex={3} marginTop="-5pt">
            <PreviewItem
              isHeader
              value1="コード ▼"
              value2="メーカー ▼"
              value3="品名・分類 ▼"
              value4="単  価 ▼"
              blocks={[1, 1, 1, 1]}
              centers={["left", "left", "left", "right"]}
            />
            <ScrollView
              contentContainerStyle={{
                borderBottomWidth: "1pt",
                borderTopColor: Colors.Primary,
              }}
            >
              {estimate.estimate_details?.map((item, index) => {
                return (
                  <PreviewItem
                    key={`${index}`}
                    value1={item?.product_info?.code}
                    value2={item?.product_info?.maker_info?.name}
                    value3={item.name}
                    value4={renderUnitPrice(item)}
                    blocks={[1, 1, 1, 1]}
                    centers={["left", "left", "left", "right"]}
                  />
                );
              })}
            </ScrollView>
          </Block>
        </Block>
        <Block flex={0.3}>{renderBottomUI()}</Block>
      </Block>
    </WrapperBlock>
  );
};

const styles = StyleSheet.create({
  radio: {
    flexDirection: "row",
  },
});

const renderUnitPrice = (estimateDetail) => {
  if (
    isAmountGreaterThanZero(estimateDetail?.unit_price) &&
    isAmountGreaterThanZero(estimateDetail?.unit_price2)
  ) {
    return (
      formatMoney(estimateDetail.unit_price2) +
      " ~ " +
      formatMoney(estimateDetail.unit_price) +
      "円"
    );
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price)) {
    return formatMoney(estimateDetail.unit_price) + "円";
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price2)) {
    return formatMoney(estimateDetail.unit_price2) + "円";
  } else {
    return formatMoney(estimateDetail.unit_price) + "円";
  }
};

const mapStateToProps = (state) => ({
  // isGettingEstimate: state.estimate.isGettingEstimate,
  // isGotEstimate: state.estimate.isGotEstimate,
  // estimate: state.estimate.estimate,
  isUpdatingEstimate: state.estimate.isUpdatingEstimate,
  isUpdatedEstimate: state.estimate.isUpdatedEstimate,
  updatedEstimate: state.estimate.updatedEstimate,
});
const mapDispatchToProps = {
  // getEstimate,
  updateEstimate,
  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationComplete);
