import React from "react";
import { Colors } from "utils";
import Block from "components/common/Block";
import TextView from "components/common/TextView";

const TextItem = ({ value1, value2, value3, value4, last }) => {
  return (
    <Block
      direction={false}
      paddingVertical="5pt"
      borderBottomWidth="1pt"
      borderBottomColor={Colors.Primary}
      borderStyle={last ? "solid" : "dashed"}
    >
      <Block direction="row" flex={1}>
        <Block flex={1} direction="row">
          <TextView
            size="13pt"
            backgroundColor={Colors.Primary}
            color={Colors.White}
            center
            regular
            paddingHorizontal={"5pt"}
          >
            {value1}
          </TextView>
          <Block center marginHorizontal={"5pt"}>
            <TextView size="13pt" color={Colors.Primary} regular>
              {value2}
            </TextView>
          </Block>
        </Block>

        <Block flex={4} direction="row" justifyContent="flex-end">
          <TextView size="13pt" color={Colors.Primary} regular>
            {value3}
          </TextView>
        </Block>
      </Block>
      <Block direction="row" justifyContent="flex-end">
        <TextView size="13pt" color={Colors.Primary} regular>
          {value4}
        </TextView>
      </Block>
    </Block>
  );
};
TextItem.defaultProps = {
  value1: "",
  value2: "",
  value3: "",
  value4: "",
  last: false,
};
export default TextItem;
