
 import axios from "axios";

import { takeEvery, put } from 'redux-saga/effects';
import {LOGIN} from '../actions/auth';
import {  axiosGet, axiosPost } from './utils';
import {API} from '../../configs/api';


function* login(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.AUTH.LOGIN, params);
  console.log('in login generator');
  if (result.success) {
    console.log('success ', result);
    yield put({
      type: LOGIN.SUCCESS,
      result: result.data.data // model account
    });
  } else {
    console.log('fail ', result);
    yield put({
      type: LOGIN.FAIL,
      result
    });
  }
}


export default function*() {
   yield takeEvery(LOGIN.REQUEST, login);

}
