import React from 'react';
import { Text, Image, View, StyleSheet, TouchableOpacity } from 'react-native';
import { Link } from 'react-router-dom';
import { useNavigate } from 'utils';

export default function Tab({ label, icon, path }) {
  const navigate = useNavigate();
  return (
    <TouchableOpacity onPress={() => navigate(path)} style={RNStyles.tab}>
      <Image
        source={icon}
        style={{ width: 32, height: 32, marginBottom: 10 }}
        resizeMode={'contain'}
      />
      <Text style={RNStyles.tabLabel}>{label}</Text>
    </TouchableOpacity>
  );
}

const styles = {
  tab: {
    display: 'flex',
    flex: 1,
  },
};

const RNStyles = StyleSheet.create({
  tab: {
    flex: 1,
    paddingTop: '10pt',
    paddingBottom: '15pt',
    alignItems: 'center',
  },
  tabLabel: {
    color: 'white',
  },
});
