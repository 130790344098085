import React, { useEffect, useState } from "react";
import { View, Dimensions, StyleSheet, ScrollView } from "react-native";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Header } from "components";
import BottomTab from "components/navigation/BottomTab";
import { bottomIcons } from "assets/icons";

import Home from "containers/home";
import Notice from "containers/notice";
import Catalog from "containers/catalog";
import Performance from "containers/performance";
import TransferRequest from "containers/transfer-request";
import PastNotice from "containers/past-notice";
import Account from "containers/account";
import PurchaseSupplier from "containers/purchase-supplier";
import QuotationSupplier from "containers/quotation-supplier";
import PastDividers from "containers/past-dividers";
import EstimateReview from "containers/past-dividers/estimate-review";
import InvoiceReview from "containers/past-dividers/invoice-review";

import Cart from "containers/cart";
import BuyReview from "containers/buy-review";
import PartitionReview from "containers/partition-review";
import QuotationReview from "containers/quotation-review";
import QuotationComplete from "containers/quotation-review/QuotationComplete";
import Purchase from "containers/purchase";
import PastPerformance from "containers/past-performance";
import TransferList from "containers/transfer-request/List";
import Transfered from "containers/transfer-request/Transfered";
import UserAuth from "containers/auth";
import Settings from "containers/settings";
import Analysis from "containers/settings/Analysis";
import CartProductDetail from "containers/cart/ProductDetail";
import CartPreview from "containers/cart/Preview";
import ProductInfo from "containers/catalog/ProductInfo";
import Estimate from "containers/estimate";
import QuoteList from "containers/quotation-list";
import QuoteProductDetail from "containers/quotation-list/ProductDetail";

import AdminAuth from "containers/admin/auth";
import AdminNotice from "containers/admin/notice";
import AdminCreateNotice from "containers/admin/notice/Create";
import store, { rehydration } from "../redux/store";
import { useHistory, useLocation } from "react-router-dom";

import { ACCOUNT_LOGIN_AUTH } from "../utils/Constant";

export default function Navigation() {
  const [height, setHeight] = useState(Dimensions.get("window").height);

  useEffect(() => {
    Dimensions.addEventListener("change", ({ window }) => {
      setHeight(window.height);
    });
  }, []);

  return (
    <Router basename={"/mobile/bas/"}>
      <View style={{ height }}>
        <Header />
        <View style={{ flex: 1 }}>
          <Switch>
            <PrivateRouter exact path="/">
              <Home />
            </PrivateRouter>
            <Route exact path="/login">
              <UserAuth />
            </Route>
            <Route exact path="/admin/login">
              <AdminAuth />
            </Route>

            <Route path="/notice">
              <Notice />
            </Route>
            <Route path="/catalog">
              <Catalog />
            </Route>
            <Route path="/performance">
              <Performance />
            </Route>
            <Route path="/past-performance">
              <PastPerformance />
            </Route>
            <Route exact path="/transfer-request">
              <TransferRequest />
            </Route>

            <Route path="/transfer-request/list">
              <TransferList />
            </Route>
            <Route path="/transfer-request/transfered">
              <Transfered />
            </Route>
            <Route path="/cart/detail">
              <CartProductDetail />
            </Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/cart-preview">
              <CartPreview />
            </Route>
            <Route path="/past-notice">
              <PastNotice />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/purchase-supplier">
              <PurchaseSupplier />
            </Route>
            <Route path="/quotation-supplier">
              <QuotationSupplier />
            </Route>
            <Route path="/buy-review">
              <BuyReview />
            </Route>
            <Route path="/past-dividers">
              <PastDividers />
            </Route>
            <Route path="/estimate-review">
              <EstimateReview />
            </Route>
            <Route path="/invoice-review">
              <InvoiceReview />
            </Route>

            <Route path="/partition-review">
              <PartitionReview />
            </Route>
            <Route path="/quotation-review">
              <QuotationReview />
            </Route>
            <Route path="/quotation-complete">
              <QuotationComplete />
            </Route>
            <Route path="/purchase">
              <Purchase />
            </Route>
            <Route path="/product-info">
              <ProductInfo />
            </Route>
            <Route path="/estimate">
              <Estimate />
            </Route>
            <Route path="/quotation-list/detail">
              <QuoteProductDetail />
            </Route>
            <Route path="/quotation-list">
              <QuoteList />
            </Route>

            <Route path="/admin/estimate">
              <Estimate />
            </Route>
            <Route path="/admin/purchase">
              <Purchase />
            </Route>
            <Route path="/admin/quotation-review">
              <QuotationReview />
            </Route>
            <Route path="/admin/partition-review">
              <PartitionReview />
            </Route>
            <Route path="/admin/past-dividers">
              <PastDividers />
            </Route>
            <Route path="/admin/estimate-review">
              <EstimateReview />
            </Route>
            <Route path="/admin/invoice-review">
              <InvoiceReview />
            </Route>

            <Route path="/admin/buy-review">
              <BuyReview />
            </Route>
            <Route path="/admin/notice/create">
              <AdminCreateNotice />
            </Route>
            <Route path="/admin/notice">
              <Notice />
            </Route>
            <Route path="/admin/catalog">
              <Catalog />
            </Route>
            <Route path="/admin/performance">
              <Performance />
            </Route>
            <Route path="/admin/past-performance">
              <PastPerformance />
            </Route>
            <Route exact path="/admin/transfer-request">
              <TransferRequest />
            </Route>
            <Route path="/admin/transfer-request/list">
              <TransferList />
            </Route>
            <Route path="/admin/transfer-request/transfered">
              <Transfered />
            </Route>
            <Route path="/admin/cart/detail">
              <CartProductDetail />
            </Route>
            <Route path="/admin/cart">
              <Cart />
            </Route>
            <Route path="/admin/cart-preview">
              <CartPreview />
            </Route>
            <Route path="/admin/past-notice">
              <PastNotice />
            </Route>
            <Route path="/admin/account">
              <Account />
            </Route>
            <Route path="/admin/purchase-supplier">
              <PurchaseSupplier />
            </Route>
            <Route path="/admin/quotation-supplier">
              <QuotationSupplier />
            </Route>
            <Route path="/admin/quotation-complete">
              <QuotationComplete />
            </Route>
            <Route path="/admin/settings/analysis">
              <Analysis />
            </Route>
            <Route path="/admin/settings">
              <Settings />
            </Route>
            <Route path="/admin/product-info">
              <ProductInfo />
            </Route>
            <Route path="/admin/quotation-list/detail">
              <QuoteProductDetail />
            </Route>
            <Route path="/admin/quotation-list">
              <QuoteList />
            </Route>
            <PrivateRouter exact path="/admin">
              <Home />
            </PrivateRouter>
          </Switch>
        </View>
        <BottomTab />
      </View>
    </Router>
  );
}

const getAuth = (location) => {
  const { isLoggedIn, account } = store.getState().auth;
  const isAdmin = /^\/admin/.test(location.pathname);
  if (isLoggedIn) {
    return isAdmin
      ? account.login_auth === ACCOUNT_LOGIN_AUTH.MOBILE_MANAGER
      : account.login_auth === ACCOUNT_LOGIN_AUTH.MOBILE;
  } else {
    return false;
  }
};

function PrivateRouter({ children, ...rest }) {
  const location = useLocation();
  const isAdmin = /^\/admin/.test(location.pathname);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getAuth(location) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isAdmin ? "/admin/login" : "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
