import {
  GET_ACCOUNT_CART,
  GET_STATUS_MANAGEMENT,
  SEARCH_ACCOUNT_VOUCHER,
} from "redux/actions/account";

const initialState = {
  //   isLoggedIn: false,
  //   isLoggingIn: false,
  //   account: {},
  //   errorMsg: "",
  //   isLoggingOut: false,
  //   isLoggedOut: false,
  accountCart: {},
  statusManagementList: [],

  isSearchingAccountVoucher: false,
  isSearchedAccountVoucher: false,
  searchAccountVoucherList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_CART.REQUEST:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: true,
      };

    case GET_ACCOUNT_CART.SUCCESS:
      return {
        ...state,
        // isLoggedIn: true,
        // isLoggingIn: false,
        accountCart: action.result,
      };

    case GET_ACCOUNT_CART.FAIL:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_STATUS_MANAGEMENT.REQUEST:
      return {
        ...state,
      };

    case GET_STATUS_MANAGEMENT.SUCCESS:
      return {
        ...state,
        statusManagementList: action.result,
      };

    case GET_STATUS_MANAGEMENT.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };
    case SEARCH_ACCOUNT_VOUCHER.REQUEST:
      return {
        ...state,
        isSearchingAccountVoucher: true,
        isSearchedAccountVoucher: false,
      };

    case SEARCH_ACCOUNT_VOUCHER.SUCCESS:
      return {
        ...state,
        isSearchingAccountVoucher: false,
        isSearchedAccountVoucher: true,
        searchAccountVoucherList: action.result,
      };

    case SEARCH_ACCOUNT_VOUCHER.FAIL:
      return {
        ...state,
        isSearchingAccountVoucher: false,
        isSearchedAccountVoucher: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
