import React from "react";
import { StyleSheet, ScrollView, Image, View } from "react-native";
import { LIST_SAVED1 } from "containers/account/fakeAcount";
import Block from "components/common/Block";
import { Colors, useNavigate } from "utils";
import Button from "components/common/Button";
import { TextView } from "components";
import { defaultIcons } from "assets/icons";
import { TouchableOpacity } from "react-native-web";
import { Alert } from "../../App";

const styles = StyleSheet.create({
  borderDashed: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.SecondPrimary,
    borderStyle: "dashed",
  },
});
const ListSaved = ({ title, cart, type, onRecordPress, onDeletePress }) => {
  const navigate = useNavigate();
  const onPressNavigate = (pathname) => navigate(pathname);

  const showConfirmModal = (item) => {
    console.log("hung showConfirmModal item:", item);
    Alert.alert("", "削除しますか?", [
      {
        label: "はい",
        primary: true,
        onPress: () => {
          Alert.hideModal();
          onDeletePress(item);
        },
      },
      {
        label: "いいえ",
        primary: false,
        onPress: () => {
          Alert.hideModal();
        },
      },
    ]);
  };
  return (
    <Block>
      <Block
        paddingVertical={10}
        direction="row"
        style={styles.borderDashed}
        style={{ flex: 3 }}
      >
        <Block
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ flex: 0.6 }}
        >
          <Button
            paddingVertical={6}
            paddingHorizontal={9}
            color={Colors.Primary}
          >
            <TextView bold size={"9pt"} color={Colors.White}>
              {title}
            </TextView>
          </Button>
        </Block>

        <Block
          style={{ flex: 2.4 }}
          paddingVertical={3}
          paddingRight={20}
          justifyContent="flex-start"
        >
          {cart !== undefined &&
            cart !== null &&
            cart instanceof Array === false && (
              <Button onPress={onRecordPress}>
                <TextView size={15} color={Colors.Primary}>
                  {cart?.code} / {cart?.customer_info?.name}
                </TextView>
              </Button>
            )}

          {cart !== undefined && cart !== null && cart instanceof Array && (
            <ScrollView style={{ height: 50 }}>
              {cart.map((item, index) => {
                return (
                  <Button
                    key={`${index}`}
                    onPress={() => onRecordPress(item)}
                    middle
                    style={{ flexDirection: "row" }}
                  >
                    <TextView size={15} color={Colors.Primary}>
                      {item?.code} / {item?.customer_info?.name}
                    </TextView>
                    <TouchableOpacity
                      style={{
                        backgroundColor: "red",
                        padding: "5pt",
                        marginRight: "5pt",
                        marginStart: "5pt",
                        borderRadius: "20pt",
                      }}
                      onPress={() => {
                        showConfirmModal(item);
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={defaultIcons.trash}
                        style={{ height: "10pt", width: "10pt" }}
                      />
                    </TouchableOpacity>
                  </Button>
                );
              })}
            </ScrollView>
          )}
        </Block>
      </Block>
    </Block>
  );
};
export default ListSaved;
