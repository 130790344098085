import moment from "moment";
import "moment/locale/ja";
import "moment/locale/vi";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";

export const DEFAULT_LOCALE = "en";

const DAYS = ["日", "月", "火", "水", "木", "金", "土"];

export const NOW_DATE = new Date();

export const getDayOfWeek = (date) => {
  if (date == undefined || date == null || date == "") return "";
  const day = DAYS[date.getDay()];
  return day;
};

export const DateTimeFormatter = {
  HH_MM_DD_MM_YYYY: " HH:mm DD/MM/YYYY",
  DD_MM_YYYY_HH_MM: "DD/MM/YYYY HH:mm",
  YYYY_MM_DD_HYPHEN: "YYYY-MM-DD",
  YYYY_MM_DD_HH_MM_HYPHEN: "YYYY-MM-DD HH:mm",
  DD_MM_YYYY: "DD/MM/YYYY",
  HH_MM_DDD_DD_MM_YYYY: "HH:mm ddd, DD/MM/YYYY",
  MM_DD_JP: "MM月DD日",
  YYYY_MM_DD_JP: "YYYY年MM月DD日",
  YYYY_MM_DD_HH_MM_JP: "YYYY年MM月DD日HH時mm分",
  YYYY_MM_DD_JP_DOT: "YYYY.MM.DD",
  MM_DD: "MM/DD",
};

const timezoneOffset = new Date().getTimezoneOffset();

const _languageToLocale = (language) => {
  if (!language.includes("zh")) {
    return language;
  }

  return language.includes("Hans") ? "zh-cn" : "zh-tw";
};

export const formatDateTime = (value, formatType) => {
  if (formatType === undefined) {
    formatType = DateTimeFormatter.YYYY_MM_DD_JP;
  }

  if (value === "" || value === undefined) {
    return "";
  }
  // moment.locale(DEFAULT_LOCALE);
  const date = moment(value);
  if (date.isValid()) {
    return moment.utc(value).format(formatType); //NOTE: all datetime will be utc , same date in db
    //return moment(value).format(formatType);
  }

  return value;
};

export const formatDateTimeLocal = (value, formatType) => {
  if (formatType === undefined) {
    formatType = DateTimeFormatter.YYYY_MM_DD_JP;
  }

  if (value === "" || value === undefined) {
    return "";
  }
  // moment.locale(DEFAULT_LOCALE);
  const date = moment(value);
  if (date.isValid()) {
    return moment(value).format(formatType);
  }

  return value;
};

export const formatDateTimeUTC = (value, formatType) => {
  if (formatType === undefined) {
    formatType = DateTimeFormatter.YYYY_MM_DD_JP;
  }

  if (value === "" || value === undefined) {
    return "";
  }

  const date = moment(value);
  if (date.isValid()) {
    return moment.utc(value).format(formatType);
    // return moment(value).format(formatType);
  }

  return value;
};

/**
 *
 * @param {*} value : a UTC dateTime: 2030-04-17T00:00:00.000000Z
 * @param {*} formatType : example: DD_MM_YYYY_HH_MM
 * @param {*} language : en/jp/zh/....
 * @param {*} isKeepUtcTime : true/false: true: keep output datetime as UTC "17/04/2020 00:00 ", false: parse to local date time "17/04/2020 07:00 "
 */
export const formatDateTimeLocale = (
  value,
  formatType,
  language,
  isKeepUtcTime
) => {
  if (formatType === undefined) {
    formatType = DateTimeFormatter.DD_MM_YYYY_HH_MM;
  }
  if (language === undefined) {
    moment.locale(DEFAULT_LOCALE);
  } else {
    moment.locale(_languageToLocale(language));
  }
  const date = moment(value);
  if (date.isValid()) {
    if (isKeepUtcTime) {
      return moment.utc(value).format(formatType);
    } else {
      return moment(value).format(formatType);
    }
  }
  return value;
};

// export const toDateTime = (value) => {
//   if (value === "" || value === undefined) {
//     return "";
//   }
//   const date = moment(value).format(DateTimeFormatter.YYYY_MM_DD_HYPHEN);
//   return date;
// };

export default {
  formatDateTime,
  formatDateTimeUTC,
  DateTimeFormatter,
  formatDateTimeLocale,
};
