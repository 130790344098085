import React from "react";
import { StyleSheet, Image, ScrollView } from "react-native";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
export const ColorSpread = (amount) =>
  amount < 0 ? Colors.Red : amount > 0 ? Colors.BlueLight03 : Colors.Primary;
const Tab1 = ({ data }) => {
  const Item = ({ item }) => {
    return (
      <Block block>
        <Block direction="row">
          <Block width="25%" center paddingVertical="12.5pt">
            <TextView size="42pt" bold color={Colors.Primary}>
              {item.title}
            </TextView>
            <TextView size="10pt" regular color={Colors.Primary}>
              {item.title_jpy}
            </TextView>
          </Block>
          <Block width="75%" marginTop="5pt">
            <Block direction="row" middle>
              <Block block>
                <Block
                  color={`${Colors.SecondPrimary}20`}
                  borderWidth={StyleSheet.hairlineWidth}
                  borderColor={`${Colors.Primary}20`}
                  paddingVertical="10pt"
                  paddingRight="10pt"
                  alignItems="flex-end"
                >
                  <TextView size="18pt" bold color={Colors.Primary}>
                    {item.price_jpy}
                  </TextView>
                </Block>
                <Block
                  color={`${Colors.SecondPrimary}20`}
                  borderWidth={StyleSheet.hairlineWidth}
                  borderColor={`${Colors.Primary}20`}
                  paddingVertical="6pt"
                  paddingRight="10pt"
                  marginTop="5pt"
                  alignItems="flex-end"
                >
                  <TextView size="12pt" bold color={Colors.Primary}>
                    {item.price_usd}
                  </TextView>
                </Block>
              </Block>
              <Block height="100%" width="43%" justifyContent="space-around">
                <Block direction="row">
                  <TextView
                    margin="5pt"
                    size="12pt"
                    bold
                    color={Colors.Primary}
                  >
                    {item.unit_jpy}
                  </TextView>
                  <Block direction="row" middle>
                    <Block>
                      <Image
                        source={item.arrow_jpy}
                        style={{ height: "25pt", width: "25pt" }}
                      />
                    </Block>
                    <TextView
                      margin="2pt"
                      size="11pt"
                      bold
                      color={ColorSpread(item.spread_jpy[0])}
                    >
                      {item.spread_jpy}
                    </TextView>
                  </Block>
                </Block>
                <Block direction="row">
                  <TextView
                    margin="5pt"
                    size="12pt"
                    bold
                    color={Colors.Primary}
                  >
                    {item.unit_usd}
                  </TextView>
                  <Block direction="row" middle>
                    <Block>
                      <Image
                        source={item.arrow_usd}
                        style={{ height: "13pt", width: "13pt" }}
                      />
                    </Block>
                    <TextView
                      margin="2pt"
                      size="11pt"
                      bold
                      color={ColorSpread(item.spread_usd[0])}
                    >
                      {item.spread_usd}
                    </TextView>
                  </Block>
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      </Block>
    );
  };
  return (
    <Block marginTop={"5pt"}>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        {data.map((item, index) => (
          <Item key={`${index}`} {...{ item }} />
        ))}
      </ScrollView>
    </Block>
  );
};

export default Tab1;

const styles = StyleSheet.create({});
