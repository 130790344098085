import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
  Text,
  TextInput,
} from 'react-native';
import { Colors } from 'utils';
import images from 'assets/images';
import { authIcons } from 'assets/icons';
import { useNavigate } from 'utils';
import { login } from 'redux/actions/auth';
import { connect } from 'react-redux';
import { usePrevious } from '../../../utils/hook';
import axiosDebugger, {
  setAxiosDefaultHeaders,
} from '../../../configs/axiosDebugger';
import { ACCOUNT_LOGIN_AUTH } from '../../../utils/Constant';
import { useHistory } from 'react-router-dom';

const Login = (props) => {
  const history = useHistory();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const submitLogin = () => {
    props.login({
      email: username,
      password: password,
      login_auth: ACCOUNT_LOGIN_AUTH.MOBILE_MANAGER,
    });
  };

  const prevLoggingIn = usePrevious(props.isLoggingIn);
  useEffect(() => {
    if (prevLoggingIn && !props.isLoggingIn) {
      if (props.isLoggedIn) {

        setAxiosDefaultHeaders({
          token: props.account.token,
          uuid: props.account.uuid,
        });
        navigate('/admin');
      } else {
        console.log('hung login false');
      }
    }
  }, [props.isLoggingIn]);

  return (
    <View style={styles.wrap}>
      <Image
        source={images.admin_auth}
        style={styles.backgroundImage}
        resizeMode={'cover'}
      />
      <View style={styles.formWrap}>
        <View style={styles.field}>
          <View style={styles.labelWrap}>
            <Image source={authIcons.admin_user} style={styles.icon} />
            <Text style={styles.label}>ログインID</Text>
          </View>
          <TextInput
            style={styles.textInput}
            onChangeText={(value) => setUsername(value)}
          />
        </View>
        <View style={styles.field}>
          <View style={styles.labelWrap}>
            <Image source={authIcons.admin_password} style={styles.icon} />
            <Text style={styles.label}>パスワード</Text>
          </View>
          <TextInput
            style={styles.textInput}
            secureTextEntry
            onChangeText={(value) => setPassword(value)}
          />
        </View>

        <TouchableOpacity onPress={() => submitLogin()} style={styles.button}>
          <Text style={[styles.buttonLabel]}>ログイン</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.errorField}
          onPress={() => {
            history.replace('/login');
          }}
        >
          <Text
            style={{
              color: '#64B5B8',
              textDecorationLine: 'underline',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            スタッフログインへ戻る
          </Text>
        </TouchableOpacity>
        <View style={styles.errorField}>
          <Text style={{ color: 'red' }}>{props.errorMsg}</Text>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoggingIn: state.auth.isLoggingIn,
  account: state.auth.account,
  errorMsg: state.auth.errorMsg,
});
const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  formWrap: {
    height: '53vh',
    paddingHorizontal: '24pt',
  },
  field: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#E6F3F3',
    marginBottom: '10pt',
  },
  labelWrap: {
    flexDirection: 'row',
    paddingLeft: '10pt',
    alignItems: 'center',
  },
  icon: { width: '16pt', height: '16pt', marginLeft: '15pt' },
  label: {
    color: Colors.Primary,
    fontWeight: 'bold',
    paddingHorizontal: '10pt',
  },
  textInput: {
    flex: 2,
    textAlign: 'right',
    color: Colors.SecondPrimary,
    paddingVertical: '25pt',
    paddingHorizontal: '15pt',
  },
  button: {
    backgroundColor: 'white',
    paddingHorizontal: '32pt',
    paddingVertical: '10pt',
    borderRadius: '40pt',
    alignSelf: 'center',
    marginTop: '40pt',
  },
  buttonLabel: {
    color: Colors.Primary,
    fontWeight: 'bold',
  },
  errorField: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '13pt',
  },
});
