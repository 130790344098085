import {
  GET_TRANSFER_CUSTOMERS,
  UPDATE_TRANSFER,
  CREATE_TRANSFER,
  SEARCH_TRANSFER,
} from "redux/actions/transfer";

const initialState = {
  //   isLoggedIn: false,
  //   isLoggingIn: false,
  transferCustomers: [],
  updatedTransfer: {},

  isCreatingTransfer: false,
  isCreatedTransfer: false,
  createdTransfer: {},

  isSearchingTransfer: false,
  isSearchedTransfer: false,
  searchTransfers: [],
  errorMsg: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_CUSTOMERS.REQUEST:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: true
        transferCustomers: [],
      };

    case GET_TRANSFER_CUSTOMERS.SUCCESS:
      return {
        ...state,
        // isLoggedIn: true,
        // isLoggingIn: false,
        transferCustomers: action.result,
      };

    case GET_TRANSFER_CUSTOMERS.FAIL:
      // console.log('hung LOGIN.FAIL, action:', action.result?.errorMessage?.response?.data?.error);
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };
    case UPDATE_TRANSFER.REQUEST:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: true
        updatedTransfer: {},
      };

    case UPDATE_TRANSFER.SUCCESS:
      return {
        ...state,
        // isLoggedIn: true,
        // isLoggingIn: false,
        updatedTransfer: action.result,
      };

    case UPDATE_TRANSFER.FAIL:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case CREATE_TRANSFER.REQUEST:
      return {
        ...state,
        isCreatingTransfer: true,
        isCreatedTransfer: false,
        createdTransfer: {},
      };

    case CREATE_TRANSFER.SUCCESS:
      return {
        ...state,
        isCreatingTransfer: false,
        isCreatedTransfer: true,
        createdTransfer: action.result,
      };

    case CREATE_TRANSFER.FAIL:
      return {
        ...state,
        isCreatingTransfer: false,
        isCreatedTransfer: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SEARCH_TRANSFER.REQUEST:
      return {
        ...state,
        isSearchingTransfer: true,
        isSearchedTransfer: false,
        searchTransfers: [],
      };

    case SEARCH_TRANSFER.SUCCESS:
      return {
        ...state,
        isSearchingTransfer: false,
        isSearchedTransfer: true,
        searchTransfers: action.result,
      };

    case SEARCH_TRANSFER.FAIL:
      return {
        ...state,
        isSearchingTransfer: false,
        isSearchedTransfer: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
