import React from "react";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import Button from "components/common/Button";
const FooterButton = ({
  label = "FAX送付依頼を送信しました。",
  button1 = "",
  button2 = "",
  onPress1 = () => true,
  onPress2 = () => true,
}) => {
  return (
    <Block block>
      <Block block justifyContent="space-around">
        {!!label && (
          <Block
            borderWidth="1pt"
            borderColor={Colors.Primary}
            paddingVertical="5pt"
            center
            alignSelf="center"
            paddingHorizontal="10pt"
          >
            <TextView size="13pt" color={Colors.Primary} bold>
              {label}
            </TextView>
          </Block>
        )}
        <Block direction="row" justifyContent="center">
          <Button
            width="48%"
            paddingVertical="5pt"
            color={Colors.SecondPrimary}
            centered
            middle
            borderRadius={3}
            onPress={onPress1}
          >
            <TextView size="12pt" color="white" bold>
              {button1}
            </TextView>
          </Button>

          <Block width="5pt" />
          {button2 && (
            <Button
              width="48%"
              paddingVertical="5pt"
              color={Colors.SecondPrimary}
              centered
              middle
              borderRadius={3}
              onPress={onPress2}
            >
              <TextView size="12pt" color="white" bold>
                {button2}
              </TextView>
            </Button>
          )}
        </Block>
      </Block>
    </Block>
  );
};

export default FooterButton;
