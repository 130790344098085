import { takeEvery, put } from "redux-saga/effects";
import {
  GET_METAL_MARKET_PRICE,
  RESET_CART,
  CHECK_CART,
  CREATE_NEW_INVOICE,
  GET_MARKET_PRICE_DATA,
  GET_AVERAGE_MARKET_PRICE,
} from "../actions/home";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* getMetalMarketPrice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.HOME.GET_METAL_MARKET_PRICE, params);
  if (result.success) {
    yield put({
      type: GET_METAL_MARKET_PRICE.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_METAL_MARKET_PRICE.FAIL,
      result,
    });
  }
}

function* reset_cart(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(replaceParams(API.HOME.RESET_CART, params));
  if (result.success) {
    yield put({
      type: RESET_CART.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: RESET_CART.FAIL,
      result,
    });
  }
}

function* checkCart(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.HOME.CHECK_CART, params);
  if (result.success) {
    yield put({
      type: CHECK_CART.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: CHECK_CART.FAIL,
      result,
    });
  }
}

function* createNewInvoice(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.INVOICE.CREATE_NEW_INVOICE, params);
  if (result.success) {
    yield put({
      type: CREATE_NEW_INVOICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: CREATE_NEW_INVOICE.FAIL,
      result,
    });
  }
}

function* getMarketPriceData(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.HOME.GET_MARKET_PRICE_DATA, params)
  );
  if (result.success) {
    yield put({
      type: GET_MARKET_PRICE_DATA.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_MARKET_PRICE_DATA.FAIL,
      result,
    });
  }
}

function* getAverageMarketPrice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.HOME.GET_AVERAGE_MARKET_PRICE, params)
  );
  if (result.success) {
    yield put({
      type: GET_AVERAGE_MARKET_PRICE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_AVERAGE_MARKET_PRICE.FAIL,
      result,
    });
  }
}

export default function* () {
  yield takeEvery(GET_METAL_MARKET_PRICE.REQUEST, getMetalMarketPrice);
  yield takeEvery(RESET_CART.REQUEST, reset_cart);
  yield takeEvery(CHECK_CART.REQUEST, checkCart);
  yield takeEvery(CREATE_NEW_INVOICE.REQUEST, createNewInvoice);
  yield takeEvery(GET_MARKET_PRICE_DATA.REQUEST, getMarketPriceData);
  yield takeEvery(GET_AVERAGE_MARKET_PRICE.REQUEST, getAverageMarketPrice);
}
