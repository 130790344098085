import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import { Block } from "components";
import { Colors, nor } from "utils";
import Back from "components/common/Back";
import { connect } from "react-redux";
import {
  getOtherInvoice,
  getLastInvoice,
  updateInvoice,
} from "../../redux/actions/invoice";
import { getSetting } from "../../redux/actions/general";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  INVOICE_STATUS,
  CART_UPDATE_INVOICE_BEHAVIOR,
} from "../../utils/Constant";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import {
  getProductCatalogDetail,
  keepSearchProductCatalog,
} from "../../redux/actions/product";
import { useHistory } from "react-router-dom";
import { getMetalMarketPrice } from "../../redux/actions/home";
import OtherInvoiceRecord from "components/common/OtherInvoiceRecord";
import Title from "components/common/Title";

// import FastImage from "react-native-fast-image";
// import { requireNativeComponent } from "react-native";
/**
 * 005-002 / 製品情報
 * @param {*} props
 */
const ProductInfo = (props) => {
  // const history = useHistory();
  const [fromToUnitPrice, setFromToUnitPrice] = useState("");
  const history = useHistory();
  const product = history?.location?.state?.product;
  useEffect(() => {
    props.getSetting();
    if (product !== undefined && product !== null) {
      props.getProductCatalogDetail({
        product_id: product.id,
        customer_id: -1,
      });
      props.getOtherInvoice({
        product_id: product?.id,
      });
      props.getMetalMarketPrice();
    }
  }, []);

  const renderPriceColorText = (currentPrice, prevPrice) => {
    if (currentPrice > prevPrice) {
      return styles.redText;
    } else {
      return styles.lightBlueText;
    }
  };

  const calculateEstimatePrice = () => {
    //     仕入単価＝(products.adjust_value_pt × market_prices.pt_jpy)
    //  + (products.adjust_value_pd × market_prices.pd_jpy)
    //  + (products.adjust_value_rh × market_prices.rh_jpy)
    // 下限仕入単価＝仕入単価 × (settings.target_profit_rate / 100)
    // 上限仕入単価＝仕入単価 × (settings.limit_profit_rate / 100)
    // => 仕入単価目安 = {下限仕入単価} ~ {上限仕入単価}
    console.log("hung props.productCatalogDetail:", props.productCatalogDetail);
    console.log("hung props.setting:", props.setting);
    if (props.productCatalogDetail !== undefined) {
      const {
        pt_jpy,
        pd_jpy,
        rh_jpy,
      } = props.productCatalogDetail?.market_price;
      const {
        total_value_pd,
        total_value_pt,
        total_value_rh,
      } = props.productCatalogDetail;
      const unitPrice =
        total_value_pt * pt_jpy +
        total_value_pd * pd_jpy +
        total_value_rh * rh_jpy;
      const fromUnitPrice =
        unitPrice * (props.setting?.target_profit_rate * 0.01);
      const toUnitPrice =
        fromUnitPrice * (props.setting?.limit_profit_rate * 0.01);
      // 14,000円〜1'5pt',500円
      const s =
        formatMoney(fromUnitPrice) + "円〜" + formatMoney(toUnitPrice) + "円";
      setFromToUnitPrice(s);
    }
  };

  useEffect(() => {
    calculateEstimatePrice();
  }, [props.setting]);

  const prevGettingProductCatalogDetail = usePrevious(
    props.isGettingProductCatalogDetail
  );
  useEffect(() => {
    if (
      prevGettingProductCatalogDetail &&
      !props.isGettingProductCatalogDetail
    ) {
      if (props.isGotProductCatalogDetail) {
        calculateEstimatePrice();
      }
    }
  }, [props.isGettingProductCatalogDetail]);

  return (
    <View style={{ flex: 2 }}>
      <View style={styles.wrap}>
        <View style={styles.topWarp}>
          {/* <View style={styles.titleWrap}>
            <Back
              label={"サンプルカタログ"}
              onPress={() =>
                props.keepSearchProductCatalog({
                  isKeep: false,
                })
              }
            />
            <Text style={styles.title}>製品情報</Text>
          </View> */}
          <Title
            backTitle={"サンプルカタログ"}
            title="製品情報"
            onBack={() =>
              props.keepSearchProductCatalog({
                isKeep: false,
              })
            }
          />

          <View style={styles.sectionHeader}>
            <Text style={styles.headerTitle}>
              製品コード：{props.productCatalogDetail?.code}
            </Text>
            <Text style={styles.headerTitle}>
              品名：{props.productCatalogDetail?.name}
            </Text>
            <Text style={styles.headerTitle}>
              メーカー：{props.productCatalogDetail?.maker_info?.name}
            </Text>
            <View style={styles.row}>
              <View style={styles.rowFlex}>
                <Text style={styles.headerBadge}>仕入単価目安</Text>
                <Text style={styles.headerText}>{fromToUnitPrice}</Text>
              </View>
              {/* <View style={styles.rowFlex}>
                <Text style={styles.headerBadge}>直近仕入日</Text>
                <Text style={[styles.headerText, { fontSize: "10pt" }]}>
                  {formatDateTime(
                    props.productCatalogDetail?.last_invoice?.date
                  )}
                </Text>
              </View> */}
            </View>
            <View style={styles.row}>
              <View style={styles.rowFlex}>
                <Text style={styles.headerBadge}>直近取引先</Text>
                <Text style={styles.headerText}>
                  {
                    props.productCatalogDetail?.last_invoice?.customer_info
                      ?.name
                  }
                </Text>
              </View>
              <View style={styles.rowFlex}>
                <Text style={styles.headerBadge}>直近仕入日</Text>
                <Text style={[styles.headerText, { fontSize: "10pt" }]}>
                  {formatDateTime(
                    props.productCatalogDetail?.last_invoice?.date
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.rowFlex}>
                <Text style={styles.headerBadge}>直近仕入単価</Text>
                <Text style={styles.headerText}>
                  {formatMoney(props.productCatalogDetail?.last_unit_price)}円
                </Text>
              </View>
              <View style={styles.rowFlex} />
            </View>
            <View style={[styles.row, { justifyContent: "space-between" }]}>
              <Text style={styles.headerBadge}>仕入時相場</Text>
              <Text style={[styles.headerBadge, styles.badgeSmall]}>PT</Text>
              <Text
                style={renderPriceColorText(
                  props.metalMarketPrice.latest_price?.pt_jpy,
                  props.productCatalogDetail?.last_invoice?.pt_jpy
                )}
              >
                {formatMoney(props.productCatalogDetail?.last_invoice?.pt_jpy)}
                円
              </Text>

              <Text style={[styles.headerBadge, styles.badgeSmall]}>PD</Text>
              <Text
                style={renderPriceColorText(
                  props.metalMarketPrice.latest_price?.pd_jpy,
                  props.productCatalogDetail?.last_invoice?.pd_jpy
                )}
              >
                {formatMoney(props.productCatalogDetail?.last_invoice?.pd_jpy)}
                円
              </Text>

              <Text style={[styles.headerBadge, styles.badgeSmall]}>RH</Text>

              <Text
                style={renderPriceColorText(
                  props.metalMarketPrice.latest_price?.rh_jpy,
                  props.productCatalogDetail?.last_invoice?.rh_jpy
                )}
              >
                {formatMoney(props.productCatalogDetail?.last_invoice?.rh_jpy)}
                円
              </Text>
            </View>
          </View>

          <ScrollView style={{ maxHeight: 130 }}>
            {props.otherInvoiceList?.map((item, index) => {
              return (
                <OtherInvoiceRecord
                  key={`${index}`}
                  otherInvoice={item}
                  invoice_market_price={
                    props.productCatalogDetail?.last_invoice?.market_price
                  }
                />
              );
            })}
          </ScrollView>
        </View>
        <View style={{ alignItems: "center" }}>
          <Image
            source={{
              uri: props.productCatalogDetail?.pic_path,
            }}
            style={{
              height: 350,
              width: "90%",
            }}
            resizeMode="contain"
          />
        </View>
      </View>
      {/* <View style={{ flex: 1 }}>
        <Image
          source={{
            uri: props.productCatalogDetail?.pic_path,
          }}
          style={{ height: 400, width: "100%" }}
          resizeMode="contain"
        />
      </View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
  },
  topWarp: {
    paddingHorizontal: "24pt",
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.SecondPrimary,
    paddingVertical: nor(15),
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: nor(18),
  },
  sectionHeader: {
    paddingTop: 15,
    paddingBottom: 10,
    borderBottomColor: Colors.GrayLight,
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
  headerTitle: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  headerText: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "11pt",
  },
  headerBadge: {
    color: "white",
    backgroundColor: Colors.Primary,
    paddingVertical: "2pt",
    paddingHorizontal: "10pt",
    minWidth: 90,
    textAlign: "center",
    marginRight: "8pt",
    fontSize: "12pt",
  },
  badgeSmall: {
    minWidth: 0,
    fontSize: "8pt",
    alignSelf: "center",
  },
  rowFlex: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 5,
  },
  redText: {
    color: "#D90000",
    fontWeight: "bold",
    fontSize: "10pt",
  },
  contentHeader: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginVertical: 10,
  },
  contentBadge: {
    color: "white",
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: 2,
    paddingHorizontal: 10,
    textAlign: "center",
    marginRight: 10,
    fontSize: 12,
  },
  price: {
    color: Colors.SecondPrimary,
    fontSize: 20,
    fontWeight: "bold",
  },
  date: {
    color: Colors.SecondPrimary,
    fontSize: 18,
    fontWeight: "bold",
  },
  lightBlueText: {
    color: "#00CBDC",
    fontWeight: "bold",
    fontSize: "10pt",
  },
});

const mapStateToProps = (state) => ({
  productCatalogDetail: state.product.productCatalogDetail,

  // otherInvoice: state.invoice.otherInvoice,
  metalMarketPrice: state.home.metalMarketPrice,
  otherInvoiceList: state.invoice.otherInvoiceList,
  isGettingProductCatalogDetail: state.product.isGettingProductCatalogDetail,
  isGotProductCatalogDetail: state.product.isGotProductCatalogDetail,
  setting: state.general.setting,
});
const mapDispatchToProps = {
  getSetting,
  getOtherInvoice,

  updateInvoice,
  // insertProductsToInvoice,
  getProductCatalogDetail,
  keepSearchProductCatalog,

  getMetalMarketPrice,
};

const ImageCard = ({ pic_path }) => {
  return (
    <Image
      key={Date.now()}
      source={{
        uri: pic_path,
      }}
      style={{ height: 400, width: "100%" }}
      resizeMode="contain"
      transition={false}
    />
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
