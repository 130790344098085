import React, { useState, useEffect } from "react";

import { Block, WrapperBlock } from "components";
import {
  TabView,
  ContentTab,
  Footer,
  InvoiceConfirm,
  EstimateConfirm,
} from "components/home";
import { useNavigate } from "utils";
import { Alert } from "../../App";
import { reset_cart, checkCart } from "../../redux/actions/home";

import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { setAxiosDefaultHeaders } from "../../configs/axiosDebugger";
import { createNewInvoice } from "../../redux/actions/home";
import { getInvoice } from "../../redux/actions/invoice";
import { createNewEstimate, getEstimate } from "../../redux/actions/estimate";
import { useHistory } from "react-router-dom";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Colors } from "utils";
import InvoiceEstimateButton from "components/common/InvoiceEstimateButton";
import Title from "components/common/Title";

const Home = (props) => {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const onPressTab = (value) => {
    setTabIndex(value);
  };
  const navigate = useNavigate();

  useEffect(() => {
    props.checkCart();
  }, []);

  return (
    <WrapperBlock>
      <Block flex={1}>
        <Title title={"貴金属相場"} />
        <TabView {...{ tabIndex, onPressTab }} />
        <ScrollView>
          <ContentTab {...{ tabIndex }} />
        </ScrollView>
      </Block>
      <Block marginBottom={"10pt"}>
        <InvoiceEstimateButton />
      </Block>
    </WrapperBlock>
  );
};

// export default Home;

const mapStateToProps = (state) => ({
  isGettingMetalMarketPrice: state.home.isGettingMetalMarketPrice,
  isGotMetalMarketPrice: state.home.isGotMetalMarketPrice,
  metalMarketPrice: state.home.metalMarketPrice,

  isResettingCart: state.home.isResettingCart,
  isResetCartFinish: state.home.isResetCartFinish,
  newCart: state.home.newCart,

  cart: state.home.cart,

  isCreatedNewInvoice: state.home.isCreatedNewInvoice,
  isCreatingNewInvoice: state.home.isCreatingNewInvoice,
  newInvoice: state.home.newInvoice,

  isCreatedNewEstimate: state.estimate.isCreatedNewEstimate,
  isCreatingNewEstimate: state.estimate.isCreatingNewEstimate,
  newEstimate: state.estimate.newEstimate,

  isGettingEstimate: state.estimate.isGettingEstimate,
  isGotEstimate: state.estimate.isGotEstimate,
  estimate: state.estimate.estimate,

  isGettingInvoice: state.invoice.isGettingInvoice,
  isGotInvoice: state.invoice.isGotInvoice,
  invoice: state.invoice.invoice,
});
const mapDispatchToProps = {
  reset_cart,
  createNewInvoice,
  createNewEstimate,
  checkCart,
  getEstimate,
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
