import { combineReducers } from "redux";
import auth from "./auth";
import home from "./home";
import invoice from "./invoice";
import product from "./product";
import transfer from "./transfer";
import general from "./general";
import estimate from "./estimate";
import customer from "./customer";
import account from "./account";

export default combineReducers({
  auth,
  home,
  invoice,
  product,
  transfer,
  general,
  estimate,
  customer,
  account,
});
