import {
  GET_TRANSFER_CUSTOMERS,
  UPDATE_CUSTOMER,
  GET_LAST_INVOICE,
  INSERT_PRODUCTS_TO_INVOICE,
  GET_INVOICE,
  UPDATE_INVOICE,
  // GET_TWO_LAST_INVOICE,
  GET_OTHER_INVOICE,
  DELETE_INVOICE_DETAIL,
  SEARCH_PAST_PERFORMANCE,
  INVOICE_UPLOAD_PDF,
  CREATE_TRANSFER_INVOICE,
  SAVE_INVOICE_DATA_GO_TO_PRODUCT_DETAIL,
  SEARCH_PRODUCT_IN_INVOICE_DETAIL,
  INVOICE_GENERATE_PDF,
} from "../../redux/actions/invoice";

import { processPaging } from "./utils";
const initialState = {
  // transferCustomers: [],
  errorMsg: "",
  isUpdatingCustomer: false,
  isUpdatedCustomer: false,
  updatedCustomerInvoice: {},

  isGettingLastInvoice: false,
  isGotLastInvoice: false,
  lastInvoice: {},

  isGettingInvoice: false,
  isGotInvoice: false,
  invoice: {},

  isUpdatingInvoice: false,
  isUpdatedInvoice: false,
  updatedInvoice: {},

  twoLastInvoice: {},
  otherInvoiceList: [],

  isDeletingInvoiceDetail: false,
  isDeletedInvoiceDetail: false,
  invoiceWhichDeletedInvoiceDetail: {},

  isSearchingPerformance: false,
  isSearchedPerformance: false,
  searchPerformanceNextPage: 1,
  searchPerformanceReachEnd: false,
  searchPerformanceResult: [],

  invoiceDataWhenGoToProductDetail: {},

  invoiceDetailSearchByProductName: [],

  isInsertingProductToInvoice: false,
  isInsertedProductToInvoice: false,
  invoiceWhichJustInsertProduct: [],

  isGeneratingPdf: false,
  isGeneratedPdf: false,
  generatedPdfUrl: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER.REQUEST:
      return {
        ...state,
        isUpdatingCustomer: true,
        isUpdatedCustomer: false,
        updatedCustomerInvoice: [],
      };

    case UPDATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        isUpdatingCustomer: false,
        isUpdatedCustomer: true,
        updatedCustomerInvoice: action.result,
      };

    case UPDATE_CUSTOMER.FAIL:
      return {
        ...state,
        isUpdatingCustomer: false,
        isUpdatedCustomer: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_LAST_INVOICE.REQUEST:
      return {
        ...state,
        isGettingLastInvoice: true,
        isGotLastInvoice: false,
        lastInvoice: [],
      };

    case GET_LAST_INVOICE.SUCCESS:
      return {
        ...state,
        isGettingLastInvoice: false,
        isGotLastInvoice: true,
        lastInvoice: action.result,
      };

    case GET_LAST_INVOICE.FAIL:
      return {
        ...state,
        isGettingLastInvoice: false,
        isGotLastInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case INSERT_PRODUCTS_TO_INVOICE.REQUEST:
      return {
        ...state,
        isInsertingProductToInvoice: true,
        isInsertedProductToInvoice: false,
      };

    case INSERT_PRODUCTS_TO_INVOICE.SUCCESS:
      return {
        ...state,
        isInsertingProductToInvoice: false,
        isInsertedProductToInvoice: true,
        invoiceWhichJustInsertProduct: action.result,
      };

    case INSERT_PRODUCTS_TO_INVOICE.FAIL:
      return {
        ...state,
        isInsertingProductToInvoice: false,
        isInsertedProductToInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_INVOICE.REQUEST:
      return {
        ...state,
        isGettingInvoice: true,
        isGotInvoice: false,
        invoice: {},
      };

    case GET_INVOICE.SUCCESS:
      return {
        ...state,
        isGettingInvoice: false,
        isGotInvoice: true,
        invoice: action.result,
      };

    case GET_INVOICE.FAIL:
      return {
        ...state,
        isGettingInvoice: false,
        isGotInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case UPDATE_INVOICE.REQUEST:
      return {
        ...state,
        isUpdatingInvoice: true,
        isUpdatedInvoice: false,
      };

    case UPDATE_INVOICE.SUCCESS:
      return {
        ...state,
        isUpdatingInvoice: false,
        isUpdatedInvoice: true,
        updatedInvoice: action.result,
      };

    case UPDATE_INVOICE.FAIL:
      return {
        ...state,
        isUpdatingInvoice: false,
        isUpdatedInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_OTHER_INVOICE.REQUEST:
      return {
        ...state,
        // isGettingInvoice: true,
        // isGotInvoice: false,
        otherInvoiceList: [],
      };

    case GET_OTHER_INVOICE.SUCCESS:
      return {
        ...state,
        // isGettingInvoice: false,
        // isGotInvoice: true,
        otherInvoiceList: action.result,
      };

    case GET_OTHER_INVOICE.FAIL:
      return {
        ...state,
        // isGettingInvoice: false,
        // isGotInvoice: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case DELETE_INVOICE_DETAIL.REQUEST:
      return {
        ...state,
        isDeletingInvoiceDetail: true,
        isDeletedInvoiceDetail: false,
        invoiceWhichDeletedInvoiceDetail: {},
      };

    case DELETE_INVOICE_DETAIL.SUCCESS:
      return {
        ...state,
        isDeletingInvoiceDetail: false,
        isDeletedInvoiceDetail: true,
        invoiceWhichDeletedInvoiceDetail: action.result,
      };

    case DELETE_INVOICE_DETAIL.FAIL:
      return {
        ...state,
        isDeletingInvoiceDetail: false,
        isDeletedInvoiceDetail: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SEARCH_PAST_PERFORMANCE.REQUEST:
      return {
        ...state,
        isSearchingPerformance: true,
        isSearchedPerformance: false,
        // searchPerformanceResult: [],
      };

    case SEARCH_PAST_PERFORMANCE.SUCCESS:
      return {
        ...state,
        isSearchingPerformance: false,
        isSearchedPerformance: true,
        // searchPerformanceResult: action.result,
        ...processPaging(
          "searchPerformanceResult",
          state,
          action.result,
          "searchPerformanceNextPage",
          "searchPerformanceReachEnd"
        ),
      };

    case SEARCH_PAST_PERFORMANCE.FAIL:
      return {
        ...state,
        isSearchingPerformance: false,
        isSearchedPerformance: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case INVOICE_UPLOAD_PDF.REQUEST:
      return {
        ...state,
      };

    case INVOICE_UPLOAD_PDF.SUCCESS:
      return {
        ...state,
        // estimateWhichDeletedEstimateDetail: action.result,
      };

    case INVOICE_UPLOAD_PDF.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case CREATE_TRANSFER_INVOICE.REQUEST:
      return {
        ...state,
      };

    case CREATE_TRANSFER_INVOICE.SUCCESS:
      return {
        ...state,
        // estimateWhichDeletedEstimateDetail: action.result,
      };

    case CREATE_TRANSFER_INVOICE.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SAVE_INVOICE_DATA_GO_TO_PRODUCT_DETAIL:
      return {
        ...state,
        invoiceDataWhenGoToProductDetail: action.params.invoiceData,
      };

    case SEARCH_PRODUCT_IN_INVOICE_DETAIL.REQUEST:
      return {
        ...state,
      };

    case SEARCH_PRODUCT_IN_INVOICE_DETAIL.SUCCESS:
      return {
        ...state,
        invoiceDetailSearchByProductName: action.result,
      };

    case SEARCH_PRODUCT_IN_INVOICE_DETAIL.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };
    case INVOICE_GENERATE_PDF.REQUEST:
      return {
        ...state,
        isGeneratingPdf: true,
        isGeneratedPdf: false,
        generatedPdfUrl: "",
      };

    case INVOICE_GENERATE_PDF.SUCCESS:
      return {
        ...state,
        isGeneratingPdf: false,
        isGeneratedPdf: true,
        generatedPdfUrl: action.result,
      };

    case INVOICE_GENERATE_PDF.FAIL:
      return {
        ...state,
        isGeneratingPdf: false,
        isGeneratedPdf: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
