import React from "react";
import { Dimensions } from "react-native";
import Block from "./Block";
const { height } = Dimensions.get("window");
export const TAB_HEIGHT = 225;
const WrapperBlock = ({ children }) => {
  return (
    <Block
      paddingBottom="10pt"
      paddingHorizontal="24pt"
      height={height - TAB_HEIGHT}
    >
      {children}
    </Block>
  );
};

export default WrapperBlock;
