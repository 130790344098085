import axios from "axios";

import { takeEvery, put } from "redux-saga/effects";
import {
  GET_ACCOUNT_CART,
  GET_STATUS_MANAGEMENT,
  SEARCH_ACCOUNT_VOUCHER,
} from "../actions/account";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* login(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.ACCOUNT.GET_ACCOUNT_CART, params);

  if (result.success) {
    yield put({
      type: GET_ACCOUNT_CART.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    console.log("fail ", result);
    yield put({
      type: GET_ACCOUNT_CART.FAIL,
      result,
    });
  }
}

function* getStatusManagement(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.ACCOUNT.GET_STATUS_MANAGEMENT, params)
  );

  if (result.success) {
    yield put({
      type: GET_STATUS_MANAGEMENT.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_STATUS_MANAGEMENT.FAIL,
      result,
    });
  }
}

function* searchAccountVoucher(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.ACCOUNT.SEARCH_ACCOUNT_VOUCHER, params)
  );

  if (result.success) {
    yield put({
      type: SEARCH_ACCOUNT_VOUCHER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: SEARCH_ACCOUNT_VOUCHER.FAIL,
      result,
    });
  }
}

export default function* () {
  yield takeEvery(GET_ACCOUNT_CART.REQUEST, login);
  yield takeEvery(GET_STATUS_MANAGEMENT.REQUEST, getStatusManagement);
  yield takeEvery(SEARCH_ACCOUNT_VOUCHER.REQUEST, searchAccountVoucher);
}
