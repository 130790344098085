import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";
import { Title, Block, Button } from "components";
import { Colors } from "utils";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  getDayOfWeek,
  NOW_DATE,
  formatDateTime,
  DateTimeFormatter,
} from "../../../utils/DateUtility";
import { insertInformation } from "../../../redux/actions/general";
import { INFORMATION_TYPE } from "../../../utils/Constant";
import { usePrevious } from "../../../utils/hook";
import { useNavigate } from "utils";

/**
 * 【管理者】004-002-1 / お知らせ・新規作成
 * @param {*} props
 */
const CreateNotice = (props) => {
  const history = useHistory();
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();

  const createInformation = () => {
    props.insertInformation({
      type: INFORMATION_TYPE.TODAY,
      message: message,
    });
  };

  const prevInsertingformation = usePrevious(props.isInsertingformation);
  useEffect(() => {
    if (prevInsertingformation && !props.isInsertingformation) {
      if (props.isInsertedformation) {
        navigate("/notice", {});
      }
    }
  }, [props.isInsertingformation]);

  return (
    <View style={styles.wrap}>
      <Title title={"お知らせ"} />
      <View style={styles.sectionRow}>
        <Text style={styles.sectionLabel}>本日の通達事項：新規作成</Text>
        <Text style={[styles.sectionLabel, { fontWeight: "bold" }]}>
          {formatDateTime(NOW_DATE)} ({getDayOfWeek(NOW_DATE)})
        </Text>
      </View>
      <TextInput
        multiline
        style={styles.textInput}
        onChangeText={(value) => setMessage(value)}
      />
      <View>
        <Button
          mediumCentered
          marginTop={32}
          onPress={() => createInformation()}
        >
          送　信
        </Button>
        <Button onPress={() => history.goBack()} mediumCentered marginTop={5}>
          お知らせTOPへ
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 24,
  },
  sectionRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 15,
    paddingBottom: 10,
  },
  sectionLabel: {
    color: Colors.Primary,
  },
  textInput: {
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: 5,
    height: "35vh",
  },
});

const mapStateToProps = (state) => ({
  // todayInformationList: state.general.todayInformationList,
  // officeInformationList: state.general.officeInformationList,
  // latestUpdatedTransactionList: state.general.latestUpdatedTransactionList,

  isInsertingformation: state.general.isInsertingformation,
  isInsertedformation: state.general.isInsertedformation,
});
const mapDispatchToProps = {
  insertInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotice);
