import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
// import Text from 'components/Text';
import { TextView, Button, Block } from "components";
import Modal from "modal-enhanced-react-native-web";
import TextButton from "./Button";
import { Colors } from "../../utils";

class Alert extends React.Component {
  state = {
    title: "",
    description: "",
    buttons: [],
    show: false,
    styleButtons: [] || {},
    styleDescription: {},
  };

  alert = (title, description, buttons, styleButtons, styleDescription) => {
    this.setState({
      title,
      description,
      buttons,
      show: true,
      styleButtons,
      styleDescription,
    });
  };

  renderContent = () => {
    const { description, title } = this.state;
    console.log("hung styleDescription:", this.state.styleDescription);
    return (
      <>
        <Text style={styles.title}>{title}</Text>
        <View style={[styles.descriptionWrap]}>
          {React.isValidElement(description) ? (
            description
          ) : (
            <Text style={[styles.description, this.state.styleDescription]}>
              {description}
            </Text>
          )}
        </View>
      </>
    );
  };

  renderButtons = () => {
    return (
      <View
        key={this.state.title}
        style={[
          styles.flexRow,
          {
            justifyContent:
              this.state.buttons.length == 1 ? "center" : "space-between",
          },
          this.state.styleButtons,
        ]}
      >
        {this.state.buttons.map((item, index) => {
          return (
            <Button
              key={`item_${index}`}
              onPress={item.onPress}
              paddingVertical="5pt"
              paddingHorizontal="15pt"
              color={Colors.SecondPrimary}
              borderRadius="3pt"
            >
              <TextView size="9pt" bold color={Colors.White}>
                {item.label}
              </TextView>
            </Button>
          );
        })}
      </View>
    );
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  render() {
    const { buttons, show } = this.state;
    return (
      <Modal animationIn={"zoomIn"} animationOut={"zoomOut"} isVisible={show}>
        <Block
          width="100%"
          color="white"
          borderWidth="1pt"
          borderColor={Colors.Primary}
          borderRadius="5pt"
          padding="5pt"
        >
          <View
            style={[
              styles.wrap,
              { paddingBottom: 0 },
              this.state.styleDescription,
            ]}
          >
            {this.renderContent()}
          </View>
          <View style={[styles.wrap, { paddingTop: 0, alignItems: "stretch" }]}>
            {!!buttons?.length && this.renderButtons()}
          </View>
        </Block>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    padding: 20,
    alignItems: "center",
    backgroundColor: "white",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionWrap: {
    minHeight: 78,
    paddingVertical: 10,
  },
  description: {
    textAlign: "center",
    fontSize: 18,
  },
  flexRow: {
    flexDirection: "row",
  },
});

export default Alert;
