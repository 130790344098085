import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { StyleSheet, TouchableOpacity, Text, View } from "react-native";
import { Colors } from "utils";
const ItemAnalysis = ({ item, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.tableDataRow}>
      <View style={styles.flex1}>
        <Text style={styles.tableDataLabel}>{item.code}</Text>
      </View>
      <View style={styles.flex2}>
        <Text style={styles.tableDataLabel}>{item.name}</Text>
      </View>
      <View style={styles.flex1}>
        <View style={styles.dataRow}>
          <Text style={styles.badge}>PT</Text>
          <Text style={styles.tableDataLabel}>{item.analyze_pt}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.badge}>PD</Text>
          <Text style={styles.tableDataLabel}>{item.analyze_pd}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.badge}>RH</Text>
          <Text style={styles.tableDataLabel}>{item.analyze_rh}</Text>
        </View>
      </View>
      <View style={styles.flex1}>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataLabelFilled}>{item.adjust_rate_pt}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataLabelFilled}>{item.adjust_rate_pd}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataLabelFilled}>{item.adjust_rate_rh}</Text>
        </View>
      </View>
      <View style={styles.flex1}>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataNumber}>{item.adjust_value_pt}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataNumber}>{item.adjust_value_pd}</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={styles.tableDataNumber}>{item.adjust_value_rh}</Text>
        </View>
      </View>
      <View style={styles.flex1}>
        <View style={styles.flex1}>
          <View style={styles.dataRow}>
            <Text style={styles.tableDataNumber}>{item.total_value_pt}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.tableDataNumber}>{item.total_value_pd}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.tableDataNumber}>{item.total_value_rh}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  tableDataRow: {
    flexDirection: "row",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.SecondPrimary,
    borderStyle: "dashed",
  },
  tableDataLabel: {
    fontSize: 12,
    color: Colors.Primary,
    marginBottom: 2,
  },
  tableDataLabelFilled: {
    fontSize: 12,
    color: Colors.Primary,
    marginBottom: 2,
    backgroundColor: Colors.PrimaryLight,
    alignSelf: "flex-end",
    paddingVertical: 2,
    paddingHorizontal: 10,
    minWidth: 40,
    textAlign: "right",
  },
  badge: {
    backgroundColor: Colors.Primary,
    paddingVertical: 1,
    paddingHorizontal: 5,
    color: "white",
    fontSize: 10,
    marginRight: 5,
  },
  dataRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 22,
  },
  tableDataNumber: {
    fontSize: 12,
    color: Colors.Primary,
    textAlign: "right",
    minWidth: 32,
  },
});

export default ItemAnalysis;
