import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, StyleSheet, Dimensions } from "react-native";
import { Colors, Metrics, nor, useNavigate } from "utils";
import Back from "components/common/Back";
import Package from "components/quotation-list/Package";
import { Block, Button, TextView } from "components";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEstimate,
  updateEstimate,
  deleteEstimateDetail,
  saveEstimateScrollYOffset,
} from "../../redux/actions/estimate";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import {
  ESTIMATE_STATUS,
  ESTIMATE_PIC_ATTACH,
  QUOTATION_FLOW,
} from "../../utils/Constant";
import Title from "components/common/Title";
const windowHeight = Dimensions.get("window")?.height;

/**
 * //screen: 003-009-1 / 見積リスト
 */

const QuoteList = (props) => {
  const navigate = useNavigate();
  const history = useHistory();
  const [estimateData, setEstimateData] = useState({});
  const [update, setUpdate] = useState(false);
  const [isGoNextScreen, setIsGoNextScreen] = useState(false);
  let scrollRef = null;

  useEffect(() => {
    const estimate_id = history?.location?.state?.estimate_id;
    if (!!estimate_id) {
      props.getEstimate({
        estimate_id: history?.location?.state?.estimate_id,
      });
      // setIsEmptyCart(false);
    } else {
      //show empty cart
      // setIsEmptyCart(true);
    }
  }, []);

  useEffect(() => {
    // if (history.action === "POP") {
    //   console.log("hung scrollRef:", props.estimateScrollYOffset);
    //   scrollRef.scrollTo({ y: props.estimateScrollYOffset });
    // } else {
    //   props.saveEstimateScrollYOffset({
    //     y_offset: 0,
    //   });
    // }
  }, [estimateData]);

  const prevGettingEstimate = usePrevious(props.isGettingEstimate);
  useEffect(() => {
    if (prevGettingEstimate && !props.isGettingEstimate) {
      if (props.isGotEstimate) {
        setupData(props.estimate);
      }
    }
  }, [props.isGettingEstimate]);

  const updateEstimate = (data, status) => {
    const estimate_details = data?.estimate_details?.map((item) => {
      return {
        id: item.id,
        product_id: item.product_info?.id,
        name: item.name,
        unit_price: formatMoney(
          item.unit_price,
          MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
        ),
        unit_price2: formatMoney(
          item.unit_price2,
          MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
        ),
        pic_attach: item.pic_attach
          ? ESTIMATE_PIC_ATTACH.ATTACHED
          : ESTIMATE_PIC_ATTACH.NO_ATTACH,
      };
    });

    const updateEstimate = {
      id: estimateData.id,
      status: status,
      estimate_details: estimate_details,
    };
    props.updateEstimate(updateEstimate);
  };

  const quotationPreview = () => {
    //Submit the data, estimate.status = 0
    updateEstimate(estimateData, ESTIMATE_STATUS.ESTIMATING);
    // navigate("cart-preview")}
    setIsGoNextScreen(true);
  };

  const updateEstimateWhenOnBlur = () => {
    updateEstimate(estimateData, ESTIMATE_STATUS.ESTIMATING);
  };

  const prevUpdatingEstimate = usePrevious(props.isUpdatingEstimate);
  useEffect(() => {
    if (prevUpdatingEstimate && !props.isUpdatingEstimate) {
      if (props.isUpdatedEstimate && isGoNextScreen) {
        // navigate("quotation-review", {
        //   pattern: QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE,
        //   estimate: props.updatedEstimate,
        // });
        const estimate_detail_pic_attach = props.updatedEstimate.estimate_details.filter(
          (item) => item.pic_attach == ESTIMATE_PIC_ATTACH.ATTACHED
        );
        // if estimate_detail_pic_attach.length > 0 : go to [003-010-1 / 見積書プレビュー（画像なし）]
        // else: go to 003-010-2 / 見積書プレビュー（画像あり）
        navigate("quotation-review", {
          pattern:
            estimate_detail_pic_attach.length > 0
              ? QUOTATION_FLOW.QUOTATION_REVIEW_WITH_IMAGE
              : QUOTATION_FLOW.QUOTATION_REVIEW_NO_IMAGE,
          estimate: props.updatedEstimate,
        });
      }
    }
  }, [props.isUpdatingEstimate]);

  const prevDeletingEstimateDetail = usePrevious(
    props.isDeletingEstimateDetail
  );
  useEffect(() => {
    if (prevDeletingEstimateDetail && !props.isDeletingEstimateDetail) {
      if (props.isDeletedEstimateDetail) {
        //TODO
        setupData(props.estimateWhichDeletedEstimateDetail);
      }
    }
  }, [props.isDeletingEstimateDetail]);

  const onImageAttachChange = (selectedIndex, pic_attach) => {
    const estimate_details = props.estimate?.estimate_details.map(
      (item, index) => {
        item.pic_attach =
          index === selectedIndex
            ? pic_attach === ESTIMATE_PIC_ATTACH.NO_ATTACH
              ? ESTIMATE_PIC_ATTACH.ATTACHED
              : ESTIMATE_PIC_ATTACH.NO_ATTACH
            : item.pic_attach;
        return item;
      }
    );
    const data = props.estimate;
    data.estimate_details = estimate_details;
    updateEstimate(data, ESTIMATE_STATUS.ESTIMATING);
  };

  const onEstimateChange = (selectedIndex, changedValue, type) => {
    let data = props.estimate;
    const estimate_details = props.estimate.estimate_details.map(
      (item, index) => {
        if (type === 1) {
          item.unit_price =
            index === selectedIndex ? changedValue : item.unit_price;
          return item;
        } else if (type === 2) {
          item.unit_price2 =
            index === selectedIndex ? changedValue : item.unit_price2;
          return item;
        } else if (type === 3) {
          item.name = index === selectedIndex ? changedValue : item.name;
          return item;
        } else {
          return item;
        }
      }
    );

    data.estimate_details = estimate_details;

    setEstimateData(data);
    setUpdate((prev) => !prev);
  };

  const setupData = (estimate) => {
    const data = estimate;
    setEstimateData(data);
    setUpdate((prev) => !prev);
  };

  return (
    <View style={styles.wrap}>
      <Title backTitle={"査 定"} title="見積リスト" />
      <View style={{}}>
        <Text style={styles.description}>
          取引先コード：{props.estimate?.customer_info?.code}
        </Text>
        <Text style={styles.description}>
          取引先名：{props.estimate?.customer_info?.name}
        </Text>
        <Text style={styles.description}>本日の貴金属相場</Text>
      </View>
      <View style={{ flexDirection: "row", marginTop: "5pt" }}>
        <View style={styles.cell}>
          <TextView size="13pt" color={Colors.Primary} bold>
            PT：{formatMoney(props.estimate?.market_price?.pt_jpy)}円
          </TextView>
        </View>
        <View style={[styles.cell, { marginHorizontal: "5pt" }]}>
          <TextView size="13pt" color={Colors.Primary} bold>
            PD：{formatMoney(props.estimate?.market_price?.pd_jpy)}円
          </TextView>
        </View>
        <View style={styles.cell}>
          <TextView size="13pt" color={Colors.Primary} bold>
            RH：{formatMoney(props.estimate?.market_price?.rh_jpy)}円
          </TextView>
        </View>
      </View>

      <View style={{ height: windowHeight - 550, marginTop: "5pt" }}>
        <ScrollView
          ref={(ref) => (scrollRef = ref)}
          onScroll={(e) => {
            // props.saveEstimateScrollYOffset({
            //   y_offset: e?.nativeEvent?.contentOffset?.y,
            // });
          }}
        >
          {estimateData?.estimate_details?.map((item, index) => {
            return (
              <Package
                estimate={estimateData}
                estimate_detail={item}
                estimateDetailIndex={index}
                key={item.id}
                onTrash={(estimate_detail) => {
                  props.deleteEstimateDetail({
                    estimate_id: history?.location?.state?.estimate_id,
                    estimate_detail_id: estimate_detail.id,
                  });
                }}
                unitPriceOnBlur={() => {
                  updateEstimateWhenOnBlur();
                }}
                onUnitPriceChange={(value) => {
                  onEstimateChange(index, value, 1);
                }}
                onUnitPrice2Change={(value) => {
                  onEstimateChange(index, value, 2);
                }}
                onNameChange={(value) => {
                  onEstimateChange(index, value, 3);
                }}
                unitPrice2OnBlur={() => {
                  updateEstimateWhenOnBlur();
                }}
                nameOnBlur={() => {
                  updateEstimateWhenOnBlur();
                }}
                onImageCheckChange={(pic_attach) => {
                  onImageAttachChange(index, pic_attach);
                }}
              />
            );
          })}
        </ScrollView>
      </View>
      <Block width="100%" center paddingTop="20pt">
        <Button
          // onPress={() => navigate("quotation-review", { pattern: "003-009-1" })}
          onPress={() => quotationPreview()}
          color={Colors.SecondPrimary}
          middle
          width={Metrics.appW * (160 / 375)}
          paddingVertical="10pt"
          borderRadius="3pt"
        >
          <TextView color="white" size={"14pt"}>
            見積書プレビュー
          </TextView>
        </Button>
      </Block>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: "24pt",
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: nor(18),
  },
  description: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "13pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 3,
  },
  cellText: {
    fontWeight: "bold",
    color: Colors.Primary,
    fontSize: "14pt",
  },
});

// export default QuoteList;

const mapStateToProps = (state) => ({
  isGettingEstimate: state.estimate.isGettingEstimate,
  isGotEstimate: state.estimate.isGotEstimate,
  estimate: state.estimate.estimate,

  isUpdatingEstimate: state.estimate.isUpdatingEstimate,
  isUpdatedEstimate: state.estimate.isUpdatedEstimate,
  updatedEstimate: state.estimate.updatedEstimate,

  isDeletingEstimateDetail: state.estimate.isDeletingEstimateDetail,
  isDeletedEstimateDetail: state.estimate.isDeletedEstimateDetail,
  estimateWhichDeletedEstimateDetail:
    state.estimate.estimateWhichDeletedEstimateDetail,

  estimateScrollYOffset: state.estimate.estimateScrollYOffset,
});
const mapDispatchToProps = {
  getEstimate,
  updateEstimate,
  deleteEstimateDetail,
  saveEstimateScrollYOffset,
  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteList);
