import React from "react";
import { Text, View, StyleSheet } from "react-native";
import { Colors } from "utils";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const Card = ({ hideStatus, item }) => {
  return (
    <View style={styles.wrap}>
      {!hideStatus && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={styles.field}>取引</Text>
          <Text
            style={[
              styles.inProgress,
              {
                backgroundColor:
                  item.transaction_status === "完了" ? "#ADADAD" : "#D90000",
              },
            ]}
          >
            {item.transaction_status}
          </Text>
          <Text style={[styles.field, { marginLeft: 20 }]}>振込</Text>
          <Text
            style={[
              styles.pending,
              {
                backgroundColor:
                  item.status == "未処理" ? "#D90000" : "#ADADAD",
              },
            ]}
          >
            {item.status}
          </Text>
        </View>
      )}
      <Text style={styles.field}>
        取引日：{formatDateTime(item.date, DateTimeFormatter.YYYY_MM_DD_JP)}
      </Text>
      <Text style={styles.field}>
        振込期日：
        {formatDateTime(item.limit_date, DateTimeFormatter.YYYY_MM_DD_JP)}
      </Text>
      <Text style={styles.field}>取引先名：{item.customer_info?.name}</Text>
      <Text style={styles.field}>
        振込金額：{formatMoney(item.total_price)} 円
      </Text>
      <Text style={styles.field}>振込内容：{item.type_name}</Text>
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.field}>備 考：</Text>
        <View style={{ flex: 1 }}>
          <Text style={styles.note}>{item.remark}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    paddingVertical: 15,
    borderBottomColor: Colors.SecondPrimary,
    borderStyle: "dashed",
    borderBottomWidth: 1,
  },
  pending: {
    marginHorizontal: 10,
    paddingHorizontal: 5,
    paddingVertical: 2,
    color: "white",
    fontSize: "12pt",
    fontWeight: "bold",
    alignSelf: "center",
  },
  inProgress: {
    backgroundColor: "#D90000",
    marginHorizontal: 10,
    paddingHorizontal: 5,
    paddingVertical: 2,
    color: "white",
    fontSize: "12pt",
    fontWeight: "bold",
    alignSelf: "center",
  },
  field: {
    fontWeight: "bold",
    color: Colors.Primary,
    marginBottom: 5,
    fontSize: "12pt",
  },
  note: {
    color: Colors.Primary,
    fontWeight: "normal",
    fontSize: "12pt",
  },
});

export default Card;
