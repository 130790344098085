import React, { useState, useEffect } from "react";

import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  ScrollView,
  FlatList,
} from "react-native";
import { Colors, nor } from "utils";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const PerformanceRow = ({ item, ...props }) => {
  const calculateUpvalue = (invoice, invoice_detail) => {
    if (
      invoice !== undefined &&
      invoice_detail !== undefined &&
      invoice_detail?.product_info !== undefined &&
      invoice_detail?.product_info !== null
    ) {
      const { pt_jpy, pd_jpy, rh_jpy } = invoice;
      const {
        total_value_pd,
        total_value_pt,
        total_value_rh,
      } = invoice_detail?.product_info;
      const unitPrice =
        total_value_pd * pd_jpy +
        total_value_pt * pt_jpy +
        total_value_rh * rh_jpy;

      const fromUnitPrice =
        unitPrice * (props.setting?.target_profit_rate * 0.01);
      const toUnitPrice =
        fromUnitPrice * (props.setting?.limit_profit_rate * 0.01);
      return formatMoney(toUnitPrice);
    }
    return 0;
  };

  return (
    <View style={{ marginTop: 20 }}>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={styles.text}>{formatDateTime(item.date)}</Text>
          <Text style={styles.marketText}>仕入時相場</Text>
        </View>
        <Text style={styles.badge}>PT</Text>
        <Text style={styles.text}>{formatMoney(item.pt_jpy)}円</Text>
        <Text style={styles.badge}>PD</Text>
        <Text style={styles.text}>{formatMoney(item.pd_jpy)}円</Text>
        <Text style={styles.badge}>RH</Text>
        <Text style={styles.text}>{formatMoney(item.rh_jpy)}円</Text>
      </View>

      <RowHeader
        customerName={item.customer_info?.name}
        accountName={item.account_info?.name}
      />
      <Row
        isHeader
        name={"品名・分類"}
        val1={"数 量"}
        val2={"仕入単価"}
        val3={"仕入時上限単価"}
      />

      {item.invoice_details?.map((detail, index) => (
        <Row
          key={index}
          name={detail.name + "【" + detail?.product_info?.code + "】"}
          val1={formatMoney(detail.amount)}
          val2={formatMoney(detail.unit_price) + "円"}
          val3={calculateUpvalue(item, detail) + "円"}
          forceSolid={index === item.invoice_details?.length - 1}
        />
      ))}
    </View>
  );
};
export default PerformanceRow;
const styles = StyleSheet.create({
  text: {
    fontWeight: "bold",
    color: Colors.Primary,
  },
  marketText: {
    fontSize: "10pt",
    fontWeight: "bold",
    color: Colors.Primary,
  },
  badge: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 1,
    paddingHorizontal: 5,
    marginHorizontal: 3,
    backgroundColor: Colors.Primary,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: nor(18),
  },
  description: {
    color: Colors.Primary,
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  tableTitle: {
    borderBottomColor: Colors.SecondPrimary,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  smallText: {
    fontSize: 12,
    color: Colors.Primary,
  },
});

const Row = ({ name, val1, val2, val3, isHeader, forceSolid }) => {
  const fontWeight = isHeader ? "bold" : "normal";
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: Colors.SecondPrimary,
        borderBottomWidth: forceSolid ? 2 : 1,
        borderStyle: isHeader || forceSolid ? "solid" : "dashed",
      }}
    >
      <View style={{ flex: 2, paddingVertical: 7 }}>
        <Text style={[styles.text, { fontWeight }]}> {name}</Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 7, alignItems: "center" }}>
        <Text style={[styles.text, { fontWeight }]}>{val1}</Text>
      </View>
      <View style={{ flex: 1, paddingVertical: 7, alignItems: "center" }}>
        <Text style={[styles.text, { fontWeight }]}> {val2}</Text>
      </View>
      <View style={{ flex: 1.5, paddingVertical: 7, alignItems: "flex-end" }}>
        <Text style={[styles.text, { fontWeight }]}> {val3}</Text>
      </View>
    </View>
  );
};

const RowHeader = ({ customerName, accountName }) => {
  const fontWeight = "bold";
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: Colors.SecondPrimary,
        borderBottomWidth: 1,
        borderStyle: "solid",
      }}
    >
      <View style={{ flex: 1, paddingVertical: 7 }}>
        <Text style={[styles.text, { fontWeight }]}>
          取引先名： {customerName}
        </Text>
      </View>

      <View style={{ paddingVertical: 7, alignItems: "flex-end" }}>
        <Text style={[styles.text, { fontWeight }]}>担当: {accountName}</Text>
      </View>
    </View>
  );
};
