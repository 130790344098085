import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { StyleSheet, TouchableOpacity, Text, View } from "react-native";
import { Block } from "components";
import Input from "components/common/Input";
import DateInput from "components/common/DateInput";
import { Colors } from "utils";
import { useNavigate } from "utils";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import { searchCustomers } from "../../redux/actions/customer";
import { searchProductInInvoiceDetail } from "../../redux/actions/invoice";
import { connect } from "react-redux";
import { searchProducts } from "../../redux/actions/product";
import DropdownList from "components/common/DropdownList";
import { getMakers } from "../../redux/actions/general";
import Title from "components/common/Title";
/**
 * 006-001 / 過去実績検索
 * @param {*} props
 */
const Performance = (props) => {
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [productName, setProductName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [
    searchCustomerTimeOutHandler,
    setSearchCustomerTimeOutHandler,
  ] = useState(undefined);

  const [
    searchProductTimeOutHandler,
    setSearchProductTimeOutHandler,
  ] = useState(undefined);
  const [selectMaker, setSelectedMaker] = useState(undefined);

  // const searchCustomerHandler = (searchName) => {
  //   props.searchCustomers({
  //     name: searchName,
  //   });
  // };
  // const searchProductHandler = (searchName) => {
  //   props.searchProductInInvoiceDetail({
  //     product_name: searchName,
  //   });
  // };

  const searchProductHandler = (searchName) => {
    if (searchProductTimeOutHandler !== undefined)
      clearTimeout(searchProductTimeOutHandler);
    setSearchProductTimeOutHandler(
      setTimeout(() => {
        if (searchName !== "") {
          props.searchProductInInvoiceDetail({
            product_name: searchName,
          });
          // props.searchProducts({
          //   search_name: searchName,
          //   search_code: "",
          // });
        }
      }, 300)
    );
  };

  const searchCustomerHandler = (searchName) => {
    if (searchCustomerTimeOutHandler !== undefined)
      clearTimeout(searchCustomerTimeOutHandler);
    setSearchCustomerTimeOutHandler(
      setTimeout(() => {
        if (searchName !== "") {
          props.searchCustomers({
            name: searchName,
          });
        }
      }, 300)
    );
  };

  useEffect(() => {
    props.getMakers();
  }, []);

  return (
    <View style={styles.wrap}>
      {/* <Title title={"過去実績検索"} /> */}
      <Title title="過去実績検索" />
      <InputWithSuggestion
        placeHolder={""}
        label={"取引先で絞り込む"}
        fetchApi={(searchName) => searchCustomerHandler(searchName)}
        data={props.searchCustomerResults}
        onSelectedItem={(item) => setCustomerName(item?.name)}
      />
      <Block marginTop={-10}>
        <InputWithSuggestion
          placeHolder={""}
          label={"品名で絞り込む"}
          fetchApi={(searchName) => searchProductHandler(searchName)}
          data={props.invoiceDetailSearchByProductName}
          onSelectedItem={(item) => setProductName(item?.name)}
        />
      </Block>
      <Block>
        <DropdownList
          label={"メーカー検索"}
          data={props.makerList}
          onSelectedItem={(item) => {
            setSelectedMaker(item);
          }}
        />
      </Block>

      <Block marginTop={"20pt"}>
        <Text style={styles.dateLabel}>期間を指定する</Text>
      </Block>
      <View style={styles.dateWrap}>
        <View style={{ flex: 1 }}>
          <DateInput
            onDateChange={(date) => {
              setFromDate(
                formatDateTime(date, DateTimeFormatter.YYYY_MM_DD_HYPHEN)
              );
            }}
            imageStyles={{ height: "24pt", width: "24pt" }}
            containerStyle={{ padding: "5pt" }}
          />
        </View>
        <Text style={styles.separator}>〜</Text>
        <View style={{ flex: 1 }}>
          <DateInput
            onDateChange={(date) => {
              setToDate(
                formatDateTime(date, DateTimeFormatter.YYYY_MM_DD_HYPHEN)
              );
            }}
            imageStyles={{ height: "24pt", width: "24pt" }}
            containerStyle={{ padding: "5pt" }}
          />
        </View>
      </View>
      <View style={styles.btnWrap}>
        <TouchableOpacity
          onPress={() =>
            navigate("past-performance", {
              search_params: {
                customer_name: customerName == undefined ? "" : customerName,
                product_name: productName == undefined ? "" : productName,
                from_date: fromDate,
                to_date: toDate,
                maker_name: selectMaker == undefined ? "" : selectMaker?.name,
                page: 1,
              },
            })
          }
        >
          <View style={styles.btn}>
            <Text style={styles.btnTitle}>過去実績検索</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    paddingHorizontal: "24pt",
  },
  btnWrap: {
    alignItems: "center",
    paddingVertical: "70pt",
  },
  btn: {
    backgroundColor: Colors.SecondPrimary,
    paddingHorizontal: "25pt",
    paddingVertical: "10pt",
    borderRadius: "2pt",
  },
  btnTitle: {
    color: "white",
    fontWeight: "bold",
    fontSize: "12pt",
  },
  dateLabel: {
    marginTop: "12pt",
    marginBottom: "6pt",
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "12pt",
  },
  dateWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    color: Colors.Primary,
    marginHorizontal: "5pt",
  },
});

const mapStateToProps = (state) => ({
  searchCustomerResults: state.customer.searchCustomerResults,
  isUpdatingCustomerEstimate: state.estimate.isUpdatingCustomerEstimate,
  isUpdatedCustomerEstimate: state.estimate.isUpdatedCustomerEstimate,
  updatedCustomerEstimate: state.estimate.updatedCustomerEstimate,
  invoiceDetailSearchByProductName:
    state.invoice.invoiceDetailSearchByProductName,
  products: state.product.products,
  makerList: state.general.makerList,
});
const mapDispatchToProps = {
  searchCustomers,
  searchProductInInvoiceDetail,
  searchProducts,
  getMakers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Performance);

// export default Performance;
