// import * as React from 'react';
// import Navigation from 'navigation';
//
// export default function App() {
//   return <Navigation />;
// }

import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Navigation from "navigation";
import { Provider } from "react-redux";
import store, { persistor } from "redux/store";
import axiosDebugger, { setAxiosDefaultHeaders } from "configs/axiosDebugger";
import { PersistGate } from "redux-persist/integration/react";

import { default as Alert_ } from "components/common/Alert";
import ErrorPage from "containers/error-page";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

let Alert = null;

export default class App extends Component {
  constructor(props) {
    super(props);
    // const { isLoggedIn } = store.getState().auth;

    axiosDebugger();
    // setAxiosDefaultHeaders({})
  }

  // checkSubDomain() {
  //   let host = window.location.host;
  //   let protocol = window.location.protocol;
  //   let parts = host.split(".");
  //   let subdomain = "";
  //   // If we get more than 3 parts, then we have a subdomain
  //   // INFO: This could be 4, if you have a co.uk TLD or something like that.
  //   console.log("hung parts:", parts);
  //   if (parts.length >= 2) {
  //     subdomain = parts[0];
  //     // Remove the subdomain from the parts list
  //     parts.splice(0, 1);
  //     // Set the location to the new url
  //     // window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  render() {
    return (
      // <Router>
      //   <Route exact path="/">
      //     {this.checkSubDomain() ? (
      //       <Redirect to={"/mobile/bas/"}></Redirect>
      //     ) : (
      //       <ErrorPage />
      //     )}
      //   </Route>
      //   <Route path="/">
      //     <DeviceOrientation
      //       lockOrientation={"portrait"}
      //       onOrientationChange={() => {}}
      //     >
      //       <Orientation orientation="portrait" alwaysRender={false}>
      //         <Provider store={store}>
      //           <PersistGate loading={null} persistor={persistor}>
      //             <Navigation />
      //             <Alert_
      //               ref={(ref) => {
      //                 Alert = ref;
      //               }}
      //             />
      //           </PersistGate>
      //         </Provider>
      //       </Orientation>
      //       <Orientation orientation="portrait">
      //         <Text>Please rotate your device</Text>
      //       </Orientation>
      //     </DeviceOrientation>
      //   </Route>
      // </Router>
      <DeviceOrientation
        lockOrientation={"portrait"}
        onOrientationChange={() => {}}
      >
        <Orientation orientation="portrait" alwaysRender={false}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Navigation />
              <Alert_
                ref={(ref) => {
                  Alert = ref;
                }}
              />
            </PersistGate>
          </Provider>
        </Orientation>
        <Orientation orientation="portrait">
          <Text>Please rotate your device</Text>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export { Alert };
