import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Colors } from "utils";
import { useHistory } from "react-router-dom";

const Back = ({ label, onPress }) => {
  const history = useHistory();
  return (
    <TouchableOpacity
      onPress={() =>
        // window.history.back()
        {
          history.goBack();
          if (onPress !== undefined) onPress();
        }
      }
      style={{ flexDirection: "row" }}
    >
      <View style={styles.leftArrow} />
      <View
        style={{
          backgroundColor: Colors.SecondPrimary,
          paddingVertical: 2,
          paddingLeft: 10,
          paddingRight: 14,
          borderTopEndRadius: 3,
          borderBottomEndRadius: 3,
        }}
      >
        <Text style={{ color: "white" }}>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = {
  leftArrow: {
    width: 0,
    height: 0,
    borderTopWidth: 12,
    borderBottomWidth: 12,
    borderRightWidth: 12,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    borderRightColor: Colors.SecondPrimary,
  },
};

export default Back;
