import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";
// import { Colors } from "configs";

const FullLoading = ({ wrapStyle, color = "#F25F14", size = "small" }) => (
  <View style={[styles.wrap, wrapStyle]}>
    <View style={styles.background}>
      <ActivityIndicator color={color} size={size} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  wrap: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  background: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 5,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
});

export default FullLoading;
