import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Colors } from 'utils';

const styles = StyleSheet.create({
  block: {
    flex: 1,
  },
});

const Button = ({
  block,
  margin,
  padding,
  width,
  height,
  border,
  borderWidth,
  borderColor,
  borderRadius,
  color,
  fontSize,
  textColor,
  style,
  textStyle,
  centered,
  middle,
  children,
  shadow,
  paddingVertical,
  paddingHorizontal,
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  activeOpacity,
  smallCentered,
  mediumCentered,
  outline,
  ...props
}) => {
  const styleComponent = StyleSheet.flatten([
    smallCentered && {
      marginTop: 14,
      alignSelf: 'center',
      paddingVertical: 3,
      paddingHorizontal: 24,
      borderRadius: 3,
      backgroundColor: Colors.SecondPrimary,
    },
    mediumCentered && {
      alignSelf: 'center',
      paddingVertical: 5,
      paddingHorizontal: 32,
      borderRadius: 3,
      backgroundColor: Colors.SecondPrimary,
    },
    outline && {
      marginTop: 20,
      alignItems: 'center',
      paddingVertical: 7,
      borderColor: Colors.SecondPrimary,
      borderWidth: 2,
      borderRadius: 5,
    },
    block && styles.block,
    margin && { margin },
    padding && { padding },
    width && { width },
    height && { height },
    border && { borderWidth: 1, borderColor: 'gray' },
    borderWidth && { borderWidth },
    borderColor && { borderColor },
    borderRadius && { borderRadius },
    color && { backgroundColor: color },
    centered && { justifyContent: 'center' },
    middle && { alignItems: 'center' },
    shadow && {
      shadowOpacity: 0.12,
      shadowRadius: 15,
      shadowColor: 'gray',
      shadowOffset: { height: 0, width: 0 },
    },
    activeOpacity && { activeOpacity },
    paddingVertical && { paddingVertical },
    paddingHorizontal && { paddingHorizontal },
    marginVertical && { marginVertical },
    marginHorizontal && { marginHorizontal },
    marginTop && { marginTop },
    marginBottom && { marginBottom },
    marginLeft && { marginLeft },
    marginRight && { marginRight },
    style,
  ]);
  const styleText = StyleSheet.flatten([
    smallCentered && { fontSize: 12, color: 'white', fontWeight: 'bold' },
    mediumCentered && { color: 'white', fontWeight: 'bold' },
    outline && { fontWeight: 'bold', color: Colors.Primary },
    fontSize && { fontSize },
    textColor && { color: textColor },
    textStyle,
  ]);

  if (typeof children == 'string') {
    return (
      <TouchableOpacity style={styleComponent} {...props}>
        <Text style={styleText}>{children}</Text>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity style={styleComponent} {...props}>
      {children}
    </TouchableOpacity>
  );
};

export default Button;
