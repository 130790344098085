import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";
import Button from "components/common/Button";
import TextView from "components/common/TextView";

const MAX_TEXT_LENGTH = 50;

const NoticeStatus = ({
  title = "",
  content = "",
  onPress = () => true,
  patterns = 0,
}) => {
  const noneContent = content === "通達事項はありません。";
  return (
    <Button activeOpacity={0.5} {...{ onPress }}>
      <Block style={styles.wrap}>
        {patterns === 0 ? (
          <TextView bold size={13} color={Colors.SecondPrimary}>
            {title}
          </TextView>
        ) : (
          <Button
            paddingHorizontal={15}
            paddingVertical={5}
            color={patterns === 1 ? Colors.Red : Colors.WhiteDark03}
          >
            <TextView bold size={13} color={Colors.White}>
              {title}
            </TextView>
          </Button>
        )}
        <Block block marginLeft={20}>
          <TextView
            bold
            size={12}
            color={noneContent ? Colors.PrimaryLight : Colors.SecondPrimary}
          >
            {content.length > MAX_TEXT_LENGTH
              ? content.substring(0, MAX_TEXT_LENGTH - 2) + "..."
              : content}
          </TextView>
        </Block>
        {/* <View>
          <Text ellipsizeMode="tail" numberOfLines={1} style={{ width: 200 }}>
            {content}
          </Text>
        </View> */}
      </Block>
    </Button>
  );
};

export default NoticeStatus;

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    borderBottomColor: Colors.Primary,
    paddingVertical: 10,
  },

  hiddenContent: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
