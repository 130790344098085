const Colors = {
  White: '#fff',
  WhiteDark01: '#EAEAEA',
  WhiteDark02: '#D5D5D5',
  WhiteDark03: '#BABABA',
  WhiteLight01: '#F6F6F6',
  Primary: '#008988',
  PrimaryLight: '#E4F1F2',
  PrimaryLight2: '#DFEFF0',
  SecondPrimary: '#64B5B8',
  Black: '#000',
  GrayLight: '#45494C',
  Gray: '#64696C',
  GrayShape01: '#54595C',
  GrayShape02: '#45494C',
  BlueLight01: '#A8D5F0',
  BlueLight02: '#80C2E9',
  BlueLight03: '#00CBDC',
  Red: '#DC000C',

  colorOpacity: (opacity) => `rgba(0,0,0,0.${opacity})`,
  AdminPrimary: '#FDB66E',
};
export default Colors;
