import React from "react";
import { StyleSheet } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";
import Button from "components/common/Button";
import TextView from "components/common/TextView";
import { ACCOUNT_STATUS_FILTER_DATA } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { useNavigate } from "utils";
import { STATUS_MANAGEMENT_RESULT_TYPE } from "../../utils/Constant";

const AccountStatusCart = ({ onPress = () => true, statusItem }) => {
  const navigate = useNavigate();

  const press = () => {
    if (statusItem.type === STATUS_MANAGEMENT_RESULT_TYPE.INVOICE) {
      navigate("invoice-review", {
        invoice_id: statusItem.id,
      });
    } else if (statusItem.type === STATUS_MANAGEMENT_RESULT_TYPE.ESTIMATE) {
      navigate("estimate-review", {
        estimate_id: statusItem.id,
      });
    } else if (statusItem.type === STATUS_MANAGEMENT_RESULT_TYPE.TRANSFER) {
      navigate("transfer-request/list", {
        transfer_id: statusItem.id,
      });
    }
  };
  return (
    <Button activeOpacity={0.5} onPress={() => press()}>
      <Block style={styles.wrap}>
        <Button
          paddingHorizontal={15}
          paddingVertical={5}
          color={
            statusItem.status === ACCOUNT_STATUS_FILTER_DATA[0].type
              ? Colors.Red
              : statusItem.status === ACCOUNT_STATUS_FILTER_DATA[3].type
              ? "#ffc107"
              : Colors.WhiteDark03
          }
        >
          <TextView
            bold
            size={13}
            color={
              statusItem.status === ACCOUNT_STATUS_FILTER_DATA[3].type
                ? Colors.Black
                : Colors.White
            }
          >
            {ACCOUNT_STATUS_FILTER_DATA[statusItem.status].status_name}
          </TextView>
        </Button>

        <Block block marginLeft={20}>
          <TextView bold size={12} color={Colors.SecondPrimary}>
            {statusItem.name} {formatDateTime(statusItem.request_date)}{" "}
            {statusItem.customer_name}
          </TextView>
        </Block>
      </Block>
    </Button>
  );
};

export default AccountStatusCart;

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    borderBottomColor: Colors.Primary,
    paddingVertical: 10,
  },
});
