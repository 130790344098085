import { createRequestTypes } from './utils';



export const LOGIN = createRequestTypes('LOGIN');
export const login = params => ({ type: LOGIN.REQUEST, params });

export const LOGOUT = createRequestTypes('LOGOUT');
export const logout = params => ({ type: LOGOUT.REQUEST, params });

