import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import ItemTable from "./ItemTable";
import {
  formatMoney,
  MoneyFormatter,
  formatInvoiceDetailAmount,
} from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { connect } from "react-redux";
import { getSetting } from "../../redux/actions/general";

const Table = ({ data, lastInvoice, ...props }) => {
  useEffect(() => {
    props.getSetting();
  }, []);

  const calculateUpperPrice = (invoice_detail) => {
    //     仕入単価＝(products.adjust_value_pt × market_prices.pt_jpy)
    //  + (products.adjust_value_pd × market_prices.pd_jpy)
    //  + (products.adjust_value_rh × market_prices.rh_jpy)
    // 下限仕入単価＝仕入単価 × (settings.target_profit_rate) / 100)
    // 上限仕入単価＝下限仕入単価 × (settings.limit_profit_rate) / 100)
    // => 仕入単価目安 = {下限仕入単価} ~ {上限仕入単価}

    if (lastInvoice !== undefined) {
      const { pt_jpy, pd_jpy, rh_jpy } = lastInvoice?.invoice;
      const {
        total_value_pd,
        total_value_pt,
        total_value_rh,
      } = invoice_detail?.product_info;
      const unitPrice =
        total_value_pd * pd_jpy +
        total_value_pt * pt_jpy +
        total_value_rh * rh_jpy;

      const fromUnitPrice =
        unitPrice * (props.setting?.target_profit_rate * 0.01);
      const toUnitPrice =
        fromUnitPrice * (props.setting?.limit_profit_rate * 0.01);
      return toUnitPrice;
    }
  };

  return (
    <Block
      block
      borderWidth="1pt"
      borderColor={Colors.SecondPrimary}
      borderRadius="5pt"
      paddingTop="5pt"
      marginTop="5pt"
    >
      <Block paddingHorizontal="10pt">
        <Block direction="row" justifyContent="space-between" middle>
          <TextView size="10pt" color={Colors.Primary} bold>
            前回仕入日：
            {formatDateTime(lastInvoice?.invoice?.date)}
          </TextView>
          <TextView size="10pt" color={Colors.Primary} bold>
            総取引回数：{lastInvoice?.total_purchase}回
          </TextView>
        </Block>
        <Block
          direction="row"
          justifyContent="space-between"
          middle
          marginTop="3pt"
          borderBottomWidth="1pt"
          borderBottomColor={Colors.Primary}
          paddingBottom="5pt"
          borderStyle="dashed"
        >
          <Block flex={2}>
            <TextView size="10pt" color={Colors.Primary} bold>
              前回仕入時の貴金属相場
            </TextView>
          </Block>
          <Block block direction="row" middle>
            <Block
              center
              color={Colors.Primary}
              paddingHorizontal="2pt"
              paddingVertical="1pt"
              marginRight="3pt"
            >
              <TextView size="10pt" color={Colors.White} bold>
                PT
              </TextView>
            </Block>
            <TextView size="10pt" color={Colors.Primary} bold>
              {formatMoney(
                lastInvoice?.invoice?.pt_jpy,
                MoneyFormatter.ZERO_DECIMAL_FORMAT
              )}
              円
            </TextView>
          </Block>
          <Block block direction="row" middle>
            <Block
              center
              color={Colors.Primary}
              paddingHorizontal="2pt"
              paddingVertical="1pt"
              marginRight="3pt"
            >
              <TextView size="10pt" color={Colors.White} bold>
                PD
              </TextView>
            </Block>
            <TextView size="10pt" color={Colors.Primary} bold>
              {formatMoney(
                lastInvoice?.invoice?.pd_jpy,
                MoneyFormatter.ZERO_DECIMAL_FORMAT
              )}
              円
            </TextView>
          </Block>
          <Block block direction="row" middle>
            <Block
              center
              color={Colors.Primary}
              paddingHorizontal="2pt"
              paddingVertical="1pt"
              marginRight="3pt"
            >
              <TextView size="10pt" color={Colors.White} bold>
                RH
              </TextView>
            </Block>
            <TextView size="10pt" color={Colors.Primary} bold>
              {formatMoney(
                lastInvoice?.invoice?.rh_jpy,
                MoneyFormatter.ZERO_DECIMAL_FORMAT
              )}
              円
            </TextView>
          </Block>
        </Block>

        <Block
          direction="row"
          justifyContent="space-between"
          middle
          marginTop="3pt"
          paddingBottom="3pt"
        >
          <Block flex={2}>
            <TextView size="10pt" color={Colors.Primary} bold>
              品名・分類
            </TextView>
          </Block>
          <Block block>
            <TextView
              size="10pt"
              color={Colors.Primary}
              bold
              textAlign="center"
            >
              数量
            </TextView>
          </Block>
          <Block block>
            <TextView
              textAlign="center"
              size="10pt"
              color={Colors.Primary}
              bold
            >
              前回仕入単価
            </TextView>
          </Block>
          <Block block>
            <TextView
              textAlign="center"
              size="10pt"
              color={Colors.Primary}
              bold
            >
              前回上限単価
            </TextView>
          </Block>
        </Block>
      </Block>
      <Block
        borderTopWidth="1pt"
        borderTopColor={Colors.SecondPrimary}
        paddingBottom="5pt"
        paddingHorizontal="10pt"
        block
      >
        <ScrollView style={{ maxHeight: 180 }}>
          {data.map((item, index) => {
            const limitPrice = calculateUpperPrice(item);
            return (
              <ItemTable
                key={`${index}`}
                name={item.name}
                check={item.check}
                amount={formatInvoiceDetailAmount(item.amount)}
                unit_price={item.unit_price}
                total_price={limitPrice}
                onCheckBoxPress={item.onCheckBoxPress}
              />
            );
          })}
        </ScrollView>
      </Block>
    </Block>
  );
};

// export default Table;

const mapStateToProps = (state) => ({
  setting: state.general.setting,
});
const mapDispatchToProps = {
  getSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
