import axios from "axios";

import { takeEvery, put } from "redux-saga/effects";
import {
  GET_SETTING,
  GET_LATEST_MARKET_PRICE,
  GET_TAX_RATE,
  UPDATE_SETTING,
  GET_INFORMATION,
  GET_LATEST_UPDATE_TRANSACTION,
  INSERT_INFORMATION,
  GET_MAKERS,
} from "../actions/general";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* getSetting(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.GENERAL.GET_SETTING, params);
  if (result.success) {
    yield put({
      type: GET_SETTING.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_SETTING.FAIL,
      result,
    });
  }
}

function* updateSetting(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.GENERAL.UPDATE_SETTING, params);
  if (result.success) {
    yield put({
      type: UPDATE_SETTING.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: UPDATE_SETTING.FAIL,
      result,
    });
  }
}

function* getLatestMarketPrice(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.GENERAL.GET_LATEST_MARKET_PRICE, params);
  if (result.success) {
    yield put({
      type: GET_LATEST_MARKET_PRICE.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_LATEST_MARKET_PRICE.FAIL,
      result,
    });
  }
}

function* getTaxRate(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(API.GENERAL.GET_TAX_RATE, params);
  if (result.success) {
    yield put({
      type: GET_TAX_RATE.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_TAX_RATE.FAIL,
      result,
    });
  }
}

function* getInformation(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.GENERAL.GET_INFORMATION, params)
  );
  if (result.success) {
    yield put({
      type: GET_INFORMATION.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_INFORMATION.FAIL,
      result,
    });
  }
}

function* getLatestUpdateTransaction(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.GENERAL.GET_LATEST_UPDATE_TRANSACTION, params)
  );
  if (result.success) {
    yield put({
      type: GET_LATEST_UPDATE_TRANSACTION.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_LATEST_UPDATE_TRANSACTION.FAIL,
      result,
    });
  }
}

function* insertInformation(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.GENERAL.INSERT_INFORMATION, params);
  if (result.success) {
    yield put({
      type: INSERT_INFORMATION.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: INSERT_INFORMATION.FAIL,
      result,
    });
  }
}

function* getMakers(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(replaceParams(API.GENERAL.GET_MAKERS, params));
  if (result.success) {
    yield put({
      type: GET_MAKERS.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_MAKERS.FAIL,
      result,
    });
  }
}

export default function* () {
  yield takeEvery(GET_SETTING.REQUEST, getSetting);
  yield takeEvery(GET_LATEST_MARKET_PRICE.REQUEST, getLatestMarketPrice);
  yield takeEvery(GET_TAX_RATE.REQUEST, getTaxRate);
  yield takeEvery(UPDATE_SETTING.REQUEST, updateSetting);
  yield takeEvery(GET_INFORMATION.REQUEST, getInformation);
  yield takeEvery(
    GET_LATEST_UPDATE_TRANSACTION.REQUEST,
    getLatestUpdateTransaction
  );
  yield takeEvery(INSERT_INFORMATION.REQUEST, insertInformation);
  yield takeEvery(GET_MAKERS.REQUEST, getMakers);
}
