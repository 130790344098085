import React from "react";
import { ScrollView } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";
import ItemSearch from "./ItemSearch";

const SearchList = ({ data, onPress }) => {
  return (
    <Block
      height="100pt"
      width="75%"
      color="white"
      position="absolute"
      right="0pt"
      bottom="-100pt"
      borderRadius="3pt"
      borderWidth="1pt"
      borderColor={Colors.SecondPrimary}
    >
      <ScrollView>
        {data.map((item, index) => {
          return (
            <ItemSearch
              data={item}
              onPress={() => onPress(item)}
              key={`${index}`}
            />
          );
        })}
      </ScrollView>
    </Block>
  );
};

export default SearchList;
