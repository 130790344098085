import React, { forwardRef } from "react";
import { StyleSheet, Text, View, TextInput, Image } from "react-native";
import { Colors } from "utils";

import { commonIcons } from "assets/icons";
import Button from "./Button";

const Input = forwardRef(
  ({
    label,
    hideIcon,
    tailText,
    paddingVertical,
    onPress,
    wrapStyle,
    textInputStyle,
    renderRight,
    refHandler,
    selectTextOnFocus,
    onFocus,
    ...props
  }) => (
    <View style={[styles.wrap]}>
      <View style={{ flex: 1 }}>
        {!!label && <Text style={styles.label}>{label}</Text>}
        <View style={[styles.inputWrap, wrapStyle]}>
          <TextInput
            style={[styles.textInput, textInputStyle, { paddingVertical }]}
            ref={(input) => {
              if (refHandler !== undefined) refHandler(input);
            }}
            selectTextOnFocus={selectTextOnFocus}
            onFocus={onFocus}
            {...props}
          />
          {!hideIcon && (
            <Button {...{ onPress }}>
              <Image source={commonIcons.search} style={styles.icon} />
            </Button>
          )}
        </View>
      </View>
      {!!renderRight && renderRight()}
      {!!tailText && <Text style={styles.tailText}>{tailText}</Text>}
    </View>
  )
);

export default Input;

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  label: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginTop: "12pt",
    marginBottom: "6pt",
    fontSize: "12pt",
  },
  inputWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.SecondPrimary,
    borderWidth: 1,
    borderRadius: 5,
  },
  textInput: {
    flex: 1,
    padding: "10pt",
  },
  icon: {
    width: "20pt",
    height: "20pt",
    marginHorizontal: "10pt",
  },
  tailText: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "5pt",
  },
});
