import React, { useState, useEffect } from "react";
import { Block, TextView, WrapperBlock, Button } from "components";
import { Table, SearchList } from "components/buy-review";
import { Colors, useNavigate } from "utils";
import Input from "components/common/Input";
import { data } from "../buy-review/fakeBuyReviews";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import {
  getLastEstimate,
  insertProductsToEstimate,
} from "../../redux/actions/estimate";
import {
  getLastInvoice,
  insertProductsToInvoice,
} from "../../redux/actions/invoice";

import { searchProducts } from "../../redux/actions/product";
import { Alert } from "../../App";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";
import { TAB_HEIGHT } from "components/common/WrapperBlock";
import Title from "components/common/Title";

const { height } = Dimensions.get("window");

/**
 *  [003-008-1 / 見積
 * @param {*} props
 */
const Estimate = (props) => {
  const navigate = useNavigate();
  const history = useHistory();

  const { new_estimate } = history?.location?.state;
  const [isFocus, setIsFocus] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [productCheck, setProductCheck] = useState({
    productIndex: -1,
    check: false,
  });
  // const [lastEstimateData, setLastEstimateData] = useState([]);
  const [lastInvoiceData, setLastInvoiceData] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [justInsertProductList, setJustInsertProductList] = useState([]);

  useEffect(() => {
    if (lastInvoiceData.length > 0) {
      const data = lastInvoiceData.map((item, index) => {
        return {
          ...item,
          check: index === productCheck.productIndex ? !item.check : item.check,
        };
      });
      setLastInvoiceData(data);
    }
  }, [productCheck]);

  useEffect(() => {
    props.getLastInvoice({
      customer_id: new_estimate?.customer_info?.id,
    });
  }, []);

  const insertAllProduct = () => {
    const data = lastInvoiceData.map((item) => {
      return {
        id: item.id,
        name: item.name,
        amount: 0,
        unit_price: 0,
        price: 0,
        tax: 0,
        total_price: 0,
        is_delete_product: false,
      };
    });
    setJustInsertProductList(data);
    insertProductsToEstimate(data, true);
  };

  const insertSelectedProducts = () => {
    const data = lastInvoiceData
      .filter(function (item) {
        return item.check;
      })
      .map(function (item) {
        return {
          id: item.id,
          name: item.name,
          amount: 0,
          unit_price: 0,
          price: 0,
          tax: 0,
          total_price: 0,
          is_delete_product: false,
        };
      });
    setJustInsertProductList(data);
    insertProductsToEstimate(data, false);
  };

  const insertNewProductToEstimate = () => {
    if (!newProductName) {
      return;
    }

    const data = [];
    data.push({
      id: -1,
      name: newProductName,
      amount: 0,
      unit_price: 0,
      price: 0,
      tax: 0,
      total_price: 0,
      is_delete_product: false,
    });
    setJustInsertProductList(data);
    insertProductsToEstimate(data, false);
  };

  const insertSearchProduct = (product) => {
    const data = [];
    data.push({
      id: product.id,
      name: product.name,
      amount: 0,
      unit_price: 0,
      price: 0,
      tax: 0,
      total_price: 0,
      is_delete_product: false,
    });
    setJustInsertProductList(data);
    insertProductsToEstimate(data, false);
    setIsFocus((e) => !e);
  };

  const insertProductsToEstimate = (data, isClearEstimateDetail) => {
    props.insertProductsToEstimate({
      estimate_id: new_estimate?.id,
      is_clear_estimate_detail: isClearEstimateDetail,
      products: data,
    });
  };

  const prevGettingLastInvoice = usePrevious(props.isGettingLastInvoice);
  useEffect(() => {
    if (prevGettingLastInvoice && !props.isGettingLastInvoice) {
      if (props.isGotLastInvoice) {
        const data = props.lastInvoice?.invoice?.invoice_details?.map(
          (item, index) => {
            return {
              id: item.product_info.id,
              name: item.product_info.name,
              check: false,
              amount: item.amount,
              unit_price: item.unit_price,
              price: item.price,
              tax: item.tax,
              total_price: item.total_price,
              product_info: item.product_info,
              onCheckBoxPress: () => {
                setProductCheck((prev) => ({
                  productIndex: index,
                  check: !prev.check,
                }));
              },
            };
          }
        );
        setLastInvoiceData(data);
      }
    }
  }, [props.isGettingLastInvoice]);

  const chooseFromCatalog = () => {
    //go to [005-001-1 / サンプルカタログ（追加ボタンあり）]
    navigate("catalog", { current_estimate: new_estimate });
  };

  const purchaseRegister = () => {
    navigate("quotation-list", {
      estimate_id: new_estimate?.id, // we send estimate_id instead of send "new_estimate" object, because many flow go to this screen "quotation-list"
    });
  };

  const showInsertFinishModal = () => {
    Alert.alert(
      "",
      <InsertProductModal justInsertProductList={justInsertProductList} />,
      [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]
    );
  };

  const prevInsertingProductToEstimate = usePrevious(
    props.isInsertingProductToEstimate
  );
  useEffect(() => {
    if (prevInsertingProductToEstimate && !props.isInsertingProductToEstimate) {
      if (props.isInsertedProductToEstimate) {
        showInsertFinishModal();
        setJustInsertProductList([]);
      } else {
        Alert.alert("", props.errorMsg, [
          {
            label: "OK",
            primary: true,
            onPress: () => {
              Alert.hideModal();
            },
          },
        ]);
      }
    }
  }, [props.isInsertingProductToEstimate]);

  const [timeOutHandler, setTimeOutHandler] = useState(undefined);
  const doSearch = (search) => {
    setSearchProductName(search);
    if (timeOutHandler !== undefined) clearTimeout(timeOutHandler);
    setTimeOutHandler(
      setTimeout(() => {
        if (search !== "") {
          props.searchProducts({
            search_name: search,
            search_code: "",
          });
        }
      }, 300)
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <Block paddingBottom="10pt" paddingHorizontal="24pt" flex={10}>
        <Block flex={5}>
          <Title backTitle={"取引先入力"} title="見積作成" />
          <Block>
            <TextView size="13pt" color={Colors.Primary} bold>
              取引先コード：{new_estimate?.customer_info?.code}
            </TextView>
            <TextView size="13pt" color={Colors.Primary} bold>
              取引先名：{new_estimate?.customer_info?.name}
            </TextView>
            <TextView size="13pt" color={Colors.Primary} bold>
              本日の貴金属相場
            </TextView>
          </Block>
          <Block direction="row" marginTop="5pt">
            <Block
              block
              center
              color={`${Colors.Primary}20`}
              paddingVertical="2pt"
            >
              <TextView size="13pt" color={Colors.Primary} bold>
                PT：{formatMoney(new_estimate?.market_price?.pt_jpy)}円
              </TextView>
            </Block>
            <Block
              marginHorizontal="5pt"
              block
              center
              color={`${Colors.Primary}20`}
            >
              <TextView size="13pt" color={Colors.Primary} bold>
                PD：{formatMoney(new_estimate?.market_price?.pd_jpy)}円
              </TextView>
            </Block>
            <Block block center color={`${Colors.Primary}20`}>
              <TextView size="13pt" color={Colors.Primary} bold>
                RH：{formatMoney(new_estimate?.market_price?.rh_jpy)}円
              </TextView>
            </Block>
          </Block>
          <Table data={lastInvoiceData} lastInvoice={props.lastInvoice} />
        </Block>
        <Block flex={5} marginTop="5pt">
          <Block flex={1.2} centered>
            <Block direction="row" block>
              <Button
                centered
                middle
                block
                borderRadius="3pt"
                paddingHorizontal="10pt"
                paddingVertical="12pt"
                color={Colors.SecondPrimary}
                onPress={() => insertAllProduct()}
              >
                <TextView size="12pt" bold color={Colors.White}>
                  前回仕入をすべて追加
                </TextView>
              </Button>
              <Block width="5pt" />
              <Button
                centered
                middle
                block
                borderRadius="3pt"
                paddingHorizontal="10pt"
                paddingVertical="12pt"
                color={Colors.SecondPrimary}
                onPress={() => insertSelectedProducts()}
              >
                <TextView size="12pt" bold color={Colors.White}>
                  選択した製品を追加
                </TextView>
              </Button>
            </Block>
            <Block flex={2}>
              <Button
                smallCentered
                width="100%"
                paddingVertical="13pt"
                onPress={() => chooseFromCatalog()}
              >
                <TextView size="12pt" bold color={Colors.White} center>
                  サンプルカタログから選ぶ
                </TextView>
              </Button>
            </Block>
          </Block>
          <Block flex={2} style={{ zIndex: 1 }}>
            <Block style={{ zIndex: 1, marginTop: "10pt" }}>
              <Input
                onPress={() => setIsFocus((e) => !e)}
                placeholder={""}
                placeholderTextColor={Colors.SecondPrimary}
                label={"品名・分類検索"}
                paddingVertical="10pt"
                value={searchProductName}
                onChangeText={(value) => doSearch(value)}
              />
              {isFocus && (
                <SearchList
                  onPress={(product) => {
                    setSearchProductName("");
                    insertSearchProduct(product);
                  }}
                  data={props.products}
                />
              )}
            </Block>

            <Block direction="row" alignItems="flex-end">
              <Block block>
                <Input
                  hideIcon
                  placeholder={""}
                  placeholderTextColor={Colors.SecondPrimary}
                  label={"新規商品（参考名称）"}
                  paddingVertical="10pt"
                  onChangeText={(value) => setNewProductName(value)}
                />
              </Block>
              <Block flex={0.2} center height="50%">
                <Button
                  color={Colors.SecondPrimary}
                  paddingVertical="4pt"
                  paddingHorizontal="7pt"
                  onPress={() => insertNewProductToEstimate()}
                >
                  <TextView size="12pt" color="white">
                    追 加
                  </TextView>
                </Button>
              </Block>
            </Block>
          </Block>
          <Block block>
            <Block
              width="50%"
              alignSelf="center"
              paddingVertical="10pt"
              color={Colors.SecondPrimary}
              borderRadius="3pt"
              marginTop="20pt"
              center
            >
              <Button onPress={() => purchaseRegister()}>
                <TextView size="12pt" color="white">
                  見積リスト登録
                </TextView>
              </Button>
            </Block>
          </Block>
        </Block>
      </Block>
    </View>
  );
};

const mapStateToProps = (state) => ({
  // lastEstimate: state.estimate.lastEstimate,
  // isGettingLastEstimate: state.estimate.isGettingLastEstimate,
  // isGotLastEstimate: state.estimate.isGotLastEstimate,
  isSearchingProduct: state.product.isSearchingProduct,
  isSearchProductFinish: state.product.isSearchProductFinish,
  products: state.product.products,

  lastInvoice: state.invoice.lastInvoice,
  isGettingLastInvoice: state.invoice.isGettingLastInvoice,
  isGotLastInvoice: state.invoice.isGotLastInvoice,

  isInsertingProductToEstimate: state.estimate.isInsertingProductToEstimate,
  isInsertedProductToEstimate: state.estimate.isInsertedProductToEstimate,

  errorMsg: state.estimate.errorMsg,
});
const mapDispatchToProps = {
  getLastEstimate,
  insertProductsToEstimate,
  searchProducts,

  getLastInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Estimate);

const InsertProductModal = ({ justInsertProductList }) => {
  return (
    <View>
      <Text>製品を追加しました</Text>
      <ScrollView style={{ maxHeight: 200, marginTop: 5 }}>
        {justInsertProductList?.map((item, index) => {
          return <Text key={`${index}`}>{item.name}</Text>;
        })}
      </ScrollView>
    </View>
  );
};
