export const data = [
  {
    value1: 'ビートル APK【7003】',
    value2: '150',
    value3: '14,900円',
    value4: '2,235,000円',
  },
  {
    value1: 'ビートル APK【7003】',
    value2: '150',
    value3: '14,900円',
    value4: '2,235,000円',
  },
  {
    value1: 'ビートル APK【7003】',
    value2: '150',
    value3: '14,900円',
    value4: '2,235,000円',
  },
  {
    value1: 'ビートル APK【7003】',
    value2: '150',
    value3: '14,900円',
    value4: '2,235,000円',
  },
  {
    value1: 'ビートル APK【7003】',
    value2: '150',
    value3: '14,900円',
    value4: '2,235,000円',
  },
];
export const data1 = [
  { title: '小  計（税別）', value: '4,446,600円' },
  { title: '消費税', value: '444,660円' },
  { title: '合  計（税込）', value: '4,891,260円' },
];
