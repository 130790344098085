import React, { useState, useEffect } from "react";
import {
  View,
  ScrollView,
  FlatList,
  Text,
  Image,
  ActivityIndicator,
} from "react-native";
import { WrapperBlock, Block, Button } from "components";
import Input from "components/common/Input";
import InsertProductModal from "components/common/InsertProductModal";
import { Card } from "components/catalog";
import { useNavigate } from "utils";
import {
  searchProductCatalog,
  saveSearchProductCatalog,
  updateSearchProductCatalogResultList,
  clearSearchProductCatalog,
  keepSearchProductCatalog,
} from "../../redux/actions/product";
import { insertProductsToEstimate } from "../../redux/actions/estimate";

import { insertProductsToInvoice } from "../../redux/actions/invoice";

import { connect } from "react-redux";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import DropdownList from "components/common/DropdownList";
import { useHistory, useLocation } from "react-router-dom";
import { getMakers } from "../../redux/actions/general";
import { usePrevious } from "../../utils/hook";
import { Alert } from "../../App";
import { checkCart } from "../../redux/actions/home";
import FullLoading from "components/common/FullLoading";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import { debounce } from "lodash";
import LazyLoad from "react-lazyload";
import OnImagesLoaded from "react-on-images-loaded";
import Title from "components/common/Title";

/**
 * 005-001-1 / サンプルカタログ
 * @param {*} props
 */
const Catalog = (props) => {
  const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchProductName, setSearchProductName] = useState("");
  const [selectMaker, setSelectedMaker] = useState(undefined);
  const [timeOutHandler, setTimeOutHandler] = useState(undefined);
  const current_invoice = history?.location?.state?.current_invoice;
  const current_estimate = history?.location?.state?.current_estimate;
  const [justInsertProductList, setJustInsertProductList] = useState([]);
  const [isLoadingMore, setisLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchProductCatalogData, setSearchProductCatalogData] = useState([]);
  const [countImageLoad, setCountImageLoad] = useState(0);

  useEffect(() => {
    setSearchProductName(props.savedSearchProductCatalog?.productName);
    setSelectedMaker(props.savedSearchProductCatalog?.selectedMaker);
    props.getMakers();
  }, []);

  useEffect(() => {
    if (selectMaker !== undefined) {
      searchProduct();
    }
  }, [selectMaker]);

  useEffect(() => {
    return () => {
      props.clearSearchProductCatalog();
    };
  }, []);

  const searchProduct = () => {
    props.searchProductCatalog({
      product_name: searchProductName,
      maker_name:
        selectMaker !== undefined
          ? selectMaker.id > -1
            ? selectMaker.name
            : ""
          : "",
      page: 1,
    });
    props.saveSearchProductCatalog({
      productName: searchProductName,
      selectedMaker: selectMaker,
    });
    setLoading(true);
  };

  const insertProductToCurrentCart = (product) => {
    if (current_invoice !== undefined && current_invoice !== null) {
      insertOrDeleteProductToCurrentInvoice(product);
    } else if (current_estimate !== undefined && current_estimate !== null) {
      insertOrDeleteProductToCurrentEstimate(product);
    }
  };

  const insertOrDeleteProductToCurrentInvoice = (product) => {
    const data = [];
    data.push({
      id: product.id,
      name: product.name,
      amount: 0,
      is_delete_product: product.is_check,
      unit_price: 0,
      price: 0,
      tax: 0,
      total_price: 0,
    });
    props.insertProductsToInvoice({
      invoice_id: current_invoice?.id,
      is_clear_invoice_detail: false,
      products: data,
    });
    const datas = props.searchProductCatalogResult.map((item) => {
      return {
        ...item,
        is_check: item.id === product.id ? !product.is_check : item.is_check,
      };
    });
    props.updateSearchProductCatalogResultList({
      searchProductCatalogResult: datas,
    });

    const justInsertData = data.filter(
      (item) => item.is_delete_product == false
    );

    setJustInsertProductList(justInsertData);
  };

  const insertOrDeleteProductToCurrentEstimate = (product) => {
    const data = [];
    data.push({
      id: product.id,
      name: product.name,
      amount: 0,
      is_delete_product: product.is_check,
      unit_price: 0,
      price: 0,
      tax: 0,
      total_price: 0,
    });
    props.insertProductsToEstimate({
      estimate_id: current_estimate?.id,
      is_clear_estimate_detail: false,
      products: data,
    });
    const datas = props.searchProductCatalogResult.map((item) => {
      return {
        ...item,
        is_check: item.id === product.id ? !product.is_check : item.is_check,
      };
    });
    props.updateSearchProductCatalogResultList({
      searchProductCatalogResult: datas,
    });
    const justInsertData = data.filter(
      (item) => item.is_delete_product == false
    );

    setJustInsertProductList(justInsertData);
  };

  const isShowAddCheckBox = () => {
    const is_have_invoice =
      current_invoice !== undefined && current_invoice !== null;
    const is_have_estimate =
      current_estimate !== undefined && current_estimate !== null;
    return is_have_invoice || is_have_estimate;
  };

  const showInsertFinishModal = () => {
    Alert.alert(
      "",
      <InsertProductModal justInsertProductList={justInsertProductList} />,
      [
        {
          label: "OK",
          primary: true,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]
    );
  };

  const prevInsertingProductToInvoice = usePrevious(
    props.isInsertingProductToInvoice
  );
  useEffect(() => {
    if (prevInsertingProductToInvoice && !props.isInsertingProductToInvoice) {
      if (props.isInsertedProductToInvoice) {
        props.checkCart();
        if (
          justInsertProductList !== undefined &&
          justInsertProductList.length > 0
        ) {
          showInsertFinishModal();
          setJustInsertProductList([]);
        }
      }
    }
  }, [props.isInsertingProductToInvoice]);

  const prevInsertingProductToEstimate = usePrevious(
    props.isInsertingProductToEstimate
  );
  useEffect(() => {
    if (prevInsertingProductToEstimate && !props.isInsertingProductToEstimate) {
      if (props.isInsertedProductToEstimate) {
        if (
          justInsertProductList !== undefined &&
          justInsertProductList.length > 0
        ) {
          showInsertFinishModal();
          setJustInsertProductList([]);
        }
      }
    }
  }, [props.isInsertingProductToEstimate]);

  const prevSearchingProductCatalog = usePrevious(
    props.isSearchingProductCatalog
  );
  useEffect(() => {
    if (prevSearchingProductCatalog && !props.isSearchingProductCatalog) {
      setisLoadingMore(false);
      setLoading(false);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 7000);
    }
  }, [props.isSearchingProductCatalog]);

  const onEndReached = debounce(() => {
    const {
      searchProductCatalogNextPage,
      searchProductCatalogReachEnd,
    } = props;

    if (!isLoadingMore && !searchProductCatalogReachEnd) {
      props.searchProductCatalog({
        product_name: searchProductName,
        maker_name:
          selectMaker !== undefined
            ? selectMaker.id > -1
              ? selectMaker.name
              : ""
            : "",
        page: searchProductCatalogNextPage,
      });
      setisLoadingMore(true);
      setLoading(true);
    }
  });

  const increaseImageLoad = () => {
    console.log("hung increaseImageLoad:");
    setCountImageLoad((prev) => ++prev);
  };

  // useEffect(() => {
  //   console.log(
  //     "hung countImageLoad:",
  //     countImageLoad,
  //     " searchProductCatalogResultCount:",
  //     props.searchProductCatalogResultCount
  //   );
  //   if (countImageLoad >= props.searchProductCatalogResultCount) {
  //     setLoading(false);
  //     setCountImageLoad(0);
  //   }
  // }, [countImageLoad]);

  return (
    <View style={{ flex: 1 }}>
      <Block paddingBottom="10pt">
        <Block flex={7} paddingHorizontal="24pt">
          <Title title={"サンプルカタログ"} />
          <Input
            label={"品名検索"}
            onChangeText={(value) => setSearchProductName(value)}
            value={searchProductName}
            defaultValue={searchProductName}
            onPress={() => searchProduct()}
          />
          <DropdownList
            label={"メーカー検索"}
            data={props.makerList}
            defaultSelectedItemName={
              props.savedSearchProductCatalog?.selectedMaker?.name
            }
            onSelectedItem={(item) => {
              setSelectedMaker(item);
            }}
            suggestionListStyle={{ height: 500 }}
          />
        </Block>
      </Block>

      {/* <ScrollView>
        {props.searchProductCatalogResult?.map((item, index) => {
          return (
            <OnImagesLoaded
              onLoaded={() => console.log("hung onLoaded")}
              onTimeout={() => console.log("hung onTimeout")}
              timeout={7000}
            >
              <LazyLoad key={index} placeholder={""}>
                <Card
                  key={index}
                  index={index}
                  product={item}
                  onPress={() => {
                    // setTest(true);
                    props.keepSearchProductCatalog({
                      isKeep: true,
                    });
                    navigate("product-info", { product: item });
                  }}
                  onCheckBoxPress={() => insertProductToCurrentCart(item)}
                  isShowCheckBox={isShowAddCheckBox()}
                />
              </LazyLoad>
            </OnImagesLoaded>
            // <Card
            //   key={index}
            //   index={index}
            //   product={item}
            //   onPress={() => {
            //     // setTest(true);
            //     props.keepSearchProductCatalog({
            //       isKeep: true,
            //     });
            //     navigate("product-info", { product: item });
            //   }}
            //   onCheckBoxPress={() => insertProductToCurrentCart(item)}
            //   isShowCheckBox={isShowAddCheckBox()}
            // />
            // <LazyLoadImage effect="blur" src={item.pic_path} />
          );
        })}
      </ScrollView> */}

      <FlatList
        data={props.searchProductCatalogResult}
        style={{ flex: 1 }}
        keyExtractor={(_, index) => `${index}`}
        renderItem={({ item, index }) => {
          return (
            // <LazyLoadComponent>
            <Card
              index={index}
              product={item}
              onPress={() => {
                // setTest(true);
                props.keepSearchProductCatalog({
                  isKeep: true,
                });
                navigate("product-info", { product: item });
              }}
              onCheckBoxPress={() => insertProductToCurrentCart(item)}
              isShowCheckBox={isShowAddCheckBox()}
              onImageLoaded={() => {
                // increaseImageLoad();
                // console.log("hung eeee");
                // setCountImageLoad((prev) => prev + 1);
              }}
              onImageTimeout={() => setLoading(false)}
            />
            // </LazyLoadComponent>
          );
        }}
        onEndReachedThreshold={1}
        onEndReached={onEndReached}
        ListFooterComponent={() =>
          isLoadingMore && (
            <View style={{ padding: 5 }}>
              <ActivityIndicator />
            </View>
          )
        }
      />

      {isShowAddCheckBox() && (
        <View
          style={{
            marginVertical: 10,
            alignSelf: "center",
          }}
        >
          <Block flex={1} center>
            <Button onPress={() => history.goBack()} mediumCentered>
              次　へ
            </Button>
          </Block>
        </View>
      )}
      {loading && <FullLoading />}
    </View>
  );
};

const mapStateToProps = (state) => ({
  // lastInvoice: state.invoice.lastInvoice,
  // isGettingLastInvoice: state.invoice.isGettingLastInvoice,
  // isGotLastInvoice: state.invoice.isGotLastInvoice,
  isSearchingProductCatalog: state.product.isSearchingProductCatalog,
  isSearchedProductCatalog: state.product.isSearchedProductCatalog,
  searchProductCatalogNextPage: state.product.searchProductCatalogNextPage,
  searchProductCatalogReachEnd: state.product.searchProductCatalogReachEnd,
  searchProductCatalogResult: state.product.searchProductCatalogResult,
  searchProductCatalogResultCount:
    state.product.searchProductCatalogResultCount,

  savedSearchProductCatalog: state.product.savedSearchProductCatalog,

  makerList: state.general.makerList,

  isInsertingProductToInvoice: state.invoice.isInsertingProductToInvoice,
  isInsertedProductToInvoice: state.invoice.isInsertedProductToInvoice,

  isInsertingProductToEstimate: state.estimate.isInsertingProductToEstimate,
  isInsertedProductToEstimate: state.estimate.isInsertedProductToEstimate,
});
const mapDispatchToProps = {
  searchProductCatalog,
  insertProductsToInvoice,
  insertProductsToEstimate,
  saveSearchProductCatalog,
  updateSearchProductCatalogResultList,
  getMakers,
  clearSearchProductCatalog,
  checkCart,
  keepSearchProductCatalog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
