import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.withCredentials = true;

export default function axiosDebugger() {
  if (true) {
    axios.interceptors.request.use((request) => {
      console.log(
        `%c ${request.method.toUpperCase()} %c${request.url || ""}`,
        "background-color: #eeffee; color: green; font-weight: bold",
        "background-color: #eeffee; text-decoration: none"
      );
      if (request.data) console.log(JSON.stringify(request.data));
      console.log(request.headers);
      return request;
    });

    axios.interceptors.response.use((response) => {
      console.log(
        `%c ${response.status} %c${response.config.url} \n`,
        "background-color: #eeeeff; color: blue; font-weight: bold",
        "background-color: #eeeeff; color: blue; text-decoration: none",
        response.data
      );
      return response;
    });
  }
}

export const setAxiosDefaultHeaders = ({ token, uuid, language }) => {
  axios.defaults.headers.common.Authorization = `token ${token}`;
  axios.defaults.headers.common.uuid = uuid;

  // axios.defaults.headers.common["Access-Control-Allow-Headers"] =  "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers ";
  // axios.defaults.headers.common["Access-Control-Request-Headers"] =  "Content-Type ";
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] ="*";
  // axios.defaults.headers.common['Content-Language'] = language;
  //  axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
};
