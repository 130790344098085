import React from 'react';
import Block from 'components/common/Block';
import { Colors } from 'utils';
import TextView from 'components/common/TextView';

const PATTERNS = [
  { backgroundColor: Colors.Primary, color: Colors.White },
  { backgroundColor: Colors.White, color: Colors.Primary },
];
const SectionHeader = ({ title = '', patterns = 0 }) => {
  const pattern = PATTERNS[patterns];
  return (
    <Block
      marginTop={10}
      color={pattern.backgroundColor}
      paddingHorizontal={patterns === 0 ? 10 : 0}
      paddingVertical={5}
    >
      <TextView size={13} bold color={pattern.color}>
        {title}
      </TextView>
    </Block>
  );
};

export default SectionHeader;
