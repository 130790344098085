import React from "react";
import { StyleSheet, Text, View, TextInput, Image } from "react-native";
import { Colors } from "utils";

const Input = ({
  label,
  tailText,
  headText,
  paddingVertical,
  headTextStyle,
  textInputStyle,
  tailTextStyle,
  wrapStyle,
  value,
  onChangeText,
  ...props
}) => {
  let amountInput = null;

  return (
    <View style={[styles.wrap, wrapStyle]}>
      <View style={{ flex: 1 }}>
        {!!label && <Text style={styles.label}>{label}</Text>}
        <View style={styles.inputWrap}>
          <View>
            {!!headText && (
              <Text style={[styles.headText, headTextStyle]}>{headText}</Text>
            )}
          </View>
          <View style={{ flex: 1 }}>
            <TextInput
              style={[styles.textInput, textInputStyle, { paddingVertical }]}
              value={value}
              onChangeText={onChangeText}
              ref={(input) => (amountInput = input)}
              selectTextOnFocus
              onFocus={amountInput?.focus()}
              {...props}
            />
          </View>
        </View>
      </View>
      {!!tailText && (
        <Text style={[styles.tailText, tailTextStyle]}>{tailText}</Text>
      )}
    </View>
  );
};

export default Input;

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  label: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginTop: 12,
    marginBottom: 6,
    fontSize: "12pt",
  },
  inputWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  textInput: {
    flex: 1,
    padding: 10,
    backgroundColor: Colors.PrimaryLight,
  },
  icon: {
    width: 20,
    height: 20,
    marginHorizontal: 10,
  },
  headText: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginRight: 5,
  },
  tailText: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: 5,
  },
});
