import React, { useState, useEffect } from "react";

import { Title, Block, WrapperBlock } from "components";
import {
  TabView,
  ContentTab,
  Footer,
  InvoiceConfirm,
  EstimateConfirm,
} from "components/home";
import { useNavigate } from "utils";
import { Alert } from "../../App";
import { reset_cart, checkCart } from "../../redux/actions/home";

import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { setAxiosDefaultHeaders } from "../../configs/axiosDebugger";
import { createNewInvoice } from "../../redux/actions/home";
import { getInvoice } from "../../redux/actions/invoice";
import { createNewEstimate, getEstimate } from "../../redux/actions/estimate";
import { useHistory } from "react-router-dom";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Colors } from "utils";

const InvoiceEstimateButton = ({
  onPress,
  onInvoicePress,
  onEstimatePress,
  ...props
}) => {
  const navigate = useNavigate();
  const [homeGoToCartScreen, setHomeGoToCartScreen] = React.useState(false);

  const [selectedEstimateIndex, setSelectedEstimateIndex] = useState(0);
  const onSelectEstimate = (index) => {
    setSelectedEstimateIndex(index);
  };

  const showInvoiceConfirmModal = () => {
    Alert.alert("", <InvoiceConfirm invoice={props.cart?.invoice} />, [
      {
        label: "はい",
        primary: true,
        onPress: () => {
          // go direct to [003-003-1 / 仕入リスト], but must go though 003-001,003-002
          setHomeGoToCartScreen(true);
          props.getInvoice({
            invoice_id: props.cart?.invoice?.id,
          });
          Alert.hideModal();
        },
      },
      {
        label: "いいえ（破棄）",
        primary: false,
        onPress: () => {
          //  clear the cart, and go to [003-001-1 / 仕入査定・取引先入力]
          props.reset_cart({ type: "1" });
        },
      },
    ]);
  };

  const showEstimateConfirmModal = () => {
    // const [state, setState] = React.useState(0);
    let selectedIndex = 0;
    const onSelectEstimateHandler = (index) => {
      onSelectEstimate(index);
      selectedIndex = index;
    };
    Alert.alert(
      "",
      <EstimateConfirm
        estimates={props.cart?.estimate}
        selectedEstimateIndex={selectedEstimateIndex}
        onSelectEstimate={(index) => onSelectEstimateHandler(index)}
      />,
      [
        {
          label: "はい",
          primary: false,
          onPress: () => {
            // onEstimateModalOkClickHandler();
            //Go directly to 003-009, but must go though 003-007, 003-008
            props.getEstimate({
              estimate_id: props.cart?.estimate[selectedIndex].id,
            });
            Alert.hideModal();
          },
        },
        {
          label: "いいえ",
          primary: true,
          onPress: () => {
            //  clear the cart, and go to  [003-007-1 /
            //props.reset_cart({ type: "2" });
            navigate("quotation-supplier", {});
            Alert.hideModal();
          },
        },
      ]
    );
  };

  const prevGettingInvoice = usePrevious(props.isGettingInvoice);
  useEffect(() => {
    if (prevGettingInvoice && !props.isGettingInvoice) {
      if (props.isGotInvoice && homeGoToCartScreen) {
        // go direct to [003-003-1 / 仕入リスト], but must go though 003-001,003-002
        // navigate("purchase-supplier", {}); //003-001
        navigate("buy-review", {
          // 003-002
          new_invoice: props.invoice,
        });

        navigate("cart", {
          invoice_id: props.cart?.invoice?.id, // we send invoice_id instead of send "new_invoice" object, because many flow go to this screen "cart"
        });
      }
    }
  }, [props.isGettingInvoice]);

  const prevGettingEstimate = usePrevious(props.isGettingEstimate);
  useEffect(() => {
    if (prevGettingEstimate && !props.isGettingEstimate) {
      if (props.isGotEstimate) {
        //Go directly to 003-009, but must go though 003-007, 003-008
        navigate("quotation-supplier", {});
        navigate("estimate", {
          //003-008
          new_estimate: props.estimate,
        });
        navigate("quotation-list", {
          estimate_id: props.estimate.id, // we send estimate_id instead of send "new_estimate" object, because many flow go to this screen "quotation-list"
        });
      }
    }
  }, [props.isGettingEstimate]);

  const prevResettingCart = usePrevious(props.isResettingCart);
  useEffect(() => {
    if (prevResettingCart && !props.isResettingCart) {
      if (props.isResetCartFinish) {
        //go to [003-001-1 / 仕入査定・取引先入力]
        props.checkCart();
        Alert.hideModal();
        if (props.newCart.type == 1) {
          //  clear the cart, and go to [003-001-1 / 仕入査定・取引先入力]
          navigate("purchase-supplier", {});
        } else {
          // clear the cart, Go to [003-007-1 / 見積・取引先入力]
          navigate("quotation-supplier", {});
        }
      }
    }
  }, [props.isResettingCart]);

  const checkCart = (type) => {
    if (type === "invoice") {
      if (props.cart.is_invoice_empty) {
        //  clear the cart, and go to [003-001-1 / 仕入査定・取引先入力]
        props.reset_cart({ type: "1" });
      } else {
        showInvoiceConfirmModal();
      }
    } else if (type === "estimate") {
      if (props.cart.is_estimate_empty) {
        //  clear the cart, and go to  [003-007-1 /
        props.reset_cart({ type: "2" });
      } else {
        showEstimateConfirmModal();
      }
    }
  };

  return (
    <Block width={"100%"} middle marginTop={10}>
      <TouchableOpacity onPress={() => checkCart("invoice")} style={styles.btn}>
        <Text style={styles.purchase}>仕入査定</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => checkCart("estimate")}
        style={[styles.btn, { marginTop: 5 }]}
      >
        <Text style={styles.purchase}>見積作成</Text>
      </TouchableOpacity>
    </Block>
  );
};

const styles = StyleSheet.create({
  btn: {
    width: "100%",
    paddingVertical: 20,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  purchase: {
    fontSize: 20,
    color: Colors.White,
  },
});

const mapStateToProps = (state) => ({
  isGettingMetalMarketPrice: state.home.isGettingMetalMarketPrice,
  isGotMetalMarketPrice: state.home.isGotMetalMarketPrice,
  metalMarketPrice: state.home.metalMarketPrice,

  isResettingCart: state.home.isResettingCart,
  isResetCartFinish: state.home.isResetCartFinish,
  newCart: state.home.newCart,

  cart: state.home.cart,

  isCreatedNewInvoice: state.home.isCreatedNewInvoice,
  isCreatingNewInvoice: state.home.isCreatingNewInvoice,
  newInvoice: state.home.newInvoice,

  isCreatedNewEstimate: state.estimate.isCreatedNewEstimate,
  isCreatingNewEstimate: state.estimate.isCreatingNewEstimate,
  newEstimate: state.estimate.newEstimate,

  isGettingEstimate: state.estimate.isGettingEstimate,
  isGotEstimate: state.estimate.isGotEstimate,
  estimate: state.estimate.estimate,

  isGettingInvoice: state.invoice.isGettingInvoice,
  isGotInvoice: state.invoice.isGotInvoice,
  invoice: state.invoice.invoice,
});
const mapDispatchToProps = {
  reset_cart,
  createNewInvoice,
  createNewEstimate,
  checkCart,
  getEstimate,
  getInvoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceEstimateButton);
