import React from 'react';
import Block from './Block';
import TextView from './TextView';
import { Colors } from 'utils';
import Button from './Button';

const Empty = ({
  onPress1,
  onPress2,
  label = '現在査定中の取引はありません。',
  button1 = '仕切書一覧',
  button2 = 'TOPへ',
}) => {
  return (
    <Block block paddingHorizontal='24pt' center>
      <TextView size='13pt' color={Colors.Primary} bold>
        {label}
      </TextView>
      <Block position='absolute' bottom='20pt' direction='row' width='100%'>
        <Button
          onPress={onPress1}
          block
          color={Colors.SecondPrimary}
          centered
          middle
          paddingVertical='5pt'
        >
          <TextView size='12pt' color='white' bold>
            {button1}
          </TextView>
        </Button>
        <Block width='5pt' />
        <Button
          onPress={onPress2}
          block
          color={Colors.SecondPrimary}
          centered
          middle
          paddingVertical='5pt'
        >
          <TextView size='12pt' color='white' bold>
            {button2}
          </TextView>
        </Button>
      </Block>
    </Block>
  );
};

export default Empty;
