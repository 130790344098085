import React from 'react';
import { Text } from 'react-native';
const TextView = ({
  margin,
  padding,
  backgroundColor,
  color,
  size,
  bold,
  center,
  textAlign,
  regular,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  style,
  children,
  paddingVertical,
  paddingHorizontal,
  lineHeight,
  ...props
}) => {
  const styleComponent = [
    { fontSize: 14 }, // default h6
    margin && { margin },
    padding && { padding },
    backgroundColor && { backgroundColor },
    color && { color },
    size && { fontSize: size },
    bold && { fontWeight: 'bold' },
    regular && { fontWeight: '400' },
    h1 && { fontSize: 40, fontWeight: 'bold' },
    h2 && { fontSize: 34, fontWeight: 'bold' },
    h3 && { fontSize: 28, fontWeight: 'bold' },
    h4 && { fontSize: 24, fontWeight: 'bold' },
    h5 && { fontSize: 18, fontWeight: 'bold' },
    h6 && { fontSize: 14, fontWeight: 'bold' },
    paddingVertical && { paddingVertical },
    center && { textAlign: 'center' },
    textAlign && { textAlign },
    paddingHorizontal && { paddingHorizontal },
    lineHeight && { lineHeight },
    style,
  ];
  return (
    <Text style={styleComponent} {...props}>
      {children}
    </Text>
  );
};

export default TextView;
