import { createRequestTypes } from "./utils";

// export const GET_TRANSFER_CUSTOMERS = createRequestTypes(
//   "GET_TRANSFER_CUSTOMERS"
// );
// export const getTransferCustomers = (params) => ({
//   type: GET_TRANSFER_CUSTOMERS.REQUEST,
//   params,
// });

export const UPDATE_CUSTOMER = createRequestTypes("UPDATE_CUSTOMER");
export const updateCustomer = (params) => ({
  type: UPDATE_CUSTOMER.REQUEST,
  params,
});

export const GET_LAST_INVOICE = createRequestTypes("GET_LAST_INVOICE");
export const getLastInvoice = (params) => ({
  type: GET_LAST_INVOICE.REQUEST,
  params,
});

export const INSERT_PRODUCTS_TO_INVOICE = createRequestTypes(
  "INSERT_PRODUCTS_TO_INVOICE"
);
export const insertProductsToInvoice = (params) => ({
  type: INSERT_PRODUCTS_TO_INVOICE.REQUEST,
  params,
});

export const GET_INVOICE = createRequestTypes("GET_INVOICE");
export const getInvoice = (params) => ({
  type: GET_INVOICE.REQUEST,
  params,
});

export const UPDATE_INVOICE = createRequestTypes("UPDATE_INVOICE");
export const updateInvoice = (params) => ({
  type: UPDATE_INVOICE.REQUEST,
  params,
});

// export const GET_TWO_LAST_INVOICE = createRequestTypes("GET_TWO_LAST_INVOICE");
// export const getTwoLastInvoice = (params) => ({
//   type: GET_TWO_LAST_INVOICE.REQUEST,
//   params,
// });

export const GET_OTHER_INVOICE = createRequestTypes("GET_OTHER_INVOICE");
export const getOtherInvoice = (params) => ({
  type: GET_OTHER_INVOICE.REQUEST,
  params,
});

export const DELETE_INVOICE_DETAIL = createRequestTypes(
  "DELETE_INVOICE_DETAIL"
);
export const deleteInvoiceDetail = (params) => ({
  type: DELETE_INVOICE_DETAIL.REQUEST,
  params,
});

export const SEARCH_PAST_PERFORMANCE = createRequestTypes(
  "SEARCH_PAST_PERFORMANCE"
);

export const searchPastPerformance = (params) => ({
  type: SEARCH_PAST_PERFORMANCE.REQUEST,
  params,
});

export const INVOICE_UPLOAD_PDF = createRequestTypes("INVOICE_UPLOAD_PDF");
export const uploadPdf = (params) => ({
  type: INVOICE_UPLOAD_PDF.REQUEST,
  params,
});

export const CREATE_TRANSFER_INVOICE = createRequestTypes(
  "CREATE_TRANSFER_INVOICE"
);
export const createTransferInvoice = (params) => ({
  type: CREATE_TRANSFER_INVOICE.REQUEST,
  params,
});

export const SAVE_INVOICE_DATA_GO_TO_PRODUCT_DETAIL = createRequestTypes(
  "SAVE_INVOICE_DATA_GO_TO_PRODUCT_DETAIL"
);

export const saveInvoiceDataWhenGoToProductDetail = (params) => ({
  type: SAVE_INVOICE_DATA_GO_TO_PRODUCT_DETAIL,
  params,
});

export const SEARCH_PRODUCT_IN_INVOICE_DETAIL = createRequestTypes(
  "SEARCH_PRODUCT_IN_INVOICE_DETAIL"
);

export const searchProductInInvoiceDetail = (params) => ({
  type: SEARCH_PRODUCT_IN_INVOICE_DETAIL.REQUEST,
  params,
});

export const INVOICE_GENERATE_PDF = createRequestTypes("INVOICE_GENERATE_PDF");
export const generatePdf = (params) => ({
  type: INVOICE_GENERATE_PDF.REQUEST,
  params,
});
