import numeral from "numeral";

/**
 * roundCeil(2.9648,2) --> 2.97
 *
 * @param {*} value
 * @param {*} ceilNum
 */
export const roundCeil = (value, ceilNum) => {
  ceilNum = ceilNum === undefined || ceilNum === "" ? 2 : ceilNum;
  var factor = Math.pow(10, ceilNum);
  var a = value * factor;
  var b = Math.ceil(a);
  var c = b / factor;
  return c;
};

/**
 * roundFloor(2.9658,2) --> 2.96
 * @param {*} value
 * @param {*} floorNum
 */
export const roundFloor = (value, floorNum) => {
  floorNum = floorNum === undefined || floorNum === "" ? 2 : floorNum;
  var factor = Math.pow(10, floorNum);
  var a = value * factor;
  var b = Math.floor(a);
  var c = b / factor;
  return c;
};

export const formatMoney = (value, formatter) => {
  if (formatter === undefined) {
    formatter = MoneyFormatter.ZERO_DECIMAL_FORMAT;
  }
  if (value === undefined || value === "") return 0;
  return numeral(value).format(formatter, Math.floor);
};

export const MoneyFormatter = {
  ZERO_DECIMAL_FORMAT: "0,0.",
  ONE_DECIMAL_FORMAT: "0,0.0",
  TWO_DECIMAL_FORMAT: "0,0.00",
  CUSTOM_DECIMAL_FORMAT: "0,0.[0000000]",
  ZERO_DECIMAL_FORMAT_NO_COMMAS: "0",
  TWO_DECIMAL_FORMAT_NO_COMMAS: "0.00",
  FIVE_DECIMAL_FORMAT_NO_COMMAS: "0.00000",
  SEVEN_DECIMAL_FORMAT_NO_COMMAS: "0.0000000",
};

// const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$') //original
const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$"); // HungNgo: edit allow max 2 decimal number
// const floatRegExp = new RegExp('^[0-9]{1,11}(?:\\.[0-9]{1,3})?$') // another, not test yet

const intRegExp = new RegExp("^(0|[1-9]\\d*)$");

export const validateDecimal = (value) => {
  return value === "" || floatRegExp.test(value);

  //  return true;
  // }else{
  //   console.log('hung not validate')
  // }
};

export const validateInteger = (value) => {
  return value === "" || intRegExp.test(value);
};

/**
 * format like below
 * 1.00 -> 0
 * 1.23 -> 1.23
 * @param {*} value
 * @returns
 */
export const formatInvoiceDetailAmount = (value) => {
  if (value !== undefined && value.includes(".")) {
    const [whole, decimal] = String(value).split(".");
    if (decimal !== "" && Number(decimal) > 0) {
      if (decimal % 10 !== 0) {
        return formatMoney(value, MoneyFormatter.TWO_DECIMAL_FORMAT);
      } else {
        return formatMoney(value, MoneyFormatter.ONE_DECIMAL_FORMAT);
      }
    } else {
      return formatMoney(value);
    }
  } else {
    return value;
  }
};

export default {
  formatMoney,
  MoneyFormatter,
  validateDecimal,
  validateInteger,
  formatInvoiceDetailAmount,
};
