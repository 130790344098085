import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
} from "react-native";
import { Colors, isAmountGreaterThanZero } from "utils";
import { commonIcons } from "assets/icons";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const EstimateConfirm = ({
  estimates,
  selectedEstimateIndex,
  onSelectEstimate,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedEstimateIndex);
  const onSelectEstimateHandler = (index) => {
    setSelectedIndex(index);
    onSelectEstimate(index);
  };
  return (
    <ScrollView style={{ minHeight: 300, maxHeight: 400 }}>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text style={{ fontWeight: "bold", fontSize: 20 }}>
          保存中のリストがあります。
        </Text>
        <Text style={{ fontSize: 16 }}>この見積を続けますか?</Text>
      </View>

      {estimates?.map((estimate, index) => {
        return (
          <View key={`${index}`}>
            <View
              style={{
                marginTop: 20,
                marginBottom: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{
                  borderRadius: 50,
                  height: 13,
                  width: 13,
                  borderWidth: 1,
                  borderColor: Colors.Primary,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={() => onSelectEstimateHandler(index)}
              >
                <View
                  style={selectedIndex === index ? styles.radioChecked : {}}
                ></View>
              </TouchableOpacity>
              <Text
                style={{
                  marginStart: 8,
                  fontSize: 15,
                  color: Colors.Primary,
                }}
              >
                取引先名：{estimate?.customer_info?.name}
              </Text>
            </View>
            <View style={styles.line}></View>
            {/* {estimate?.estimate_details !== undefined &&
              estimate?.estimate_details.length > 0 && (
                <View style={{ marginVertical: 10 }}>
                  <EstimateDetail
                    estimate_detail={estimate?.estimate_details[0]}
                  />
                </View>
              )} */}
            <ScrollView style={{ maxHeight: 100 }}>
              {estimate.estimate_details?.map((estimate_detail, index) => {
                return (
                  <View key={`${index}`} style={{ marginVertical: 10 }}>
                    <EstimateDetail estimate_detail={estimate_detail} />
                  </View>
                );
              })}
            </ScrollView>

            <View style={styles.line}></View>
          </View>
        );
      })}
    </ScrollView>
  );
};

const EstimateDetail = ({ estimate_detail }) => {
  return (
    <View style={styles.wrap1}>
      <View onPress={() => {}} style={styles.titleWrap}>
        <View style={{ paddingRight: 5 }}>
          <Text style={styles.fieldName}>品 名:</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.fieldName}>
            {estimate_detail?.name}【{estimate_detail?.product_info?.code}】
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.fieldName}>仕入単価: </Text>
        <Text style={styles.fieldName}>{renderUnitPrice(estimate_detail)}</Text>
      </View>
    </View>
  );
};

const renderUnitPrice = (estimateDetail) => {
  if (
    isAmountGreaterThanZero(estimateDetail?.unit_price) &&
    isAmountGreaterThanZero(estimateDetail?.unit_price2)
  ) {
    return (
      formatMoney(estimateDetail.unit_price2) +
      " ~ " +
      formatMoney(estimateDetail.unit_price) +
      "円"
    );
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price)) {
    return formatMoney(estimateDetail.unit_price) + "円";
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price2)) {
    return formatMoney(estimateDetail.unit_price2) + "円";
  } else {
    return formatMoney(estimateDetail.unit_price) + "円";
  }
};

const styles = StyleSheet.create({
  wrap: {
    borderColor: Colors.PrimaryLight2,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 5,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    // padding: 10,
    // borderBottomColor: Colors.PrimaryLight2,
    // borderBottomWidth: 1,
    // paddingVertical: 5,
  },
  fieldName: {
    fontSize: 15,
    color: Colors.Primary,
  },
  radioChecked: {
    borderColor: Colors.Primary,
    borderWidth: 1,
    borderRadius: 50,
    width: 5,
    height: 5,
    backgroundColor: Colors.Primary,
    borderStyle: "solid",
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.Primary,
  },
});

export default EstimateConfirm;
