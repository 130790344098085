import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Colors } from "utils";
import {
  formatMoney,
  MoneyFormatter,
  validateInteger,
} from "../../utils/MoneyFormatter";
import {
  formatDateTime,
  DateTimeFormatter,
  formatDateTimeLocal,
} from "../../utils/DateUtility";
import DateInput from "components/common/DateInput";
import { TransferView } from "components/cart";

const InputAmountView = ({
  transfer,
  invoiceData,
  setInputAmountChange,
  transferAmount,
  cashAmount,
  submit,
  setLimitDate,
}) => {
  let transferAmountInput;
  let cashAmountInput;
  const [inputTransfer, setInputTransfer] = useState(
    transferAmount == undefined ? "" : transferAmount
  );
  const [inputCash, setInputCash] = useState(
    cashAmount == undefined ? "" : cashAmount
  );

  useEffect(() => {
    setInputCash(formatMoney(cashAmount, MoneyFormatter.ZERO_DECIMAL_FORMAT));
  }, [cashAmount]);
  useEffect(() => {
    setInputTransfer(
      formatMoney(transferAmount, MoneyFormatter.ZERO_DECIMAL_FORMAT)
    );
  }, [transferAmount]);

  const validateValueWithCommas = (value) => {
    const formatValue = formatMoney(
      value,
      MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS
    );
    return validateInteger(formatValue);
  };

  return (
    <View>
      <TransferView transferAmount={transfer?.total_price} />
      <View style={{ flexDirection: "row", marginTop: 8 }}>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <View style={styles.row}>
            <View style={{ alignSelf: "center", marginRight: "5pt" }}>
              <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                口座振込
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <TextInput
                style={styles.textInput}
                editable={
                  transfer !== undefined
                    ? +invoiceData?.total_price > +transfer?.total_price
                    : true
                }
                onChangeText={(value) => {
                  if (validateValueWithCommas(value)) {
                    setInputAmountChange({ type: 0, amount: value });
                    setInputTransfer(formatMoney(value));
                  }
                }}
                value={inputTransfer}
                keyboardType={"phone-pad"}
                ref={(input) => (transferAmountInput = input)}
                selectTextOnFocus
                onFocus={transferAmountInput?.focus()}
              />
            </View>
            <View style={{ marginLeft: "5pt" }}>
              <Text style={styles.smallText}>円</Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", flex: 1, marginLeft: "10pt" }}>
          <View style={styles.row}>
            <View style={{ alignSelf: "center", marginRight: "5pt" }}>
              <Text style={[styles.smallText, { fontWeight: "bold" }]}>
                小口現金
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <TextInput
                style={styles.textInput}
                editable={
                  transfer !== undefined
                    ? +invoiceData?.total_price > +transfer?.total_price
                    : true
                }
                onChangeText={(value) => {
                  if (validateValueWithCommas(value)) {
                    setInputAmountChange({ type: 1, amount: value });
                    setInputCash(formatMoney(value));
                  }
                }}
                value={inputCash}
                keyboardType={"phone-pad"}
                ref={(input) => (cashAmountInput = input)}
                selectTextOnFocus
                onFocus={cashAmountInput?.focus()}
              />
            </View>
            <Text style={styles.smallText}> 円</Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: "5pt" }}>
        <View style={[styles.row, { marginTop: "5pt" }]}>
          <View style={{ alignSelf: "center", marginRight: "5pt" }}>
            <Text style={[styles.smallText, { fontWeight: "bold" }]}>
              振込期日
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <DateInput
              containerStyle={[
                styles.textInput,
                {
                  borderWidth: 0,
                  borderRadius: 0,
                },
              ]}
              textStyle={{ fontSize: "12pt" }}
              onDateChange={(date) => {
                setLimitDate(
                  formatDateTimeLocal(
                    date,
                    DateTimeFormatter.YYYY_MM_DD_HH_MM_HYPHEN
                  )
                );
              }}
            />
          </View>
        </View>
        <View style={[styles.row]} />
      </View>

      <View style={styles.buttonWrap}>
        <TouchableOpacity style={styles.button} onPress={() => submit()}>
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            査定完了
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ alignItems: "center" }}>
        <Text
          style={{
            color: Colors.Primary,
            fontSize: "12pt",
            fontWeight: "bold",
          }}
        >
          査定を完了すると口座振込依頼が送信されます
        </Text>
      </View>
    </View>
  );
};

const { width } = Dimensions.get("window");

const styles = StyleSheet.create({
  textInput: {
    paddingVertical: "5pt",
    paddingHorizontal: "5pt",
    backgroundColor: Colors.PrimaryLight2,
    textAlign: "right",
    fontSize: "12pt",
  },
  button: {
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    alignItems: "center",
    borderRadius: "5pt",
  },
  buttonWrap: {
    paddingTop: "25pt",
    paddingBottom: "5pt",
    width: width * (160 / 375),
    alignSelf: "center",
  },
  smallText: {
    fontSize: "12pt",
    color: Colors.Primary,
  },

  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
});

export default InputAmountView;
