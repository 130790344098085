import {
  GET_SETTING,
  GET_LATEST_MARKET_PRICE,
  GET_TAX_RATE,
  UPDATE_SETTING,
  GET_INFORMATION,
  GET_LATEST_UPDATE_TRANSACTION,
  INSERT_INFORMATION,
  GET_MAKERS,
} from "redux/actions/general";
import { INFORMATION_TYPE } from "../../utils/Constant";

const initialState = {
  setting: {},
  errorMsg: "",
  latestMarketPrice: {},
  taxRate: {},
  isGettingSetting: false,
  isGotSetting: false,

  isUpdatingSetting: false,
  isUpdatedSetting: false,
  updatedSetting: {},

  isGettingInformationList: false,
  isGotInformationList: false,
  todayInformationList: [],
  officeInformationList: [],

  latestUpdatedTransactionList: [],

  isInsertingformation: false,
  isInsertedformation: false,
  insertedInformation: {},
  makerList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTING.REQUEST:
      return {
        ...state,
        isGettingSetting: true,
        isGotSetting: false,
      };

    case GET_SETTING.SUCCESS:
      return {
        ...state,
        isGettingSetting: false,
        isGotSetting: true,
        setting: action.result,
      };

    case GET_SETTING.FAIL:
      return {
        ...state,
        isGettingSetting: false,
        isGotSetting: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_LATEST_MARKET_PRICE.REQUEST:
      return {
        ...state,
        setting: {},
      };

    case GET_LATEST_MARKET_PRICE.SUCCESS:
      return {
        ...state,
        // isLoggedIn: true,
        // isLoggingIn: false,
        setting: action.result,
      };

    case GET_LATEST_MARKET_PRICE.FAIL:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_TAX_RATE.REQUEST:
      return {
        ...state,
        taxRate: {},
      };

    case GET_TAX_RATE.SUCCESS:
      return {
        ...state,
        taxRate: action.result,
      };

    case GET_TAX_RATE.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case UPDATE_SETTING.REQUEST:
      return {
        ...state,
        updatedSetting: {},
      };

    case UPDATE_SETTING.SUCCESS:
      return {
        ...state,
        updatedSetting: action.result,
      };

    case UPDATE_SETTING.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_INFORMATION.REQUEST:
      return {
        ...state,
        isGettingInformationList: true,
        isGotInformationList: false,
      };

    case GET_INFORMATION.SUCCESS:
      const resultList = action.result;
      if (resultList !== undefined && resultList.length > 0) {
        if (resultList[0].type === INFORMATION_TYPE.TODAY) {
          return {
            ...state,
            isGettingInformationList: false,
            isGotInformationList: true,
            todayInformationList: resultList,
          };
        } else {
          return {
            ...state,
            isGettingInformationList: false,
            isGotInformationList: true,
            officeInformationList: resultList,
          };
        }
      } else {
        return {
          ...state,
          isGettingInformationList: false,
          isGotInformationList: true,
        };
      }

    case GET_INFORMATION.FAIL:
      return {
        ...state,
        isGettingInformationList: false,
        isGotInformationList: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_LATEST_UPDATE_TRANSACTION.REQUEST:
      return {
        ...state,
      };

    case GET_LATEST_UPDATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        latestUpdatedTransactionList: action.result,
      };

    case GET_LATEST_UPDATE_TRANSACTION.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case INSERT_INFORMATION.REQUEST:
      return {
        ...state,
        isInsertingformation: true,
        isInsertedformation: false,
      };

    case INSERT_INFORMATION.SUCCESS:
      return {
        ...state,
        isInsertingformation: false,
        isInsertedformation: true,
        insertedInformation: action.result,
      };

    case INSERT_INFORMATION.FAIL:
      return {
        ...state,
        isInsertingformation: false,
        isInsertedformation: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_MAKERS.REQUEST:
      return {
        ...state,
        makerList: [],
      };

    case GET_MAKERS.SUCCESS:
      // makerList.push({
      //   id: -1, name: "all"
      // })
      const a = [
        ...action.result.slice(0, 0),
        { id: -1, name: "すべて" },
        ...action.result.slice(0),
      ];
      return {
        ...state,
        makerList: a,
      };

    case GET_MAKERS.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
