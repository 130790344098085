import { GET_LAST_INVOICE } from "../redux/actions/invoice";

const API_ROOT = process.env.REACT_APP_API_URL;
console.log("hung process.env.NODE_ENV: ", process.env.NODE_ENV);
export const API = {
  AUTH: {
    LOGIN: API_ROOT + "accounts/login/",
  },
  ACCOUNT: {
    GET_ACCOUNT_CART: API_ROOT + "accounts/get_account_cart/",
    GET_STATUS_MANAGEMENT:
      API_ROOT + "accounts/get_status_management/?filter_type={filter_type}",
    SEARCH_ACCOUNT_VOUCHER:
      API_ROOT +
      "accounts/search_account_voucher/?type={type}&customer_name={customer_name}&from_date={from_date}&to_date={to_date}",
  },
  HOME: {
    GET_METAL_MARKET_PRICE: API_ROOT + "home/get_metal_market_price/",
    RESET_CART: API_ROOT + "home/reset_cart/?type={type}",
    CHECK_CART: API_ROOT + "home/check_cart/",
    CREATE_NEW_INVOICE: API_ROOT + "home/create_new_invoice/",
    GET_MARKET_PRICE_DATA:
      API_ROOT + "home/get_market_price/?days_ago={days_ago}",
    GET_AVERAGE_MARKET_PRICE: API_ROOT + "home/get_average_market_price/",
  },
  INVOICE: {
    // GET_TRANSFER_CUSTOMERS: API_ROOT + "invoices/get_transfer_customers/",
    UPDATE_CUSTOMER: API_ROOT + "invoices/update_customer/",
    GET_LAST_INVOICE:
      API_ROOT + "invoices/get_last_invoice/?customer_id={customer_id}",
    INSERT_PRODUCTS_TO_INVOICE:
      API_ROOT + "invoices/insert_products_to_invoice/",
    GET_INVOICE: API_ROOT + "invoices/get_invoice/?invoice_id={invoice_id}",
    UPDATE_INVOICE: API_ROOT + "invoices/update/",
    // GET_TWO_LAST_INVOICE:
    //   API_ROOT +
    //   "invoices/get_two_last_invoice/?customer_id={customer_id}&product_id={product_id}",
    GET_OTHER_INVOICE:
      API_ROOT + "invoices/get_other_invoice/?product_id={product_id}",
    DELETE_INVOICE_DETAIL: API_ROOT + "invoices/delete_invoice_detail/",
    CREATE_NEW_INVOICE: API_ROOT + "invoices/create_new_invoice/",
    SEARCH_PERFORMANCE:
      API_ROOT +
      "invoices/search_performances/?customer_name={customer_name}&product_name={product_name}&maker_name={maker_name}&from_date={from_date}&to_date={to_date}&page={page}",
    UPLOAD_PDF: API_ROOT + "invoices/upload_pdf/",
    CREATE_TRANSFER_INVOICE: API_ROOT + "invoices/create_transfer_invoice/",
    SEARCH_PRODUCT_IN_INVOICE_DETAIL:
      API_ROOT +
      "invoices/search_product_in_invoice_detail/?product_name={product_name}",
    GENERATE_PDF: API_ROOT + "invoices/generate_pdf/",
  },
  PRODUCT: {
    SEARCH_PRODUCTS:
      API_ROOT +
      "products/search_products/?search_name={search_name}&search_code={search_code}",

    UPDATE_PRODUCT: API_ROOT + "products/update/",
    SEARCH_PRODUCT_CATALOG:
      API_ROOT +
      "products/search_product_catalog/?product_name={product_name}&maker_name={maker_name}&page={page}",
    GET_PRODUCT_CATALOG_DETAIL:
      API_ROOT +
      "products/get_product_catalog/?product_id={product_id}&customer_id={customer_id}",
  },
  TRANSFER: {
    GET_TRANSFER_CUSTOMERS:
      API_ROOT +
      "transfers/get_transfer_customers/?customer_id={customer_id}&customer_name={customer_name}",

    UPDATE_TRANSFER: API_ROOT + "transfers/update/",
    CREATE_TRANSFER: API_ROOT + "transfers/create/",
    SEARCH_TRANSFER:
      API_ROOT +
      "transfers/search/?customer_id={customer_id}&transfer_id={transfer_id}",
  },
  GENERAL: {
    GET_SETTING: API_ROOT + "generals/get_setting/",
    UPDATE_SETTING: API_ROOT + "generals/update_setting/",
    GET_LATEST_MARKET_PRICE: API_ROOT + "generals/get_latest_market_price/",
    GET_TAX_RATE: API_ROOT + "generals/get_tax/",
    GET_INFORMATION: API_ROOT + "generals/information/?type={type}",
    GET_LATEST_UPDATE_TRANSACTION:
      API_ROOT + "generals/get_latest_update_transaction/",
    INSERT_INFORMATION: API_ROOT + "generals/information/",
    GET_MAKERS: API_ROOT + "generals/get_makers/",
  },
  ESTIMATE: {
    UPDATE_CUSTOMER: API_ROOT + "estimates/update_customer/",
    GET_LAST_ESTIMATE:
      API_ROOT + "estimates/get_last_estimate/?customer_id={customer_id}",
    INSERT_PRODUCTS_TO_ESTIMATE:
      API_ROOT + "estimates/insert_products_to_estimate/",
    GET_ESTIMATE:
      API_ROOT + "estimates/get_estimate/?estimate_id={estimate_id}",
    UPDATE_ESTIMATE: API_ROOT + "estimates/update/",
    CREATE_NEW_ESTIMATE: API_ROOT + "estimates/create_new_estimate/",
    DELETE_ESTIMATE_DETAIL: API_ROOT + "estimates/delete_estimate_detail/",
    UPLOAD_PDF: API_ROOT + "estimates/upload_pdf/",
    GENERATE_PDF: API_ROOT + "estimates/generate_pdf/",
    DELETE_ESTIMATE: API_ROOT + "estimates/delete_estimate/",
    COPY_ESTIMATE: API_ROOT + "estimates/copy_estimate/",
  },
  CUSTOMER: {
    SEARCH_CUSTOMERS: API_ROOT + "customers/search/?name={name}",
    SEARCH_CUSTOMER_TRANSFER:
      API_ROOT +
      "customers/search_customer_transfer/?customer_name={customer_name}",
    GET_CUSTOMER_TRANSFER:
      API_ROOT + "customers/get_customer_transfer/?customer_id={customer_id}",
  },
  PERFORMANCE: {
    GET_LATEST:
      API_ROOT + "invoices/get_performances/?customer_name={customer_name}",
  },
};
