import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Title, Block, Button, TextView } from "components";
import Input from "components/common/Input";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import { Colors, useNavigate } from "utils";
import ModalPurchase from "components/modal/ModalPurchase";
import { connect } from "react-redux";
import {
  updateCustomer,
  insertProductsToInvoice,
} from "../../redux/actions/invoice";
// import { getTransferCustomers } from "../../redux/actions/transfer";
import {
  searchCustomerTransfer,
  getCustomerTransfer,
} from "../../redux/actions/customer";
import { usePrevious } from "../../utils/hook";
import { useHistory, useLocation } from "react-router-dom";
import { useWindowDimensions } from "react-native-web";
import { reset_cart, checkCart } from "../../redux/actions/home";
import { Alert } from "../../App";
import {
  TabView,
  ContentTab,
  Footer,
  InvoiceConfirm,
  EstimateConfirm,
} from "components/home";
import { ACCOUNT_PREVIOUS_VOUCHER_TYPE } from "../../utils/Constant";

/**
 * Screen: 003-001-1 / 仕入査定・取引先入力
 * Screen: 003-001-2 / 仕入査定・取引先入力
 * @returns {*}
 * @constructor
 */
const PurchaseSupplier = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useWindowDimensions();

  const [isVisible, setIsVisible] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [useTransfer, setUseTransfer] = useState(false);

  const history = useHistory();
  const {
    from_home,
    choose_estimate_from_estimate_review,
  } = history?.location?.state;

  useEffect(() => {
    props.checkCart();
    if (choose_estimate_from_estimate_review !== undefined) {
      props.getCustomerTransfer({
        customer_id: choose_estimate_from_estimate_review?.customer_info?.id,
      });
    }
  }, []);

  const prevGettingCustomerTransfer = usePrevious(
    props.isGettingCustomerTransfer
  );
  useEffect(() => {
    if (prevGettingCustomerTransfer && !props.isGettingCustomerTransfer) {
      if (props.isGotCustomerTransfer) {
        setSelectedCustomer(props.customerTransfer);
      }
    }
  }, [props.isGettingCustomerTransfer]);

  const next = () => {
    // props.reset_cart({ type: "1" });

    if (selectedCustomer !== undefined) {
      const customer = selectedCustomer;
      if (
        customer.transfer_info !== undefined &&
        customer.transfer_info !== null
      ) {
        setIsVisible(true);
      } else {
        setUseTransfer(false);
        props.updateCustomer({
          invoice_id: -1, // create new invoice
          customer_id: selectedCustomer?.id,
          customer_name: "",
        });
      }
    } else if (newCustomerName !== "") {
      // Insert a new record into table customers
      //Go to [003-002-1 / 仕入査定]
      props.updateCustomer({
        invoice_id: -1, // create new invoice
        customer_id: -1,
        customer_name: newCustomerName,
      });
    }
  };

  const onModalDeny = () => {
    setIsVisible(false);
    setUseTransfer(false);
    props.updateCustomer({
      invoice_id: -1, // create new invoice
      customer_id: selectedCustomer?.id,
      customer_name: "",
    });
  };
  const onModalConfirm = () => {
    setIsVisible(false);
    setUseTransfer(true);
    props.updateCustomer({
      invoice_id: -1, // create new invoice
      customer_id: selectedCustomer?.id,
      customer_name: "",
    });
  };

  const prevUpdatingCustomer = usePrevious(props.isUpdatingCustomer);
  useEffect(() => {
    if (prevUpdatingCustomer && !props.isUpdatingCustomer) {
      if (props.isUpdatedCustomer) {
        props.checkCart();
        if (choose_estimate_from_estimate_review !== undefined) {
          insertEstimateProductToInvoice(props.updatedCustomerInvoice);
        } else {
          //go to [003-002-1 / 仕入査定], update customer_id
          navigate("buy-review", {
            new_invoice: props.updatedCustomerInvoice,
            use_transfer: useTransfer,
          });
        }
      }
    }
  }, [props.isUpdatingCustomer]);

  const insertEstimateProductToInvoice = (newInvoice) => {
    const data = choose_estimate_from_estimate_review?.estimate_details?.map(
      (item) => {
        return {
          id: item?.product_info?.id,
          name: item?.product_info?.name,
          amount: 0,
          unit_price: 0,
          price: 0,
          tax: 0,
          total_price: 0,
          is_delete_product: false,
        };
      }
    );
    props.insertProductsToInvoice({
      invoice_id: newInvoice?.id,
      is_clear_invoice_detail: false,
      products: data,
    });
  };

  const prevInsertingProductToInvoice = usePrevious(
    props.isInsertingProductToInvoice
  );
  useEffect(() => {
    if (prevInsertingProductToInvoice && !props.isInsertingProductToInvoice) {
      if (props.isInsertedProductToInvoice) {
        // when have choose_estimate_from_estimate_review will insert ProductToInvoice
        // after that go to buy-review
        //go to [003-002-1 / 仕入査定], update customer_id
        props.checkCart();
        navigate("buy-review", {
          new_invoice: props.updatedCustomerInvoice,
          use_transfer: useTransfer,
        });
      } else {
      }
    }
  }, [props.isInsertingProductToInvoice]);

  const searchCustomerTransfer = (searchName) => {
    props.searchCustomerTransfer({
      customer_name: searchName,
    });
  };

  const prevCheckingCart = usePrevious(props.isCheckingCart);
  useEffect(() => {
    if (prevCheckingCart && !props.isCheckingCart) {
      if (
        props.isCheckedCart &&
        location.pathname.includes("purchase-supplier")
      ) {
        if (props.cart.is_invoice_empty) {
          //  clear the cart, and go to [003-001-1 / 仕入査定・取引先入力]
          // props.reset_cart({ type: "1" });
        } else {
          if (
            choose_estimate_from_estimate_review === undefined ||
            choose_estimate_from_estimate_review === null
          ) {
            showInvoiceConfirmModal();
          }
        }
      }
    }
  }, [props.isCheckingCart]);

  const showInvoiceConfirmModal = () => {
    Alert.alert("", <InvoiceConfirm invoice={props.cart?.invoice} />, [
      {
        label: "はい",
        primary: true,
        onPress: () => {
          // go direct to [003-003-1 / 仕入リスト], but must go though 003-001,003-002
          navigate("buy-review", {
            // 003-002
            new_invoice: props.cart?.invoice,
          });

          navigate("cart", {
            invoice_id: props.cart?.invoice?.id,
          });
          Alert.hideModal();
        },
      },
      {
        label: "いいえ（破棄）",
        primary: false,
        onPress: () => {
          //  clear the cart, and go to [003-001-1 / 仕入査定・取引先入力]
          props.reset_cart({ type: "1" });
          Alert.hideModal();
        },
      },
    ]);
  };

  const prevResettingCart = usePrevious(props.isResettingCart);
  useEffect(() => {
    if (prevResettingCart && !props.isResettingCart) {
      if (props.isResetCartFinish) {
      }
    }
  }, [props.isResettingCart]);

  return (
    <>
      <Block paddingBottom="10pt" paddingHorizontal="24pt">
        <Block flex={6}>
          <Title title={"仕入査定 / 取引先入力"} />

          <InputWithSuggestion
            placeHolder={""}
            label={"取引先検索"}
            fetchApi={(searchName) => searchCustomerTransfer(searchName)}
            data={props.customerTransferList}
            onSelectedItem={(item) => setSelectedCustomer(item)}
          />
          <Button
            onPress={() => {
              navigate("past-dividers", {
                title: "見積書一覧",
                type: ACCOUNT_PREVIOUS_VOUCHER_TYPE.ESTIMATE,
                from_date: "",
                to_date: "",
                customer_name: "",
              });
            }}
          >
            <Block
              color={Colors.SecondPrimary}
              width={"40%"}
              height={50}
              centered
              middle
              borderRadius={5}
              marginTop={20}
            >
              <TextView bold color={Colors.White} h5>
                見積書を選択
              </TextView>
            </Block>
          </Button>
        </Block>
        <Block flex={4}>
          <Block marginTop="5pt">
            <Input
              paddingVertical={15}
              hideIcon
              disabled
              defaultValue={
                choose_estimate_from_estimate_review !== undefined
                  ? choose_estimate_from_estimate_review?.customer_info?.name
                  : ""
              }
            />
          </Block>
          <Block marginTop={20}>
            <Input
              paddingVertical={15}
              hideIcon
              label={"新規取引先"}
              onChangeText={(value) => setNewCustomerName(value)}
            />
          </Block>
          <Block width="100%" marginTop="40pt">
            <Button
              onPress={() => next()}
              smallCentered
              width={width / 3.75}
              paddingVertical="8pt"
              middle
            >
              <TextView bold color={Colors.White} h5>
                次　へ
              </TextView>
            </Button>
          </Block>
        </Block>
      </Block>
      <ModalPurchase
        {...{ isVisible }}
        onClose={() => {
          onModalDeny();
        }}
        onConfirm={() => onModalConfirm()}
        customer_transfer={selectedCustomer}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  // transferCustomers: state.transfer.transferCustomers,
  isUpdatingCustomer: state.invoice.isUpdatingCustomer,
  isUpdatedCustomer: state.invoice.isUpdatedCustomer,
  updatedCustomerInvoice: state.invoice.updatedCustomerInvoice,

  customerTransferList: state.customer.customerTransferList,

  isCheckedCart: state.home.isCheckedCart,
  isCheckingCart: state.home.isCheckingCart,
  cart: state.home.cart,

  isResettingCart: state.home.isResettingCart,
  isResetCartFinish: state.home.isResetCartFinish,
  newCart: state.home.newCart,

  isGettingCustomerTransfer: state.customer.isGettingCustomerTransfer,
  isGotCustomerTransfer: state.customer.isGotCustomerTransfer,
  customerTransfer: state.customer.customerTransfer,
  isInsertingProductToInvoice: state.invoice.isInsertingProductToInvoice,
  isInsertedProductToInvoice: state.invoice.isInsertedProductToInvoice,
});
const mapDispatchToProps = {
  // getTransferCustomers,
  updateCustomer,
  searchCustomerTransfer,
  getCustomerTransfer,
  checkCart,
  reset_cart,
  insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSupplier);
