import React from "react";
import Modal from "modal-enhanced-react-native-web";
import { TextView, Button, Block } from "components";
import { Colors } from "utils";
import { Dimensions } from "react-native-web";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";

const { height } = Dimensions.get("window");
const ModalPurchase = ({
  isVisible,
  onClose,
  onConfirm,
  customer_transfer,
}) => {
  console.log(isVisible);
  return (
    <Modal backdropOpacity={0} {...{ isVisible }}>
      <Block
        width="270pt"
        color="white"
        borderWidth="1pt"
        borderColor={Colors.Primary}
        borderRadius="5pt"
        padding="10pt"
        position="absolute"
        alignSelf="center"
        marginTop={-height * 0.15}
      >
        <TextView size="12pt" color={Colors.SecondPrimary} regular>
          対象の取引先に下記の取引未完了事前振込依頼が あります。
        </TextView>
        <Block
          height="0.5pt"
          color={Colors.SecondPrimary}
          marginVertical="10pt"
        />
        <TextView size="10pt" color={Colors.SecondPrimary} regular>
          取引先名：{customer_transfer?.name}
        </TextView>
        <TextView size="10pt" color={Colors.SecondPrimary} regular>
          取引日：
          {formatDateTime(
            customer_transfer?.transfer_info?.date,
            DateTimeFormatter.MM_DD_JP
          )}{" "}
          振込期日：
          {formatDateTime(
            customer_transfer?.transfer_info?.limit_date,
            DateTimeFormatter.MM_DD_JP
          )}
        </TextView>
        <TextView size="10pt" color={Colors.SecondPrimary} regular>
          振込金額：{formatMoney(customer_transfer?.transfer_info?.total_price)}{" "}
          円{"          "}
          ステータス：{customer_transfer?.transfer_info?.status}
        </TextView>
        <Block
          height="0.5pt"
          color={Colors.SecondPrimary}
          marginVertical="10pt"
        />
        <TextView center size="12pt" color={Colors.SecondPrimary} regular>
          この取引に読み込みますか？
        </TextView>
        <Block
          direction="row"
          justifyContent="space-around"
          paddingHorizontal="20%"
          marginVertical={25}
        >
          <Button
            paddingVertical="5pt"
            paddingHorizontal="15pt"
            color={Colors.SecondPrimary}
            borderRadius="3pt"
            onPress={onConfirm}
          >
            <TextView size="9pt" bold color={Colors.White}>
              は　い
            </TextView>
          </Button>
          <Button
            onPress={onClose}
            paddingVertical="5pt"
            paddingHorizontal="15pt"
            color={Colors.SecondPrimary}
            borderRadius="3pt"
          >
            <TextView size="9pt" bold color={Colors.White}>
              いいえ
            </TextView>
          </Button>
        </Block>
      </Block>
    </Modal>
  );
};

export default ModalPurchase;
