import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StyleSheet } from "react-native";
import { Colors } from "utils";
import { defaultIcons } from "assets/icons";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import Tab1 from "./Tab1";
import Tab3 from "./Tab3";
import Tab2 from "./Tab2";
import { usePrevious } from "../../utils/hook";
import { getMetalMarketPrice } from "../../redux/actions/home";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import {
  formatDateTime,
  DateTimeFormatter,
  formatDateTimeUTC,
} from "../../utils/DateUtility";
import FullLoading from "components/common/FullLoading";

const data = [
  {
    title: "PT",
    title_jpy: "プラチナ",
    price_jpy: "3,414",
    price_usd: "3,300",
    unit_jpy: "円/g",
    unit_usd: "ドル/oz",
    spread_jpy: "+211円",
    spread_usd: "+○○＄",
    arrow_jpy: defaultIcons.arrow_up,
    arrow_usd: defaultIcons.arrow_up,
  },
  {
    title: "PD",
    title_jpy: "パラジウム",
    price_jpy: "3,620",
    price_usd: "3,540",
    unit_jpy: "円/g",
    unit_usd: "ドル/oz",
    spread_jpy: "+156円",
    spread_usd: "-○○＄",
    arrow_jpy: defaultIcons.arrow_up,
    arrow_usd: defaultIcons.arrow_down,
  },
  {
    title: "RH",
    title_jpy: "ロジウム",
    price_jpy: "4,700",
    price_usd: "3,540",
    unit_jpy: "円/g",
    unit_usd: "ドル/oz",
    spread_jpy: "-82円",
    spread_usd: "+○○＄",
    arrow_jpy: defaultIcons.arrow_down,
    arrow_usd: defaultIcons.arrow_up,
  },
];
const ContentTab = (props) => {
  const [loading, setLoading] = useState(false);
  const [metalMarketPrice, setMetalMarketPrice] = useState([]);
  const formatPrice = (value) => {
    return formatMoney(value, MoneyFormatter.ZERO_DECIMAL_FORMAT);
  };

  const prevGettingMetalMarketPrice = usePrevious(
    props.isGettingMetalMarketPrice
  );
  useEffect(() => {
    if (prevGettingMetalMarketPrice && !props.isGettingMetalMarketPrice) {
      if (props.isGotMetalMarketPrice) {
        const spread_pt_jpy =
          props.metalMarketPrice.latest_price.pt_jpy -
          props.metalMarketPrice.previous_price.pt_jpy;
        const spread_pt_usd =
          props.metalMarketPrice.latest_price.pt_usd -
          props.metalMarketPrice.previous_price.pt_usd;

        const spread_pd_jpy =
          props.metalMarketPrice.latest_price.pd_jpy -
          props.metalMarketPrice.previous_price.pd_jpy;
        const spread_pd_usd =
          props.metalMarketPrice.latest_price.pd_usd -
          props.metalMarketPrice.previous_price.pd_usd;

        const spread_rh_jpy =
          props.metalMarketPrice.latest_price.rh_jpy -
          props.metalMarketPrice.previous_price.rh_jpy;
        const spread_rh_usd =
          props.metalMarketPrice.latest_price.rh_usd -
          props.metalMarketPrice.previous_price.rh_usd;

        const data = [
          {
            title: "PT",
            title_jpy: "プラチナ",
            price_jpy: formatPrice(props.metalMarketPrice.latest_price.pt_jpy),
            price_usd: formatPrice(props.metalMarketPrice.latest_price.pt_usd),
            unit_jpy: "円/g",
            unit_usd: "ドル/oz",
            spread_jpy:
              (spread_pt_jpy > 0
                ? "+" + formatPrice(spread_pt_jpy)
                : formatPrice(spread_pt_jpy)) + "円",
            spread_usd:
              (spread_pt_usd > 0
                ? "+" + formatPrice(spread_pt_usd)
                : formatPrice(spread_pt_usd)) + "＄",
            arrow_jpy:
              spread_pt_jpy > 0
                ? defaultIcons.arrow_up
                : spread_pt_jpy < 0
                ? defaultIcons.arrow_down
                : null,
            arrow_usd:
              spread_pt_usd > 0
                ? defaultIcons.arrow_up
                : spread_pt_usd < 0
                ? defaultIcons.arrow_down
                : null,
          },
          {
            title: "PD",
            title_jpy: "パラジウム",
            price_jpy: formatPrice(props.metalMarketPrice.latest_price.pd_jpy),
            price_usd: formatPrice(props.metalMarketPrice.latest_price.pd_usd),
            unit_jpy: "円/g",
            unit_usd: "ドル/oz",
            spread_jpy:
              (spread_pd_jpy > 0
                ? "+" + formatPrice(spread_pd_jpy)
                : formatPrice(spread_pd_jpy)) + "円",
            spread_usd:
              (spread_pd_usd > 0
                ? "+" + formatPrice(spread_pd_usd)
                : formatPrice(spread_pd_usd)) + "＄",
            arrow_jpy:
              spread_pd_jpy > 0
                ? defaultIcons.arrow_up
                : spread_pd_jpy < 0
                ? defaultIcons.arrow_down
                : null,
            arrow_usd:
              spread_pd_usd > 0
                ? defaultIcons.arrow_up
                : spread_pd_usd < 0
                ? defaultIcons.arrow_down
                : null,
          },
          {
            title: "RH",
            title_jpy: "ロジウム",
            price_jpy: formatPrice(props.metalMarketPrice.latest_price.rh_jpy),
            price_usd: formatPrice(props.metalMarketPrice.latest_price.rh_usd),
            unit_jpy: "円/g",
            unit_usd: "ドル/oz",
            spread_jpy:
              (spread_rh_jpy > 0
                ? "+" + formatPrice(spread_rh_jpy)
                : formatPrice(spread_rh_jpy)) + "円",
            spread_usd:
              (spread_rh_usd > 0
                ? "+" + formatPrice(spread_rh_usd)
                : formatPrice(spread_rh_usd)) + "＄",
            arrow_jpy:
              spread_rh_jpy > 0
                ? defaultIcons.arrow_up
                : spread_rh_jpy < 0
                ? defaultIcons.arrow_down
                : null,
            arrow_usd:
              spread_rh_usd > 0
                ? defaultIcons.arrow_up
                : spread_rh_usd < 0
                ? defaultIcons.arrow_down
                : null,
          },
        ];

        setMetalMarketPrice(data);
      } else {
      }
      setLoading(false);
    }
  }, [props.isGettingMetalMarketPrice]);

  useEffect(() => {
    props.getMetalMarketPrice();
    setLoading(true);
  }, []);

  return (
    <Block block>
      {props.tabIndex !== 1 && (
        <Block
          marginTop={"15pt"}
          alignItems="flex-end"
          paddingBottom={"5pt"}
          style={styles.dash}
        >
          <Block>
            <TextView bold size={"11pt"} color={Colors.SecondPrimary}>
              {props.tabIndex === 0 ? "前営業日との比較" : "比 較"}
            </TextView>
            <TextView bold size={"11pt"} center color={Colors.SecondPrimary}>
              {props.tabIndex === 0
                ? formatDateTimeUTC(
                    props.metalMarketPrice?.previous_price?.date,
                    DateTimeFormatter.MM_DD_JP
                  )
                : ""}
            </TextView>
          </Block>
        </Block>
      )}
      {props.tabIndex === 0 && <Tab1 data={metalMarketPrice} />}
      {props.tabIndex === 1 && <Tab2 {...{ data }} />}
      {props.tabIndex === 2 && <Tab3 {...{ data }} />}
      {loading && <FullLoading />}
    </Block>
  );
};

const mapStateToProps = (state) => ({
  isGettingMetalMarketPrice: state.home.isGettingMetalMarketPrice,
  isGotMetalMarketPrice: state.home.isGotMetalMarketPrice,
  metalMarketPrice: state.home.metalMarketPrice,

  isGettingAverageMarketPrice: state.home.isGettingAverageMarketPrice,
  isGotAverageMarketPrice: state.home.isGotAverageMarketPrice,
  averageMarketPrice: state.home.averageMarketPrice,
});
const mapDispatchToProps = {
  getMetalMarketPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTab);

const styles = StyleSheet.create({
  dash: {
    borderStyle: "dashed",
    borderBottomWidth: 1,
    borderBottomColor: Colors.SecondPrimary,
  },
  view02: {
    backgroundColor: Colors.PrimaryLight,
    flex: 1,
    paddingVertical: 13,
    alignItems: "flex-end",
    paddingRight: 10,
    marginLeft: 15,
    marginRight: 5,
  },
  txt02: {
    fontSize: 18,
    fontWeight: "500",
    color: Colors.Primary,
  },
  txt03: {
    fontSize: 12,
    fontWeight: "500",
    color: Colors.Primary,
  },
  imgArrow: { height: 18, width: 18 },
  imgArrowDown: { height: 14, width: 14 },
  txt04: {
    fontSize: 8,
    color: Colors.SecondPrimary,
    marginTop: 2,
    marginLeft: 5,
  },
  containerView: {
    justifyContent: "space-around",
  },
});
