import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { StyleSheet, FlatList, Image, TouchableOpacity } from "react-native";
import { Title, Block, Button, TextView, WrapperBlock } from "components";
import Input from "components/common/Input";
import { Colors, useNavigate } from "utils";
import { CUSTOMER } from "containers/purchase-supplier/fakePurchaseSupplier";
import { defaultIcons } from "assets/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { searchCustomers } from "../../redux/actions/customer";
import { updateCustomerEstimate } from "../../redux/actions/estimate";
import { usePrevious } from "../../utils/hook";
import InputWithSuggestion from "components/common/InputWithSuggestion";
import { Dimensions, useWindowDimensions } from "react-native-web";

const styles = StyleSheet.create({
  list: {
    width: "90%",
    alignSelf: "flex-end",
    borderWidth: 1,
    borderColor: Colors.SecondPrimary,
    borderRadius: 5,
    borderTopWidth: 0,
  },
});

/**
 * 003-007-1 / 見積・取引先入力
 */
const QuotationSupplier = (props) => {
  const navigate = useNavigate();
  const onPressNavigate = (pathname) => navigate(pathname);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [withoutCustomer, setWithoutCustomer] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  // const { new_estimate } = history?.location?.state;

  const next = () => {
    if (withoutCustomer) {
      props.updateCustomerEstimate({
        estimate_id: -1, //create new estimate
        customer_id: 0,
        customer_name: "",
      });
    } else if (selectedCustomer !== undefined) {
      props.updateCustomerEstimate({
        estimate_id: -1, //create new estimate
        customer_id: selectedCustomer?.id,
        customer_name: "",
      });
    } else if (newCustomerName !== "") {
      props.updateCustomerEstimate({
        estimate_id: -1, //create new estimate
        customer_id: -1,
        customer_name: newCustomerName,
      });
    }
  };

  const prevUpdatingCustomer = usePrevious(props.isUpdatingCustomerEstimate);
  useEffect(() => {
    if (prevUpdatingCustomer && !props.isUpdatingCustomerEstimate) {
      if (props.isUpdatedCustomerEstimate) {
        //go to  [003-008-1 / 見積, update customer_id
        navigate("estimate", {
          new_estimate: props.updatedCustomerEstimate,
        });
      }
    }
  }, [props.isUpdatingCustomerEstimate]);

  const searchCustomerHandler = (searchName) => {
    props.searchCustomers({
      name: searchName,
    });
  };

  return (
    <WrapperBlock>
      <Title title={"見積作成 / 取引先入力"} />

      <InputWithSuggestion
        placeHolder={""}
        label={"取引先検索"}
        fetchApi={(searchName) => searchCustomerHandler(searchName)}
        data={props.searchCustomerResults}
        onSelectedItem={(item) => setSelectedCustomer(item)}
      />
      <Block block>
        <Block marginTop={"20pt"}>
          <Input
            paddingVertical={"15pt"}
            hideIcon
            label={"新規取引先"}
            onChangeText={(value) => setNewCustomerName(value)}
          />
        </Block>
        <Block direction="row" marginTop={"20pt"} height={"20pt"} middle>
          <Button onPress={() => setWithoutCustomer((prev) => !prev)}>
            <Image
              style={{ height: "18pt", width: "18pt" }}
              source={
                withoutCustomer
                  ? defaultIcons.check_success
                  : defaultIcons.check_none
              }
            />
          </Button>
          <Block marginLeft={"10pt"}>
            <TextView color={Colors.SecondPrimary} size={"12pt"} on>
              取引先を指定せずに見積を作成する
            </TextView>
          </Block>
        </Block>
        <Block width="100%" position="absolute" bottom="30pt">
          <Button
            onPress={() => next()}
            smallCentered
            paddingVertical="10pt"
            width={width * (100 / 375)}
            middle
          >
            <TextView bold color={Colors.White} size={"12pt"}>
              次　へ
            </TextView>
          </Button>
        </Block>
      </Block>
    </WrapperBlock>
  );
};

const mapStateToProps = (state) => ({
  searchCustomerResults: state.customer.searchCustomerResults,
  isUpdatingCustomerEstimate: state.estimate.isUpdatingCustomerEstimate,
  isUpdatedCustomerEstimate: state.estimate.isUpdatedCustomerEstimate,
  updatedCustomerEstimate: state.estimate.updatedCustomerEstimate,
});
const mapDispatchToProps = {
  searchCustomers,
  updateCustomerEstimate,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationSupplier);
