import {
  GET_CUSTOMERS,
  SEARCH_CUSTOMERS,
  SEARCH_CUSTOMER_TRANSFER,
  GET_CUSTOMER_TRANSFER,
} from "redux/actions/customer";

const initialState = {
  isSearchingCustomer: false,
  isSearchCustomerFinish: false,
  searchCustomerResults: [],

  errorMsg: "",
  customerTransferList: [],

  isGettingCustomerTransfer: false,
  isGotCustomerTransfer: false,
  customerTransfer: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS.REQUEST:
      return {
        ...state,
      };

    case GET_CUSTOMERS.SUCCESS:
      return {
        ...state,
        // isLoggedIn: true,
        // isLoggingIn: false,
        // latestMarketPrice: action.result,
      };

    case GET_CUSTOMERS.FAIL:
      return {
        ...state,
        // isLoggedIn: false,
        // isLoggingIn: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SEARCH_CUSTOMERS.REQUEST:
      return {
        ...state,
        isSearchingCustomer: true,
        isSearchCustomerFinish: false,
        searchCustomerResults: [],
      };

    case SEARCH_CUSTOMERS.SUCCESS:
      return {
        ...state,
        isSearchingCustomer: false,
        isSearchCustomerFinish: true,
        searchCustomerResults: action.result,
      };

    case SEARCH_CUSTOMERS.FAIL:
      return {
        ...state,
        isSearchingCustomer: false,
        isSearchCustomerFinish: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case SEARCH_CUSTOMER_TRANSFER.REQUEST:
      return {
        ...state,
        customerTransferList: [],
      };

    case SEARCH_CUSTOMER_TRANSFER.SUCCESS:
      return {
        ...state,
        customerTransferList: action.result,
      };

    case SEARCH_CUSTOMER_TRANSFER.FAIL:
      return {
        ...state,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_CUSTOMER_TRANSFER.REQUEST:
      return {
        ...state,
        isGettingCustomerTransfer: true,
        isGotCustomerTransfer: false,
        customerTransfer: {},
      };

    case GET_CUSTOMER_TRANSFER.SUCCESS:
      return {
        ...state,
        isGettingCustomerTransfer: false,
        isGotCustomerTransfer: true,
        customerTransfer: action.result,
      };

    case GET_CUSTOMER_TRANSFER.FAIL:
      return {
        ...state,
        isGettingCustomerTransfer: false,
        isGotCustomerTransfer: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
