import {
  UPDATE_CUSTOMER_ESTIMATE,
  GET_LAST_ESTIMATE,
  INSERT_PRODUCTS_TO_ESTIMATE,
  GET_ESTIMATE,
  UPDATE_ESTIMATE,
  CREATE_NEW_ESTIMATE,
  DELETE_ESTIMATE_DETAIL,
  ESTIMATE_UPLOAD_PDF,
  ESTIMATE_GENERATE_PDF,
  DELETE_ESTIMATE,
  ESTIMATE_SCROLL_Y_OFFSET,
  COPY_ESTIMATE,
} from "../actions/estimate";

const initialState = {
  // transferCustomers: [],
  errorMsg: "",
  isUpdatingCustomerEstimate: false,
  isUpdatedCustomerEstimate: false,
  updatedCustomerEstimate: {},

  isGettingLastEstimate: false,
  isGotLastEstimate: false,
  lastEstimate: {},

  isGettingEstimate: false,
  isGotEstimate: false,
  estimate: {},

  isUpdatingEstimate: false,
  isUpdatedEstimate: false,
  updatedEstimate: {},

  isCreatingNewEstimate: false,
  isCreatedNewEstimate: false,
  newEstimate: {},

  isDeletingEstimateDetail: false,
  isDeletedEstimateDetail: false,
  estimateWhichDeletedEstimateDetail: {},

  isInsertingProductToEstimate: false,
  isInsertedProductToEstimate: false,

  isUploadingEstimatePdf: false,
  isUploadedEstimatePdf: false,
  estimateWhichUploadPdf: {},

  isGeneratingPdf: false,
  isGeneratedPdf: false,
  generatedPdfUrl: {},

  isDeletingEstimate: false,
  isDeletedEstimate: false,
  estimateScrollYOffset: 0,

  isCopyingEstimate: false,
  isCopiedEstimate: false,
  copiedEstimate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_ESTIMATE.REQUEST:
      return {
        ...state,
        isUpdatingCustomerEstimate: true,
        isUpdatedCustomerEstimate: false,
      };

    case UPDATE_CUSTOMER_ESTIMATE.SUCCESS:
      return {
        ...state,
        isUpdatingCustomerEstimate: false,
        isUpdatedCustomerEstimate: true,
        updatedCustomerEstimate: action.result,
      };

    case UPDATE_CUSTOMER_ESTIMATE.FAIL:
      return {
        ...state,
        isUpdatingCustomerEstimate: false,
        isUpdatedCustomerEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_LAST_ESTIMATE.REQUEST:
      return {
        ...state,
        isGettingLastEstimate: true,
        isGotLastEstimate: false,
        lastEstimate: {},
      };

    case GET_LAST_ESTIMATE.SUCCESS:
      return {
        ...state,
        isGettingLastEstimate: false,
        isGotLastEstimate: true,
        lastEstimate: action.result,
      };

    case GET_LAST_ESTIMATE.FAIL:
      return {
        ...state,
        isGettingLastEstimate: false,
        isGotLastEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case INSERT_PRODUCTS_TO_ESTIMATE.REQUEST:
      return {
        ...state,
        isInsertingProductToEstimate: true,
        isInsertedProductToEstimate: false,
      };

    case INSERT_PRODUCTS_TO_ESTIMATE.SUCCESS:
      return {
        ...state,
        isInsertingProductToEstimate: false,
        isInsertedProductToEstimate: true,
      };

    case INSERT_PRODUCTS_TO_ESTIMATE.FAIL:
      return {
        ...state,
        isInsertingProductToEstimate: false,
        isInsertedProductToEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case GET_ESTIMATE.REQUEST:
      return {
        ...state,
        isGettingEstimate: true,
        isGotEstimate: false,
        estimate: {},
      };

    case GET_ESTIMATE.SUCCESS:
      return {
        ...state,
        isGettingEstimate: false,
        isGotEstimate: true,
        estimate: action.result,
      };

    case GET_ESTIMATE.FAIL:
      return {
        ...state,
        isGettingEstimate: false,
        isGotEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case UPDATE_ESTIMATE.REQUEST:
      return {
        ...state,
        isUpdatingEstimate: true,
        isUpdatedEstimate: false,
        updatedEstimate: {},
      };

    case UPDATE_ESTIMATE.SUCCESS:
      return {
        ...state,
        isUpdatingEstimate: false,
        isUpdatedEstimate: true,
        updatedEstimate: action.result,
      };

    case UPDATE_ESTIMATE.FAIL:
      return {
        ...state,
        isUpdatingEstimate: false,
        isUpdatedEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case CREATE_NEW_ESTIMATE.REQUEST:
      return {
        ...state,
        isCreatingNewEstimate: true,
        isCreatedNewEstimate: false,
        newEstimate: {},
      };

    case CREATE_NEW_ESTIMATE.SUCCESS:
      return {
        ...state,
        isCreatingNewEstimate: false,
        isCreatedNewEstimate: true,
        newEstimate: action.result,
      };

    case CREATE_NEW_ESTIMATE.FAIL:
      return {
        ...state,
        isCreatingNewEstimate: false,
        isCreatedNewEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case DELETE_ESTIMATE_DETAIL.REQUEST:
      return {
        ...state,
        isDeletingEstimateDetail: true,
        isDeletedEstimateDetail: false,
        estimateWhichDeletedEstimateDetail: {},
      };

    case DELETE_ESTIMATE_DETAIL.SUCCESS:
      return {
        ...state,
        isDeletingEstimateDetail: false,
        isDeletedEstimateDetail: true,
        estimateWhichDeletedEstimateDetail: action.result,
      };

    case DELETE_ESTIMATE_DETAIL.FAIL:
      return {
        ...state,
        isDeletingEstimateDetail: false,
        isDeletedEstimateDetail: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case ESTIMATE_UPLOAD_PDF.REQUEST:
      return {
        ...state,
        isUploadingEstimatePdf: true,
        isUploadedEstimatePdf: false,
      };

    case ESTIMATE_UPLOAD_PDF.SUCCESS:
      return {
        ...state,
        isUploadingEstimatePdf: false,
        isUploadedEstimatePdf: true,
        estimateWhichUploadPdf: action.result,
      };

    case ESTIMATE_UPLOAD_PDF.FAIL:
      return {
        ...state,
        isUploadingEstimatePdf: false,
        isUploadedEstimatePdf: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case ESTIMATE_GENERATE_PDF.REQUEST:
      return {
        ...state,
        isGeneratingPdf: true,
        isGeneratedPdf: false,
        generatedPdfUrl: "",
      };

    case ESTIMATE_GENERATE_PDF.SUCCESS:
      return {
        ...state,
        isGeneratingPdf: false,
        isGeneratedPdf: true,
        generatedPdfUrl: action.result,
      };

    case ESTIMATE_GENERATE_PDF.FAIL:
      return {
        ...state,
        isGeneratingPdf: false,
        isGeneratedPdf: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case DELETE_ESTIMATE.REQUEST:
      return {
        ...state,
        isDeletingEstimate: true,
        isDeletedEstimate: false,
        generatedPdfUrl: "",
      };

    case DELETE_ESTIMATE.SUCCESS:
      return {
        ...state,
        isDeletingEstimate: false,
        isDeletedEstimate: true,
      };

    case DELETE_ESTIMATE.FAIL:
      return {
        ...state,
        isDeletingEstimate: false,
        isDeletedEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    case ESTIMATE_SCROLL_Y_OFFSET:
      console.log("hung action.params?.y_offset:", action.params?.y_offset);
      return {
        ...state,
        estimateScrollYOffset: action.params?.y_offset,
      };

    case COPY_ESTIMATE.REQUEST:
      return {
        ...state,
        isCopyingEstimate: true,
        isCopiedEstimate: false,
      };

    case COPY_ESTIMATE.SUCCESS:
      return {
        ...state,
        isCopyingEstimate: false,
        isCopiedEstimate: true,
        copiedEstimate: action.result,
      };

    case COPY_ESTIMATE.FAIL:
      return {
        ...state,
        isCopyingEstimate: false,
        isCopiedEstimate: false,
        errorMsg: action.result?.errorMessage?.response?.data?.error,
      };

    default:
      return state;
  }
}
