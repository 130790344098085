import axios from "axios";

import { takeEvery, put } from "redux-saga/effects";
import {
  SEARCH_PRODUCTS,
  UPDATE_PRODUCT,
  SEARCH_PRODUCT_CATALOG,
  GET_PRODUCT_CATALOG_DETAIL,
} from "../actions/product";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* searchProducts(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.PRODUCT.SEARCH_PRODUCTS, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_PRODUCTS.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: SEARCH_PRODUCTS.FAIL,
      result,
    });
  }
}

function* updateProduct(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.PRODUCT.UPDATE_PRODUCT, params);
  if (result.success) {
    yield put({
      type: UPDATE_PRODUCT.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: UPDATE_PRODUCT.FAIL,
      result,
    });
  }
}

function* searchProductCatalog(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.PRODUCT.SEARCH_PRODUCT_CATALOG, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_PRODUCT_CATALOG.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: SEARCH_PRODUCT_CATALOG.FAIL,
      result,
    });
  }
}

function* getProductCatalogDetail(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.PRODUCT.GET_PRODUCT_CATALOG_DETAIL, params)
  );
  if (result.success) {
    yield put({
      type: GET_PRODUCT_CATALOG_DETAIL.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_PRODUCT_CATALOG_DETAIL.FAIL,
      result,
    });
  }
}

export default function* () {
  yield takeEvery(SEARCH_PRODUCTS.REQUEST, searchProducts);
  yield takeEvery(UPDATE_PRODUCT.REQUEST, updateProduct);
  yield takeEvery(SEARCH_PRODUCT_CATALOG.REQUEST, searchProductCatalog);
  yield takeEvery(GET_PRODUCT_CATALOG_DETAIL.REQUEST, getProductCatalogDetail);
}
