import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";

const Footer = ({ onPress }) => {
  return (
    <Block width={"100%"} middle marginTop={10}>
      <TouchableOpacity
        onPress={() => onPress("/purchase-supplier")}
        style={styles.btn}
      >
        <Text style={styles.purchase}>仕入査定</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onPress("/quotation-supplier")}
        style={[styles.btn, { marginTop: 5 }]}
      >
        <Text style={styles.purchase}>見積作成</Text>
      </TouchableOpacity>
    </Block>
  );
};

export default Footer;

const styles = StyleSheet.create({
  btn: {
    width: "100%",
    paddingVertical: 20,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  purchase: {
    fontSize: 20,
    color: Colors.White,
  },
});
