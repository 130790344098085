import React from "react";
import { Dimensions } from "react-native";
import { Block, Empty } from "components";
import Title from "components/common/Title";
const { height } = Dimensions.get("window");
const TAB_HEIGHT = 160;
const Purchase = () => {
  return (
    <Block
      paddingBottom="10pt"
      paddingHorizontal="24pt"
      height={height - TAB_HEIGHT}
    >
      <Title backTitle={"TOP"} title="仕入リスト" />
      <Empty />
    </Block>
  );
};

export default Purchase;
