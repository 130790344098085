import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  FlatList,
} from "react-native";
import { Title, Block, Button, TextView, WrapperBlock } from "components";
import Input from "components/common/Input";

import DateInput from "components/common/DateInput";
import { Colors } from "utils";
import { useNavigate } from "utils";
import { searchCustomers } from "../../redux/actions/customer";
import { createTransfer } from "../../redux/actions/transfer";
import { connect } from "react-redux";
import { TRANSFER_TYPE } from "../../utils/Constant";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import { usePrevious } from "../../utils/hook";

const InputWithSuggestion = ({
  placeHolder,
  label,
  fetchApi,
  data,
  onSelectedItem,
  inputStyle,
  inputPaddingVertical,
}) => {
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [searchItemName, setSearchItemName] = useState("");
  const [isShowCustomerList, setIsShowCustomerList] = React.useState(false);

  useEffect(() => {
    if (searchItemName !== "" && searchItemName !== selectedItemName) {
      fetchApi(searchItemName);
      setIsShowCustomerList(true);
    } else if (searchItemName === "") {
      onSelectedItem(undefined);
      setIsShowCustomerList(false);
    } else {
      setIsShowCustomerList(false);
    }
  }, [searchItemName]);

  const selectItemHandler = (item, index) => {
    setSelectedItem(item);
    setSelectedItemName(item.name);
    setSearchItemName(item.name);
    setIsShowCustomerList(false);
    onSelectedItem(item);
  };

  return (
    <View>
      <Block marginTop="10pt">
        <Input
          placeholder={placeHolder}
          placeholderTextColor={`${Colors.SecondPrimary}40`}
          label={label}
          value={searchItemName}
          paddingVertical={
            inputPaddingVertical !== undefined ? inputPaddingVertical : 15
          }
          onChangeText={(value) => setSearchItemName(value)}
          wrapStyle={inputStyle}
        />
      </Block>
      {isShowCustomerList && (
        <Block
          color="#ECECEC"
          width="90%"
          height="300pt"
          alignSelf="flex-end"
          borderWidth="1pt"
          borderColor={Colors.SecondPrimary}
          borderRadius="3pt"
        >
          <FlatList
            data={data}
            style={{ flex: 1 }}
            keyExtractor={(_, index) => `${index}`}
            renderItem={({ item, index }) => {
              return (
                <Block
                  key={`${index}`}
                  paddingVertical="10pt"
                  paddingLeft="15pt"
                  borderWidth={1}
                  borderColor={Colors.PrimaryLight}
                  color={"white"}
                >
                  <TouchableOpacity
                    onPress={() => selectItemHandler(item, index)}
                  >
                    <TextView color={Colors.Primary} size={14} bold>
                      {item.name}
                      {item.code !== undefined ? "【" + item.code + "】" : ""}
                    </TextView>
                  </TouchableOpacity>
                </Block>
              );
            }}
          />
        </Block>
      )}
    </View>
  );
};

export default InputWithSuggestion;
