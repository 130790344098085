import { takeEvery, put } from "redux-saga/effects";
import {
  UPDATE_CUSTOMER_ESTIMATE,
  GET_LAST_ESTIMATE,
  INSERT_PRODUCTS_TO_ESTIMATE,
  GET_ESTIMATE,
  UPDATE_ESTIMATE,
  CREATE_NEW_ESTIMATE,
  DELETE_ESTIMATE_DETAIL,
  ESTIMATE_UPLOAD_PDF,
  ESTIMATE_GENERATE_PDF,
  DELETE_ESTIMATE,
  COPY_ESTIMATE,
} from "../actions/estimate";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* updateCustomer(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.UPDATE_CUSTOMER, params);
  if (result.success) {
    yield put({
      type: UPDATE_CUSTOMER_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: UPDATE_CUSTOMER_ESTIMATE.FAIL,
      result,
    });
  }
}

function* getLastEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.ESTIMATE.GET_LAST_ESTIMATE, params)
  );
  if (result.success) {
    yield put({
      type: GET_LAST_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_LAST_ESTIMATE.FAIL,
      result,
    });
  }
}

function* insertProductsToEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(
    API.ESTIMATE.INSERT_PRODUCTS_TO_ESTIMATE,
    params
  );
  if (result.success) {
    yield put({
      type: INSERT_PRODUCTS_TO_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: INSERT_PRODUCTS_TO_ESTIMATE.FAIL,
      result,
    });
  }
}

function* getEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.ESTIMATE.GET_ESTIMATE, params)
  );
  if (result.success) {
    yield put({
      type: GET_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_ESTIMATE.FAIL,
      result,
    });
  }
}

function* updateEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.UPDATE_ESTIMATE, params);
  if (result.success) {
    yield put({
      type: UPDATE_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: UPDATE_ESTIMATE.FAIL,
      result,
    });
  }
}

function* createNewEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.CREATE_NEW_ESTIMATE, params);
  if (result.success) {
    yield put({
      type: CREATE_NEW_ESTIMATE.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: CREATE_NEW_ESTIMATE.FAIL,
      result,
    });
  }
}

function* deleteEstimateDetail(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.DELETE_ESTIMATE_DETAIL, params);
  if (result.success) {
    yield put({
      type: DELETE_ESTIMATE_DETAIL.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: DELETE_ESTIMATE_DETAIL.FAIL,
      result,
    });
  }
}

const createFormData = (pdf, body) => {
  const data = new FormData();
  data.append("file", {
    name: pdf.filename,
    uri: pdf.uri,
  });

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
};

function* uploadPdf(obj) {
  const { params = {} } = obj;
  const formData = new FormData();
  formData.append("files", params.files, params.file_name);
  formData.append("estimate_id", params.estimate_id);
  const result = yield axiosPost(API.ESTIMATE.UPLOAD_PDF, formData);

  if (result.success) {
    yield put({
      type: ESTIMATE_UPLOAD_PDF.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: ESTIMATE_UPLOAD_PDF.FAIL,
      result,
    });
  }
}

function* generatePdf(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.GENERATE_PDF, params);

  if (result.success) {
    yield put({
      type: ESTIMATE_GENERATE_PDF.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: ESTIMATE_GENERATE_PDF.FAIL,
      result,
    });
  }
}

function* deleteEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.DELETE_ESTIMATE, params);
  if (result.success) {
    yield put({
      type: DELETE_ESTIMATE.SUCCESS,
      result: result?.data?.data,
    });
  } else {
    yield put({
      type: DELETE_ESTIMATE.FAIL,
      result,
    });
  }
}

function* copyEstimate(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.ESTIMATE.COPY_ESTIMATE, params);
  if (result.success) {
    yield put({
      type: COPY_ESTIMATE.SUCCESS,
      result: result?.data?.data,
    });
  } else {
    yield put({
      type: COPY_ESTIMATE.FAIL,
      result,
    });
  }
}

export default function* () {
  // yield takeEvery(GET_TRANSFER_CUSTOMERS.REQUEST, getTransferCustomers);
  yield takeEvery(UPDATE_CUSTOMER_ESTIMATE.REQUEST, updateCustomer);
  yield takeEvery(GET_LAST_ESTIMATE.REQUEST, getLastEstimate);
  yield takeEvery(
    INSERT_PRODUCTS_TO_ESTIMATE.REQUEST,
    insertProductsToEstimate
  );
  yield takeEvery(GET_ESTIMATE.REQUEST, getEstimate);
  yield takeEvery(UPDATE_ESTIMATE.REQUEST, updateEstimate);
  // yield takeEvery(GET_TWO_LAST_INVOICE.REQUEST, getTwoLastInvoice);
  yield takeEvery(CREATE_NEW_ESTIMATE.REQUEST, createNewEstimate);
  yield takeEvery(DELETE_ESTIMATE_DETAIL.REQUEST, deleteEstimateDetail);
  yield takeEvery(ESTIMATE_UPLOAD_PDF.REQUEST, uploadPdf);
  yield takeEvery(ESTIMATE_GENERATE_PDF.REQUEST, generatePdf);
  yield takeEvery(DELETE_ESTIMATE.REQUEST, deleteEstimate);
  yield takeEvery(COPY_ESTIMATE.REQUEST, copyEstimate);
}
