import React, { useEffect, useState } from "react";
import Modal from "modal-enhanced-react-native-web";
import { TextView, Button, Block, Input } from "components";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image,
  ScrollView,
} from "react-native";
import { Colors } from "utils";
import { Dimensions } from "react-native-web";
import validator from "validator";
import { validateInteger } from "../../utils/MoneyFormatter";
import { commonIcons } from "assets/icons";

const { height } = Dimensions.get("window");
const ModalAnalysis = ({ isVisible, onClose, selectedProduct, onUpdate }) => {
  const [adjustRatePT, setAdjustRatePT] = useState(0);
  const [adjustRatePD, setAdjustRatePD] = useState(0);
  const [adjustRateRH, setAdjustRateRH] = useState(0);
  let adjustRatePTInput = null;
  let adjustRatePDInput = null;
  let adjustRateRHInput = null;

  useEffect(() => {
    if (isVisible) {
      // console.log('hung Modal init selectedProduct:',selectedProduct)
      setAdjustRatePT(selectedProduct?.adjust_rate_pt);
      setAdjustRatePD(selectedProduct?.adjust_rate_pd);
      setAdjustRateRH(selectedProduct?.adjust_rate_rh);
    }
  }, [
    isVisible,
    selectedProduct?.adjust_rate_pd,
    selectedProduct?.adjust_rate_pt,
    selectedProduct?.adjust_rate_rh,
  ]);

  return (
    <Modal backdropOpacity={0} isVisible={isVisible}>
      <Block
        width="270pt"
        color="white"
        borderWidth="1pt"
        borderColor={Colors.Primary}
        borderRadius="5pt"
        padding="10pt"
        position="absolute"
        alignSelf="center"
        marginTop={-height * 0.15}
      >
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <TextView size="12pt" center color={Colors.SecondPrimary} regular>
            補正率入力
          </TextView>
          <TouchableOpacity
            onPress={onClose}
            style={{ position: "absolute", right: 0 }}
          >
            <Image
              source={commonIcons.close}
              style={{ height: "12pt", width: "12pt" }}
            />
          </TouchableOpacity>
        </View>
        <Block height="0.5pt" marginVertical="10pt" />
        <TextView size="10pt" color={Colors.SecondPrimary} regular>
          コード：{selectedProduct?.code}
        </TextView>
        <TextView size="10pt" color={Colors.SecondPrimary} regular>
          品名・分類：{selectedProduct?.name}
        </TextView>
        <Block direction="row" justifyContent="space-around" marginTop="5pt">
          <Block direction="row">
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              PT
            </TextView>
            <Input
              defaultValue={selectedProduct?.adjust_rate_pt || ""}
              onChangeText={(value) => {
                if (validateInteger(value)) {
                  setAdjustRatePT(value);
                }
              }}
              hideIcon
              style={{
                width: 50,
                color: Colors.SecondPrimary,
                textAlign: "right",
              }}
              wrapStyle={{
                marginHorizontal: 5,
                borderRadius: 0,
              }}
              value={adjustRatePT}
              keyboardType={"phone-pad"}
              refHandler={(input) => (adjustRatePTInput = input)}
              selectTextOnFocus
              onFocus={adjustRatePTInput?.focus()}
            />
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              %
            </TextView>
          </Block>
          <Block direction="row">
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              PD
            </TextView>
            <Input
              defaultValue={selectedProduct?.adjust_rate_pd || ""}
              onChangeText={(value) => {
                if (validateInteger(value)) {
                  setAdjustRatePD(value);
                }
              }}
              hideIcon
              style={{
                width: 50,
                color: Colors.SecondPrimary,
                textAlign: "right",
              }}
              wrapStyle={{ marginHorizontal: 5, borderRadius: 0 }}
              value={adjustRatePD}
              keyboardType={"phone-pad"}
              refHandler={(input) => (adjustRatePDInput = input)}
              selectTextOnFocus
              onFocus={adjustRatePDInput?.focus()}
            />
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              %
            </TextView>
          </Block>
          <Block direction="row">
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              RH
            </TextView>
            <Input
              defaultValue={selectedProduct?.adjust_rate_rh || ""}
              onChangeText={(value) => {
                if (validateInteger(value)) {
                  setAdjustRateRH(value);
                }
              }}
              hideIcon
              style={{
                width: 50,
                color: Colors.SecondPrimary,
                textAlign: "right",
              }}
              wrapStyle={{ marginHorizontal: 5, borderRadius: 0 }}
              value={adjustRateRH}
              keyboardType={"phone-pad"}
              refHandler={(input) => (adjustRateRHInput = input)}
              selectTextOnFocus
              onFocus={adjustRateRHInput?.focus()}
            />
            <TextView size="10pt" color={Colors.SecondPrimary} regular>
              %
            </TextView>
          </Block>
        </Block>
        <Block
          direction="row"
          justifyContent="space-around"
          marginVertical={25}
        >
          <Button
            onPress={() =>
              onUpdate(
                selectedProduct,
                adjustRatePT,
                adjustRatePD,
                adjustRateRH
              )
            }
            paddingVertical="5pt"
            paddingHorizontal="25pt"
            color={Colors.SecondPrimary}
            borderRadius="3pt"
          >
            <TextView size="9pt" bold color={Colors.White}>
              更新
            </TextView>
          </Button>
        </Block>
      </Block>
    </Modal>
  );
};

export default ModalAnalysis;
