export const bottomIcons = {
  bell: require("./Bell.png"),
  currency: require("./Currency.png"),
  document: require("./Document.png"),
  file: require("./File.png"),
  settings: require("./Settings.png"),
};

export const headerIcons = {
  cart: require("./Cart.png"),
  profile: require("./Profile.png"),
  reload: require("./Reload.png"),
};

export const defaultIcons = {
  arrow_up: require("./arrow@2x.png"),
  arrow_down: require("./arrow1@2x.png"),
  check_none: require("./check_none.png"),
  check_success: require("./check_success.png"),
  caret_down: require("./caret_down.png"),
  check: require("./check.png"),
  trash: require("./trash.png"),
};

export const commonIcons = {
  search: require("./Search.png"),
  calendar: require("./Calendar.png"),
  share: require("./Share.png"),
  close: require("./close.png"),
  pdf: require("./Pdf.png"),
  arrow_down: require("./arrow_down.png"),
  RadioCheck: require("./Radio_check.png"),
};

export const authIcons = {
  user: require("./user.png"),
  password: require("./password.png"),
  admin_user: require("./admin_user.png"),
  admin_password: require("./admin_password.png"),
};
