import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Back from "components/common/Back";
import { Colors, handleFocus, nor } from "utils";
import Package from "components/cart/Package";
import { useNavigate } from "utils";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getInvoice,
  updateInvoice,
  deleteInvoiceDetail,
  uploadPdf,
  saveInvoiceDataWhenGoToProductDetail,
} from "../../redux/actions/invoice";
import { getTaxRate } from "../../redux/actions/general";
import { usePrevious } from "../../utils/hook";
import {
  formatMoney,
  MoneyFormatter,
  formatInvoiceDetailAmount,
} from "../../utils/MoneyFormatter";
import {
  INVOICE_STATUS,
  CART_UPDATE_INVOICE_BEHAVIOR,
} from "../../utils/Constant";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Block, TextView, Button } from "components";
import { Utils } from "utils";
import { checkCart } from "../../redux/actions/home";
import { Alert } from "../../App";
import {
  ACCOUNT_STATUS_FILTER_DATA,
  ACCOUNT_PREVIOUS_VOUCHER_TYPE,
} from "../../utils/Constant";
import Title from "components/common/Title";

/**
 * //screen: [003-003-1 / 仕入リスト]
 */
const Cart = (props) => {
  const navigate = useNavigate();
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState({});
  // const [isGoNextScreen, setIsGoNextScreen] = useState(false);
  const [cartUpdateInvoiceBehavior, setCartUpdateInvoiceBehavior] = useState(0);
  const [invoiceDataChange, setInvoiceDataChange] = useState({
    type: 1,
    productIndex: -1,
    amount: 0,
    unit_price: 0,
  });
  const prevGettingInvoice = usePrevious(props.isGettingInvoice);
  const [isEmptyCart, setIsEmptyCart] = useState(false);
  const [isFinishPurchase, setIsFinishPurchase] = useState(false);
  const { use_transfer } = history?.location?.state;
  const { finish_purchase_from_product_detail } = history?.location?.state;
  useEffect(() => {
    const { invoice_id } = history?.location?.state;
    if (finish_purchase_from_product_detail !== undefined) {
      setIsFinishPurchase(true);
    } else if (invoice_id !== "" && invoice_id !== undefined) {
      props.getInvoice({
        invoice_id: history?.location?.state?.invoice_id,
      });
      setIsEmptyCart(false);
    } else {
      //show empty cart
      setIsEmptyCart(true);
    }
  }, []);

  useEffect(() => {
    if (prevGettingInvoice && !props.isGettingInvoice) {
      if (props.isGotInvoice) {
        setupData(props.invoice);
        props.getTaxRate();
      }
    }
  }, [props.isGettingInvoice]);
  const formatMoneyZeroDecimalNoCommas = (value) => {
    return formatMoney(value, MoneyFormatter.ZERO_DECIMAL_FORMAT_NO_COMMAS);
  };
  const formatMoneyTwoDecimalNoCommas = (value) => {
    return formatMoney(value, MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS);
  };

  const setupData = (invoice) => {
    const data = invoice;
    const invoice_detail = invoice.invoice_details.map((item, index) => {
      return {
        ...item,
        amount: formatInvoiceDetailAmount(item.amount),
        onQuantityChange: (value) => {
          setInvoiceDataChange((prev) => ({
            ...prev,
            type: 1, //type =1 update amount
            productIndex: index,
            amount: value,
          }));
        },
        onUnitPriceChange: (value) => {
          setInvoiceDataChange((prev) => ({
            ...prev,
            type: 2, //type =2 update unit_price
            productIndex: index,
            unit_price: value,
          }));
        },
      };
    });
    data.invoice_details = invoice_detail;
    setInvoiceData(data);
  };

  const calculateInvoiceDetailTotalPrice = (
    currentIndex,
    invoiceDataChange,
    invoiceDetail
  ) => {
    let totalPrice = 0;
    if (currentIndex === invoiceDataChange.productIndex) {
      if (invoiceDataChange.type === 1) {
        totalPrice =
          formatMoneyTwoDecimalNoCommas(invoiceDataChange.amount) *
          formatMoneyZeroDecimalNoCommas(invoiceDetail.unit_price);
        totalPrice = formatMoney(totalPrice);
      } else {
        totalPrice =
          formatMoneyTwoDecimalNoCommas(invoiceDetail.amount) *
          formatMoneyZeroDecimalNoCommas(invoiceDataChange.unit_price);
        totalPrice = formatMoney(totalPrice);
      }
    } else {
      totalPrice =
        formatMoneyTwoDecimalNoCommas(invoiceDetail.amount) *
        formatMoneyZeroDecimalNoCommas(invoiceDetail.unit_price);
      totalPrice = formatMoney(totalPrice);
    }
    return totalPrice;
  };
  useEffect(() => {
    if (invoiceData !== undefined) {
      const data = { ...invoiceData };

      const tempData = data?.invoice_details?.map((item, index) => {
        return {
          ...item,
          amount:
            index === invoiceDataChange.productIndex &&
            invoiceDataChange.type === 1
              ? invoiceDataChange.amount
              : item.amount,
          unit_price:
            index === invoiceDataChange.productIndex &&
            invoiceDataChange.type === 2
              ? invoiceDataChange.unit_price
              : item.unit_price,

          total_price: calculateInvoiceDetailTotalPrice(
            index,
            invoiceDataChange,
            item
          ),
        };
      });

      const subTotal = tempData?.reduce(
        (prev, current, index) =>
          prev + +formatMoneyZeroDecimalNoCommas(current.total_price),
        0
      );
      const tax = formatMoney(
        props.taxRate?.tax == undefined ? 0 : props.taxRate?.tax,
        MoneyFormatter.ZERO_DECIMAL_FORMAT
      );
      let consumptionTax = subTotal * (tax * 0.01);
      let total = subTotal + consumptionTax;
      data.price = formatMoney(subTotal, MoneyFormatter.ZERO_DECIMAL_FORMAT);
      data.tax = formatMoney(
        consumptionTax,
        MoneyFormatter.ZERO_DECIMAL_FORMAT
      );
      data.total_price = formatMoney(total, MoneyFormatter.ZERO_DECIMAL_FORMAT);
      data.invoice_details = tempData;
      setInvoiceData(data);
    }
  }, [invoiceDataChange]);

  // console.log("hung invoiceDataChange:", invoiceDataChange);

  useEffect(() => {
    if (invoiceData !== undefined) {
      const data = { ...invoiceData };
      let subTotal = 0;

      subTotal = data?.invoice_details?.reduce(
        (prev, current, index) => prev + +current.total_price,
        0
      );
      let consumptionTax = subTotal * (props.taxRate?.tax * 0.01);
      let total = subTotal + consumptionTax;
      data.price = formatMoney(subTotal, MoneyFormatter.ZERO_DECIMAL_FORMAT);
      data.tax = formatMoney(
        consumptionTax,
        MoneyFormatter.ZERO_DECIMAL_FORMAT
      );
      data.total_price = formatMoney(total, MoneyFormatter.ZERO_DECIMAL_FORMAT);
      setInvoiceData(data);
    }
  }, [props.taxRate]);

  const updateInvoice = (status, useTransfer, is_submit) => {
    const invoice_details = invoiceData?.invoice_details.map((item) => {
      return {
        id: item.id,
        product_id: item.product_info?.id,
        amount: formatMoney(
          item.amount,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        price: formatMoney(
          item.price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        unit_price: formatMoney(
          item.unit_price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
        total_price: formatMoney(
          item.total_price,
          MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
        ),
      };
    });

    const updateInvoice = {
      id: invoiceData.id,
      price: formatMoney(
        invoiceData.price,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      tax: formatMoney(
        invoiceData.tax,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      total_price: formatMoney(
        invoiceData.total_price,
        MoneyFormatter.TWO_DECIMAL_FORMAT_NO_COMMAS
      ),
      status: status,
      invoice_details: invoice_details,
      transfer_price: 0,
      cash_price: 0,
      use_transfer: useTransfer,
      is_submit: is_submit,
    };
    props.updateInvoice(updateInvoice);
  };

  const submitPreview = () => {
    // Submit the data, invoices.status = 1
    // Go to [003-004-01 / 仕切書プレビュー]
    updateInvoice(INVOICE_STATUS.PURCHASING, false, false);
    setCartUpdateInvoiceBehavior(CART_UPDATE_INVOICE_BEHAVIOR.PREVIEW_INVOICE);
    // generatePdf(false);
  };

  const finishPurchase = () => {
    showSubmitPurchaseModal();
  };

  const updateInvoiceWhenOnBlur = () => {
    updateInvoice(INVOICE_STATUS.PURCHASING, false, false);
    setCartUpdateInvoiceBehavior(
      CART_UPDATE_INVOICE_BEHAVIOR.UPDATE_INVOICE_ON_BLUR
    );
  };
  const prevUpdatingInvoice = usePrevious(props.isUpdatingInvoice);
  useEffect(() => {
    if (prevUpdatingInvoice && !props.isUpdatingInvoice) {
      if (props.isUpdatedInvoice) {
        props.checkCart();
        if (
          cartUpdateInvoiceBehavior ==
          CART_UPDATE_INVOICE_BEHAVIOR.PREVIEW_INVOICE
        ) {
          navigate("cart-preview", {
            invoice: props.updatedInvoice,
            use_transfer: use_transfer,
          });
        } else if (
          cartUpdateInvoiceBehavior ==
          CART_UPDATE_INVOICE_BEHAVIOR.SUBMIT_INVOICE
        ) {
          setIsFinishPurchase(true);
        } else {
          //do nothing
        }
      }
    }
  }, [props.isUpdatingInvoice]);

  const prevDeletingInvoiceDetail = usePrevious(props.isDeletingInvoiceDetail);
  useEffect(() => {
    if (prevDeletingInvoiceDetail && !props.isDeletingInvoiceDetail) {
      if (props.isDeletedInvoiceDetail) {
        props.checkCart();
        setupData(props.invoiceWhichDeletedInvoiceDetail);
      }
    }
  }, [props.isDeletingInvoiceDetail]);

  const showSubmitPurchaseModal = () => {
    Alert.alert(
      "",
      <View>
        <Text style={{ textAlign: "center", fontSize: 16 }}>
          {
            "未入力で査定を終了すると\n登録された仕入れリストは保存されません。\n査定を終了しますか。"
          }
        </Text>
      </View>,
      [
        {
          label: "はい",
          primary: true,
          onPress: () => {
            Alert.hideModal();
            //Submit the data, invoices.status = 1
            updateInvoice(
              INVOICE_STATUS.PURCHASE_COMPLETED,
              use_transfer,
              true
            );
            setCartUpdateInvoiceBehavior(
              CART_UPDATE_INVOICE_BEHAVIOR.SUBMIT_INVOICE
            );
          },
        },
        {
          label: "いいえ",
          primary: false,
          onPress: () => {
            Alert.hideModal();
          },
        },
      ]
    );
  };

  return (
    <View style={styles.wrap}>
      {/* <View style={styles.titleWrap}>
        <Back label={isEmptyCart || isFinishPurchase ? "TOP" : "査 定"} />
        <Text style={styles.title}>仕入リスト</Text>
      </View> */}
      <Title
        backTitle={isEmptyCart || isFinishPurchase ? "TOP" : "査 定"}
        title="仕入リスト"
      />
      {isEmptyCart ? (
        <EmptyCartView navigate={navigate} />
      ) : isFinishPurchase ? (
        <FinishPurchaseView navigate={navigate} />
      ) : (
        <View style={{ flex: 1 }}>
          <div id="viewToRenderPdf">
            <View style={{}}>
              <Text style={styles.description}>
                取引先コード：{props.invoice?.customer_info?.code}
              </Text>
              <Text style={styles.description}>
                取引先名：{props.invoice?.customer_info?.name}
              </Text>
              <Text style={styles.description}>本日の貴金属相場</Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: "5pt" }}>
              <View style={styles.cell}>
                <TextView size="13pt" color={Colors.Primary} bold>
                  PT：{formatMoney(props.invoice?.market_price?.pt_jpy)}円
                </TextView>
              </View>
              <View style={[styles.cell, { marginHorizontal: "5pt" }]}>
                <TextView size="13pt" color={Colors.Primary} bold>
                  PD：{formatMoney(props.invoice?.market_price?.pd_jpy)}円
                </TextView>
              </View>
              <View style={styles.cell}>
                <TextView size="13pt" color={Colors.Primary} bold>
                  RH：{formatMoney(props.invoice?.market_price?.rh_jpy)}円
                </TextView>
              </View>
            </View>

            <ScrollView style={{ height: "40vh", marginTop: "5pt" }}>
              {invoiceData.invoice_details?.map((item, index) => {
                return (
                  <Package
                    invoice={invoiceData}
                    invoice_detail={item}
                    invoiceDetailIndex={index}
                    key={item.id}
                    use_transfer={use_transfer}
                    onTrash={(invoice_detail) => {
                      props.deleteInvoiceDetail({
                        invoice_id: history?.location?.state?.invoice_id,
                        invoice_detail_id: invoice_detail.id,
                      });
                    }}
                    onGoToDetail={() => {
                      props.saveInvoiceDataWhenGoToProductDetail({
                        invoiceData,
                      });
                    }}
                    amountOnBlur={() => {
                      setInvoiceDataChange((prev) => ({
                        ...prev,
                        type: 1, //type =1 update amount
                        productIndex: index,
                        amount: formatInvoiceDetailAmount(item.amount),
                      }));
                      updateInvoiceWhenOnBlur();
                    }}
                    unitPriceOnBlur={() => {
                      updateInvoiceWhenOnBlur();
                    }}
                  />
                );
              })}
            </ScrollView>
            <View style={{ flexDirection: "row", paddingTop: 10 }}>
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View>
                    <Text style={styles.cellText}>小 計（税別）</Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <TextInput
                      style={styles.textInput}
                      value={invoiceData?.price}
                    />
                  </View>
                  <Text style={[styles.cellText, { marginLeft: "1pt" }]}>
                    円
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 5,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View>
                    <Text style={[styles.cellText, { marginRight: 52 }]}>
                      消費税
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <TextInput
                      style={styles.textInput}
                      value={invoiceData?.tax}
                    />
                  </View>
                  <Text style={[styles.cellText, { marginLeft: "1pt" }]}>
                    円
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, paddingLeft: 10 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "flex-end",
                    alignSelf: "stretch",
                  }}
                >
                  <View>
                    <Text style={[styles.cellText, { fontSize: 20 }]}>
                      合計
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <TextInput
                      style={styles.textInput}
                      value={invoiceData?.total_price}
                    />
                  </View>
                  <Text
                    style={[
                      styles.cellText,
                      { marginLeft: "3pt", fontSize: 20 },
                    ]}
                  >
                    円
                  </Text>
                </View>
              </View>
            </View>
          </div>
          <View style={{ flexDirection: "row", paddingVertical: 32 }}>
            <TouchableOpacity
              style={[styles.button, { marginRight: 10 }]}
              onPress={() => submitPreview()}
            >
              <Text
                style={{ color: "white", fontWeight: "bold", fontSize: 16 }}
              >
                仕切書プレビュー
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => finishPurchase()}
              style={styles.button}
            >
              <Text
                style={{ color: "white", fontWeight: "bold", fontSize: 16 }}
              >
                未入力で査定を終了
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
};

const EmptyCartView = ({ navigate }) => {
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>現在査定中の取引はありません。</Text>
      </View>
      <View style={{ flexDirection: "row", paddingVertical: 32 }}>
        <TouchableOpacity
          style={[styles.button, { marginRight: 10 }]}
          onPress={() => {
            // TODO: navigate to  [002-002 / 過去の仕切書]
            navigate("past-dividers", {
              title: "仕切書一覧",
              type: ACCOUNT_PREVIOUS_VOUCHER_TYPE.INVOICE,
              from_date: "",
              to_date: "",
              customer_name: "",
            });
          }}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            仕切書一覧
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigate("");
          }}
          style={styles.button}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            TOPへ
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const FinishPurchaseView = ({ navigate }) => {
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{ flex: 1, justifyContent: "flex-end", alignItems: "center" }}
      >
        <Block
          borderWidth="2pt"
          borderColor={Colors.SecondPrimary}
          paddingTop="5pt"
          paddingBottom="5pt"
          marginTop="40pt"
          marginLeft="30pt"
          marginRight="30pt"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Text
            style={{ color: Colors.Primary, fontWeight: "bold", padding: 5 }}
          >
            査定を完了しました。
          </Text>
        </Block>
      </View>
      <View
        style={{
          flexDirection: "row",
          paddingVertical: 32,
          paddingHorizontal: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigate("");
          }}
          style={styles.button}
        >
          <Text style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>
            TOPへ
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingBottom: "20pt",
    paddingHorizontal: "24pt",
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: "15pt",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "10pt",
    fontSize: "18pt",
  },
  description: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "13pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 3,
  },
  cellText: {
    fontWeight: "500",
    color: Colors.Primary,
    fontSize: "12pt",
  },

  textInput: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
    textAlign: "right",
    fontSize: "12pt",
  },
  button: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "10pt",
    alignItems: "center",
    borderRadius: 5,
  },
});

const mapStateToProps = (state) => ({
  isGettingInvoice: state.invoice.isGettingInvoice,
  isGotInvoice: state.invoice.isGotInvoice,
  invoice: state.invoice.invoice,

  isUpdatingInvoice: state.invoice.isUpdatingInvoice,
  isUpdatedInvoice: state.invoice.isUpdatedInvoice,
  updatedInvoice: state.invoice.updatedInvoice,

  taxRate: state.general.taxRate,

  isDeletingInvoiceDetail: state.invoice.isDeletingInvoiceDetail,
  isDeletedInvoiceDetail: state.invoice.isDeletedInvoiceDetail,
  invoiceWhichDeletedInvoiceDetail:
    state.invoice.invoiceWhichDeletedInvoiceDetail,

  invoiceDataWhenGoToProductDetail:
    state.invoice.invoiceDataWhenGoToProductDetail,
});
const mapDispatchToProps = {
  getInvoice,
  updateInvoice,
  getTaxRate,
  deleteInvoiceDetail,
  uploadPdf,
  saveInvoiceDataWhenGoToProductDetail,
  checkCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
