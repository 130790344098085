import React, { useEffect, useState } from "react";
import {
  Block,
  Empty,
  TextView,
  FooterButton,
  WrapperBlock,
  Button,
  CheckBox,
} from "components";
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Dimensions,
  ScrollView,
  Text,
} from "react-native";

import { commonIcons } from "assets/icons";
import { TextItem } from "components/quotation-review";
import { useLocation } from "react-router-dom";
import { PreviewItem } from "components/partition-preview";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { usePrevious } from "../../utils/hook";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
import images from "assets/images";
import {
  ESTIMATE_SEND_TYPE,
  ESTIMATE_STATUS,
  ESTIMATE_PIC_ATTACH,
  QUOTATION_FLOW,
} from "../../utils/Constant";
import {
  getEstimate,
  updateEstimate,
  uploadPdf,
  copyEstimate,
} from "../../redux/actions/estimate";
import { useNavigate, useNavigateReplace } from "utils";
import Title from "components/common/Title";
import { Colors, isAmountGreaterThanZero } from "utils";
import { Alert } from "../../App";

const { height, width } = Dimensions.get("window");
const HEIGHT_IMAGE = height / 7;

/**
 * 003-010-1 / 見積書プレビュー（画像なし）2 (this is for review from past-dividers)
 */
const EstimateReview = (props) => {
  const navigate = useNavigate();
  const navigateReplace = useNavigateReplace();
  const [sortedEstimateDetail, setSortedEstimateDetail] = useState(undefined);
  const location = useLocation();
  const { estimate_id } = location?.state;
  const [sortAscending, setSortAscending] = useState({
    sortCode: true,
    sortName: true,
    sortUnitPrice: true,
    sortMaker: true,
  });
  const setSortAscendingValue = (key, value) => {
    setSortAscending((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    props.getEstimate({
      estimate_id: estimate_id,
    });
  }, []);

  const prevGettingEstimate = usePrevious(props.isGettingEstimate);
  useEffect(() => {
    if (prevGettingEstimate && !props.isGettingEstimate) {
      if (props.isGotEstimate) {
        const data = props.estimate?.estimate_details;

        sortDataHandler(2, data);
      }
    }
  }, [props.isGettingEstimate]);

  const goBackToPurchaseSupplier = () => {
    navigateReplace("purchase-supplier", {
      choose_estimate_from_estimate_review: props.estimate,
    });
  };

  const sortDataHandler = (noImageSortType, sortedEstimateDetail) => {
    const data1 = sortedEstimateDetail.sort((a, b) => {
      if (noImageSortType === 1) {
        //コード順: Sort by Product Code
        setSortAscendingValue("sortCode", !sortAscending.sortCode);

        if (a?.product_info?.id > b?.product_info?.id)
          return !sortAscending.sortCode ? 1 : -1;
        if (a?.product_info?.id < b?.product_info?.id)
          return !sortAscending.sortCode ? -1 : 1;
        return 0;
      } else if (noImageSortType === 2) {
        //品名順: Sort by Product Name
        setSortAscendingValue("sortName", !sortAscending.sortName);
        if (a?.name > b?.name) return !sortAscending.sortName ? 1 : -1;
        if (a?.name < b?.name) return !sortAscending.sortName ? -1 : 1;
        return 0;
      } else if (noImageSortType === 3) {
        //単価順: Sort by Product Price
        setSortAscendingValue("sortUnitPrice", !sortAscending.sortUnitPrice);
        if (a?.unit_price > b?.unit_price)
          return !sortAscending.sortUnitPrice ? 1 : -1;
        if (a?.unit_price < b?.unit_price)
          return !sortAscending.sortUnitPrice ? -1 : 1;
        return 0;
      } else if (noImageSortType === 4) {
        //Maker: Sort by Maker
        const aa =
          a?.product_info?.maker_info?.name !== undefined
            ? a?.product_info?.maker_info?.name
            : "";
        const bb =
          b?.product_info?.maker_info?.name !== undefined
            ? b?.product_info?.maker_info?.name
            : "";
        setSortAscendingValue("sortMaker", !sortAscending.sortMaker);
        if (aa > bb) return !sortAscending.sortMaker ? 1 : -1;
        if (aa < bb) return !sortAscending.sortMaker ? -1 : 1;
        return 0;
      }
    });

    const haveImageData = data1.filter(
      (item) => item.pic_attach === ESTIMATE_PIC_ATTACH.ATTACHED
    );
    const noImageData = data1.filter(
      (item) => item.pic_attach === ESTIMATE_PIC_ATTACH.NO_ATTACH
    );
    const sortDataWithImageShowFirst = [...haveImageData, ...noImageData];
    setSortedEstimateDetail(sortDataWithImageShowFirst);
  };

  const showCopyDialog = () => {
    Alert.alert("", "見積書をコピーしますか？", [
      {
        label: "はい",
        primary: true,
        onPress: () => {
          Alert.hideModal();
          props.copyEstimate({ estimate_id: estimate_id });
        },
      },
      {
        label: "いいえ",
        primary: false,
        onPress: () => {
          Alert.hideModal();
        },
      },
    ]);
  };

  const prevCopyingEstimate = usePrevious(props.isCopyingEstimate);
  useEffect(() => {
    if (prevCopyingEstimate && !props.isCopyingEstimate) {
      if (props.isCopiedEstimate) {
        navigate("quotation-list", {
          estimate_id: props.copiedEstimate.id,
        });
      }
    }
  }, [props.isCopyingEstimate]);

  return (
    <WrapperBlock>
      <Title backTitle={"見積書一覧"} title="見積書プレビュー" />
      <Block block style={{ marginBottom: "30pt" }}>
        <View style={{ flex: 1 }}>
          <Block block paddingTop="15pt">
            <Block>
              <TouchableOpacity
                onPress={() => {
                  showCopyDialog();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  padding: 5,
                  position: "absolute",
                  right: 0,
                  marginEnd: "",
                }}
              >
                <Text style={{ color: Colors.White }}>コピー</Text>
              </TouchableOpacity>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                取引日：{formatDateTime(props.estimate?.date)}
              </TextView>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                取引先名：{props.estimate?.customer_info?.name} {"様"}
              </TextView>
              <TextView
                size="13pt"
                color={Colors.Primary}
                bold
                lineHeight="18pt"
              >
                担当者：{props.estimate?.account_info?.name}
              </TextView>
              <Block
                marginTop="10pt"
                borderBottomWidth="1pt"
                borderBottomColor={Colors.Primary}
                marginBottom="5pt"
              >
                <TextView
                  size="8pt"
                  color={Colors.Primary}
                  bold
                  lineHeight="18pt"
                >
                  見積書コード：{props.estimate?.code}
                </TextView>
              </Block>
            </Block>

            <Block block>
              <FlatList
                style={{ marginTop: 15 }}
                extraData={sortedEstimateDetail}
                data={sortedEstimateDetail}
                keyExtractor={(item, index) => `${index}`}
                renderItem={renderItem}
              />
            </Block>
          </Block>
        </View>
      </Block>
      <Block>
        <FooterButton
          label=""
          button1="選  択"
          onPress1={() => goBackToPurchaseSupplier()}
        />
      </Block>
    </WrapperBlock>
  );
};

const styles = StyleSheet.create({
  radio: {
    flexDirection: "row",
  },
});

const mapStateToProps = (state) => ({
  isGettingEstimate: state.estimate.isGettingEstimate,
  isGotEstimate: state.estimate.isGotEstimate,
  estimate: state.estimate.estimate,
  isUpdatingEstimate: state.estimate.isUpdatingEstimate,
  isUpdatedEstimate: state.estimate.isUpdatedEstimate,
  updatedEstimate: state.estimate.updatedEstimate,

  isUploadingEstimatePdf: state.estimate.isUploadingEstimatePdf,
  isUploadedEstimatePdf: state.estimate.isUploadedEstimatePdf,
  estimateWhichUploadPdf: state.estimate.estimateWhichUploadPdf,

  isCopyingEstimate: state.estimate.isCopyingEstimate,
  isCopiedEstimate: state.estimate.isCopiedEstimate,
  copiedEstimate: state.estimate.copiedEstimate,
});
const mapDispatchToProps = {
  getEstimate,
  updateEstimate,
  uploadPdf,
  copyEstimate,
  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstimateReview);

const renderItem = ({ item, index }) => {
  const { pic_path } = item.product_info;
  return (
    <Block width="100%">
      {item.pic_attach == ESTIMATE_PIC_ATTACH.ATTACHED && (
        <Image
          source={{
            uri:
              pic_path == undefined || pic_path == ""
                ? images.no_image
                : pic_path,
          }}
          style={{ height: "80vw", width: "100%" }}
          resizeMode="contain"
        />
      )}
      <TextItem
        value1={item.product_info?.code}
        value2={item.product_info?.maker_info?.name}
        value3={item.name}
        value4={renderUnitPrice(item)}
      />
    </Block>
  );
};

const renderUnitPrice = (estimateDetail) => {
  if (
    isAmountGreaterThanZero(estimateDetail?.unit_price) &&
    isAmountGreaterThanZero(estimateDetail?.unit_price2)
  ) {
    return (
      formatMoney(estimateDetail.unit_price2) +
      " ~ " +
      formatMoney(estimateDetail.unit_price) +
      "円"
    );
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price)) {
    return formatMoney(estimateDetail.unit_price) + "円";
  } else if (isAmountGreaterThanZero(estimateDetail?.unit_price2)) {
    return formatMoney(estimateDetail.unit_price2) + "円";
  } else {
    return formatMoney(estimateDetail.unit_price) + "円";
  }
};
