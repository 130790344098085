import React from "react";
import Block from "components/common/Block";
import TextView from "components/common/TextView";
import { Colors } from "utils";
import Button from "components/common/Button";

const ItemSearch = ({ data, onPress }) => {
  return (
    <Block
      direction="row"
      paddingHorizontal="10pt"
      paddingVertical="8pt"
      borderBottomWidth="0.5pt"
      borderBottomColor={`${Colors.SecondPrimary}50`}
    >
      <Block block>
        <TextView size="10pt" bold color={Colors.Primary}>
          {data?.name}
        </TextView>
      </Block>
      <Button
        {...{ onPress }}
        paddingHorizontal="5pt"
        paddingVertical="3pt"
        color={Colors.SecondPrimary}
      >
        <TextView size="10pt" regular color={Colors.White}>
          {"追 加"}
        </TextView>
      </Button>
    </Block>
  );
};
ItemSearch.defaultProps = {
  title: "ビートル AZJ【7041】",
  labelButton: "追 加",
  onPress: () => true,
};
export default ItemSearch;
