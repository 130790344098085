import { all, call, spawn } from "redux-saga/effects";

import watchAuth from "./auth";
import watchHome from "./home";
import watchInvoice from "./invoice";
import watchProduct from "./product";
import watchTransfer from "./transfer";
import watchGeneral from "./general";
import watchEstimate from "./estimate";
import watchCustomer from "./customer";
import watchAccount from "./account";

export default function* sagas() {
  const sagas = [
    watchAuth,
    watchHome,
    watchInvoice,
    watchProduct,
    watchTransfer,
    watchGeneral,
    watchEstimate,
    watchCustomer,
    watchAccount,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
