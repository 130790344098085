import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Colors } from "utils";
import { formatMoney } from "../../utils/MoneyFormatter";

const TransferView = ({ transferAmount }) => {
  return (
    <View style={{ flexDirection: "row", marginTop: 18 }}>
      <View style={styles.container}>
        <Text style={[styles.smallText, { fontWeight: "bold" }]}>事前振込</Text>
        <View style={[styles.row, styles.value]}>
          <Text style={[styles.amountText, { paddingRight: "5pt" }]}>
            {formatMoney(transferAmount)}
          </Text>
          <Text style={[styles.smallText]}>円</Text>
        </View>
      </View>
      <View style={{ flex: 1 }}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  smallText: {
    fontSize: "12pt",
    color: Colors.Primary,
  },
  value: { alignItems: "center", marginRight: "5pt" },
  amountText: {
    fontSize: 18,
    color: Colors.Primary,
    fontWeight: "bold",
  },
});

export default TransferView;
