import axios from "axios";

import { takeEvery, put } from "redux-saga/effects";
import { GET_TRANSFER_CUSTOMERS, UPDATE_TRANSFER, CREATE_TRANSFER, SEARCH_TRANSFER } from "../actions/transfer";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* getTransferCustomers(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.TRANSFER.GET_TRANSFER_CUSTOMERS, params)
  );
  if (result.success) {
    yield put({
      type: GET_TRANSFER_CUSTOMERS.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: GET_TRANSFER_CUSTOMERS.FAIL,
      result,
    });
  }
}

function* updateTransfer(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.TRANSFER.UPDATE_TRANSFER, params);
  if (result.success) {
    yield put({
      type: UPDATE_TRANSFER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: UPDATE_TRANSFER.FAIL,
      result,
    });
  }
}


function* createTransfer(obj) {
  const { params = {} } = obj;
  const result = yield axiosPost(API.TRANSFER.CREATE_TRANSFER, params);
  if (result.success) {
    yield put({
      type: CREATE_TRANSFER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: CREATE_TRANSFER.FAIL,
      result,
    });
  }
}



function* searchTransfer(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(replaceParams(API.TRANSFER.SEARCH_TRANSFER, params));
  if (result.success) {
    yield put({
      type: SEARCH_TRANSFER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: SEARCH_TRANSFER.FAIL,
      result,
    });
  }
}


export default function* () {
  yield takeEvery(GET_TRANSFER_CUSTOMERS.REQUEST, getTransferCustomers);
  yield takeEvery(UPDATE_TRANSFER.REQUEST, updateTransfer);
  yield takeEvery(CREATE_TRANSFER.REQUEST, createTransfer);
  yield takeEvery(SEARCH_TRANSFER.REQUEST, searchTransfer);
}
