import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
// import Back from "components/common/Back";
import { Colors, nor } from "utils";
// import Package from "components/cart/Package";
// import { useNavigate, useNavigateReplace } from "utils";
// import { Block } from "components";
// import { useHistory } from "react-router-dom";
import { formatMoney, MoneyFormatter } from "../../utils/MoneyFormatter";
import { connect } from "react-redux";
// import {
//   getOtherInvoice,
//   getLastInvoice,
//   updateInvoice,
// } from "../../redux/actions/invoice";
// import { getSetting } from "../../redux/actions/general";
import { formatDateTime, DateTimeFormatter } from "../../utils/DateUtility";
// import { usePrevious } from "../../utils/hook";

const OtherInvoiceRecord = ({ otherInvoice, invoice_market_price }) => {
  const renderPriceColorText = (currentPrice, prevPrice) => {
    if (currentPrice > prevPrice) {
      return styles.redText;
    } else {
      return styles.lightBlueText;
    }
  };

  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderStyle: "dashed",
        borderBottomColor: Colors.SecondPrimary,
        paddingBottom: 5,
      }}
    >
      <Text style={styles.contentHeader}>
        他社仕入実績：{otherInvoice?.customer_info?.name}
      </Text>
      <View style={styles.row}>
        <View style={[styles.rowFlex, { flex: 1.1 }]}>
          <Text style={styles.contentBadge}>他社仕入単価</Text>
          <Text style={styles.price}>{otherInvoice?.price}円</Text>
        </View>
        <View style={styles.rowFlex}>
          <Text style={styles.contentBadge}>仕入日</Text>
          <Text style={styles.date}>{formatDateTime(otherInvoice?.date)}</Text>
        </View>
      </View>
      <View style={[styles.row, { justifyContent: "space-between" }]}>
        <Text style={styles.contentBadge}>仕入時相場</Text>
        <Text style={[styles.contentBadge, styles.badgeSmall]}>PT</Text>
        <Text
          style={renderPriceColorText(
            invoice_market_price?.pt_jpy,
            otherInvoice?.market_price?.pt_jpy
          )}
        >
          {formatMoney(otherInvoice?.market_price?.pt_jpy)}円
        </Text>
        <Text style={[styles.contentBadge, styles.badgeSmall]}>PD</Text>
        <Text
          style={renderPriceColorText(
            invoice_market_price?.pd_jpy,
            otherInvoice?.market_price?.pd_jpy
          )}
        >
          {formatMoney(otherInvoice?.market_price?.pd_jpy)}円
        </Text>
        <Text style={[styles.contentBadge, styles.badgeSmall]}>RH</Text>
        <Text
          style={renderPriceColorText(
            invoice_market_price?.rh_jpy,
            otherInvoice?.market_price?.rh_jpy
          )}
        >
          {formatMoney(otherInvoice?.market_price?.rh_jpy)}円
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrap: {
    marginTop: 10,
    borderColor: Colors.PrimaryLight,
    borderWidth: 1,
    borderRadius: 5,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: Colors.WhiteDark03,
    paddingVertical: nor(15),
  },
  titleWrap2: {
    flexDirection: "row",
    paddingVertical: "10pt",
    paddingHorizontal: "10pt",
    borderBottomColor: Colors.PrimaryLight,
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  title: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginLeft: "10pt",
    fontSize: "16pt",
  },
  description: {
    color: Colors.Primary,
    marginBottom: "5pt",
    fontWeight: "bold",
    fontSize: "12pt",
  },
  cell: {
    flex: 1,
    backgroundColor: Colors.PrimaryLight2,
    alignItems: "center",
    paddingVertical: 5,
  },
  cellText: {
    fontWeight: "bold",
    color: Colors.Primary,
  },
  textInput: {
    flex: 1,
    padding: "5pt",
    marginLeft: 5,
    backgroundColor: Colors.PrimaryLight2,
  },
  button: {
    flex: 1,
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    borderRadius: 5,
  },
  headerText: {
    color: Colors.Primary,
    fontWeight: "bold",
    fontSize: "10pt",
  },
  headerBadge: {
    color: "white",
    backgroundColor: Colors.Primary,
    paddingVertical: "2pt",
    paddingHorizontal: 10,
    minWidth: 100,
    textAlign: "center",
    marginRight: "10pt",
    fontSize: "8pt",
  },
  badgeSmall: {
    minWidth: 0,
    fontSize: "8pt",
    alignSelf: "center",
  },
  rowFlex: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: "3pt",
  },
  redText: {
    color: "#D90000",
    fontWeight: "bold",
    fontSize: "10pt",
  },
  contentHeader: {
    color: Colors.Primary,
    fontWeight: "bold",
    marginVertical: "3pt",
    fontSize: "11pt",
  },
  contentBadge: {
    color: "white",
    backgroundColor: Colors.SecondPrimary,
    paddingVertical: "2pt",
    paddingHorizontal: "8pt",
    textAlign: "center",
    marginRight: "7pt",
    fontSize: "10pt",
  },
  price: {
    color: Colors.SecondPrimary,
    fontSize: "10pt",
    fontWeight: "bold",
  },
  date: {
    color: Colors.SecondPrimary,
    fontSize: "12pt",
    fontWeight: "bold",
  },
  lightBlueText: {
    color: "#00CBDC",
    fontWeight: "bold",
    fontSize: "10pt",
  },
  borderBottom: {
    borderBottomColor: Colors.PrimaryLight,
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderStyle: "dashed",
  },
});

const mapStateToProps = (state) => ({
  //   twoLastInvoice: state.invoice.twoLastInvoice,
  //   setting: state.general.setting,
  //   otherInvoice: state.invoice.otherInvoice,
  //   lastInvoice: state.invoice.lastInvoice,
  //   isUpdatingInvoice: state.invoice.isUpdatingInvoice,
  //   isUpdatedInvoice: state.invoice.isUpdatedInvoice,
  //   updatedInvoice: state.invoice.updatedInvoice,
});
const mapDispatchToProps = {
  //   getSetting,
  //   getOtherInvoice,
  //   getLastInvoice,
  //   updateInvoice,
  // insertProductsToInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherInvoiceRecord);
