import axios from "axios";

import { takeEvery, put } from "redux-saga/effects";
import {
  SEARCH_CUSTOMERS,
  SEARCH_CUSTOMER_TRANSFER,
  GET_CUSTOMER_TRANSFER,
} from "../actions/customer";
import { axiosGet, axiosPost, replaceParams } from "./utils";
import { API } from "../../configs/api";

function* searchCustomers(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.CUSTOMER.SEARCH_CUSTOMERS, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_CUSTOMERS.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: SEARCH_CUSTOMERS.FAIL,
      result,
    });
  }
}

function* searchCustomerTransfer(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.CUSTOMER.SEARCH_CUSTOMER_TRANSFER, params)
  );
  if (result.success) {
    yield put({
      type: SEARCH_CUSTOMER_TRANSFER.SUCCESS,
      result: result.data.data, // model account
    });
  } else {
    yield put({
      type: SEARCH_CUSTOMER_TRANSFER.FAIL,
      result,
    });
  }
}

function* getCustomerTransfer(obj) {
  const { params = {} } = obj;
  const result = yield axiosGet(
    replaceParams(API.CUSTOMER.GET_CUSTOMER_TRANSFER, params)
  );
  if (result.success) {
    yield put({
      type: GET_CUSTOMER_TRANSFER.SUCCESS,
      result: result.data.data,
    });
  } else {
    yield put({
      type: GET_CUSTOMER_TRANSFER.FAIL,
      result,
    });
  }
}

export default function* () {
  yield takeEvery(SEARCH_CUSTOMERS.REQUEST, searchCustomers);
  yield takeEvery(SEARCH_CUSTOMER_TRANSFER.REQUEST, searchCustomerTransfer);
  yield takeEvery(GET_CUSTOMER_TRANSFER.REQUEST, getCustomerTransfer);
}
