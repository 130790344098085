import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Colors } from "utils";
import Block from "components/common/Block";

const Footer = ({ style, onPress1, onPress2 }) => {
  return (
    <Block width={"100%"} middle {...{ style }}>
      <TouchableOpacity onPress={onPress1} style={styles.btn2}>
        <Text style={styles.text}>仕入査定</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onPress2} style={styles.btn2}>
        <Text style={styles.text}>見積作成</Text>
      </TouchableOpacity>
    </Block>
  );
};

export default Footer;

const styles = StyleSheet.create({
  purchase: {
    fontSize: "20pt",
    color: Colors.White,
  },
  text: {
    fontSize: "20pt",
    color: Colors.White,
  },
  btn2: {
    width: "100%",
    paddingVertical: "7pt",
    backgroundColor: Colors.SecondPrimary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5pt",
    marginTop: "5pt",
  },
});
