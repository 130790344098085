import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useLocation } from 'react-router-dom';

import Tab from './Tab';
import { bottomIcons } from 'assets/icons';

const BottomTabs = () => {
  const location = useLocation();
  const [showTabs, setShowTabs] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if (/\/login/.test(location.pathname)) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
    if (/^\/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [location]);

  return (
    showTabs && (
      <View style={styles.tabWrap}>
        {(isAdmin ? ADMIN_TABS : TABS).map((item, index) => (
          <Tab
            key={`tab_${index}`}
            icon={item.icon}
            label={item.label}
            path={item.path}
          />
        ))}
      </View>
    )
  );
};

const TABS = [
  {
    icon: bottomIcons.bell,
    label: 'お知らせ',
    path: '/notice',
  },
  {
    icon: bottomIcons.file,
    label: '製品カタログ',
    path: '/catalog',
  },
  {
    icon: bottomIcons.document,
    label: '過去実績',
    path: '/performance',
  },
  {
    icon: bottomIcons.currency,
    label: '振込依頼',
    path: '/transfer-request',
  },
];
const ADMIN_TABS = [
  {
    icon: bottomIcons.bell,
    label: 'お知らせ',
    path: '/admin/notice',
  },
  {
    icon: bottomIcons.file,
    label: '製品カタログ',
    path: '/admin/catalog',
  },
  {
    icon: bottomIcons.document,
    label: '過去実績',
    path: '/admin/performance',
  },
  {
    icon: bottomIcons.currency,
    label: '振込依頼',
    path: '/admin/transfer-request',
  },
  {
    icon: bottomIcons.settings,
    label: '設  定',
    path: '/admin/settings',
  },
];

const styles = StyleSheet.create({
  tabWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#008988',
    paddingBottom: '10pt',
  },
});

export default BottomTabs;
